module.exports = [
   {
     "anonymous": false,
     "inputs": [
       {
         "indexed": true,
         "internalType": "uint256",
         "name": "_poolId",
         "type": "uint256"
       }
     ],
     "name": "CancelPool",
     "type": "event"
   },
   {
     "anonymous": false,
     "inputs": [
       {
         "indexed": true,
         "internalType": "uint256",
         "name": "poolId",
         "type": "uint256"
       },
       {
         "indexed": false,
         "internalType": "uint256",
         "name": "_game",
         "type": "uint256"
       },
       {
         "indexed": false,
         "internalType": "uint256",
         "name": "_gameMode",
         "type": "uint256"
       },
       {
         "indexed": false,
         "internalType": "uint256",
         "name": "_currentPrize",
         "type": "uint256"
       },
       {
         "indexed": false,
         "internalType": "uint256",
         "name": "_entryFee",
         "type": "uint256"
       },
       {
         "indexed": false,
         "internalType": "uint256",
         "name": "_startOn",
         "type": "uint256"
       },
       {
         "indexed": false,
         "internalType": "uint256",
         "name": "_finishOn",
         "type": "uint256"
       },
       {
         "indexed": false,
         "internalType": "address",
         "name": "_tokenAddr",
         "type": "address"
       }
     ],
     "name": "NewPool",
     "type": "event"
   },
   {
     "anonymous": false,
     "inputs": [
       {
         "indexed": true,
         "internalType": "address",
         "name": "previousOwner",
         "type": "address"
       },
       {
         "indexed": true,
         "internalType": "address",
         "name": "newOwner",
         "type": "address"
       }
     ],
     "name": "OwnershipTransferred",
     "type": "event"
   },
   {
     "anonymous": false,
     "inputs": [
       {
         "indexed": true,
         "internalType": "uint256",
         "name": "_poolId",
         "type": "uint256"
       },
       {
         "indexed": false,
         "internalType": "address",
         "name": "_winner",
         "type": "address"
       }
     ],
     "name": "PoolFinished",
     "type": "event"
   },
   {
     "anonymous": false,
     "inputs": [
       {
         "indexed": true,
         "internalType": "uint256",
         "name": "_poolId",
         "type": "uint256"
       },
       {
         "indexed": false,
         "internalType": "address",
         "name": "_player",
         "type": "address"
       }
     ],
     "name": "PoolJoinedBy",
     "type": "event"
   },
   {
     "anonymous": false,
     "inputs": [
       {
         "indexed": true,
         "internalType": "uint256",
         "name": "_poolId",
         "type": "uint256"
       },
       {
         "indexed": false,
         "internalType": "address",
         "name": "_player",
         "type": "address"
       }
     ],
     "name": "PoolLeftBy",
     "type": "event"
   },
   {
     "anonymous": false,
     "inputs": [
       {
         "indexed": true,
         "internalType": "uint256",
         "name": "_poolId",
         "type": "uint256"
       },
       {
         "indexed": false,
         "internalType": "address",
         "name": "_winnerAddress",
         "type": "address"
       },
       {
         "indexed": false,
         "internalType": "uint8",
         "name": "_position",
         "type": "uint8"
       }
     ],
     "name": "PrizeClaimFinished",
     "type": "event"
   },
   {
     "inputs": [],
     "name": "bbFee",
     "outputs": [
       {
         "internalType": "uint256",
         "name": "",
         "type": "uint256"
       }
     ],
     "stateMutability": "view",
     "type": "function"
   },
   {
     "inputs": [
       {
         "internalType": "uint256",
         "name": "_poolId",
         "type": "uint256"
       }
     ],
     "name": "cancelPool",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
   },
   {
     "inputs": [
       {
         "internalType": "uint256",
         "name": "_poolId",
         "type": "uint256"
       },
       {
         "internalType": "address payable",
         "name": "_winnerAddress",
         "type": "address"
       },
       {
         "internalType": "uint8",
         "name": "_position",
         "type": "uint8"
       }
     ],
     "name": "claimPrize",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
   },
   {
     "inputs": [
       {
         "internalType": "uint8",
         "name": "_game",
         "type": "uint8"
       },
       {
         "internalType": "uint8",
         "name": "_gameMode",
         "type": "uint8"
       },
       {
         "internalType": "uint256",
         "name": "_currentPrize",
         "type": "uint256"
       },
       {
         "internalType": "uint256",
         "name": "_entryFee",
         "type": "uint256"
       },
       {
         "internalType": "uint256",
         "name": "_startOn",
         "type": "uint256"
       },
       {
         "internalType": "uint256",
         "name": "_finishOn",
         "type": "uint256"
       },
       {
         "internalType": "uint16[]",
         "name": "_winnersFees",
         "type": "uint16[]"
       },
       {
         "internalType": "address",
         "name": "_tokenAddr",
         "type": "address"
       }
     ],
     "name": "createPool",
     "outputs": [
       {
         "internalType": "uint256",
         "name": "",
         "type": "uint256"
       }
     ],
     "stateMutability": "payable",
     "type": "function"
   },
   {
     "inputs": [
       {
         "internalType": "uint256",
         "name": "_poolId",
         "type": "uint256"
       },
       {
         "internalType": "address[]",
         "name": "_winners",
         "type": "address[]"
       }
     ],
     "name": "finishPool",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
   },
   {
     "inputs": [
       {
         "internalType": "uint256",
         "name": "_poolId",
         "type": "uint256"
       },
       {
         "internalType": "uint8",
         "name": "_position",
         "type": "uint8"
       }
     ],
     "name": "getPoolPaidPosition",
     "outputs": [
       {
         "internalType": "address",
         "name": "_address",
         "type": "address"
       }
     ],
     "stateMutability": "view",
     "type": "function"
   },
   {
     "inputs": [],
     "name": "initialize",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
   },
   {
     "inputs": [
       {
         "internalType": "uint256",
         "name": "_poolId",
         "type": "uint256"
       }
     ],
     "name": "joinPool",
     "outputs": [],
     "stateMutability": "payable",
     "type": "function"
   },
   {
     "inputs": [
       {
         "internalType": "uint256",
         "name": "_poolId",
         "type": "uint256"
       }
     ],
     "name": "leavePool",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
   },
   {
     "inputs": [],
     "name": "owner",
     "outputs": [
       {
         "internalType": "address",
         "name": "",
         "type": "address"
       }
     ],
     "stateMutability": "view",
     "type": "function"
   },
   {
     "inputs": [],
     "name": "poolCount",
     "outputs": [
       {
         "internalType": "uint256",
         "name": "",
         "type": "uint256"
       }
     ],
     "stateMutability": "view",
     "type": "function"
   },
   {
     "inputs": [
       {
         "internalType": "uint256",
         "name": "",
         "type": "uint256"
       }
     ],
     "name": "pools",
     "outputs": [
       {
         "internalType": "uint8",
         "name": "game",
         "type": "uint8"
       },
       {
         "internalType": "uint8",
         "name": "gameMode",
         "type": "uint8"
       },
       {
         "internalType": "uint8",
         "name": "firstFee",
         "type": "uint8"
       },
       {
         "internalType": "uint8",
         "name": "secondFee",
         "type": "uint8"
       },
       {
         "internalType": "uint8",
         "name": "thirdFee",
         "type": "uint8"
       },
       {
         "internalType": "bool",
         "name": "finished",
         "type": "bool"
       },
       {
         "internalType": "uint256",
         "name": "currentPrize",
         "type": "uint256"
       },
       {
         "internalType": "uint256",
         "name": "entryFee",
         "type": "uint256"
       },
       {
         "internalType": "address",
         "name": "winner",
         "type": "address"
       },
       {
         "internalType": "uint256",
         "name": "startOn",
         "type": "uint256"
       },
       {
         "internalType": "uint256",
         "name": "finishOn",
         "type": "uint256"
       },
       {
         "internalType": "address",
         "name": "tokenAddr",
         "type": "address"
       },
       {
         "internalType": "uint256",
         "name": "prize",
         "type": "uint256"
       }
     ],
     "stateMutability": "view",
     "type": "function"
   },
   {
     "inputs": [],
     "name": "renounceOwnership",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
   },
   {
     "inputs": [
       {
         "internalType": "address",
         "name": "newOwner",
         "type": "address"
       }
     ],
     "name": "transferOwnership",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
   },
   {
     "inputs": [],
     "name": "treasury",
     "outputs": [
       {
         "internalType": "address payable",
         "name": "",
         "type": "address"
       }
     ],
     "stateMutability": "view",
     "type": "function"
   },
   {
     "inputs": [
       {
         "internalType": "uint256",
         "name": "_newFee",
         "type": "uint256"
       }
     ],
     "name": "updateFee",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
   },
   {
     "inputs": [
       {
         "internalType": "address payable",
         "name": "_newTreasury",
         "type": "address"
       }
     ],
     "name": "updateTreasury",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
   },
   {
     "inputs": [
       {
         "internalType": "address",
         "name": "",
         "type": "address"
       },
       {
         "internalType": "uint256",
         "name": "",
         "type": "uint256"
       }
     ],
     "name": "userPools",
     "outputs": [
       {
         "internalType": "uint256",
         "name": "",
         "type": "uint256"
       }
     ],
     "stateMutability": "view",
     "type": "function"
   },
   {
     "inputs": [],
     "name": "viewActivePools",
     "outputs": [
       {
         "internalType": "uint256[]",
         "name": "_activePools",
         "type": "uint256[]"
       }
     ],
     "stateMutability": "view",
     "type": "function"
   },
   {
     "inputs": [
       {
         "internalType": "uint256",
         "name": "_poolId",
         "type": "uint256"
       }
     ],
     "name": "viewPoolPlayers",
     "outputs": [
       {
         "internalType": "address payable[]",
         "name": "_users",
         "type": "address[]"
       }
     ],
     "stateMutability": "view",
     "type": "function"
   },
   {
     "inputs": [
       {
         "internalType": "address",
         "name": "_addr",
         "type": "address"
       }
     ],
     "name": "viewPools",
     "outputs": [
       {
         "internalType": "uint256[]",
         "name": "_userPools",
         "type": "uint256[]"
       }
     ],
     "stateMutability": "view",
     "type": "function"
   }
 ]