import React, { useEffect, useState } from "react";
import * as S from "./styles";
import Flex from "../../components/v2/common/Flex";
import { SpinnerLoader } from "../../components/v2/common/SpinningLoader";
import moment from "moment-timezone";
import { TextNoMargin } from "../../components/v2/common/Text";
import Fire from "../../assets/fire.png";
import { padString, truncateWithDots } from "../../helpers/utils";

export const TournamentRow = ({ data, active, poolsToDisplay = [] }) => {
  const [timeState, setTimeState] = useState({});
  useEffect(() => {
    if (!active) return;
    const intervalVar = setInterval(() => {
      const currentTime = Date.now();
      let timeLeft;
      if (
        data?.poolStatus === "RUNNING" ||
        data?.poolStatus === "JOINING" ||
        data?.poolStatus === "PROCESSING_REGISTERED_USERS"
      ) {
        // Not using the timeState for any poolStatus other than NOT_STARTED. Keeping this line forfuture reference
        timeLeft = moment.duration(data?.endDate - currentTime);
      } else if (data?.poolStatus === "NOT_STARTED") {
        timeLeft = moment.duration(data?.startDate - currentTime);
      }

      setTimeState(timeLeft);
    }, 1000);

    return () => clearInterval(intervalVar);
  }, [poolsToDisplay]);

  return (
    <S.TournamentRow active={active}>
      <div style={{ display: "flex", alignItems: "center", columnGap: "12px" }}>
        <img src={data?.gameIcon} alt="game icon" style={{ width: "49px", height: "49px" }} />
        <S.RowName>{truncateWithDots(data?.name, 50)}</S.RowName>
      </div>

      <S.RowDate>{data?.startDate ? moment(data?.startDate).format("MMM Do") : "00:00"}</S.RowDate>
      <S.RowDate>{data?.startDate ? moment(data?.startDate).format("hh:mm A") : "00:00"}</S.RowDate>

      <div>
        {data?.entrance ? `${data?.entrance}` : "Free"}
        {data?.entrance && data?.entranceTokenImage ? <S.TokenImg src={data?.entranceTokenImage} /> : null}
      </div>
      <div>
        {data?.poolPrize ? `${data?.poolPrize}` : "0"}
        {data?.poolPrize && data?.tokenImage ? <S.TokenImg src={data?.tokenImage} /> : null}
      </div>
      <div>{data?.users ? `${data?.users?.length}` : "0"} </div>
      <S.PoolButtonContainer poolStatus={data?.poolStatus}>
        <S.FinishButton>
          {active ? (
            // Showing the text "Playing" if status is ACTIVE, JOINING, OR PROCESSING_REGISTERED_USERS
            data?.poolStatus === "RUNNING" ||
            data?.poolStatus === "JOINING" ||
            data?.poolStatus === "PROCESSING_REGISTERED_USERS" ? (
              "Playing"
            ) : (
              <div>
                <img src={Fire} alt="fire" style={{ marginRight: "8px", width: "10px", height: "12px" }} />
                {timeState?._data?.days > 0
                  ? `In ${timeState?._data?.days} days`
                  : `${padString(timeState?._data?.hours, 0) || "00"}:${
                      padString(timeState?._data?.minutes, 0) || "00"
                    }:${padString(timeState?._data?.seconds, 0) || "00"}`}
              </div>
            )
          ) : (
            "Finished"
          )}
        </S.FinishButton>
      </S.PoolButtonContainer>
    </S.TournamentRow>
  );
};

const TournamentRowHeadings = () => {
  return (
    <S.TournamentRowHeadings>
      <div>
        <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
          Game
        </TextNoMargin>{" "}
      </div>
      <div></div>
      <div>
        <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
          Date
        </TextNoMargin>
      </div>
      <div>
        <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
          Time
        </TextNoMargin>
      </div>
      <div>
        <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
          Entry Fees
        </TextNoMargin>
      </div>
      <div>
        <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
          Prize Pool
        </TextNoMargin>
      </div>
      <div>
        <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
          Teams
        </TextNoMargin>
      </div>
      <div />
    </S.TournamentRowHeadings>
  );
};

const TournamentsSection = ({ activePools, allPools }) => {
  const [currentTab, setCurrentTab] = useState("all");
  const [poolsToDisplay, setPoolsToDisplay] = useState(allPools);

  useEffect(() => {
    if (currentTab === "all") {
      setPoolsToDisplay(allPools);
    } else if (currentTab === "active") {
      setPoolsToDisplay(
        allPools.filter(
          pool =>
            pool.poolStatus === "RUNNING" ||
            pool.poolStatus === "NOT_STARTED" ||
            pool.poolStatus === "JOINING" ||
            pool.poolStatus === "PROCESSING_REGISTERED_USERS",
        ),
      );
    } else if (currentTab === "played") {
      setPoolsToDisplay(
        allPools.filter(
          pool =>
            !(
              pool.poolStatus === "RUNNING" ||
              pool.poolStatus === "NOT_STARTED" ||
              pool.poolStatus === "JOINING" ||
              pool.poolStatus === "PROCESSING_REGISTERED_USERS"
            ),
        ),
      );
    }
  }, [currentTab]);

  return (
    <div>
      <S.TabsBar>
        <S.TabButton
          onClick={() => {
            // setPoolsToDisplay([]);
            setCurrentTab("all");
          }}
          border={currentTab === "all" ? "4px solid #3F2FAA" : "4px solid #201366"}
        >
          All
        </S.TabButton>
        <S.TabButton
          onClick={() => {
            // setPoolsToDisplay([]);
            setCurrentTab("active");
          }}
          border={currentTab === "active" ? "4px solid #3F2FAA" : "4px solid #201366"}
        >
          Active
        </S.TabButton>
        <S.TabButton
          onClick={() => {
            // setPoolsToDisplay([]);
            setCurrentTab("played");
          }}
          border={currentTab === "played" ? "4px solid #3F2FAA" : "4px solid #201366"}
        >
          Played
        </S.TabButton>
        {/* <S.TabButton
          onClick={() => {
            // setPoolsToDisplay([]);
            setCurrentTab("won");
          }}
          border={currentTab === "won" ? "4px solid #3F2FAA" : "4px solid #201366"}
        >
          Won
        </S.TabButton> */}
      </S.TabsBar>
      <Flex>
        <TournamentRowHeadings />
      </Flex>
      <Flex direction="column" rowGap="21">
        {poolsToDisplay?.map(pool => (
          <TournamentRow
            data={pool}
            active={
              pool.poolStatus === "RUNNING" ||
              pool.poolStatus === "NOT_STARTED" ||
              pool.poolStatus === "JOINING" ||
              pool.poolStatus === "PROCESSING_REGISTERED_USERS"
            }
            currentTab={currentTab}
            poolsToDisplay={poolsToDisplay}
          />
        ))}
      </Flex>
    </div>
  );
};

export default TournamentsSection;
