import React, { useRef } from "react";
import Loader from "../../Loader";
import Modal from "../../Modal";
import * as S from "./styles";
import { Button } from "../../common/Button";
import { EphereLogo, Tick } from "../../../../assets/svg";
import { EPHERE_REDIRECT_URL } from "../../../../constants/env";

const EphereModal = ({ showModal, toggleModal, isLoading, customErrMsg }) => {
  // 448 - 768

  return (
    <div>
      <Modal
        centerHeader
        showModal={showModal}
        toggleModal={toggleModal}
        customStyles={{ maxHeight: "644px", background: "#342788", minHeight: "548px", width: "687px" }}
        tabletStyles={{ width: "95vw", overflowX: "hidden" }}
        mobileStyles={{ width: "98vw" }}
      >
        <S.errorContentContainer>
          <S.errorColumn>
            <EphereLogo />
            <h3>There was an error connecting to the tournament</h3>
            {customErrMsg ? (
              <p>{customErrMsg}</p>
            ) : (
              <p>
                Team not found. It seems your team doesn't exist.
                {/* Please go to{" "}
                <span style={{ color: "#BE7EFF" }}>bla bla</span> to... */}
              </p>
            )}
            <a href={EPHERE_REDIRECT_URL} target="_blank">
              <Button
                borderRadius="30px"
                background="linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)"
                width="166px"
                height="40px;"
                color="#fff"
                margin="3rem auto 0px auto"
              >
                Go to ephere
              </Button>
            </a>
          </S.errorColumn>
        </S.errorContentContainer>
      </Modal>
    </div>
  );
};

export default EphereModal;
