import { useEffect } from "react";

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      /* This code snippet is checking if the click event occurred inside any popover content, if so it doesn't hide the modal and allows to continue the interaction. */
      const popoverContent = document.querySelectorAll(".ant-popover-content");
      /* This code snippet is iterating over each element in the `popoverContent` NodeList, which contains
      all elements with the class name "ant-popover-content". For each element, it checks if the event
      target (the element that triggered the event) is contained within that specific popover content
      element. */
      for (let content of popoverContent) {
        if (content.contains(event.target)) {
          return;
        }
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
