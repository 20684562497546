import React, { useState } from "react";
import { CloseIcon } from "../../../assets/svg";
// import Loader from "../../../Loader";
import * as S from "./styles";

export default function Modal({
  showModal,
  toggleModal,
  children,
  hideCloseBtn = false,
  customStyles,
  header,
  centerHeader,
  mobileStyles,
  tabletStyles,
  beforeClose,
  afterClose
}) {
  return (
    <>
      <S.ModalContainer
        beforeClose={() => {
          if (beforeClose) {
            beforeClose();
          }
        }}
        afterClose={() => {
          if(afterClose) {
            afterClose();
          }
        }}
        isOpen={showModal}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        styleOverrides={customStyles}
        mobileStyles={mobileStyles}
        tabletStyles={tabletStyles}
      >
        {!hideCloseBtn && (
          <S.CloseIconContainer onClick={toggleModal}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {centerHeader && <div />}
              {header ? <S.ModalHeader>{header}</S.ModalHeader> : <div />}
              <CloseIcon />
            </div>
          </S.CloseIconContainer>
        )}
        {children}
      </S.ModalContainer>
    </>
  );
}
