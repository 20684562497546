import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Gotham";
    color: black;
    margin: 0;
  }

  h1 {
    font-size: 16px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  code {
    font-family: Gotham, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }
`;
