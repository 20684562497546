import { PRICE_API_CACHE_LIVES } from "../constants/env";

export class BlastCache {
  constructor() {
    this.priceCache = JSON.parse(localStorage.getItem("price"));
    if (this.priceCache === null) this.priceCache = {};
  }

  getPrice(from, to) {
    if (
      this.priceCache &&
      this.priceCache[from] &&
      parseFloat(this.priceCache[from][to].updatedTime) + parseFloat(PRICE_API_CACHE_LIVES) > new Date().getTime()
    ) {
      return this.priceCache[from][to].value;
    }
    return null;
  }

  setPrice(from, to, value) {
    if (this.priceCache[from] === undefined) this.priceCache[from] = {};
    this.priceCache[from][to] = {
      updatedTime: new Date().getTime(),
      value,
    };
    localStorage.setItem("price", JSON.stringify(this.priceCache));
  }
}

const blastCache = new BlastCache();
export default blastCache;
