import styled, { keyframes } from "styled-components";
import Modal from "../Modal";
import { breakpoints } from "../../../themes";

export const StyledModal = styled(Modal)``;

export const ModalContainer = styled.div`
  width: 520px;
  height: 432px;
  ${breakpoints.down("690px")} {
    width: calc(100% - 36px);
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 0 16px;
`;

export const MainWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const IconWrapper = styled.div`
  position: relative;
  float: right;
  top: 16px;
  right: 18px;
  z-index: 3;

  :hover {
    cursor: pointer;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.p`
  font-size: 28px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  margin: 0;
  color: #ffffff;
`;

export const StepCounterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 36px;
`;

export const InputSectionWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const FirstStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SecondStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    margin-top: 25px;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #fff;
  }
  p {
    width: 323px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    padding: 0;
    color: #877add;
  }
`;

export const ThirdStepContainer = styled.div`
  height: 130px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const AvatarContainer = styled.div``;

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  margin-bottom: 30px;
`;

export const IconContainer = styled.div`
  color: #fff;
`;

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 30px 0 24px 0;
`;
export const RegisterText = styled.div`
  text-align: center;
  color: #877add;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  a {
    color: #ffffff;
    text-decoration: underline;
  }
`;

export const LoadingIndicatorAnimation = keyframes`
  from { 
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingIndicatorContainer = styled.div`
  width: 100%;
  height: 125px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  animation: ${LoadingIndicatorAnimation} 1s linear infinite;
`;

export const GameTagIdContainer = styled.div`
  margin-top: 20px;
`;

export const logoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  width: 250px;
  height: 42px;

  border: 1px solid #877add;
  border-radius: 10px;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  font-size: 14px;
  box-shadow: none;
  background-color: #302862;
`;

export const loader = styled.span`
    width: 17px;
    height: 17px;
    border: 3px solid #877ADD;
    border-bottom-color: #3A2F81;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
`;

export const errorMessageContainer = styled.div`
  text-align: center;
`;
export const errorMessage = styled.p`
  height: 40px;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: ${props => (props.color ? props.color : "#D06565")};
`;

export const TickContainer = styled.div`
  position: relative;
  margin: auto;
  height: 134px;
  width: 134px;
`;

export const TickArmContainer = styled.div`
  position: absolute;
  display: inline-block;
  transform: rotate(45deg);
  top: 11px;
  left: 67px;
`;

const TickArm1Draw = keyframes`
    0% { opacity: 0; width: 8px; }
    2% { opacity: 1; }
    30% { width: 33px; }
    100% { opacity: 1; width: 33px; }
`;

const TickArm2Draw = keyframes`
    0% { height: 0px; top: 66px; }
    29% { height: 0px; top: 66px; }
    100% { height: 69px; top: 0; }
`;

const Draw = keyframes`
    0% { stroke-dashoffset: 400; stroke-dasharray: 400; }
    100% { stroke-dashoffset: 0; stroke-dasharray: 400; }
`;

export const TickArm1 = styled.div`
  opacity: 0;
  background-color: #2ed06e;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  position: absolute;
  top: 61px;
  left: 19px;
  animation: ${TickArm1Draw} 0.4s 1s cubic-bezier(0.8, 0.2, 0, 0) forwards;
  -webkit-animation: ${TickArm1Draw} 0.4s 1s cubic-bezier(0.8, 0.2, 0, 0) forwards;
`;

export const TickArm2 = styled.div`
  background-color: #2ed06e;
  width: 8px;
  height: 0px;
  border-radius: 4px;
  position: absolute;
  top: 66px;
  left: 44px;
  animation: ${TickArm2Draw} 0.4s 1s cubic-bezier(0.5, 0, 0, 1) forwards;
  -webkit-animation: ${TickArm2Draw} 0.4s 1s cubic-bezier(0.5, 0, 0, 1) forwards;
`;

export const TickCircle = styled.div`
  animation: ${Draw} 0.8s cubic-bezier(0.8, 0.2, 0.2, 1);
  -webkit-animation: ${Draw} 0.8s cubic-bezier(0.8, 0.2, 0.2, 1);
`;

export const InfoContainer = styled.div`
  position: relative;
  top: -29px;
  left: 226px;
`;

export const SecureLogoContainer = styled.div`
  position: relative;
  width: 583px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  bottom: -26px;
  margin-left: 18px;
`;

export const SecondaryButton = styled.span`
  margin-left: 8px;
  cursor: pointer;
  font-size: 0.78rem;
  color: white;
  font-weight: bold;
  text-decoration: underline;
`;

export const SuccessWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 117px;
  margin-bottom: 16px;
`;

export const BigContent = styled.p`
  color: white;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
`;

export const Description = styled.p`
  text-align: center;
  color: #877add;
  font-size: 14px;
  line-height: 20px;
`;

export const CheckboxContainer = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckTerms = styled.p`
  margin-bottom: 0;
  margin-left: 10px;
  width: 213px;
  font-size: 12px;
  font-weight: 400;
  color: #d1ccef;
`;

export const Span = styled.span`
  color: #be7eff;
  text-decoration: underline;
  cursor: pointer;
`;

export const Link = styled.a`
  :hover {
    color: #be7eff;
  }
`;

export const SecureContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Secure = styled.p`
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  margin-bottom: 0;
  margin-left: 10px;
`;
