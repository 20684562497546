import styled, { keyframes } from "styled-components";

export const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

export const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  margin-left: 1em;
`;

export const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

export const Indicator = styled.div`
  width: 34px;
  height: 34px;
  background: #302862;
  position: absolute;
  top: -16px;
  right: 0px;
  border: 1px solid #4a3e9c;
  border-radius: 0.2em;
  border-radius: 200px;
  border: 2px solid #3A2F81;

  ${Input}:not(:disabled):checked & {
    background: #d1d1d1;
    border: 2px solid green;
  }

  ${Label}:hover & {
    background: #3a2f81;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 0.35em;
    left: 0.85em;
    width: 25%;
    height: 50%;
    border: solid;
    border-image: linear-gradient(246.04deg, #12C2EA -25.64%, #FC74FF 124.36%);
    border-image-slice: 1;
    // background-image: linear-gradient(246.04deg, #12C2EA -25.64%, #FC74FF 124.36%);
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    animation-duration: 0.01s;
    animation-fill-mode: forwards;
  }

  &::disabled {
    cursor: not-allowed;
  }
`;
