/* eslint-disable react/require-default-props */
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useUserAddress } from "eth-hooks";
import { useWallet } from "./WalletProvider";
import { callAxios } from "../hooks/Axios";
import { ENDPOINT } from "../constants/endpoints";
import useCallAxios from "../hooks/useAxiosCall";
import { ToastContainer, toast } from "react-toastify";
import Flex from "../components/v2/common/Flex";
import { WarningIcon } from "../assets/svg";
import { TOAST_OPTIONS } from "../constants";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { useGetSecHeaders } from "../hooks/SecurityHeaders";

const AppStateContext = React.createContext();

function AppStateProvider({ children }) {
  const { injectedProvider } = useWallet();
  const address = useUserAddress(injectedProvider);
  // const headers = useGetSecHeaders();
  const appLoading = false;
  // const txPending = processingCount > 0;
  const txPending = false;
  // if (processingCount === 0) {
  //   message.destroy();
  // }

  const locationByHook = useLocation();
  const history = useHistory();

  // Example url: http://localhost:3000/?gclid=CjwKCAiD_BwE/#/?param1=firstparam
  // locationByHook gets the location received from useLocation. It will have the search parameters which come AFTER the hash synmobl.
  // So in the example usl, the locationByHook will give search: "?param1=firstparam"
  // However, window.location will give the search value as ?gclid=CjwKC.....

  useEffect(() => {
    if (!window?.location?.search?.includes("gclid")) return;
    const gclidVal = window?.location?.search?.substring(
      window?.location?.search?.indexOf("?gclid=") + 7,
      window?.location?.search?.length,
    ); // Using 7 to get rid of the text gclid=

    let newRoute = `${window?.origin}/#/?gclid=${gclidVal}&${locationByHook?.search}`;
    window.location = newRoute;
  }, [locationByHook, window?.location]);

  // state variables to determine whether to show wallet install modal
  const [showWalletOnboarding, setShowWalletOnboarding] = useState(false);
  const [isConnectingWallet, setIsConnectingWallet] = useState(false);
  const [linkWalletError, setLinkWalletError] = useState("");
  const [closeConnectWalletModal, setCloseConnectWalletModal] = useState(false);
  const [platformState, setPlatformState] = useState();
  // function that detects whether metamask is installed, and toggles modal if it isn't.
  // accepts an optional callback function as an argument and only runs the function if metamask is installed
  const detectMetamask = cb => {
    // if (typeof web3 === "undefined") {
    //   setShowWalletOnboarding(true);
    //   return;
    // }
    if (cb) {
      cb();
    }
  };

  const { callAxiosFunc } = useCallAxios();

  // function that toggles wallet install modal
  const toggleWalletOnboarding = () => {
    setShowWalletOnboarding(!showWalletOnboarding);
  };

  useEffect(() => {
    if (linkWalletError) {
      toast(
        <Flex alignItems="center">
          <WarningIcon style={{ height: "40px", width: "40px" }} />
          {linkWalletError || "Something went wrong. Please try again!"}
        </Flex>,
        TOAST_OPTIONS,
      );
    }
  }, [linkWalletError]);

  useEffect(() => {
    const getAppState = async () => {
      try {
        const response = await callAxiosFunc({
          method: "get",
          url: `${ENDPOINT.APP_STATE}`,
          // headers: JSON.stringify({ accept: "*/*", Authorization: JSON.stringify(headers)}),
        });

        if (response && response.data) {
          setPlatformState(response.data);
        }
      } catch (err) {
        console.log("error while getting app state", err);
      }
    };
    getAppState();
  }, []);

  return (
    <AppStateContext.Provider
      value={{
        isLoading: appLoading,
        txPending,
        address,
        aTag: "",
        // platform: data ? data.platform : "",
        platform: "",
        // nick: data ? data.nick : "",
        nick: "",
        detectMetamask,
        showWalletOnboarding,
        toggleWalletOnboarding,
        platformState,
        isConnectingWallet,
        setIsConnectingWallet,
        closeConnectWalletModal,
        setCloseConnectWalletModal,
        linkWalletError,
        setLinkWalletError,
      }}
    >
      <ToastContainer />
      {children}
    </AppStateContext.Provider>
  );
}

AppStateProvider.propTypes = { children: PropTypes.node };

function useAppState() {
  return useContext(AppStateContext);
}

export { AppStateProvider, useAppState };
