/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import * as S from "./styles";
import { Button } from "../../common/Button";
import { RedWarningIcon } from "../../../../assets/svg";
import { useTournamentHook } from "../../../../hooks";
import Modal from "../../Modal";

export default function LeaveModal({ isOpen, toggleModal, tournamentData, fetchTournamentData }) {
  const [now, setNow] = useState(new Date());
  const { data, isLoading, error, leaveTournament, leavePoolCalled } = useTournamentHook();

  useEffect(() => {
    const clock = setInterval(() => {
      setNow(new Date());
    }, 1000);
    clearInterval(clock);
  }, []);

  // useEffect(() => {
  //   if (!isLoading && data !== undefined && data.type === "leave" && data.error === false) {
  //     toggleModal(false);
  //   }
  // }, [isLoading, data, error]);

  const leavePool = () => {
    if (error) return;

    if (!isLoading && data !== undefined && data.type === "leave" && error === false) {
      toggleModal(true);

      // When user leaves a tournament, they can still be seen as part of the leaderboard and the banner stays on top.
      // We need to refetch tournament data to get the proper attributes and remove user from leaderboard and the banner. Hence we manually refetch the data
      fetchTournamentData();
      return;
    }
    leaveTournament(tournamentData);
  };

  return (
    <Modal showModal={isOpen} toggleModal={toggleModal}>
      <S.ModalContainer>
        <S.MainWrapper>
          <S.ContentWrapper>
            <RedWarningIcon />
            <S.Title>Leave the tournament</S.Title>
            <S.Body>
              <div>
                {!error && now.getTime() < tournamentData.startDate && (
                  <>
                    <p>You haven&apos;t played your tournament yet.</p>
                    <p>Do you want to leave without playing?</p>
                  </>
                )}
                {!error && now.getTime() > tournamentData.startDate && !leavePoolCalled && (
                  <p>Are you sure to leave?</p>
                )}
                {!error && now.getTime() > tournamentData.startDate && leavePoolCalled && <p>You left</p>}
                {error && <p>Something went wrong</p>}
              </div>
              <S.ButtonSection>
                {/* hiding canceel button after pool has been left */}
                {!(!isLoading && data && data.type === "leave" && !error && leavePoolCalled) && (
                  <S.Cancel
                    onClick={() => {
                      // toggleModal takes in a "leave" propwich should be true only if leave was successful. We shouldn't pass it here as  we are  only closing the modal
                      toggleModal();
                    }}
                  >
                    Cancel
                  </S.Cancel>
                )}
                <Button
                  height="42px"
                  width="119px"
                  borderRadius="30px"
                  fontSize="14px"
                  onClick={leavePool}
                  disabled={!!error}
                >
                  {isLoading && data && data.type === "leave" && "leaving..."}
                  {!isLoading && !leavePoolCalled && "Yes, leave"}
                  {!isLoading && leavePoolCalled && error && "Yes, leave"}
                  {!isLoading && data && data.type === "leave" && !error && leavePoolCalled && "Ok"}
                </Button>
              </S.ButtonSection>
            </S.Body>
          </S.ContentWrapper>
        </S.MainWrapper>
      </S.ModalContainer>
    </Modal>
  );
}
