import styled from "styled-components";
import { breakpoints } from "../../themes";

export const BannerContainer = styled.div`
  position: absolute;
  top: 0;
`;
export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 116px;
  margin-top: -110px;
  padding-top: 110px;
  ${breakpoints.down(breakpoints.tablet)} {
    margin: 0 64px;
    margin-top: -110px;
  }
  ${breakpoints.down(breakpoints.mobile)} {
    margin: 0px;
    margin-top: -110px;
  }
`;

export const FaqContainer = styled.div`
  z-index: 1;
  margin-bottom: 100px;
`;

export const Input = styled.input`
  background: #3f2faa;
  border-radius: 500px;
  width: 698px;
  outline: none;
  border: none;
  height: 52px;
  padding: 22px;
  padding-left: 54px;
  position: relative;
  ::placeholder {
    font-size: 16px;
    line-height: 32px;
    color: #877add;
  }
  margin-bottom: 100px;
  color: #877add;
  ${breakpoints.down(breakpoints.laptopL)} {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const SearchIconContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: 22px;
  top: 18px;
`;

export const PageTitle = styled.h1`
  font-size: 40px;
  line-height: 56px;
  color: #ffffff;
  z-index: 1;
`;
