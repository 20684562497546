import styled from "styled-components";

export const NavBottomWrapper = styled.div`
  display: flex;
  margin-bottom: 6px;
  border-top: 1px solid #585c7d;
  justify-content: space-between;
`;

export const NavBottomItem = styled.li`
  width: 206px;
  height: 49px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 14px 23px 18px 0px;
  font-style: normal;
  font-weight: 400;
  margin-top: 9px;
  color: white;

  :hover {
    span {
      text-decoration: underline;
    }
  }
`;

export const IconWrapper = styled.span`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 23px;
`;

export const NavSocialLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 33.14px;
  // margin-top: 32px;
  align-items: center;
  svg:hover {
    cursor: pointer;
  }
`;

export const SocialIconWrapper = styled.div`
  :hover {
    svg {
      path {
        fill: white;
      }
    }
  }
`;

export const NavBottomContainer = styled.div`
  display: none;
  // height: 180px;
  width: 100%;
  margin: auto;
  margin-top: 64px;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const SocialLinks = styled.a`
  :hover {
    color: white;
  }
`;
