import { useWallet } from "../providers/WalletProvider";
import useUserLogin from "./UserLogin";
import axios from "axios";
import useDisconnectUser from "./useDisconnectUser";

export default function useCallAxios() {
  const { logoutOfWeb3Modal } = useWallet();
  const { disconnecUser } = useDisconnectUser();

  async function callAxiosFunc({ url, method, body = null, headers = null, extraHeader = null }) {
    try {
      const response = await axios(url, {
        method,
        data: extraHeader && extraHeader["Content-Type"] ? body : JSON.parse(body),
        headers: {
          ...JSON.parse(headers),
          ...extraHeader,
        },
      });
      return { data: response.data };
    } catch (err) {
      console.log({ err });
      if (err?.response?.status === 403) {
        await disconnecUser();
        logoutOfWeb3Modal();
      }
      return { err };
    }
  }

  return { callAxiosFunc };
}
