import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { useUserAddress } from "eth-hooks";
import { useWallet } from "../providers/WalletProvider";
import useContractLoader from "./ContractLoader";
import * as erc20abi from "../contracts/ERC20.abi";
import {
  NATIVE_TOKEN_ADDRESS,
  FE_ERROR_NO_PROVIDER,
  FE_ERROR_BC_NOT_ENOUGH_BALANCE,
  FE_ERROR_API_JOIN_POOL,
  FE_ERROR_BC_APPROVE_DENIED,
  FE_ERROR_BC_JOIN_POOL_ERROR,
} from "../constants";
import { useGetSecHeaders } from "./SecurityHeaders";
import { ENDPOINT } from "../constants/endpoints";
import { APPROVE_AMOUNT } from "../constants/env";
import useCallAxios from "./useAxiosCall";

let globalSecHeaders = null;

const joinPool = async (poolContract, poolId, value, gas, body) => {
  try {
    let joinPoolResult;
    if (body && body?.joinConfiguration?.length) {
      // This is for Koins, we need to send the list of the nfts added.
      let koins = body.joinConfiguration.map(item => item.id);
      const gasLimit = body.joinConfiguration.length * gas;
      joinPoolResult = await poolContract.joinPool(poolId, koins, { value, gasLimit });
    } else {
      // Normal flow
      joinPoolResult = await poolContract.joinPool(poolId, { value });
    }
    return { error: false, data: joinPoolResult, type: "join" };
  } catch (e) {
    console.log("join pool result error -> ", e.code);
    return { error: true, data: e, type: "join" };
  }
};

const leavePool = async (poolContract, poolId, gasLimit) => {
  try {
    const result = await poolContract.leavePool(poolId /*, { gasLimit }*/);
    return { error: false, data: result, type: "leave" };
  } catch (e) {
    console.log("leave pool result error -> ", e);
    return { error: true, data: e, type: "leave" };
  }
};

export const useTournamentHook = () => {
  const { injectedProvider } = useWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [leavePoolCalled, setLeavePoolCalled] = useState(false);
  const headers = useGetSecHeaders();
  const address = useUserAddress(injectedProvider);

  useEffect(() => {
    if (headers) globalSecHeaders = headers;
  }, [headers]);

  // blast contract
  const poolContracts = useContractLoader(injectedProvider);

  const { callAxiosFunc } = useCallAxios();

  const apiJoinPool = async (headers, poolId, body) => {
    try {
      const response = await callAxiosFunc({
        method: "POST",
        url: `${ENDPOINT.JOIN_POOL}/${poolId}`,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
        body: JSON.stringify(body) || undefined,
      });
      if ((response && response.err) || (response && response.data.error)) {
        let errorMessage = "";
        if (response.err.response.data.message !== undefined) {
          errorMessage = response.err.response.data.message;
        } else if (response.data.message !== undefined) {
          errorMessage = response.data.message;
        }
        return { error: true, data: errorMessage || "Something went wrong", type: "join" };
      }
      return response.data;
    } catch (e) {
      console.log(e);
      console.log("join api pool result error -> ", e);
      return { error: true, data: e, type: "join" };
    }
  };

  const apiLeavePool = async (headers, poolId, body) => {
    try {
      const response = await callAxiosFunc({
        method: "PUT",
        url: `${ENDPOINT.LEAVE_POOL}/${poolId}`,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
        body: JSON.stringify(body) || undefined,
      });
      if ((response && response.err) || (response && response.data.error)) {
        let errorMessage = "";
        if (response.err.response.data.message !== undefined) {
          errorMessage = response.err.response.data.message;
        }
        return { error: true, data: errorMessage, type: "leave" };
        //   throw Error(`- ${errorMessage}`);
      }
      return { error: false, data: response.data, type: "leave" };
    } catch (e) {
      console.log("leave api pool result error -> ", e);
      return { error: true, data: e, type: "leave" };
    }
  };

  const removeUserFromDbPool = async (poolId, reason, body) => {
    // remove user from db pool leaderboard
    await apiLeavePool(globalSecHeaders, poolId, body);
    // set a new user->notification
  };

  const joinTournament = async (tournamentData, handleError, body) => {
    if (tournamentData.entrance <= 0) {
      const apiJoinPoolResult = await apiJoinPool(headers, tournamentData.id, body);
      if (apiJoinPoolResult.error) {
        console.log(`${FE_ERROR_API_JOIN_POOL} -> ${apiJoinPoolResult.data}`);
        setError(FE_ERROR_API_JOIN_POOL);
        setIsLoading(false);
        if (handleError) {
          handleError(apiJoinPoolResult);
        }
        return;
      }

      if (
        (apiJoinPoolResult?.data?.joined === false &&
          apiJoinPoolResult?.data?.configType === "JOIN_CONFIGURATION_REQUIRED") ||
        apiJoinPoolResult?.data?.configType === "INVALID_INVITATIONAL_CODE" ||
        apiJoinPoolResult?.data?.configType === "JOIN_STEPS_ARE_REQUIRED" ||
        apiJoinPoolResult?.data?.configType === "INVALID_DIVISION" ||
        apiJoinPoolResult?.data?.configType === "INVALID_ADDRESS" ||
        apiJoinPoolResult?.data?.configType === "MAX_PLAYERS_REACHED" ||
        apiJoinPoolResult?.data?.configType === "TEAM_REQUIRED"
      ) {
        setIsLoading(false);
        return apiJoinPoolResult;
      }

      setData({
        error: false,
        data: "",
        type: "join",
      });
      setIsLoading(false);
      return;
    } else {
      const blastContract = Object.values(poolContracts).filter(
        item => item.address === tournamentData.gamePoolContractAddress,
      )[0];

      setData();
      setError();
      setIsLoading(true);

      const defGasLimit = "350600";
      const defApproveAmount = tournamentData.approveMax
        ? ethers.constants.MaxUint256
        : ethers.BigNumber.from(APPROVE_AMOUNT);
      const tokenContractAddress = tournamentData.entranceTokenAddress;

      let feeMultiplier = 1;
      if (body && body.joinConfiguration?.length > 1) {
        feeMultiplier = body.joinConfiguration.length;
      }
      let tournamentEntryFee = tournamentData.entrance * feeMultiplier;
      let erc20Contract = null;
      let isNativeToken = true;
      if (injectedProvider && address && headers) {
        if (tokenContractAddress && tokenContractAddress !== NATIVE_TOKEN_ADDRESS) {
          // instantiate token contract
          erc20Contract = new ethers.Contract(tokenContractAddress, erc20abi, injectedProvider.getSigner());
          const dec = await erc20Contract.decimals();
          tournamentEntryFee = ethers.utils.parseUnits(String(tournamentEntryFee), dec);
          isNativeToken = false;
        } else {
          // Because of Dani's concerns, this needs to be checked if we change from Polygon to another Network.
          tournamentEntryFee = ethers.utils.parseEther(tournamentEntryFee.toString());
        }

        if (tournamentData.entrance > 0) {
          // check user token balance before doing the join pool
          let usrTokenBalance = 0;
          if (isNativeToken) {
            usrTokenBalance = await injectedProvider.getBalance(address);
          } else {
            usrTokenBalance = await erc20Contract.balanceOf(address);
          }
          if (usrTokenBalance.lt(tournamentEntryFee)) {
            // user doesn't have enough token balance to join this pool
            setData({ error: true, data: "", type: "join" });
            setError(FE_ERROR_BC_NOT_ENOUGH_BALANCE);
            setIsLoading(false);
            return;
          }
        }
        // join api pool
        const apiJoinPoolResult = await apiJoinPool(headers, tournamentData.id, body);
        if (apiJoinPoolResult.error) {
          console.log(`${FE_ERROR_API_JOIN_POOL} -> ${apiJoinPoolResult.data}`);
          setError(FE_ERROR_API_JOIN_POOL);
          setIsLoading(false);
          if (handleError) {
            handleError(apiJoinPoolResult);
          }
          return;
        }

        if (
          (apiJoinPoolResult?.data?.joined === false &&
            apiJoinPoolResult?.data?.configType === "JOIN_CONFIGURATION_REQUIRED") ||
          apiJoinPoolResult?.data?.configType === "INVALID_INVITATIONAL_CODE" ||
          apiJoinPoolResult?.data?.configType === "JOIN_STEPS_ARE_REQUIRED" ||
          apiJoinPoolResult?.data?.configType === "INVALID_DIVISION" ||
          apiJoinPoolResult?.data?.configType === "INVALID_ADDRESS" ||
          apiJoinPoolResult?.data?.configType === "MAX_PLAYERS_REACHED" ||
          apiJoinPoolResult?.data?.configType === "TEAM_REQUIRED"
        ) {
          setIsLoading(false);
          return apiJoinPoolResult;
        }

        if (!isNativeToken && tournamentEntryFee.gt(0)) {
          // Not a native token -> Check allowance
          const allowanceBalance = await erc20Contract.allowance(address, tournamentData.gamePoolContractAddress);
          if (!allowanceBalance.gte(tournamentEntryFee)) {
            // ask for approve...
            try {
              const approveResult = await erc20Contract.approve(
                tournamentData.gamePoolContractAddress,
                defApproveAmount,
              );
              console.log("User sign...: ", approveResult);
              // should log trx result?
            } catch (e) {
              console.log(`${FE_ERROR_BC_APPROVE_DENIED} -> ${e}`);

              // if reject remove user from this pool
              if (bcJoinPoolResponse.data.code && bcJoinPoolResponse.data.code == "ACTION_REJECTED") {
                const leaveConfiguration = body?.joinConfiguration?.map(item => ({ id: String(item.id) }));
                const modifiedReqBody = { ...body, leaveConfiguration };
                removeUserFromDbPool(tournamentData.id, FE_ERROR_BC_APPROVE_DENIED, modifiedReqBody);
              }

              setError(FE_ERROR_BC_APPROVE_DENIED);
              setIsLoading(false);
              return;
            }
          }
        }

        // This comparation is because if the tournament use a no native token (example DAI, KUZ, etc)
        // the value needs to be 0, the smart contract will calculate the fee.
        // For native tokens the value needs to be specified.
        tournamentEntryFee = isNativeToken ? tournamentEntryFee : 0;
        ////////////////////////////////////////////////////////////

        // BC join Pool
        const bcJoinPoolResponse = await joinPool(
          blastContract,
          tournamentData.onChainId,
          tournamentEntryFee,
          defGasLimit,
          body,
        );
        if (bcJoinPoolResponse.error) {
          console.log(`${FE_ERROR_BC_JOIN_POOL_ERROR} -> ${bcJoinPoolResponse.data}`);

          // if reject remove user from this pool
          if (bcJoinPoolResponse.data.code && bcJoinPoolResponse.data.code == "ACTION_REJECTED") {
            const leaveConfiguration = body?.joinConfiguration?.map(item => ({ id: String(item.id) }));
            const modifiedReqBody = { ...body, leaveConfiguration };
            removeUserFromDbPool(tournamentData.id, FE_ERROR_BC_JOIN_POOL_ERROR, modifiedReqBody);
          }

          setError(FE_ERROR_BC_JOIN_POOL_ERROR);
          if (handleError) {
            handleError();
          }
          setIsLoading(false);
          return;
        }
        // return ok
        setData({
          error: false,
          data: "",
          type: "join",
        });
        setIsLoading(false);
        return;
      }
      // Error: no provider
      setError(FE_ERROR_NO_PROVIDER);
      setIsLoading(false);
      return;
    }
  };

  const leaveTournament = async tournamentData => {
    setData({ type: "leave" });
    setError();
    setIsLoading(true);

    if (tournamentData.entrance <= 0) {
      setLeavePoolCalled(true);
      const apiLeavePoolResult = await apiLeavePool(headers, tournamentData.id);
      if (apiLeavePoolResult.error) {
        console.log(apiLeavePoolResult);

        setError("Error Leaving Pool.");
        setIsLoading(false);
        setData({ test: "test" });
        return;
      }
      setError(false);
      setData(apiLeavePoolResult);
      setIsLoading(false);
      return;
    } else {
      const blastContract = Object.values(poolContracts).filter(
        item => item.address === tournamentData.gamePoolContractAddress,
      )[0];

      const defGasLimit = "350600";

      if (injectedProvider && address) {
        // leave Pool
        const leavePoolResponse = await leavePool(blastContract, tournamentData.onChainId);
        setLeavePoolCalled(true);
        if (leavePoolResponse.error) {
          console.log("Error doing leavePool ->", leavePoolResponse.data);
          setError("Error Leaving Pool.");
          setIsLoading(false);
          setData(leavePoolResponse);
          return;
        }
        setError(false);
        // call API -> leavePool
        console.log("Calling Leave pool");
        const apiLeavePoolResult = await apiLeavePool(headers, tournamentData.id);
        if (apiLeavePoolResult.error) {
          // setError(`Error Leaving Pool ${apiLeavePoolResult.data}`);
          setIsLoading(false);
          return;
        }

        setData(apiLeavePoolResult);
        setIsLoading(false);
        return;
      }

      // cannot get provider/address
      setError("Error, there's no provider.");
      setIsLoading(false);
    }
  };

  const checkTokenApproved = async (tokenAddress, entranceFee, contractAddress) => {
    const blastContract = Object.values(poolContracts).filter(item => item.address === contractAddress)[0];

    let tournamentEntryFee = entranceFee;
    if (tokenAddress !== NATIVE_TOKEN_ADDRESS) {
      const erc20Contract = new ethers.Contract(tokenAddress, erc20abi, injectedProvider.getSigner());
      const dec = await erc20Contract.decimals();
      tournamentEntryFee = ethers.utils.parseUnits(String(entranceFee), dec);
      const allowanceBalance = await erc20Contract.allowance(address, blastContract.address);
      const approvalBool = allowanceBalance.gte(tournamentEntryFee);
      return approvalBool;
    } else {
      return true;
    }
  };

  const getTokenBalance = async tokenContractAddress => {
    const erc20Contract = new ethers.Contract(tokenContractAddress, erc20abi, injectedProvider.getSigner());
    const usrTokenBalance = await erc20Contract.balanceOf(address);
    return usrTokenBalance;
  };
  // returns
  return {
    data,
    isLoading,
    error,
    joinTournament,
    leaveTournament,
    leavePoolCalled,
    getTokenBalance,
    checkTokenApproved,
  };
};

export default useTournamentHook;
