import React from "react";
import styled from "styled-components";
import Accordion from "./Accordion";

const RulesContainer = styled.div`
  border-radius: 10px;
  padding-bottom: 7px;
  margin-bottom: 32px;
`;

const RulesWrapper = styled.div``;

export default function Rules({ rules, isLoading }) {
  return (
    <>
      <RulesContainer>
        <RulesWrapper>
          <Accordion rules={rules} isLoading={isLoading} />
        </RulesWrapper>
      </RulesContainer>
    </>
  );
}
