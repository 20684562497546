import styled from "styled-components";
import { Tooltip } from "antd";

export const Card = styled.div`
  width: 100%;
  height: 100%;
  background: #3f2faa;
  border-radius: 6px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 19px;
  padding-top: 21px;
`;

export const Flex = styled.div`
  display: flex;

  ${props => `
  row-gap: ${props.rowGap ? `${props.rowGap}px` : "unset"}};
  column-gap: ${props.columnGap ? `${props.columnGap}px` : "unset"}};
  flex-direction: ${props.direction ? props.direction : "row"};
  align-items: ${props.alignItems ? props.alignItems : "center"};
  justify-content: ${props.justifyContent ? props.justifyContent : "unset"};
`}
`;

export const Text = styled.div`
  ${props => `
    color: ${props.color ? `${props.color}` : "#fff"}};
    font-size: ${props.size ? `${props.size}px` : "16px"}};
  `}
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const PlayerListContainer = styled.div`
  background: #36279c;
  border: 1px solid #5647b5;
  border-radius: 4px;
  height: 201px;
  width: 100%;
  padding: 16px 21px;
  @media (max-width: 425px) {
    padding-left: 14px;
  }
`;

export const PlayerRow = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 18px;
  align-items: center;

  div:nth-child(1) {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 355px) {
    gap: 6px;

    div:nth-child(1) {
      width: 12px;
    }
  }
`;

export const PlayerListInner = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 6px;
    padding: 20px;
    margin: 20px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: #2b1c7e;
    padding: 0 4px;
    border-radius: 24px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #3f338c;
  }
`;

export const TeamName = styled.div`
  font-size: 26px;
  color: #fff;
  :hover {
    color: #be7eff;
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }
  @media (max-width: 425px) {
    font-size: 20px;
  }
`;

export const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
  display: flex;
`;

export const TeamStatusWrapper = styled.div`
  width: 82px;
  max-height: 28px;
  background-color: #4e3caa;
  border-radius: 6px;
  padding: 8px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
`;

export const TeamStatusDot = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${props => (props.status === "active" ? "#77CA70" : "#A498ED")};
  display: ${props => (props.status === "inactive" || props.status === "active" ? "block" : "none")};
`;

export const TeamStatusText = styled.p`
  margin: 0;
  font-size: 13px;
  font-weight: 350;
  line-height: 28px;
  text-align: center;
  color: #a498ed;
`;
