/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Tooltip from "react-simple-tooltip";
import * as S from "./styles";
import { Button } from "../../common/Button";
import Loader from "../../Loader";
import { ModalCancelIcon, OkTick, QuestionMark, ErrorCross, TickSuccess } from "../../../../assets/svg";
import { CHAIN_EXPLORER, POOLFACTORYV2_ADDRESS } from "../../../../constants/env";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { cutUnderNDigits } from "../../../../helpers/utils";
import { usePriceConverter } from "../../../../hooks";
import { CHAIN_TOKEN_NAME } from "../../../../constants/env";

const ERROR_MAP = {
  "fe-error-bc-not-enough-balance": "You do not have enough balance to join this pool",
  "User doesn't have platform account.":
    "You need to connect an account to join this pool. Please go to your profile and connect an account",
};

const ModalContent = ({
  errorStatus,
  errorMessage,
  buttonDisabled,
  toggleModal,
  joinedSuccess,
  entrance,
  tokenImage,
  tokenName,
  checkHandler,
  checkStatus,
  buttonHandler,
  buttonText,
  toolTipText,
  bcExplorer,
  isMobile,
  entranceTokenName,
  entranceTokenSymbol
}) => {
  if (errorStatus) {
    return (
      <S.errorWrapper>
        <ErrorCross />
        <S.modalHeader>There was an error</S.modalHeader>
        <S.modalSubtext>
          {ERROR_MAP[errorMessage] ? ERROR_MAP[errorMessage] : "We can't process your request at this time"}
        </S.modalSubtext>
        {/* <S.modalSubtext>Please try again</S.modalSubtext> */}
        <S.ButtonWrapper>
          <Button
            disabled={buttonDisabled}
            fontSize="16px"
            lineHeight="20px"
            borderRadius="30px"
            width="168px"
            height="40px"
            onClick={() => toggleModal()}
            style={{ marginTop: "37px" }}
          >
            Close
          </Button>
        </S.ButtonWrapper>
      </S.errorWrapper>
    );
  }
  if (joinedSuccess) {
    return (
      <S.errorWrapper>
        <TickSuccess />
        <S.modalHeader>You joined successfully</S.modalHeader>
        <S.modalSubtext>We're processing your request.</S.modalSubtext>
        <S.modalSubtext>It will take 5 minutes to be available.</S.modalSubtext>
        <S.ButtonWrapper>
          <Button
            disabled={buttonDisabled}
            fontSize="16px"
            lineHeight="20px"
            borderRadius="30px"
            width="168px"
            height="40px"
            onClick={() => toggleModal()}
            style={{ marginTop: "37px" }}
          >
            Continue
          </Button>
        </S.ButtonWrapper>
      </S.errorWrapper>
    );
  }
  return (
    <S.SettingsWrapper>
      <S.CurrencyWrapper>
        <S.CurrencyText>{entrance > 0 ? entrance : "Free Entry"}</S.CurrencyText>
      </S.CurrencyWrapper>

      <S.CriptoWrapper>
        <S.CriptoContentWrapper>
          {entrance > 0 ? (
            <>
              {tokenImage ? <img src={tokenImage} alt="token logo" height={30} width={30} /> : tokenName}
              <S.CriptoText>{tokenName}</S.CriptoText>
            </>
          ) : (
            <S.CriptoText>-</S.CriptoText>
          )}
        </S.CriptoContentWrapper>
      </S.CriptoWrapper>

      {entrance > 0 ? (
        <S.CheckBoxContainer>
          {tokenName?.toLowerCase() !== CHAIN_TOKEN_NAME.toLowerCase() && (
            <>
              <S.CheckBox onClick={checkHandler} checked={checkStatus} isMobile={isMobile}>
                <OkTick
                  style={{
                    visibility: checkStatus ? "visible" : "hidden",
                    left: "4px",
                    bottom: "4px",
                    position: "relative",
                    height: "6px",
                    width: "9px",
                  }}
                />
              </S.CheckBox>
              <S.CheckBoxText>Do not request approval again.</S.CheckBoxText>
            </>
          )}
        </S.CheckBoxContainer>
      ) : (
        <div style={{ marginBottom: "20px" }} />
      )}

      <S.ButtonWrapper>
        <Button
          disabled={buttonDisabled}
          fontSize="16px"
          lineHeight="20px"
          borderRadius="30px"
          width="168px"
          height="40px"
          onClick={buttonHandler}
          id={buttonText === "Join" ? "analytics-join-modal" : ""}
        >
          {buttonText}
        </Button>
      </S.ButtonWrapper>

      {entrance > 0 && entranceTokenSymbol !== "MATIC" && entranceTokenName !== "Matic" && (
        <S.ToolTipContainer>
          <Tooltip
            fontSize="11px"
            fontFamily="Gotham"
            color="#2E2759"
            border="#2E2759"
            content={
              <div style={{ width: "180px", height: "50px" }}>
                {toolTipText[0]}
                <br />
                {toolTipText[1]}
                <a target="_blank" href={bcExplorer}>
                  {toolTipText[2]}
                </a>
                <br />
                {toolTipText[3]}
              </div>
            }
            placement="right"
            background="#877ADD"
            arrow={12}
            fadeEasing="linear"
            fadeDuration={300}
            radius={6}
          >
            <QuestionMark
              style={{
                position: "relative",
                width: "16px",
                height: "16px",
                left: "4px",
                bottom: "0px",
              }}
            />
          </Tooltip>
        </S.ToolTipContainer>
      )}
    </S.SettingsWrapper>
  );
};

export default function TournamentModal({
  isOpen,
  opacity,
  toggleModal,
  buttonText,
  buttonHandler,
  buttonDisabled,
  entrance,
  checkHandler,
  checkStatus,
  errorStatus,
  errorMessage,
  name,
  image,
  bgImage,
  startDate,
  leaderboard,
  tokenImage,
  tokenName,
  modalContentLoading,
  isLoading,
  joinedSuccess,
  poolPrize,
  tokenSymbol,
  entranceTokenImage,
  entranceTokenName,
  entranceTokenSymbol,
}) {
  const toolTipText = [
    "By granting permission, you are ",
    "allowing the following ",
    "contract ",
    "to access your funds",
  ];
  const bcExplorer = `${CHAIN_EXPLORER}${POOLFACTORYV2_ADDRESS}`;
  const { isMobile } = useWindowSize();
  const { convertedPrice } = usePriceConverter(tokenSymbol, poolPrize, "USD");
  return (
    <div>
      <S.StyledModal
        isOpen={isOpen}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        error={errorStatus}
        isMobile={isMobile}
      >
        <S.ModalContainer>
          <S.MainWrapper>
            {isMobile ? <S.mobBg /> : <S.BgImage src={bgImage} alt="" />}
            {!isMobile && <S.Img src={image} alt="" />}
            <S.IconWrapper onClick={toggleModal}>
              <ModalCancelIcon />
            </S.IconWrapper>
            <S.ContentWrapper>
              {!isMobile && (
                <>
                  <S.TimeAndIconsWrapper>
                    <S.TimeWrapper>
                      <S.TimeText>{startDate}</S.TimeText>
                    </S.TimeWrapper>
                    <S.TimeWrapper>
                      <S.TimeText>{leaderboard.length} players</S.TimeText>
                    </S.TimeWrapper>
                  </S.TimeAndIconsWrapper>
                </>
              )}
              <S.Title>{name}</S.Title>

              <S.PrizeWrapper>
                <S.TextBlock>
                  <S.BlockDescription>Prize Pool</S.BlockDescription>
                  <S.BlockContent>
                    {poolPrize} {tokenImage ? <S.TokenImg src={tokenImage} /> : tokenSymbol} ~ $
                    {cutUnderNDigits(convertedPrice, 2) || 0}
                  </S.BlockContent>
                </S.TextBlock>
                {entrance === 0 && (
                  <>
                    <S.TextBlock>
                      <S.BlockDescription>Entry</S.BlockDescription>
                      <S.BlockContent>Free</S.BlockContent>
                    </S.TextBlock>
                  </>
                )}
              </S.PrizeWrapper>
            </S.ContentWrapper>
          </S.MainWrapper>
          {modalContentLoading || isLoading ? (
            <S.loaderWrapper>
              <Loader animation="rotate" width={100} />
            </S.loaderWrapper>
          ) : (
            <ModalContent
              errorStatus={errorStatus}
              errorMessage={errorMessage}
              buttonDisabled={buttonDisabled}
              toggleModal={toggleModal}
              joinedSuccess={joinedSuccess}
              entrance={entrance}
              tokenImage={entranceTokenImage}
              tokenName={entranceTokenName}
              checkHandler={checkHandler}
              checkStatus={checkStatus}
              buttonHandler={buttonHandler}
              buttonText={buttonText}
              toolTipText={toolTipText}
              bcExplorer={bcExplorer}
              isMobile={isMobile}
              entranceTokenName={entranceTokenName}
              entranceTokenSymbol={entranceTokenSymbol}
            />
          )}
        </S.ModalContainer>
      </S.StyledModal>
    </div>
  );
}
