import React, { useEffect, useRef, useState } from "react";
import * as S from "../Teams/styles";
import Flex from "../../components/v2/common/Flex";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../../components/v2/common/SpinningLoader";
import moment from "moment-timezone";
import { TextNoMargin } from "../../components/v2/common/Text";
import pigSaveAnimation from "../../assets/lotties/pig_save_animation.json";
import Fire from "../../assets/fire.png";
import { padString, truncateWithDots } from "../../helpers/utils";
import Lottie from "react-lottie";
import TournamentRow from "./TournamentRow";
import ConnectWalletModal from "../../components/v2/ConnectWalletModal";
import { useWallet } from "../../providers/WalletProvider";
import { sessionState } from "../../state/globalState";
import { useRecoilValue } from "recoil";
import { ToastContainer, toast } from "react-toastify";
import { WarningIcon } from "../../assets/svg";
import { TOAST_OPTIONS } from "../../constants";
import { useUserAddress } from "eth-hooks";
import ConnectWalletModalClaims from "../../components/v2/ConnectWalletModal/ConnectWalletModalClaims";
import { Button } from "../../components/v2/common/Button";
import NoTournamentFound from "../../components/v2/Tournament/NoTournamentFound";
import { useWindowSize } from "../../hooks/useWindowSize";
import UserActivityPaginationWrapper from "./UserActivityPaginationWrapper";

const TournamentRowHeadings = () => {
  return (
    <S.TournamentRowHeadings>
      <div>
        <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
          Tournaments
        </TextNoMargin>
      </div>
      <div></div>
      <div>
        <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
          Mode
        </TextNoMargin>
      </div>
      <div>
        <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
          Date
        </TextNoMargin>
      </div>
      <div>
        <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
          Position
        </TextNoMargin>
      </div>
      <div>
        <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
          Prize Pool
        </TextNoMargin>
      </div>
      <div>
        <TextNoMargin fontWeight="325" fontSize="13px" lineHeight="36px" color="#877ADD">
          Earned
        </TextNoMargin>
      </div>
      <div />
    </S.TournamentRowHeadings>
  );
};

const TournamentsSection = ({
  allPools,
  isLoadingUserActivity,
  claimFunc,
  currentPage,
  setCurrentPage,
  currentTab = "all",
  setCurrentTab = () => {},
  allPoolsArrEmpty = false,
  recentPoolsArrEmpty = false,
}) => {
  const { activities, totalPages } = allPools || {};
  const [poolsToDisplay, setPoolsToDisplay] = useState([]);
  const [currentTime] = useState(() => Date.now());

  const { isMobile } = useWindowSize();
  const { loadWeb3Modal, web3Modal, injectedProvider, displaySelectionModal } = useWallet();
  const session = useRecoilValue(sessionState);
  const address = useUserAddress(injectedProvider);

  const [openWalletModal, setOpenWalletModal] = useState(false);
  const toggleWalletModal = () => {
    setOpenWalletModal(!openWalletModal);
  };

  // Prize To display on connect wallet modal (in case we need to display that modal).
  const [connectModalPrizeDisplay, setConnectModalPrizeDisplay] = useState({});

  const [makeBtnRef, setMakeBtnRef] = useState(null);

  const btnRef = useRef();

  useEffect(() => {
    // Initially we were filtering allPools on the FE so we created a poolsToDisplay property.
    // We're not filtering on the FE but keeping this state for reference and future purposes.
    setPoolsToDisplay(activities);
  }, [activities]);

  return allPoolsArrEmpty && recentPoolsArrEmpty ? null : (
    <S.SectionContainer>
      <div style={{ marginBottom: "36px" }}>
        <S.TabsTitle>My Activity</S.TabsTitle>
      </div>
      <S.TabsBar>
        <S.TabButton
          onClick={() => {
            if (isLoadingUserActivity) return;
            setCurrentTab("active");
          }}
          border={currentTab === "active" ? "4px solid #3F2FAA" : "4px solid #201366"}
          isLoading={isLoadingUserActivity}
        >
          Recent
        </S.TabButton>
        <S.TabButton
          onClick={() => {
            if (isLoadingUserActivity) return;
            setCurrentTab("all");
          }}
          border={currentTab === "all" ? "4px solid #3F2FAA" : "4px solid #201366"}
          isLoading={isLoadingUserActivity}
        >
          All
        </S.TabButton>
      </S.TabsBar>
      <Flex>{poolsToDisplay?.length <= 0 || isLoadingUserActivity ? null : <TournamentRowHeadings />}</Flex>
      {isLoadingUserActivity ? (
        <SpinnerLoaderWrapper>
          <SpinnerLoader />
        </SpinnerLoaderWrapper>
      ) : poolsToDisplay?.length < 1 ? (
        <NoTournamentFound activeFilter={currentTab === "active" ? "active" : ""} />
      ) : (
        // poolsToDisplay?.map((props, idx) => (
        //   <div style={{ marginBottom: "21px" }} key={props?.reference}>
        //     <TournamentRow
        //       data={{ ...props }}
        //       currentTab={currentTab}
        //       poolsToDisplay={poolsToDisplay || []}
        //       claimFunc={claimFunc}
        //       currentTime={currentTime}
        //       session={session}
        //       address={address}
        //       setOpenWalletModal={setOpenWalletModal}
        //       btnRef={btnRef}
        //       setConnectModalPrizeDisplay={setConnectModalPrizeDisplay}
        //       makeBtnRef={makeBtnRef}
        //       setMakeBtnRef={setMakeBtnRef}
        //     />
        //   </div>
        // ))
        <UserActivityPaginationWrapper
          data={{ poolsToDisplay: activities, totalPages }}
          isMobile={isMobile}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={isLoadingUserActivity}
          renderComponentProps={{
            currentTab: currentTab,
            poolsToDisplay: poolsToDisplay || [],
            claimFunc,
            currentTime,
            session,
            address,
            setOpenWalletModal,
            btnRef,
            setConnectModalPrizeDisplay,
            makeBtnRef,
            setMakeBtnRef,
          }}
          // RenderComponent={props => (
          //   <div style={{ marginBottom: "21px" }}>
          //     <TournamentRow
          //       data={{ ...props }}
          //       currentTab={currentTab}
          //       poolsToDisplay={poolsToDisplay || []}
          //       claimFunc={claimFunc}
          //       currentTime={currentTime}
          //       session={session}
          //       address={address}
          //       setOpenWalletModal={setOpenWalletModal}
          //       btnRef={btnRef}
          //       setConnectModalPrizeDisplay={setConnectModalPrizeDisplay}
          //       makeBtnRef={makeBtnRef}
          //       setMakeBtnRef={setMakeBtnRef}
          //     />
          //   </div>
          // )}
          pageLimit={isMobile ? 3 : 5}
          dataLimit={4}
        />
      )}

      <ConnectWalletModalClaims
        loadWeb3Modal={loadWeb3Modal}
        displaySelectionModal={displaySelectionModal}
        showModal={openWalletModal}
        setShowModal={setOpenWalletModal}
        toggleModal={toggleWalletModal}
        connectModalPrizeDisplay={connectModalPrizeDisplay}
        setMakeBtnRef={setMakeBtnRef}
        resumePrevFlow={() => {
          setConnectModalPrizeDisplay({});
          if (btnRef.current) {
            btnRef.current.click();
          }
        }}
      />
      <ToastContainer />
    </S.SectionContainer>
  );
};

export default TournamentsSection;
