import React, { useState } from "react";
import styled from "styled-components";
import { Skeleton } from "antd";
import { AccordionArrowIcon } from "../../../assets/svg";
import { sanitizeHTML } from "../../../helpers/utils";

const AccordionWrapper = styled.div`
  font-size: 15px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const AccordionItemWrapper = styled.div`
  background: #3f2faa;
  border-radius: 10px;
  padding: ${props => (props.isLoading ? "20px" : "0 28px")};
`;

const AccordionHeader = styled.div`
  height: 60px;
  /* padding: 20px; */
  width: 100%;
  display: flex;
  justify-content: start;
  gap: 18px;
  align-items: center;

  :hover {
    cursor: pointer;
  }

  svg {
    transform: ${props => (props.visible ? "rotate(90deg)" : "rotate(0deg)")};
  }
`;

const AccordionContent = styled.div`
  transition: all 0.15s linear;
  overflow: hidden;
  color: #d4cedf;
  height: ${props => (props.visible ? "fit-content" : "0")};
  padding: ${props => (props.visible ? "0px 29px 28px 61px" : "0px 29px 0px 61px")};
`;

const AccordionHeaderText = styled.p`
  font-size: 18px;
  line-height: 36px;
  color: white;
  margin: 0;
`;

const AccordionContentText = styled.span`
  margin-bottom: 0;
  a {
    text-decoration: underline;
  }

  h1 {
    font-size: 2em;
    color: #fff;
  }

  h2 {
    font-size: 1.5em;
    color: #fff;
  }

  h3 {
    font-size: 1.3em;
    color: #fff;
  }

  h4 {
    font-size: 1em;
    color: #fff;
  }

  h5 {
    font-size: 0.8em;
    color: #fff;
  }

  h6 {
    font-size: 0.7em;
    color: #fff;
  }

  ul > li {
    list-style: disc;
  }

  p {
    margin: 0;
    display: inline;
  }
`;

const AccordionItem = ({ rule, isLoading }) => {
  const [visibility, setVisibility] = useState(false);

  const handleToggleVisibility = () => {
    setVisibility(!visibility);
  };

  const sanitizedStr = sanitizeHTML(rule?.content);

  return (
    <AccordionItemWrapper visible={visibility} isLoading={isLoading}>
      <Skeleton loading={isLoading} active paragraph={{ rows: 1 }}>
        <AccordionHeader onClick={handleToggleVisibility} visible={visibility}>
          <AccordionArrowIcon />
          <AccordionHeaderText>{rule.title}</AccordionHeaderText>
        </AccordionHeader>
      </Skeleton>
      <AccordionContent visible={visibility}>
        <AccordionContentText style={{ whiteSpace: "pre-wrap" }} id="rule-content">
          <span dangerouslySetInnerHTML={{ __html: sanitizedStr }} />
        </AccordionContentText>
      </AccordionContent>
    </AccordionItemWrapper>
  );
};

const Accordion = ({ rules = [], isLoading }) => {
  const [sortedRules] = useState(rules?.sort((a, b) => a.sortOrder - b.sortOrder));
  return (
    <AccordionWrapper>
      {sortedRules.map((rule, index) => (
        <AccordionItem key={index} rule={rule} isLoading={isLoading} />
      ))}
    </AccordionWrapper>
  );
};

export default Accordion;
