import React, { useEffect, useState } from "react";
import { useTournamentHook } from "../../../../hooks";
import { Button } from "../../common/Button";
import Flex from "../../common/Flex";
import { Text, TextNoMargin } from "../../common/Text";
import Loader from "../../Loader";
import Modal from "../../Modal";
import { ModalHeader } from "../../Modal/styles";
import * as S from "./styles";
import { truncateWithDots } from "../../../../helpers/utils";
import { PlayerAvatarsContainer, PlayerCountCircle } from "../../Teams/TeamProfileBanner/styles";
import Avatar from "../../common/Avatar";
import { InfoIconNew, Tick, WarningIcon, YellowWarningIcon } from "../../../../assets/svg";
import { SpinnerLoader } from "../../common/SpinningLoader";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import TeamsModal from "../../Teams/TeamsModal";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../../../constants";
import { useHistory } from "react-router-dom";
import { useMedia } from "use-media";

const TeamRow = ({ team, isSelected, teamUsersQty, isMobile = false, tooltipMsg = "" }) => {
  return (
    <S.SingleTeamRowContainer enabled={team.enabled}>
      {isSelected ? (
        <S.EmptyCheckbox
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#6C47FF",
            opacity: 1,
          }}
        >
          <Tick style={isMobile ? { height: "30px", width: "30px" } : {}} />
        </S.EmptyCheckbox>
      ) : (
        <Tooltip
          trigger="click"
          title={isMobile ? tooltipMsg : ""}
          color="#7265C8"
          placement="top"
          overlayStyle={{
            maxWidth: "179px",
            fontSize: "11px",
            padding: "11px 8px",
            borderRadius: "3px",
            zIndex: 999999,
          }}
        >
          <S.EmptyCheckbox />
        </Tooltip>
      )}
      <div style={{ width: isMobile ? "70px" : "130px" }}>
        <Tooltip
          trigger="click"
          title={isMobile ? tooltipMsg : ""}
          color="#7265C8"
          placement="top"
          overlayStyle={{
            maxWidth: "179px",
            fontSize: "11px",
            padding: "11px 8px",
            borderRadius: "3px",
            zIndex: 999999,
          }}
        >
          <TextNoMargin
            fontWeight="325"
            fontSize={isMobile ? "15px" : "18px"}
            lineHeight="36px"
            style={{ whiteSpace: "nowrap" }}
          >
            {truncateWithDots(team.name, isMobile ? 8 : 10)}
          </TextNoMargin>
        </Tooltip>
      </div>
      <S.MembersCount>
        {team?.users?.length} / {teamUsersQty}
      </S.MembersCount>
      {!isMobile ? (
        <div>
          <PlayerAvatarsContainer border="1px solid #36279c">
            <>
              {team?.users?.slice(0, 4).map((item, indx) => (
                <Avatar size="40px" key={indx} />
              ))}
              {team.users?.length > 4 ? (
                <PlayerCountCircle border="1px solid #36279c">+{team.users?.length - 4}</PlayerCountCircle>
              ) : null}
            </>
          </PlayerAvatarsContainer>
        </div>
      ) : null}
      {team.enabled ? (
        <Button width={isMobile ? "75px" : "106px"} background="#6C47FF" borderRadius="18.5px">
          Select
        </Button>
      ) : (
        <S.ManagePlayers>
          {isMobile ? (
            <div style={{ display: "flex", alignItems: "center", gap: "14px" }}>
              <Link to={`/team-profile/${team.id}`}>Manage Players</Link>
              <Tooltip
                trigger="click"
                title={tooltipMsg}
                color="#7265C8"
                placement="top"
                overlayStyle={{
                  maxWidth: "179px",
                  fontSize: "11px",
                  padding: "11px 8px",
                  borderRadius: "3px",
                  zIndex: 999999,
                }}
              >
                <InfoIconNew style={{ height: "30px", width: "30px" }} />
              </Tooltip>
            </div>
          ) : (
            <Link to={`/team-profile/${team.id}`}>Manage Players</Link>
          )}
        </S.ManagePlayers>
      )}
    </S.SingleTeamRowContainer>
  );
};

const TeamJoinPoolModal = ({ showModal, toggleModal, joinPoolWithConfig, gameDetails, teamJoinModalConfig }) => {
  const { teamsArray, invitationalCode } = teamJoinModalConfig || {};

  const [selectedTeam, setSelectedTeam] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [displayCreateTeamModal, setDisplayCreateTeamModal] = useState(false);
  const history = useHistory();

  const isMobile = useMedia({ maxWidth: "640px" });

  const handleError = err => {
    console.log("Error occured while joining pool with team");
    toast(
      <Flex alignItems="center">
        <WarningIcon style={{ height: "40px", width: "40px" }} /> {err.data || "Something went wrong. Please try again"}
      </Flex>,
      TOAST_OPTIONS,
    );
    setIsLoading(false);
  };

  const onCloseCallback = () => {
    setIsLoading(false);
    setSelectedTeam("");
  };

  const handleSubmit = async (config, errorhandler) => {
    setIsLoading(true);
    if (invitationalCode) {
      config.invitationalCode = invitationalCode;
    }
    try {
      await joinPoolWithConfig(config, errorhandler);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleTeamsModal = () => {
    setDisplayCreateTeamModal(!displayCreateTeamModal);
  };

  return (
    <>
      <Modal
        showModal={showModal}
        toggleModal={() => toggleModal(onCloseCallback)}
        customStyles={{ maxHeight: "644px", background: "#3F2FAA", minHeight: "548px", width: "673px" }}
        mobileStyles={{ width: "90%" }}
      >
        <S.Container>
          <Text fontSize="26px" lineHeight="36px" fontWeight="325">
            Select a team you want to play with
          </Text>
          <Text fontSize="16px" lineHeight="19px" fontWeight="325" color="#A498ED">
            The team should have the same number of players as the one in the game. To change the number of players to
            be able to play, go to manage team.
          </Text>

          {(teamsArray && teamsArray.length) < 1 ? (
            <S.TeamsRowContainer background="#36279c" border="1px solid #5647b5">
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                extraStyles={{ height: "100%", width: "100%" }}
              >
                <Text fontSize="18px" lineHeight="22px" fontWeight="325" style={{ textAlign: "center", width: "75%" }}>
                  You don't have any teams with the required number of players
                </Text>
                <Button
                  width="156px"
                  height="39px"
                  borderRadius="30px"
                  fontSize="16px"
                  lineHeight="32px"
                  onClick={() => history.push("/teams")}
                >
                  Manage teams
                </Button>
                <TeamsModal showModal={displayCreateTeamModal} toggleModal={toggleTeamsModal} />
              </Flex>
            </S.TeamsRowContainer>
          ) : (
            <S.TeamsRowContainer

            // border="1px solid #5647b5"
            >
              {teamsArray?.map(team => (
                <Tooltip
                  title={
                    isMobile ? "" : !team.enabled ? team?.message || "You cannot enter this team in this pool" : ""
                  }
                  color="#7265C8"
                  placement="right"
                  overlayStyle={{
                    maxWidth: "320px",
                    fontSize: "11px",
                    padding: "11px 8px",
                    borderRadius: "3px",
                    zIndex: 999999,
                  }}
                >
                  <div
                    id={team?.id}
                    onClick={() => {
                      if (team.enabled && !isLoading) {
                        setSelectedTeam(team?.id);
                      }
                    }}
                    style={isMobile ? { width: "100%" } : null}
                  >
                    <TeamRow
                      team={team}
                      isSelected={selectedTeam === team.id}
                      teamUsersQty={gameDetails.teamUsersQty}
                      isMobile={isMobile}
                      tooltipMsg={!team.enabled ? team?.message || "You cannot enter this team in this pool" : ""}
                    />
                  </div>
                </Tooltip>
              ))}
            </S.TeamsRowContainer>
          )}
          {teamsArray?.length < 1 ? null : (
            <S.ButtonContainer>
              <Button
                width="156px"
                height="39px"
                borderRadius="30px"
                fontSize="16px"
                lineHeight="32px"
                onClick={() => handleSubmit({ teamReqData: { id: selectedTeam } }, handleError)}
                disabled={isLoading || !selectedTeam}
              >
                {isLoading ? <SpinnerLoader /> : "Confirm"}
              </Button>
            </S.ButtonContainer>
          )}
        </S.Container>
      </Modal>
    </>
  );
};

export default TeamJoinPoolModal;
