import React, { useState } from "react";
import styled from "styled-components";
import { Carousel } from "antd";

const CustomCarousel = styled(Carousel)`
  margin-bottom: 10px;

  .slick-dots {
    margin-top: 20px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
  }

  .slick-dots-bottom {
    bottom: -10px !important;
  }

  .slick-dots li {
    width: 12px !important;
  }

  .slick-dots li.slick-active {
    width: 12px !important;
  }

  .slick-dots li button:before {
    display: none !important;
  }

  .slick-dots li button {
    background: #3f2faa !important;
    width: 12px !important;
    height: 12px !important;
    border-radius: 50% !important;
  }

  .slick-dots li.slick-active button {
    background: linear-gradient(245deg, #5487f4 0%, #8c54fd 100%) !important;
    width: 12px !important;
    height: 12px !important;
    border-radius: 50% !important;
  }
`;

const CarouselItem = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const PromotedTournamentBanner = ({ bannerArray }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const goToIndex = newIndex => {
    setActiveSlide(newIndex);
  };

  const slides = bannerArray.map(banner => {
    return (
      <a key={banner.id} href={banner?.url} target="_blank" rel="noreferrer noopener">
        <CarouselItem src={banner.largeImage || banner.smallImage} alt={banner.description} />
      </a>
    );
  });

  return (
    <CustomCarousel afterChange={goToIndex} dots={true} dotPosition="bottom" autoplay>
      {slides}
    </CustomCarousel>
  );
};
