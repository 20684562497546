import { useState, useEffect, useCallback } from "react";
import useSWR from "swr";
import { callAxios } from "./Axios";
import { useGetSecHeaders } from "./SecurityHeaders";
import { ENDPOINT } from "../constants/endpoints";
import blastCache from "../helpers/cache";

/*
  ~ What it does? ~

  makes conversion between currencies using the api`

  ~ How can I use? ~

   const { convertedPrice, conversionError } = usePriceConverter(tokenSymbol, poolPrize, "USD");
*/

const usePriceConverter = (from="MATIC", amount, to) => {
  const [convertedPrice, setConvertedPrice] = useState(0);
  const [conversionError, setConversionError] = useState(false);
  const headers = useGetSecHeaders();

  const fetcher = useCallback(() => {
    const cachedPrice = blastCache.getPrice(from, to);
    if (cachedPrice) {
      return cachedPrice;
    }

    return callAxios({
      method: "GET",
      url: `${ENDPOINT.CONVERSION}/${from}/1/${to}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
    }).then(res => {
      return res.data.price;
    });
  }, [headers, from, to]);

  const { data } = useSWR(`${ENDPOINT.CONVERSION}/${from}/1/${to}`, fetcher, {
    // dedupingInterval: 2000,
    // focusThrottleInterval: 2000,
  });

  useEffect(() => {
    let atc = amount;
    if (typeof amount === "string") atc = parseFloat(amount);

    if (data) {
      blastCache.setPrice(from, to, data);
      setConvertedPrice(atc * data);
      setConversionError(false);
    } else {
      setConversionError(true);
    }
  }, [data, from, to, amount]);

  return {
    convertedPrice,
    conversionError,
  };
};

export default usePriceConverter;
