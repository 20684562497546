import styled from "styled-components";

export const PillButton = styled.button`
  width: ${props => (props.width ? props.width : "auto")};
  height: ${props => (props.height ? props.height : "31px")};
  border: none;
  padding: ${props => (props.padding ? props.padding : "0px 16px 0px 16px")};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "8px")};
  color: ${props => (props.color ? props.color : "white")};
  font-size: ${props => (props.fontSize ? props.fontSize : "12px")};
  line-height: ${props => (props.fontSize ? props.fontSize : "28px")};
  background: ${props =>
    props.gradient ? props.gradient : "linear-gradient(162deg, rgba(29, 197, 236, 1) 0%, rgba(129, 30, 230, 1) 100%)"};
  cursor: pointer;
`;
