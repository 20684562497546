import styled, { keyframes } from "styled-components";

export const HeaderContainer = styled.div`
  text-align: center;
  margin-top: 36px;
  width: 80%;

  @media (max-width: 425px) {
    width: 90%;
  }

  //   border: ${props => (props.hasError ? "1px solid red" : "1px solid #4a3e9c")};
`;

export const Header = styled.div`
  font-weight: 325;
  font-size: 38px;
  color: #fff;
  margin-bottom: 39px;
`;

export const SubHeader = styled.div`
  font-weight: 325;
  font-size: 16px;
  color: #a498ed;
`;

export const Disclaimer = styled.div`
  text-align: center;
  width: 80%;
  font-size: 12px;
  color: #a498ed;
  @media (max-width: 425px) {
    width: 90%;
  }
  a {
    color: #be7eff;
    text-decoration: none;
  }
`;

export const ErrorText = styled.div`
  font-weight: 325;
  font-size: 13px;
  /* or 277% */

  text-align: center;

  color: #bb5959;
`;
