import React from "react";
import * as S from "./style";

const RoundCheckbox = React.forwardRef((props, ref) => {
  const { value, checked, onChange, name, id, label, disabled, onClick } = props;
  return (
    <S.Label htmlFor={id} disabled={disabled} ref={ref}>
      {label}
      <S.Input
        id={id}
        type="checkbox"
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
      />
      <S.Indicator />
    </S.Label>
  );
});
export default RoundCheckbox;