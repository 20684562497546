/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import { Contract } from "@ethersproject/contracts";
import { useState, useEffect } from "react";

/*
  ~ What it does? ~

  Loads your local contracts and gives options to read values from contracts 
                                              or write transactions into them

  ~ How can I use? ~

  const readContracts = useContractLoader(localProvider) // or
  const writeContracts = useContractLoader(userProvider)

  ~ Features ~

  - localProvider enables reading values from contracts
  - userProvider enables writing transactions into contracts
  - Example of keeping track of "purpose" variable by loading contracts into readContracts 
    and using ContractReader.js hook:
    const purpose = useContractReader(readContracts,"YourContract", "purpose")
  - Example of using setPurpose function from our contract and writing transactions by Transactor.js helper:
    tx( writeContracts.YourContract.setPurpose(newPurpose) )
*/

const loadContract = (contractName, signer) => {
  let contractAddress;
  switch (contractName) {
    case "CodChallenges":
      contractAddress = process.env.REACT_APP_CODCHALLENGES_ADDRESS;
      break;
    case "CodChallengesV2":
      contractAddress = process.env.REACT_APP_CODCHALLENGESV2_ADDRESS;
      break;
    case "PoolFactoryV2":
      contractAddress = process.env.REACT_APP_POOLFACTORYV2_ADDRESS;
      break;
    case "PoolFactoryKoins":
      contractAddress = process.env.REACT_APP_POOLFACTORYKOINS_ADDRESS;
      break;
    default:
      console.log();
      break;
  }

  const newContract = new Contract(
    // require(`../contracts/${contractName}.address.js`),
    contractAddress,
    require(`../contracts/${contractName}.abi.js`),
    signer,
  );
  try {
    newContract.bytecode = require(`../contracts/${contractName}.bytecode.js`);
  } catch (e) {
    console.log(e);
  }
  return newContract;
};

export default function useContractLoader(providerOrSigner) {
  const [contracts, setContracts] = useState();
  useEffect(() => {
    async function loadContracts() {
      if (typeof providerOrSigner !== "undefined") {
        try {
          // we need to check to see if this providerOrSigner has a signer or not
          let signer;
          let accounts;
          if (providerOrSigner && typeof providerOrSigner.listAccounts === "function") {
            accounts = await providerOrSigner.listAccounts();
          }

          if (accounts && accounts.length > 0) {
            signer = providerOrSigner.getSigner();
          } else {
            signer = providerOrSigner;
          }

          const contractList = require("../contracts/contracts");

          const newContracts = contractList.reduce((accumulator, contractName) => {
            accumulator[contractName] = loadContract(contractName, signer);
            return accumulator;
          }, {});
          setContracts(newContracts);
        } catch (e) {
        }
      }
    }
    loadContracts();
  }, [providerOrSigner]);
  return contracts;
}
