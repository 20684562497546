/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import moment, { min } from "moment-timezone";
import FlipNumbers from "react-flip-numbers";
import Flex from "../common/Flex";
import * as S from "./styles";
import Loader from "../Loader";

const TimeBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  section {
    span {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 4px;
      margin: 0 1.5px;
      display: flex;
      justify-content: start;
      span {
        margin-left: 0px;
      }
    }
  }
  small {
    color: #ffffff80;
    margin-top: 2px;
  }

  @media (max-width: 450px) {
    margin-right: 0px;
  }
`;

const TimeDetailsContainer = styled.div`
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 30px;
  @media (max-width: 450px) {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }
  margin-bottom: 20px;
`;

const Container = styled.div`
  background: transparent;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 450px) {
    display: block;
    background-color: transparent;
    padding-left: 4px;
    padding-right: 4px;
  }
`;

const StartingText = styled.div`
  color: #ffffff;
  font-size: 14px;
  font-weight: 325;
  margin-bottom: 4px;
  @media (max-width: 450px) {
    margin-left: 0px;
    font-size: 14px;
    font-weight: 325;
  }
`;

const LoaderContainer = styled.div`
  width: 40px;
  height: 60px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  @media (max-width: 450px) {
    width: 24px;
    height: 36px;
  }
`;

const LoaderBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  @media (max-width: 450px) {
    margin-right: 12px;
  }
`;

const LoadingSpinner = styled.span`
  width: 17px;
  height: 17px;
  border: 3px solid #ffffff99;
  border-bottom-color: #ffffffcc;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SmallLabel = styled.small`
  color: rgba(255, 255, 255, 0.5);
  margin-top: 2px;
`;

export default function CountdownTimer({ text, endDate, isMobile }) {
  const [days, setDays] = useState(null);
  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [seconds, setSeconds] = useState(null);

  useEffect(() => {
    const intervalVar = setInterval(() => {
      const currentTime = Date.now();
      let timeLeft;
      let endDateFormatted = moment(endDate);
      timeLeft = moment.duration(endDate - currentTime);
      setDays(
        (timeLeft?._data?.months >= 1
          ? endDateFormatted.diff(currentTime, "days")
          : timeLeft?._data?.days >= 0 && timeLeft?._data?.days) || 0,
      );
      setHours((timeLeft?._data?.hours >= 0 && timeLeft?._data?.hours) || 0);
      setMinutes((timeLeft._data?.minutes >= 0 && timeLeft?._data?.minutes) || 0);
      setSeconds((timeLeft._data?.seconds >= 0 && timeLeft?._data?.seconds) || 0);
    }, 1000);

    return () => clearInterval(intervalVar);
  }, [endDate]);
  return (
    <Container>
      {seconds !== null ? (
        <>
          <StartingText>{text}</StartingText>
          <TimeDetailsContainer>
            <TimeBlock>
              <FlipNumbers
                play
                color="#fff"
                width={isMobile ? 24 : 40}
                height={isMobile ? 36 : 60}
                numbers={days > 0 ? days.toString()?.padStart(2, "0") : "ㅤㅤ"}
                numberStyle={{
                  fontSize: isMobile ? "24px" : "30px",
                  lineHeight: isMobile ? "30px" : "40px",
                  fontWeight: 300,
                }}
                nonNumberStyle={{
                  height: isMobile ? "36px" : "60px",
                  width: isMobile ? "24px" : "40px",
                  fontSize: isMobile ? "24px" : "30px",
                  lineHeight: isMobile ? "30px" : "40px",
                  fontWeight: 300,
                }}
              />
              <small>Days</small>
            </TimeBlock>
            <TimeBlock>
              <FlipNumbers
                play
                color="#fff"
                width={isMobile ? 24 : 40}
                height={isMobile ? 36 : 60}
                numbers={
                  (days === 0 && hours > 0) || hours > 0
                    ? hours.toString()?.padStart(2, "0")
                    : days > 0 && hours === 0
                    ? "00"
                    : "ㅤㅤ"
                }
                numberStyle={{
                  fontSize: isMobile ? "24px" : "30px",
                  lineHeight: isMobile ? "30px" : "40px",
                  fontWeight: 300,
                }}
                nonNumberStyle={{
                  height: isMobile ? "36px" : "60px",
                  width: isMobile ? "24px" : "40px",
                  fontSize: isMobile ? "24px" : "30px",
                  lineHeight: isMobile ? "30px" : "40px",
                  fontWeight: 300,
                }}
              />
              <small>Hours</small>
            </TimeBlock>
            <TimeBlock>
              <FlipNumbers
                play
                color="#fff"
                width={isMobile ? 24 : 40}
                height={isMobile ? 36 : 60}
                numbers={
                  (days === 0 && hours > 0 && minutes > 0) || minutes > 0
                    ? minutes.toString()?.padStart(2, "0")
                    : days > 0 && hours > 0 && minutes === 0
                    ? "00"
                    : "ㅤㅤ"
                }
                numberStyle={{
                  fontSize: isMobile ? "24px" : "30px",
                  lineHeight: isMobile ? "30px" : "40px",
                  fontWeight: 300,
                }}
                nonNumberStyle={{
                  height: isMobile ? "36px" : "60px",
                  width: isMobile ? "24px" : "40px",
                  fontSize: isMobile ? "24px" : "30px",
                  lineHeight: isMobile ? "30px" : "40px",
                  fontWeight: 300,
                }}
              />
              <small>Min</small>
            </TimeBlock>
            <TimeBlock>
              <FlipNumbers
                play
                color="#fff"
                width={isMobile ? 24 : 40}
                height={isMobile ? 36 : 60}
                numbers={
                  (days === 0 && hours > 0 && minutes > 0 && seconds > 0) || seconds > 0
                    ? seconds.toString()?.padStart(2, "0")
                    : minutes > 0 && seconds === 0
                    ? "00"
                    : "ㅤㅤ"
                }
                numberStyle={{
                  fontSize: isMobile ? "24px" : "30px",
                  lineHeight: isMobile ? "30px" : "40px",
                  fontWeight: 300,
                }}
                nonNumberStyle={{
                  height: isMobile ? "36px" : "60px",
                  width: isMobile ? "24px" : "40px",
                  fontSize: isMobile ? "24px" : "30px",
                  lineHeight: isMobile ? "30px" : "40px",
                  fontWeight: 300,
                }}
              />
              <small>Sec</small>
            </TimeBlock>
          </TimeDetailsContainer>
        </>
      ) : (
        <div>
          <StartingText>Loading...</StartingText>
          <TimeDetailsContainer>
            <LoaderBlock>
              <Flex columnGap="4">
                <LoaderContainer />
                <LoaderContainer />
              </Flex>
              <SmallLabel>Days</SmallLabel>
            </LoaderBlock>
            <LoaderBlock>
              <Flex columnGap="4">
                <LoaderContainer />
                <LoaderContainer />
              </Flex>
              <SmallLabel>Hours</SmallLabel>
            </LoaderBlock>
            <LoaderBlock>
              <Flex columnGap="4">
                <LoaderContainer />
                <LoaderContainer />
              </Flex>
              <SmallLabel>Minutes</SmallLabel>
            </LoaderBlock>
            <LoaderBlock>
              <Flex columnGap="4">
                <LoaderContainer />
                <LoaderContainer />
              </Flex>
              <SmallLabel>Seconds</SmallLabel>
            </LoaderBlock>
          </TimeDetailsContainer>
        </div>
      )}
    </Container>
  );
}
