import styled from "styled-components";
import gun from "../../assets/gun.png";
import bg from "../../assets/bg.png";
import bgSecond from "../../assets/bg1.png";
import { breakpoints } from "../../themes";

export const Background = styled.div`
  height: 100vh;
  background-image: url(${gun}), url(${Math.floor(Math.random() * 2) + 1 === 1 ? bg : bgSecond});
  width: 100%;
  box-shadow: inset 0 0 0 2000px rgba(31, 26, 61, 0.4);
  background-repeat: no-repeat;
  background-size: 750px, cover;
  background-position: 48vw bottom, center;
  overflow: hidden;
  position: relative;
`;

export const IconContainer = styled.div`
  position: relative;
  background: #ffffff1a;
  height: 47px;
  width: 47px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  &:hover {
    height: 57px;
    width: 57px;
    background: rgba(32, 32, 32, 0.4);
    box-shadow: 0px 0px 20px rgba(89, 144, 162, 0.5);
    &::before {
      content: "";
      position: absolute;
      background: rgba(60, 60, 60, 0.2);
      opacity: 0.2;
      box-shadow: 0px 0px 20px rgba(89, 144, 162, 0.8);
      height: 77px;
      width: 77px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    font-size: 12px;
    line-height: 15px;
  }
`;

export const IconsSection = styled.div`
  ${breakpoints.down(breakpoints.laptop)} {
    display: none;
  }
  div {
    z-index: 1;
    &:nth-child(1) {
      position: absolute;
      right: 60px;
      bottom: 300px;
      &:hover + div {
        display: block;
      }
    }
    &:nth-child(2) {
      display: none;
      color: #fff;
      position: absolute;
      right: 140px;
      bottom: 310px;
      font-size: 12px;
      line-height: 15px;
    }

    &:nth-child(3) {
      position: absolute;
      right: 120px;
      bottom: 230px;
      &:hover + div {
        display: block;
      }
    }
    &:nth-child(4) {
      display: none;
      color: #fff;
      position: absolute;
      right: 210px;
      bottom: 250px;
      font-size: 12px;
      line-height: 15px;
    }

    &:nth-child(5) {
      position: absolute;
      right: 115px;
      bottom: 120px;
      &:hover + div {
        display: block;
      }
    }
    &:nth-child(6) {
      display: none;
      color: #fff;
      position: absolute;
      right: 190px;
      bottom: 130px;
      font-size: 12px;
      line-height: 15px;
    }

    &:nth-child(7) {
      position: absolute;
      right: 58px;
      bottom: 50px;
      &:hover + div {
        display: block;
      }
    }

    &:nth-child(8) {
      display: none;
      color: #fff;
      position: absolute;
      right: 120px;
      bottom: 60px;
      font-size: 12px;
      line-height: 15px;
    }
  }
`;

export const CircleOutline = styled.span`
  position: absolute;
  right: -150px;
  bottom: 47px;
  height: 302px;
  width: 305px;
  border-radius: 100%;
  background: radial-gradient(84.59% 86.28% at 65.08% 45.03%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
`;
export const HealthBarHoverContent = styled.div`
  position: absolute;
  display: none;
  left: 60px;
  top: 50px;
  align-items: baseline;
  width: 500px;
`;

export const HealthBarContainer = styled.div`
  position: relative;
  margin-left: 30px;
  margin-top: 30px;
  width: fit-content;
  &:hover ${HealthBarHoverContent} {
    display: flex;
  }
`;

export const HealthText = styled.div`
  color: #ffffff;
  font-size: 20px;
  line-height: 15px;
  font-weight: bold;
  margin-top: 23px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const HealthBarHoverText = styled.div`
  color: #ffffff;
  margin-left: 10px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  width: 150px;
`;

export const Circle = styled.div`
  background-image: radial-gradient(45.98% 50.27% at 51.84% 45.37%, rgba(39, 32, 84, 0) 0%, #000000 100%);
  background-size: cover;
  height: 100vh;
  position: absolute;
  width: 100vw;
`;

export const PageHeader = styled.div`
  color: #ffffff;
  font-size: 150px;
  font-weight: 400;
  margin-bottom: 63px;
  line-height: 200px;

  ${breakpoints.down(breakpoints.tablet)} {
    font-size: 110px;
    margin-bottom: 0px;
  }
`;

export const LargeSubtext = styled.div`
  color: #ffffff;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 10px;
  text-align: center;
  ${breakpoints.down(breakpoints.tablet)} {
    font-size: 28px;
  }
`;
export const SmallSubtext = styled.div`
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 30px;
`;
export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 60px;
  ${breakpoints.down(breakpoints.laptop)} {
    justify-content: flex-start;
    padding: 60px 20px;
  }
`;

export const Button = styled.button`
  width: 115px;
  background: linear-gradient(#0fc0e8, #8514f8);
  border-radius: 30px;
  color: #ffffff;
  border: none;
  height: 34px;
  cursor: pointer;

  ${breakpoints.down(breakpoints.tablet)} {
    height: 44px;
    width: 148px;
    background: linear-gradient(263.33deg, #0fc0e8 -45.54%, #8514f8 135.42%);
  }
`;
