import React from "react";

import styled from "styled-components";
import { breakpoints } from "../../../themes";

const Container = styled.div`
  width: 230px;
  height: 100%;

  ${breakpoints.down(breakpoints.tablet)} {
    width: 159px;
  }
`;

const GameSectionCardContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    margin-top: -5px;
    cursor: pointer;
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;

const GameTitle = styled.div`
  width: 100%;
  height: 40px;
  font-weight: 325;
  font-size: 14px;
  line-height: 20px;
  color: #d7cfe8;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 6px;
`;
function GameCard({ imageUrl, gameName }) {
  return (
    <Container>
      <GameSectionCardContainer>
        <ImageContainer>
          <Image src={imageUrl} alt="game" />
        </ImageContainer>
        <TitleContainer>
          <GameTitle>{gameName}</GameTitle>
        </TitleContainer>
      </GameSectionCardContainer>
    </Container>
  );
}

export default GameCard;
