import styled from "styled-components";

// export const Container = styled.div`
//   padding: 26px;
//   padding-top: 21px;
//   padding-bottom: 34px;
//   width: 457px;
//   @media (max-width: 768px) {
//     width: 100%;
//     padding: 0;
//     padding-top: 21px;
//     padding-bottom: 34px;
//   }
// `;

// export const Flex = styled.div`
//   display: flex;

//   ${props => `
//   row-gap: ${props.rowGap ? `${props.rowGap}px` : "unset"}};
//   column-gap: ${props.columnGap ? `${props.columnGap}px` : "unset"}};
//   flex-direction: ${props.direction ? props.direction : "row"};
//   align-items: ${props.alignItems ? props.alignItems : "unset"};
//   justify-content: ${props.justifyContent ? props.justifyContent : "unset"};
// `}
// `;

// export const Text = styled.div`
//   ${props => `
//     color: ${props.color ? `${props.color}` : "#fff"}};
//     font-size: ${props.size ? `${props.size}px` : "16px"}};
// `}:
// `;

export const Container = styled.div`
  margin-top: 134px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 19px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 26px;
  }
`;

export const CardContainer = styled.div`
  height: 109px;
  background: #3f2faa;
  border-radius: 6px;
  display: flex;
  align-items: center;
`;

export const FlexLeft = styled.div`
  flex: 1 1 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const FlexRight = styled.div`
  flex: 3 1 1rem;
`;

export const TextNumber = styled.h1`
  color: #ffffff;
  font-size: 24px;
  font-weight: 325;
  font-style: normal;
  padding: 0 0 4px 0;
  margin: 0;
`;

export const Text = styled.p`
  font-size: 14px;
  color: #877add;
  font-weight: 325;
  line-height: 18px;
  margin: 0;
  padding: 0;
`;
