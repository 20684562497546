import styled, { keyframes } from "styled-components";
import Modal from "styled-react-modal";

export const ModalContainer = Modal.styled`
    width: fit-content;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-color: #342788;
    border-radius: 4px;
    opacity: 1;
    transition : all 0.3s ease-in-out;
    position: absolute;
    top: 50%;
    padding: 30px 30px 25px 25px; 
    transform: translateY(-50%);
    @media (max-width: 450px) {
      top: 20%;
      transform: translateY(-20%);
      ${props => ({ ...props.mobileStyles })};

    };

    @media (min-width: 448px) and (max-width: 768px) {
      ${props => ({ ...props.tabletStyles })};
    }
    ::-webkit-scrollbar {
      width: 6px;
    }
    
    ::-webkit-scrollbar-track {
      background: #7265C8;
    }
    
    ::-webkit-scrollbar-thumb {
      background: #3F338CE6;
    }
    
    ::-webkit-scrollbar-thumb:hover {
      background: #3F338C;
    }
   
    ${({ styleOverrides }) => ({ ...styleOverrides })}

`;

export const CloseIconContainer = styled.div`
  cursor: pointer;
  margin-bottom: 15px;
`;

export const ModalHeader = styled.div`
  margin-bottom: 28px;
  line-height: 30px;
  color: rgba(209, 204, 239, 1);
  font-size: 20px;
`;
