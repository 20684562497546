import styled from "styled-components";

export const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  max-width: 583px;
`;

export const SubText = styled.div`
  color: #7a6cd0;
  font-size: 15px;
  text-align: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  gap: 8px;
  text-align: center;
`;

export const ContentContainer = styled.div`
  margin: 0 auto;
  margin-top: 120px;
  margin-bottom: 80px;
  width: 391px;
  display: flex;
  justify-content: space-between;
  position: relative;
  @media (max-width: 450px) {
    width: 80vw;
  }
`;

export const LineContainer = styled.div`
  svg {
    position: absolute;
    z-index: -1;
    left: 10px;
    @media (max-width: 450px) {
      width: 70%;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  small {
    width: 120px;
    text-align: center;
  }
  background: transparent;
  color: #fff;
  small {
    color: #877add;
    margin-bottom: 8px;
  }
`;

export const ColumnCircle = styled.div`
  width: 51px;
  height: 51px;
  border-radius: 500px;
  background: ${props => (props.success ? "#6C47FF" : "#201366")};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #6c47ff;
  color: #fff;
`;

export const errorContentContainer = styled.div`
  width: 480px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 450px) {
    width: 80vw;
  }
`;

export const errorColumn = styled.div`
  text-align: center;
  h3 {
    margin: 1.5rem auto;
    max-width: 463px;
    font-family: "Gotham";
    font-style: normal;
    font-weight: 325;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
  }
  p {
    margin:47px auto;
    max-width: 443px
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 325;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #877ADD;
  }
  @media (max-width: 450px) {
    h3{
      font-size:25px
    }
  }
`;
