import React from "react";
import * as S from "./style";

export default function Checkbox({
  value,
  defaultChecked,
  checked,
  onChange,
  name,
  id,
  label,
  disabled,
  onClick,
  hasError,
  isChecked,
  accentColor = "",
  checkedBgColor = "",
}) {
  return (
    <S.Label htmlFor={id} disabled={disabled}>
      {label}
      <S.Input
        id={id}
        type="checkbox"
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        onClick={onClick}
        isChecked={isChecked}
      />
      <S.Indicator
        accentColor={accentColor}
        checkedBgColor={checkedBgColor}
        hasError={hasError}
        isChecked={isChecked}
      />
    </S.Label>
  );
}
