/* eslint-disable import/no-named-as-default-member */
import React, { useState, useEffect, useRef } from "react";
import styled /* , { withTheme } */ from "styled-components";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import useMedia from "use-media";
import { useAppState } from "../../../providers/AppStateProvider";
import {
  NoNotificationIcon,
  NotificationIcon,
  NoMessagesIcon,
  MessagesIcon,
  // SearchIcon,
  ProfileIcon,
  // SettingsIcon,
  LogoutIcon,
  Clipboard,
  // LogoIcon,
  // CancelIcon,
} from "../../../assets/svg";
// import useActiveMenu from "../../../hooks/ActiveMenu";
import { Button } from "../common/Button";
// eslint-disable-next-line import/no-named-as-default
import Notification from "../Notification";
import { sessionState } from "../../../state/globalState";
import { breakpoints } from "../../../themes";
import Avatar from "../common/Avatar";
import { shortenAddress } from "../../../lib/web3-utils";
import { WalletOutlined } from "@ant-design/icons";
import ConnectWalletModal from "../ConnectWalletModal";
import { ModalProvider } from "styled-react-modal";

const HeaderWrapper = styled.div`
  background-color: #201366;
  padding: 17px 0px 17px 45px;
  padding-right: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  position: fixed;
  z-index: 29;
  width: 100vw;

  ${props => `
    top: ${props.visible ? "0" : "-200px"};
    // width: calc(100vw - ${props.collapseSidebar ? "198px" : "324px"});
  `}
  transition: all 0.2s linear;
  box-shadow: 0 0 30px #0c0433;

  ${breakpoints.down(breakpoints.tablet)} {
    padding: 17px 20px 17px 20px;
    display: flex;
    width: 100vw;
    justify-content: space-between;
  }

  ${breakpoints.up(breakpoints.tablet)} {
    width: 100vw;
  }
`;

// const SearchWrapper = styled.div`
//   width: 285px;
//   height: 37px;
//   display: flex;
//   gap: 19px;
//   padding-left: 17px;
//   padding-right: 17px;
//   align-items: center;
//   border-radius: 10px;
//   border: none;
//   ${props => `
//     background-color: ${props.theme.primaryColor};
//   `}

//   & input {
//     width: 100%;
//     height: 100%;
//     border: none;
//     border-radius: 10px;
//     background-color: ${props => props.theme.primaryColor};
//     color: ${props => props.theme.textThird};
//     font-size: 12px;
//     ::placeholder {
//       color: ${props => props.theme.textThird};
//     }
//     :focus {
//       outline: none;
//     }
//   }

//   @media (max-width: 450px) {
//     display: none;
//   }
// `;

const IconWrapper = styled.span`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: ${props => (props.icon === "notification" ? "41px" : "14px")};

  @media (max-width: 450px) {
    margin-right: ${props => (props.icon === "notification" ? "24px" : "14px")};
  }
`;

const NotificationIconWrapper = styled(IconWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserInfoWrapper = styled.div`
  position: relative;
  display: flex;
  user-select: none;
  align-items: center;
  gap: 10px;
  color: ${props => props.theme.textMain};

  @media (max-width: 450px) {
    gap: 15px;
  }
`;

const UserSettingWrapper = styled.div`
  position: absolute;
  top: 67px;
  right: 37px;
  border-radius: 10px;
  z-index: 2;
  display: ${props => (props.activeSettings ? "block" : "none")};
  ${props => `
    background-color: #362890;
  `}

  @media (max-width: 450px) {
    top: 70px;
    right: 24px;
  }
`;
const SettingsBar = styled.ul`
  display: flex;
  flex-direction: column;
  li:first-child {
    margin-top: 8px !important;
  }
  li:last-child {
    margin-bottom: 8px !important;
  }
`;

const SettingsItem = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 203px;
  margin-left: 8px;
  margin-right: 8px;
  height: 48px;
  padding: 13px;
  border-radius: 4px;
  //   padding: 13px 23px 13px 30px;
  font-style: normal;
  font-weight: 400;
  ${props => `
      color: ${props.theme.textMain};
      &:hover {
        color: #BE7EFF;
        background: #3F2FAA;
      }
      & svg {
        path {
          stroke: ${props.theme.textMain};
        }
      }
      &:hover svg {
        path {
          stroke: #BE7EFF;
        }
      }

    }
  `}
`;

const HiddenDiv = styled.div`
  width: 100%;
  ${breakpoints.down(breakpoints.laptopM)} {
    display: block;
  }
  ${breakpoints.up(breakpoints.laptopM)} {
    display: none;
  }
`;
// const LogoWrapper = styled.div`
//   cursor: pointer;

//   @media (min-width: 451px) {
//     display: none;
//   }
// `;

const DesktopContainer = styled.div`
  @media (max-width: 450px) {
    display: none;
  }
`;

const MobileContainer = styled.div`
  @media (min-width: 450px) {
    display: none;
  }
`;

// const CancelIconWrapper = styled.div`
//   display: ${props => (props.searchText === "" ? "none" : "block")};
//   padding-top: 2px;
// `;

const NickName = styled.span`
  @media (max-width: 451px) {
    display: none;
  }
  cursor: pointer;
`;

const PageTitle = styled.span`
  font-size: 22px;
  line-height: 28px;
  color: white;
  display: none;
  ${breakpoints.down(breakpoints.tablet)} {
    display: block;
  }
`;

const MessageIconWrapper = styled(IconWrapper)`
  position: relative;
  ${breakpoints.down(breakpoints.tablet)} {
    display: none;
  }
`;

const settingsItems = [
  {
    icon: <ProfileIcon />,
    title: "Profile",
    public: true,
    desktop: true,
  },
  {
    icon: <LogoutIcon />,
    title: "Disconnect",
    public: true,
    desktop: true,
  },
];

const matches = [
  {
    match: "/profile",
    title: "Profile",
  },
  {
    match: "/pool",
    title: "Tournament",
  },
  {
    match: "/challenge",
    title: "challenge",
  },
  {
    match: "/games",
    title: "Games",
  },
  {
    match: "messages",
    title: "Messages",
  },
];

export default function Header({
  loadWeb3Modal,
  web3Modal,
  logoutOfWeb3Modal,
  bodyRef,
  collapseSidebar,
  setShowAuthModal,
  displaySelectionModal,
}) {
  const session = useRecoilValue(sessionState);
  // const [searchText, setSearchText] = useState("");
  const [, setUserNick] = useState("...");
  const [user, setUser] = useState({});
  const [activeSettings, setActiveSettings] = useState(false);
  // const { menuOpen, setMenuOpen } = useActiveMenu();
  const ref = useRef(null);
  const mobileRef = useRef(null);
  const avatarRef = useRef(null);
  const { detectMetamask } = useAppState();
  const [visibleNotification, setVisibleNotification] = useState(false);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const notificationIconRef = useRef();

  const [openWalletModal, setOpenWalletModal] = useState(false);
  const toggleWalletModal = () => {
    setOpenWalletModal(!openWalletModal);
  };

  const history = useHistory();

  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    const page = matches.find(item => {
      if (history.location.pathname.startsWith(item.match)) return true;
      return false;
    });

    if (!page) {
      if (history.location.pathname === "/") setPageTitle("Home");
    } else {
      setPageTitle(page.title);
    }
  }, [history.location.pathname]);

  const isWide = useMedia({ minWidth: "450px" });

  function debounce(func, wait, immediate) {
    let timeout;
    return function () {
      const context = this;
      const args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
  const handleScroll = debounce(e => {
    const currentScrollPos = e.srcElement.scrollTop;

    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);

    setPrevScrollPos(currentScrollPos);
  }, 1);

  useEffect(() => {
    if (bodyRef && bodyRef.current) bodyRef.current.addEventListener("scroll", handleScroll);
    const bodyRefCleanup = bodyRef.current;
    return () => {
      if (bodyRef && bodyRefCleanup) {
        bodyRefCleanup.removeEventListener("scroll", handleScroll);
      }
    };
  }, [prevScrollPos, visible, handleScroll, bodyRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && event.target !== avatarRef.current) {
        setActiveSettings(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && event.target !== avatarRef.current) {
        setActiveSettings(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileRef]);

  useEffect(() => {
    if (session) {
      setUserNick(session.user.nick);
      setUser(session.user);
    }
  }, [session]);

  // const passSearchText = event => {
  //   setSearchText(event.target.value);
  // };

  const handleItemClick = title => {
    // eslint-disable-next-line default-case
    switch (title) {
      case "Disconnect":
        history.push("/")
        logoutOfWeb3Modal();
        break;
      case "Profile":
        history.push("/profile");
        break;
    }
    setActiveSettings(false);
  };

  function connectWeb3() {
    detectMetamask(loadWeb3Modal);
  }

  const toggleNotification = open => {
    setVisibleNotification(prev => {
      if (typeof open === "boolean") return open;
      return !prev;
    });
  };

  return (
    <ModalProvider>
      <HeaderWrapper visible={visible} collapseSidebar={collapseSidebar}>
        {/* <LogoWrapper onClick={() => setMenuOpen(!menuOpen)}>
            <LogoIcon />
          </LogoWrapper> */}
        {/* <PageTitle />  //! This causes the Button of connect wallet to not being centered */}
        {session?.user && (
          <div style={{ marginRight: "63px" }}>
            {session && session.user?.address ? (
              <div
                style={{
                  background: "#3F338C",
                  color: "#fff",
                  width: "fit-content",
                  height: "37px",
                  fontSize: "14px",
                  padding: "13px 11px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "30px ",
                  gap: "8px",
                }}
              >
                {shortenAddress(session.user.address, 4)}
                <div
                  onClick={async () => {
                    await navigator.clipboard.writeText(session.user.address || "");
                  }}
                >
                  <Clipboard style={{ color: "#fff", height: "24px", cursor: "pointer" }} />
                </div>{" "}
              </div>
            ) : (
              <Button
                height="37px"
                padding="8px"
                borderRadius="30px"
                fontSize="14px"
                background="#3F338C"
                onClick={() => setOpenWalletModal(true)}
                data-testid="connect-wallet-button"
              >
                <WalletOutlined style={{ marginRight: "8px" }} />
                <p style={{ margin: "0", width: "110px"}}>Connect Wallet</p>
              </Button>
            )}
          </div>
        )}
        <HiddenDiv id="hidden-div" />
        <div id="connectAccount" style={{ display: "flex", alignItems: "center" }}>
          {session && session.user && (
            <IconWrapper>
              {/* Change to MessageIconRef */}
              {/* <MessageIconWrapper icon="messages" ref={notificationIconRef}>
                  <span>
                    <MessagesIcon />
                  </span>
                </MessageIconWrapper> */}
              <NotificationIconWrapper icon="notification" ref={notificationIconRef}>
                <span onClick={toggleNotification} style={{ display: "flex", alignItems: "center" }}>
                  {session && session.user && session.user.unreadMessages > 0 ? (
                    <NotificationIcon />
                  ) : (
                    <NoNotificationIcon />
                  )}
                </span>
                <Notification
                  open={visibleNotification}
                  toggle={toggleNotification}
                  notificationIconRef={notificationIconRef}
                />
              </NotificationIconWrapper>
            </IconWrapper>
          )}
          {session && session.user ? (
            <UserInfoWrapper onClick={() => setActiveSettings(prev => !prev)}>
              <NickName>{user.nick}</NickName>
              <div ref={avatarRef}>
                <Avatar src={user.thumbnail ? user.thumbnail : ""} alt="" />
              </div>
            </UserInfoWrapper>
          ) : (
            <Button
              height="35px"
              width="110px"
              borderRadius="30px"
              fontSize="14px"
              onClick={() => setShowAuthModal(true)}
              data-testid="connect-button"
              id="analytics-sign-in"
            >
              Sign In
            </Button>
          )}
          {isWide ? (
            <DesktopContainer>
              <UserSettingWrapper ref={ref} activeSettings={activeSettings}>
                <SettingsBar>
                  {settingsItems.map(
                    item =>
                      item.desktop && (
                        <SettingsItem
                          key={item.title}
                          onClick={() => {
                            handleItemClick(item.title);
                          }}
                          id={item.title === "Profile" ? "analytics-profile" : ""}
                        >
                          <IconWrapper>{item.icon}</IconWrapper>
                          <span>{item.title}</span>
                        </SettingsItem>
                      ),
                  )}
                </SettingsBar>
              </UserSettingWrapper>
            </DesktopContainer>
          ) : (
            <MobileContainer>
              <UserSettingWrapper ref={mobileRef} activeSettings={activeSettings}>
                <SettingsBar>
                  {settingsItems.map(item => (
                    <SettingsItem
                      key={item.title}
                      onClick={() => {
                        handleItemClick(item.title);
                      }}
                      id={item.title === "Profile" ? "analytics-profile" : ""}
                    >
                      <IconWrapper>{item.icon}</IconWrapper>
                      <span>{item.title}</span>
                    </SettingsItem>
                  ))}
                </SettingsBar>
              </UserSettingWrapper>
            </MobileContainer>
          )}
        </div>
      </HeaderWrapper>

      <ConnectWalletModal
        loadWeb3Modal={loadWeb3Modal}
        showModal={openWalletModal}
        setShowModal={setOpenWalletModal}
        toggleModal={toggleWalletModal}
        displaySelectionModal={displaySelectionModal}
        resumePrevFlow={() => {}}
      />
    </ModalProvider>
  );
}
