/* eslint-disable no-underscore-dangle */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import {
  MoneyIcon,
  HealthIcon,
  RadioIcon,
  BinocularsIcon,
  HealthBarIcon,
  HealthBarArrow,
  HorizontalArrow,
} from "../../assets/svg";

export default function ErrorPage() {
  useEffect(() => {
    (function () {
      // Add event listener
      const elem = document.querySelector("#parallax");
      // Magic happens here
      function parallax(e) {
        const _w = window.innerWidth / 2;
        const _h = window.innerHeight / 2;
        const _mouseX = e.clientX * 0.5;
        const _mouseY = e.clientY * 0.3;
        const _depth1 = `${50 - (_mouseX - _w) * 0.01}% ${50 - (_mouseY - _h) * 0.01}%`;
        const _depth2 = `${50 - (_mouseX - _w) * 0.02}% ${50 - (_mouseY - _h) * 0.02}%`;
        const _depth3 = `${50 - (_mouseX - _w) * 0.06}% ${50 - (_mouseY - _h) * 0.06}%`;
        const x = `${_depth3}, ${_depth2}, ${_depth1}`;

        elem.style.backgroundPosition = x;
      }
      document.addEventListener("mousemove", parallax);
    })();
  }, []);
  const history = useHistory();
  return (
    <S.Background id="parallax">
      <S.Circle>
        <S.HealthBarContainer>
          <HealthBarIcon />
          <S.Flex>
            <S.HealthText>
              3 / <span style={{ fontWeight: "100", fontSize: "18px" }}>12</span>
            </S.HealthText>
            <S.HealthBarHoverContent>
              <HealthBarArrow />
              <S.HealthBarHoverText>Yeah! there are even more tournaments</S.HealthBarHoverText>
            </S.HealthBarHoverContent>
          </S.Flex>
        </S.HealthBarContainer>
        <S.PageContent>
          <S.PageHeader>404</S.PageHeader>
          <S.LargeSubtext>Damn! We couldn't find your enemies here</S.LargeSubtext>
          <S.SmallSubtext>The enemy is not here</S.SmallSubtext>
          <S.Button onClick={() => history.push("/")}>Go Home</S.Button>
          <S.IconsSection>
            <S.IconContainer>
              <HealthIcon />
            </S.IconContainer>
            <div>
              Go for them <HorizontalArrow style={{ margin: "0 20px" }} />
            </div>

            <S.IconContainer>
              <MoneyIcon />
            </S.IconContainer>
            <div>Go earn some money</div>

            <S.IconContainer>
              <RadioIcon />
            </S.IconContainer>
            <div>
              Go for them <HorizontalArrow style={{ margin: "0 20px" }} />
            </div>

            <S.IconContainer>
              <BinocularsIcon />
            </S.IconContainer>
            <div>
              Go for them <HorizontalArrow style={{ margin: "0 20px" }} />
            </div>
            <S.CircleOutline />
          </S.IconsSection>
        </S.PageContent>
      </S.Circle>
    </S.Background>
  );
}
