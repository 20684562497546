import React from "react";
import * as S from "../styles";
import PlayerRow from "../PlayerRow/PlayerRow";
import Pagination from "../../../Pagination";
import img1 from "../../../../../assets/leaderboard-img-1.png";
import img2 from "../../../../../assets/leaderboard-img-2.png";
import img3 from "../../../../../assets/leaderboard-img-3.png";
import { LEADERBOARD_PAGE_LIMIT, LEADERBOARD_ROWS, MOBILE_LEADERBOARD_PAGE_LIMIT } from "../../../../../constants/env";

const images = [img1, img2, img3];
const randomIndex = Math.floor(Math.random() * 2);

export default function KoinsLeaderboard({
  koinsLeaderboardHeaders,
  leaderBoardInfo,
  isMobile,
  gamePlatform,
  joinMode,
  selectPlayerForTemplate,
  hasTemplate,
}) {
  const koinsLeaderboardData = leaderBoardInfo.map(data => ({ ...data, dynamicHeaders: koinsLeaderboardHeaders }));
  return koinsLeaderboardHeaders.length > 0 ? (
    <>
      {!isMobile && (
        <S.RowHeaderWrapper colCount={koinsLeaderboardHeaders.length + 1}>
          <S.HeaderText>Ranking</S.HeaderText>
          {koinsLeaderboardHeaders.map(item => (
            <S.HeaderText>{item}</S.HeaderText>
          ))}
        </S.RowHeaderWrapper>
      )}
      <Pagination
        data={koinsLeaderboardData}
        isMobile={isMobile}
        RenderComponent={props => (
          <PlayerRow
            {...props}
            joinMode={joinMode}
            selectPlayerForTemplate={selectPlayerForTemplate}
            hasTemplate={hasTemplate}
          />
        )}
        pageLimit={isMobile ? Number(MOBILE_LEADERBOARD_PAGE_LIMIT) || 3 : Number(LEADERBOARD_PAGE_LIMIT) || 10}
        dataLimit={Number(LEADERBOARD_ROWS) || 5}
      />
    </>
  ) : (
    <S.ImageWrapper>
      <img src={images[randomIndex]} alt="" />
    </S.ImageWrapper>
  );
}
