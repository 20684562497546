import React from "react";
import * as S from "./styles";

export default function Input({ width, height, label, placeholder, inputValue, onchange }) {
  return (
    <S.MainWrapper>
      <S.Input
        type="text"
        width={width}
        height={height}
        onChange={e => {
          onchange(e.target.value);
        }}
        value={inputValue}
        placeholder={placeholder}
        required
      />
      <S.Label>{label}</S.Label>
    </S.MainWrapper>
  );
}
