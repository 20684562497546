import styled from "styled-components";

export const DottedMenuCotnainer = styled.div`
  position: relative;
`;

export const DottedMenuButton = styled.button`
  cursor: pointer;
  height: 40px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
`;

export const MenuContent = styled.div`
  /* background-color: #201366; */
  background-color: #3f2faa;
  /* border: 1px solid #4e3dca; */
  width: fit-content;
  position: absolute;
  right: -16px;
  padding: 0;
  border-radius: 6px;
  color: #fff;
  z-index: 999;
  overflow: visible !important;
  min-width: 120px;
`;
