import React, { useState } from "react";
import * as S from "../Teams/styles";
import PrizeRow from "./PrizeRow";

const PrizeSetion = ({ prizes }) => {
  const [currentTab, setCurrentTab] = useState("all");

  return (
    <div>
      <S.TabsBar>
        <S.TabButton
          onClick={() => {
            // setPoolsToDisplay([]);
            setCurrentTab("all");
          }}
          border={currentTab === "all" ? "4px solid #3F2FAA" : "4px solid #201366"}
        >
          All
        </S.TabButton>
        <S.TabButton
          onClick={() => {
            // setPoolsToDisplay([]);
            setCurrentTab("active");
          }}
          border={currentTab === "active" ? "4px solid #3F2FAA" : "4px solid #201366"}
        >
          Active
        </S.TabButton>
        <S.TabButton
          onClick={() => {
            // setPoolsToDisplay([]);
            setCurrentTab("won");
          }}
          border={currentTab === "won" ? "4px solid #3F2FAA" : "4px solid #201366"}
        >
          Won
        </S.TabButton>
      </S.TabsBar>

      {prizes?.map(prize => {
        return <PrizeRow prize={prize} />;
      })}
    </div>
  );
};

export default PrizeSetion;
