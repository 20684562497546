import styled from "styled-components";

export const Container = styled.div`
  padding: 26px;
  padding-top: 21px;
  padding-bottom: 34px;
  width: 457px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    padding-top: 21px;
    padding-bottom: 34px;
  }
`;

export const Card = styled.div`
  //   width: 551px;
  height: 390px;
  background: #3f2faa;
  border-radius: 6px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 19px;
  padding-top: 21px;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Flex = styled.div`
  display: flex;

  ${props => `
  row-gap: ${props.rowGap ? `${props.rowGap}px` : "unset"}};
  column-gap: ${props.columnGap ? `${props.columnGap}px` : "unset"}};
  flex-direction: ${props.direction ? props.direction : "row"};
  align-items: ${props.alignItems ? props.alignItems : "unset"};
  justify-content: ${props.justifyContent ? props.justifyContent : "unset"};
`}
`;

export const PlayerListContainer = styled.div`
  background: #36279c;
  border: 1px solid #5647b5;
  border-radius: 4px;
  height: 201px;
  width: 100%;
  padding-left: 26px;
  padding-right: 10px;
  padding-top: 16px;
`;

export const PlayerRow = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 21px;
  align-items: center;

  div:nth-child(1) {
    width: 24px;
  }
`;

export const PlayerListInner = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 6px;
    padding: 20px;
    margin: 20px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: #2b1c7e;
    padding: 0 4px;
    border-radius: 24px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #3f338c;
  }
`;

export const InputContainer = styled.div`
  width: 401px;
  height: 38px;
  background: #5443c7;
  border-radius: 19px;
  margin-bottom: 15px;
  position: relative;
  color: #fff;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledInput = styled.input`
  height: 100%;
  width: 100%;
  background: #5443c7;
  color: #fff;
  border-radius: 19px;
  border: 0;
  outline: none;
  padding-left: 36px;
  // change placeholder color
  ::placeholder {
    color: #fff;
  }
`;

export const Text = styled.div`
  ${props => `
    color: ${props.color ? `${props.color}` : "#fff"}};
    font-size: ${props.size ? `${props.size}px` : "16px"}};
`}
`;

export const NotFound = styled.div`
  /* border: 2px solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: larger;
  padding-bottom: 10px;
`;
