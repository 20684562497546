import React from "react";
import { TwitterIcon, NeedMoreIcon, DiscordIcon, InstagramIconNew } from "../../../../assets/svg";
import * as S from "./styles";
import { BLAST_DISCORD_ALT } from "../../../../constants";

const MobileSocialNav = () => {
  return (
    <S.NavBottomContainer>
      <S.NavBottomWrapper>
        <S.NavBottomItem>
          <S.IconWrapper>
            <NeedMoreIcon />
          </S.IconWrapper>
          <S.SocialLinks href={BLAST_DISCORD_ALT} target="_blank">
            Need help ?
          </S.SocialLinks>
        </S.NavBottomItem>
        <S.NavSocialLinkWrapper>
          <S.SocialIconWrapper>
            <S.SocialLinks href="https://www.instagram.com/blastgmi" target="_blank">
              <InstagramIconNew />
            </S.SocialLinks>
          </S.SocialIconWrapper>
          <S.SocialIconWrapper>
            <S.SocialLinks href="https://twitter.com/blastgmi" target="_blank">
              <TwitterIcon />
            </S.SocialLinks>
          </S.SocialIconWrapper>
          <S.SocialIconWrapper>
            <S.SocialLinks href={BLAST_DISCORD_ALT} target="_blank">
              <DiscordIcon />
            </S.SocialLinks>
          </S.SocialIconWrapper>
        </S.NavSocialLinkWrapper>
      </S.NavBottomWrapper>
    </S.NavBottomContainer>
  );
};

export default MobileSocialNav;
