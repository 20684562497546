import React, { useState } from "react";
import * as S from "./styles";
import { Button } from "../../components/v2/common/Button";
import Flex from "../../components/v2/common/Flex";
import { Text } from "../../components/v2/common/Text";
import { Link } from "react-router-dom";
import { SpinnerLoader } from "../../components/v2/common/SpinningLoader";
import Avatar from "../../components/v2/common/Avatar";
import { TeamDefaultAvatar } from "../../assets/svg";

const RequestRow = ({ req, acceptJoinInvite, isMobile }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <S.MemberRow>
      <Flex alignItems="center" columnGap="24">
        {req.thumbnail ? <Avatar size="66.99px" src={req?.thumbnail} /> : <TeamDefaultAvatar />}
        <Text fontSize="18px" style={{ marginBottom: 0 }}>
          You have been invited to join{" "}
          <Link to={`team-profile/${req.teamId}`} style={{ textDecoration: "underline" }}>
            {req.name}
          </Link>
        </Text>
      </Flex>
      <Flex alignItems="center" columnGap={isMobile ? "2" : "13"} extraStyles={{ "margin-right": "34.31px" }}>
        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <>
            <Button
              paddingLeft={isMobile ? "6px" : "16px"}
              paddingRight={isMobile ? "12px" : "16px"}
              background="transparent"
              color="#877ADD"
              fontSize="16px"
              style={{ textDecoration: "underline" }}
              onClick={() => acceptJoinInvite(req.teamId, "rejected", setIsLoading)}
            >
              Deny
            </Button>
            <Button
              fontSize="16px"
              width={isMobile ? "auto" : "105px"}
              height="36px"
              background="#6C47FF"
              borderRadius="18.5px"
              onClick={() => acceptJoinInvite(req.teamId, "accepted", setIsLoading)}
            >
              Accept
            </Button>
          </>
        )}
      </Flex>
    </S.MemberRow>
  );
};

export default RequestRow;
