import styled, { keyframes } from "styled-components";
import { breakpoints } from "../../../../themes";

export const ButtonContainer = styled.div`
  margin-top: 42px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 25px;
  align-items: center;
`;

export const Container = styled.div``;

export const CardsContainer = styled.div`
  display: grid;
  gap: 23px;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 940px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
    width: 300px;
    justify-items: center;
  }
`;

export const TopContainer = styled.div`
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`;

export const NoKoinsContainer = styled.div`
  background-color: #272054;
  border: 1px solid #3f338c;
  border-radius: 6px;
  height: 337px;
  width: 1013px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

export const NoKoinsTextContainer = styled.div`
  width: 60%;
`;

export const NoKoinsTitle = styled.h1`
  color: #d1ccef;
  font-size: 26px;
  font-weight: 325;
  letter-spacing: 0px;
  padding-bottom: 10px;
  text-align: center;
  ${breakpoints.down(breakpoints.mobileL)} {
    font-size: 20px;
  }
`;

export const NoKoinsText = styled.h3`
  font-style: normal;
  font-weight: 325;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #7a6cd0;
`;

export const Link = styled.a`
  text-decoration: underline;
`;

export const Card = styled.div`
  height: 337px;
  width: 236px;
  background: #272054;
  padding: 13px;
  padding-bottom: 16px;
  cursor: pointer;
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;
export const InfoRow = styled.div`
  display: flex;
  gap: 8px;
  div:nth-child(1) {
    color: #7f81b0;
    font-size: 11px;
  }
  div:nth-child(2) {
    color: #fff;
    font-size: 11px;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 18px;
  margin-bottom: 11px;
  div:nth-child(1) {
    color: #fff;
    font-size: 16px;
  }
  div:nth-child(2) {
    color: #7f81b0;
    font-size: 16px;
  }
`;

export const SubText = styled.div`
  color: #7a6cd0;
  font-size: 20px;
  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

export const SelectedKoinsCount = styled.div`
  color: #be7eff;
  font-size: 15px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const SelectContainer = styled.div`
  margin-bottom: 21.94px;
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    border: 1px solid #877add;
    outline: none;
    color: rgba(135, 122, 221, 1);
    width: 234.33px;  
  }

  .ant-select-arrow {
    display: none;
  }
  
}
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
`;

export const Checkbox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 2px solid;
  margin-top: 12px;
  border-color: ${props => (props.checked ? "#7485ff" : "#3A2F81")};
  border-radius: 4px;
  box-sizing: border-box;
  background: ${props => (props.checked ? "#3A2F81" : "#272054")};
  :hover {
    background: #3a2f81;
  }
  cursor: pointer;
`;

export const CheckBoxText = styled.p`
  position: relative;
  bottom: 5px;
  width: 260px;
  height: 32px;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  color: #877add;
  color: rgba(209, 204, 239, 1);
  cursor: pointer;
  width: min-content;
`;

export const Select = styled.select`
  background: #372d70;
  color: #fff;
  padding: 6px 4px;
  width: ${props => (props.width ? props.width : "")};
  height: ${props => (props.height ? props.height : "")};
  border: ${props => (props.hasError ? "1px solid red" : "0")};
  border-radius: 6px;
`;

export const Input = styled.input`
  background: ${props => (props.background ? props.background : "#372d70")};
  outline: none;
  border: ${props => (props.hasError ? "1px solid red" : "0")};
  width: ${props => (props.width ? props.width : "")};
  height: ${props => (props.height ? props.height : "")};
  border-radius: 6px;
  color: #fff;
  padding: 13px;
  @media (max-width: 768px) {
    font-size: 0.8rem;
    width: fit-content;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &: [type= "number" ] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #877add;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #877add;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #877add;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : "")};
  align-items: ${props => (props.alignItems ? props.alignItems : "")};
  gap: ${props => (props.gap ? props.gap : "")};
  width: ${props => (props.width ? props.width : "")};
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : "row")};
`;

export const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

export const Label = styled.label`
  color: ${props => (props.color ? props.color : "#fff")};
  size: ${props => (props.fontSize ? props.fontSize : "14px")};
  text-align: ${props => (props.textAlign ? props.textAlign : "unset")};
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export const checkbox = styled.div`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

export const Indicator = styled.div`
  width: 1.2em;
  height: 1.2em;
  background: #302862;
  position: absolute;
  top: 0em;
  left: -1.6em;
  border: 1px solid #4a3e9c;
  border-radius: 0.2em;

  ${Input}:not(:disabled):checked & {
    background: #d1d1d1;
  }

  ${Label}:hover & {
    background: #3a2f81;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 0.1em;
    left: 0.35em;
    width: 35%;
    height: 70%;
    border: solid #aa57ff;
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  &::disabled {
    cursor: not-allowed;
  }
`;

export const FieldContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.gap ?? "unset"};
  align-items: ${props => props.alignItems ?? "unset"};
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: start;
  }
`;

export const FieldGroupContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 13px;
`;

export const JoinMFormModalHeader = styled.div`
  margin-bottom: 12px;
  font-size: 28px;
`;

export const ValidationGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  @media (max-width: 768px) {
    margin: auto;
  }
`;

export const DynamicFieldsContainer = styled.div`
  display: flex;

  max-height: 223px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #7265c8;
  }

  ::-webkit-scrollbar-thumb {
    background: #3f338ce6;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #3f338c;
  }
`;
