import React from "react";
import { useMemo } from "react";
import styled from "styled-components";
import { METAMASK_DEFAULT_CHAINS_ID } from "../../../constants";
import { CHAIN_ID, CHAIN_NAME, CHAIN_RPC_URL, CHAIN_TOKEN_NAME, CHAIN_TOKEN_SYMBOL, CHAIN_TOKEN_DECIMALS } from "../../../constants/env";
import { useNetworkListener } from "../../../hooks/useNetworkListener";
import { breakpoints } from "../../../themes";

const FullScreenWrapper = styled.div`
  transition: opacity 0.1s ease-in-out 0s;
  text-align: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  margin-left: -50vw;
  top: 0px;
  left: 50%;
  z-index: 1500;
  will-change: opacity;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
`;

const ModalContent = styled.div`
  position: fixed;
  top: 71px;
  right: 41px;
  width: 348px;
  height: 237px;
  border-radius: 10px;
  background: linear-gradient(249.73deg, #5487f4 2.87%, #8c54fd 89.48%);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 27px 38px 27px 31px;
  color: white;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  ${breakpoints.down(breakpoints.mobileL)} {
    right: 50%;
    transform: translateX(50%);
  }

  ${breakpoints.down("368px")} {
    width: 280px;
  }
  ${breakpoints.down("280px")} {
    width: 260px;
  }
`;

const Button = styled.button`
  border: none;
  border-radius: 30px;
  height: 40px;
  width: 157px;
  padding: 10px 16px;
  background: white;
  font-size: 14px;
  line-height: 20px;
  color: #29353e;
  margin-top: 28px;
  cursor: pointer;
  white-space: nowrap;
  ${breakpoints.down("280px")} {
    margin-top: 0px;
  }
`;

const chains = {
  137: {
    name: "Polygon",
    currency: "MATIC",
  },
  42: {
    name: "Kovan",
    currency: "KOV",
  },
  4: {
    name: "Rinkby",
    currency: "RIN",
  },
  11155111: {
    name: "Sepolia",
    currency: "ETH"
  }
};

function NetworkSwitch() {
  const { chainId } = useNetworkListener();
  
  const handleNetworkChange = () => {
    if (window.ethereum) {
      if (METAMASK_DEFAULT_CHAINS_ID.includes(CHAIN_ID)) {
        window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            { 
              chainId: `0x${typeof CHAIN_ID === "string" ? parseInt(CHAIN_ID).toString(16) : CHAIN_ID.toString(16)}` ,
            }
          ]
          });
      } else {
        window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            { 
              chainId: `0x${typeof CHAIN_ID === "string" ? parseInt(CHAIN_ID).toString(16) : CHAIN_ID.toString(16)}` ,
              rpcUrls: [CHAIN_RPC_URL],
              chainName: CHAIN_NAME,
              blockExplorerUrls: [CHAIN_RPC_URL],
              nativeCurrency: {
                name: CHAIN_TOKEN_NAME,
                symbol: CHAIN_TOKEN_SYMBOL,
                decimals: +CHAIN_TOKEN_DECIMALS
              }
            }
          ],
        });
      };
    }
  };

  const switchNetwork = useMemo(() => {
    return (
      <>
        {chainId !== undefined && chainId !== CHAIN_ID ? (
          <FullScreenWrapper>
            <ModalContent>
              {localStorage.getItem("connected_with") === "metamask" ? (
                <>
                  <p>Blast runs on {chains[CHAIN_ID]?.name} Network, Please switch the network.</p>
                  <Button onClick={handleNetworkChange}>Switch to {chains[CHAIN_ID]?.name}</Button>{" "}
                </>
              ) : (
                <p>Blast runs on {chains[CHAIN_ID]?.name} Network, Please switch your network from your wallet</p>
              )}
            </ModalContent>
          </FullScreenWrapper>
        ) : (
          ""
        )}
      </>
    );
  }, [chainId]);

  return switchNetwork;
}

export default NetworkSwitch;
