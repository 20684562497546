import styled from "styled-components";
import { breakpoints } from "../../../../themes";

export const ButtonContainer = styled.div`
  margin-top: 42px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 25px;
  align-items: center;
`;

export const Container = styled.div``;

export const CardsContainer = styled.div`
  display: grid;
  gap: 23px;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 940px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
    width: 300px;
    justify-items: center;
  }
`;

export const NoKoinsContainer = styled.div`
  background-color: #272054;
  border: 1px solid #3f338c;
  border-radius: 6px;
  height: 337px;
  width: 1013px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

export const NoKoinsTextContainer = styled.div`
  width: 60%;
`;

export const NoKoinsTitle = styled.h1`
  color: #d1ccef;
  font-size: 26px;
  font-weight: 325;
  letter-spacing: 0px;
  padding-bottom: 10px;
  text-align: center;
  ${breakpoints.down(breakpoints.mobileL)} {
    font-size: 20px;
  }
`;

export const NoKoinsText = styled.h3`
  font-style: normal;
  font-weight: 325;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #7a6cd0;
`;

export const Link = styled.a`
  text-decoration: underline;
`;

export const Card = styled.div`
  height: 337px;
  width: 236px;
  background: #272054;
  padding: 13px;
  padding-bottom: 16px;
  cursor: pointer;
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;
export const InfoRow = styled.div`
  display: flex;
  gap: 8px;
  div:nth-child(1) {
    color: #7f81b0;
    font-size: 11px;
  }
  div:nth-child(2) {
    color: #fff;
    font-size: 11px;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 18px;
  margin-bottom: 11px;
  div:nth-child(1) {
    color: #fff;
    font-size: 16px;
  }
  div:nth-child(2) {
    color: #7f81b0;
    font-size: 16px;
  }
`;

export const SubText = styled.div`
  color: #7a6cd0;
  font-size: 15px;
`;

export const SelectedKoinsCount = styled.div`
  color: #be7eff;
  font-size: 15px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  max-width: 583px;
`;
