import styled from "styled-components";
import { breakpoints } from "../../themes";

export const TournamentListPageWrapper = styled.div`
  position: relative;
`;

export const SectionContainer = styled.div`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 45px;
  margin: 107px auto 0;
  max-width: 1087px;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
    padding: 23px;
    padding-bottom: 128px;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const FilterTitle = styled.p`
  font-size: 14px;
  line-height: 36px;
  margin-bottom: 23px;
  color: white;
  padding-bottom: 0px;
  position: relative;
  text-decoration: none;

  ${props =>
    props.active &&
    ":after {background: #f00;background: linear-gradient(0.82turn, #a55bfe, #7481f8);content: '';position: absolute;bottom: 0;left: 0;height: 2px;width: 100%;}"};

  :hover {
    cursor: pointer;
    :after {
      background: #f00;
      background: linear-gradient(0.82turn, #a55bfe, #7481f8);
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
    }
  }
`;

export const DottedMenuCotnainer = styled.div`
  position: relative;
`;

export const MenuContent = styled.div`
  background-color: #3F2FAA;
  width: 233px;
  position: absolute;
  right: 0;
  padding: 5px 8px;
  border-radius: 6px;
  color: #fff;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  div {
    margin: 15px 0px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: #be7eff;
    }
  }
  button {
    font-size: 14px;
    height: 34px;
    cursor: pointer;
    background: #4E3CCA;
    border: none;
    border-radius: 4px;
    width: 100%;
    &:hover {
      color: #be7eff;
    }
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding-left: 9px;
  }
  z-index: 999;
`;

export const TopMenu = styled.div`
  width: 1087px;
  display: flex;
  justify-content: space-between;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

export const DottedMenuButton = styled.button`
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
`;

export const HeaderContainer = styled.div`
  margin-top: 57px;
  margin-left: 45px;
  margin-right: 67px;
  max-width: 1087px;
  @media (min-width: 1570px) {
    margin: 107px auto 0;
  }
`;

export const HeaderTitle = styled.div`
  font-size: 26px;
  line-height: 35px;
  color: white;
  margin-bottom: 19px;
  ${breakpoints.down(breakpoints.tablet)} {
    position: absolute;
    top: -150px;
    width: 180px;
    font-size: 22px;
  }
`;

export const NotificationContainer = styled.div`
  position: relative;
  background-color: #342788;
  width: 1087px;
  // min-height: 400px;
  border-radius: 10px;
  overflow: hidden;
  min-height: 400px;
  @media (min-width: 1570px) {
    margin: auto;
  }
  margin-bottom: 95px !important;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;
export const NotificationListContainer = styled.div`
  margin-bottom: 180px;
`;

export const NotificationBlock = styled.div`
  background-color: ${props => (props.unread ? "#3F2FAA" : "#342788")};
  display: flex;
  align-items: center;
  padding-left: 26px;
  border-bottom: 1px solid #4e3cca;
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    color: #fff;
    margin: 0px;
    padding: 0px;
  }
  cursor: pointer;
  transition: all 0.5s linear;
`;

export const LoaderWrapper = styled.div`
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotificationContent = styled.div`
  display: flex;
`;

export const NotificationIcon = styled.div`
  min-width: 11px;
  height: 11px;
  background: ${props =>
    props.unread ? "linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%)" : "transparent"};
  border-radius: 8px;
  margin-right: 27px;
  margin-top: 32px;
  align-self: flex-start;
`;

export const NotificationCheckboxContainer = styled.div`
  min-width: 11px;
  height: 11px;
  border-radius: 8px;
  margin-right: 27px;
  margin-top: 32px;
  align-self: flex-start;
`;

export const NotificationCheckbox = styled.input`
  min-width: 11px;
  height: 11px;
  border-radius: 8px;
  margin-right: 27px;
  margin-top: 32px;
  align-self: flex-start;
`;
export const NotificationDescription = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0px;
  color: #fff;
  overflow: hidden;
  width: 854px;
  max-height: ${props => (props.showDesc ? "75px" : "0")};
  margin: ${props => (props.showDesc ? "8px auto 16px" : "0")};
  transition: all 0.25s ease-in;

  ${breakpoints.down(breakpoints.tablet)} {
    width: fit-content;
    max-height: ${props => (props.showDesc ? "250px" : "0")};
  }
`;
export const NotificationHeader = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #fff;
  margin-top: 32px;
`;
export const NotificationSubtext = styled.div`
  margin-bottom: 23px;
  display: flex;
  align-items: center;
  small {
    color: #877add;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
  }
  span {
    display: inline-block;
    height: 3px;
    width: 3px;
    background-color: #877add;
    margin-right: 9px;
    margin-left: 7px;
    border-radius: 8px;
  }
`;

export const NoNotificationBlock = styled.div`
  display: flex;
  height: 400px;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 48px;
  right: 50%;
  transform: translateX(50%);
  background: #4e3cca;
  width: 143px;
  display: flex;
  justify-content: space-between;
  border-radius: 18px;
  align-items: center;
  p {
    color: #fff;
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const Arrow = styled.button`
  width: 36px;
  height: 36px;
  background-color: #4e3cca;
  border: none;
  color: #877add;
  border-radius: 500px;
  cursor: pointer;
  margin: 0px 4px;
  &.disabled {
    cursor: default;
    opacity: 0.4;
  }
`;

export const AdWrapper = styled.div`
  position: absolute;
  top: -108px;
  max-width: 1087px;
  width: 1087px;
  transform: translateX(-50%);
  left: 50%;

  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
    img {
      width: 800px;
      height: 400px;
      transform: translateX(10%);
    }
  }
  @media (max-width: 500px) {
    img {
      width: 800px;
      height: 400px;
      transform: translateX(-10%);
    }
  }
  @media (max-width: 425px) {
    img {
      width: 800px;
      height: 400px;
      transform: translateX(-35%);
    }
  }
`;

export const AdTextWrapper = styled.div`
  position: absolute;
  top: 101px;

  & > p {
    color: white;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 0px;
  }

  & span {
    color: #be7eff;
  }

  ${breakpoints.down(breakpoints.tablet)} {
    display: none;
  }
`;

export const Link = styled.a`
  color: white;
  text-decoration: none;
  &:hover {
    color: #877add;
    text-decoration: underline;
  }
`;
