import React from "react";
import styled from "styled-components";

import { breakpoints } from "../../themes";
import TournamentCardLoader from "./TournamentCardLoader";

const TournamentListContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  min-height: 325px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${breakpoints.up(breakpoints.tablet)} {
    display: grid;
    grid-template-columns: ${props =>
      !props.isLoading && props.length >= 1 ? "repeat(auto-fill, minmax(300px, 1fr))" : "1fr"};
    align-self: center;
    justify-self: center;
  }
`;
const TournamentListLoader = () => {
  return (
    <TournamentListContainer length={12}>
      {[...Array(6).keys()].map(() => (
        <TournamentCardLoader />
      ))}
    </TournamentListContainer>
  );
};

export default TournamentListLoader;
