import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import rotateAnimation from "../../assets/lotties/rotate_animation.json";
import trackAnimation from "../../assets/lotties/track_animation.json";
import christmasAnimation from "../../assets/lotties/christmas_animation.json";

const Wrapper = styled.div`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 100vh;
  width: 100vw;
`;

const FullScreenWrapper = styled.div`
  transition: opacity 0.1s ease-in-out 0s;
  text-align: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  margin-left: -50vw;
  top: 0px;
  left: 50%;
  z-index: 1500;
  will-change: opacity;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
`;

function Loader({ animation, width, height, fullScreen, noWrapper = false }) {
  const defaultOptions = {
    // animationData: animation === "rotate" ? rotateAnimation : trackAnimation,
    // animationData: rotateAnimation,
    animationData: christmasAnimation,
  };

  if (noWrapper) {
    return <Lottie options={defaultOptions} height={height || 170} width={width || 170} />;
  }

  return (
    <>
      {fullScreen ? (
        <FullScreenWrapper>
          <Lottie options={defaultOptions} height={height || 170} width={width || 170} />
        </FullScreenWrapper>
      ) : (
        <Wrapper>
          <Lottie options={defaultOptions} height={height || 170} width={width || 170} />
        </Wrapper>
      )}{" "}
    </>
  );
}

export default Loader;
