import React, { useEffect, useState } from "react";
import * as S from "./styles";
import Flex from "../../../components/v2/common/Flex";
import { Button } from "../../../components/v2/common/Button";
import AccountSettings from "./AccountSettings";
import NotificationSettings from "./NotificationSettings";
import { useLocation } from "react-router-dom";

const ProfileSettings = () => {
  const [activeSection, setActiveSection] = useState("account");
  const isAccountSectionActive = activeSection === "account";
  const isNotifSectionActive = activeSection === "notification";

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const showNotifSettings = queryParams.get("showNotifSettings");

  useEffect(() => {
    if (showNotifSettings) setActiveSection("notification");
  }, [showNotifSettings]);

  return (
    <>
      <S.SettingsBtnContainer>
        <S.SectionBtn active={isAccountSectionActive} onClick={() => setActiveSection("account")}>
          Account
        </S.SectionBtn>
        <S.SectionBtn active={isNotifSectionActive} onClick={() => setActiveSection("notification")}>
          Notification
        </S.SectionBtn>
      </S.SettingsBtnContainer>

      {isAccountSectionActive && <AccountSettings />}
      {isNotifSectionActive && <NotificationSettings />}
    </>
  );
};

export default ProfileSettings;
