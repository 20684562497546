/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import moment, { duration } from "moment-timezone";
import * as S from "./styles";
import { useAppState } from "../../../providers/AppStateProvider";
import { useGetSecHeaders, usePriceConverter } from "../../../hooks";
import newDefaultAvatar from "../../../assets/svg/new-default-avatar.svg";
import {
  CalendarIcon,
  CoinIcon,
  CupIcon,
  Less24Hours,
  Livecam,
  LivecamWhite,
  ProfileIcon,
  TeamIcon,
  TicketIcon,
  UserIcon,
} from "../../../assets/svg";
import "./TournamentCard.css";
import { PoolStatus } from "../../../constants/games";
import { formatDateTime, cutUnderNDigits, truncateWithDots, padString, toUpperCase } from "../../../helpers/utils";
import Loader from "../Loader";
import { breakpoints } from "../../../themes";
import Avatar from "../common/Avatar";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";

const CardContainer = styled.div`
  width: 100%;
  height: 380px;
  display: inline-block;
  border-radius: 6px;
  background-color: #3f2faa;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    margin-top: -5px;
    box-shadow: 0px 12px 30px 0px rgba(26, 14, 86, 1);
    cursor: hover;
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  ${breakpoints.down(breakpoints.mobileL)} {
    min-height: 430px;
  }
`;

const Container = styled.div`
  position: relative;
  opacity: 0.9999999;
  ${breakpoints.down(breakpoints.mobileL)} {
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  width: 90%;
  height: 168px;
  background: url(${props => props.bgImage});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  overflow: hidden;
  opacity: ${props => (props.isFinished ? "0.4" : "1")};
  position: relative;
  top: 14px;
  left: 50%;
  transform: translateX(-50%);
  ${breakpoints.down(breakpoints.mobileL)} {
    width: 90%;
    height: 195px;
  }
`;

const Img = styled.img`
  display: ${props => (props.showItem || props.playing || props.isFinished ? "block" : "none")};
  ${breakpoints.down(breakpoints.mobileL)} {
    width: 100%;
    height: 195px;
  }
`;

const TeamContainer = styled.div`
  background: rgba(63, 47, 170, 0.5);
  border-radius: 6px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  left: 8px;
  :hover {
    background: rgba(63, 47, 170, 1);
  }
`;

const GameNameContainer = styled.div`
  background: rgba(63, 47, 170, 0.5);
  border-radius: 6px;
  width: fit-content;
  height: 34px;
  display: flex;
  padding: 6px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  left: ${props => (props.leaveSpaceForTeamIcon ? "48px" : "8px")};
  :hover {
    background: rgba(63, 47, 170, 1);
  }

  /* add a small dark background */
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0.1;
    filter: blur(10px);
    border-radius: 6px;
  }
`;

const LiveWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: #eb0400;
  border-radius: 3px;
  width: 49px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  & > p {
    margin: 0;
    font-size: 10px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: center;
    color: #ffffff;
  }

  & > svg {
    padding-bottom: 2px;
  }
`;

const PoolDataWrapper = styled.div`
  padding-right: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const PoolNameText = styled.p`
  font-size: 18px;
  line-height: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
  max-width: 95%;
  height: 25px;
  padding-top: 8px;
  padding-bottom: 9px;
  margin: 0;
`;

const DescriptionText = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  margin: 0;
  color: #a498ed;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CurrencyText = styled.p`
  font-size: 26px;
  line-height: 25px;
  color: #cfdce8;
  margin-left: 16px;
  margin-bottom: 10px;
  letter-spacing: 3px;
  ${props =>
    props.sponsored &&
    "background-image: linear-gradient(to right, #12c2ea, #fc74ff); -webkit-background-clip: text; -webkit-text-fill-color: transparent; -moz-background-clip: text; -moz-text-fill-color: transparent;"};
`;

const JoinBtn = styled.button`
  width: 75px;
  height: 30px;
  border-radius: 4px;
  border: none;
  color: white;
  background-color: #3a2f81;
  font-size: 15px;
  border-radius: 50px;
  &:hover {
    cursor: pointer;
  }
`;

const StateWrapper = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3f2faa;
  width: ${props => (props.poolStatus === "JOINING" ? "140px" : "100px")};
  height: 34px;
  border: 2px solid
    ${props =>
      props.poolStatus === "FINISHED"
        ? "#FF5C5C"
        : props.poolStatus === "FINISHED_COLLECTING_DATA"
        ? "#FF5C5C"
        : props.poolStatus === "ARCHIVED"
        ? "#FF5C5C"
        : props.poolStatus === "RUNNING"
        ? "#B2FA4F"
        : ![
            "STARTED",
            "RUNNING",
            "NOT_STARTED",
            "FINISHED",
            "FINISHED_COLLECTING_DATA",
            "ARCHIVED",
            "JOINING",
            "PROCESSING_REGISTERED_USERS",
          ].includes(props.poolStatus)
        ? "#B2FA4F"
        : props.poolStatus === "NOT_STARTED" && props.isStarting24 === "startsMoreThan24Hours"
        ? "#BE7EFF"
        : props.poolStatus === "NOT_STARTED" && props.isStarting24 === "startsIn24Hours"
        ? "none"
        : props.poolStatus === "PROCESSING_REGISTERED_USERS" && props.isStarting24 === "startsMoreThan24Hours"
        ? "#BE7EFF"
        : props.poolStatus === "PROCESSING_REGISTERED_USERS" && props.isStarting24 === "startsIn24Hours"
        ? "#BE7EFF"
        : props.poolStatus === "JOINING"
        ? "none"
        : "#B2FA4F"};
  border-radius: 50px;
`;

const Border = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: ${props =>
    props.poolStatus === "NOT_STARTED" && props.isStarting24 === "startsMoreThan24Hours"
      ? "none"
      : props.poolStatus === "NOT_STARTED" && props.isStarting24 === "startsIn24Hours"
      ? "linear-gradient(to right, #E544FF, #FF9B18)"
      : props.poolStatus === "PROCESSING_REGISTERED_USERS" && props.isStarting24 === "startsMoreThan24Hours"
      ? "none"
      : props.poolStatus === "PROCESSING_REGISTERED_USERS" && props.isStarting24 === "startsIn24Hours"
      ? "none"
      : props.poolStatus === "JOINING"
      ? "linear-gradient(to right, #7283F7, #BE7EFF)"
      : ![
          "STARTED",
          "RUNNING",
          "NOT_STARTED",
          "FINISHED",
          "FINISHED_COLLECTING_DATA",
          "ARCHIVED",
          "JOINING",
          "PROCESSING_REGISTERED_USERS",
        ].includes(props.poolStatus)
      ? "none"
      : props.isStarting24 === "startsMoreThan24Hours"
      ? "none"
      : props.isStarting24 === "startsIn24Hours"
      ? "linear-gradient(to right, #E544FF, #FF9B18)"
      : "none"};

  &:before {
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 97%;
    height: 93%;
    border-radius: 50px;
    background: ${props =>
      props.isStarting24 === "startsIn24Hours" || props.poolStatus === "JOINING" ? "#3f2faa" : "none"};
  }
`;

const StateText = styled.div`
  position: absolute;
  margin: 0;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
`;

const DateTimeText = styled.div`
  font-size: 9px;
  margin-bottom: 0px;
  font-weight: 300;
  background: #3a2f81;
  color: #c3c4e1;
  padding: 0px 6px;
  align-self: center;
  border-radius: 2px;
  text-transform: uppercase;
`;

const TimeStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const TokenImg = styled.img`
  width: 11px;
  height: 9px;
`;

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0px 8px 16px;
  flex-direction: column;
  align-items: flex-start;
`;

const WinnerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 0px 6px 18px;
  font-size: 16px;
`;

const WinnerPosition = styled.div`
  color: #7f81b0;
  font-weight: normal;
  font-size: 9px;
`;
const WinnerName = styled.div`
  font-weight: 400;
  color: white;
  margin-left: 8px;
`;

const CountDownTimer = styled.span`
  font-size: 9px;
  color: #7f81b0;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  background-color: #3a2f81;
  padding: 1px 4px;
  border-radius: 2px;
`;

const PlayingWrapper = styled.div`
  height: 30px;
  width: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;

  p {
    margin: 0px;
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
  }
  div {
    width: 12px;
    height: 12px;
    background-color: transparent;
    opacity: 0.8;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 9px;
  }
  span {
    width: 8px;
    height: 8px;
    background: #b2fa4f;
    border-radius: 50px;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    /* transform: scale(1);
    animation: pulse 1.4s infinite;
    @keyframes pulse {
      0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
      }
      40% {
        transform: scale(1);
        box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.4);
      }
      100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      }
    } */
  }
`;

const Less24HoursContainer = styled.div`
  height: 30px;
  width: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  p {
    margin: 0px;
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
  }
  div {
    width: 12px;
    height: 12px;
    background-color: transparent;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 9px;
  }
`;

const EntryFeeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const DataWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
`;

const DetailsText = styled.span`
  font-size: 12px;
  line-height: 26px;
  color: #9f9bcd;
  align-self: center;
  margin-left: 3px;
`;

const BottomLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 16px;
`;

const PlayerIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

const CardContent = styled.div`
  height: 150px;
  width: 100%;
`;

const PlayingStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const WinnerNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const CurrencySmall = styled.span`
  color: #a498ed;
  font-size: 9px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  margin: 0;
`;

const TokenSymbolWrapper = styled.span`
  margin-right: 4px;
  margin-left: 1px;
`;

const TeamWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 17px;

  ${breakpoints.down(breakpoints.tablet)} {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
`;

const TournamentDataMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const TournamentDataWrapper = styled.div`
  display: flex;
  gap: 7px;
`;

const TournamentDataContainer = styled.div`
  display: flex;
  padding: 7px;
  align-items: center;
  border-radius: 6px;
  gap: 6px;
  background: #4e3cca;
`;

const CardDetailsText = styled.span`
  font-size: 11px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  margin: 0;
  color: #ffffff;
`;

export default function TournamentCard({
  image,
  unformattedStartDate,
  poolName,
  description,
  startDateTime,
  price,
  sponsored,
  playing,
  endDate,
  isFinished,
  winner,
  tokenName,
  players,
  entrance,
  leaderboard,
  poolId,
  tokenImage,
  poolStatus,
  headers,
  poolPrize,
  tokenSymbol,
  entranceTokenImage,
  entranceTokenSymbol,
  length,
  joinMode,
  gameId,
  winnerPrize = [],
  maxPlayers,
  tournCardLogo,
  organizerInfo = [],
  streamStatus,
}) {
  const getPlayers = leaderboard => {
    // get the players of leaderboard (leaderboard is an array of objects, inside each object there is a playerData object)
    const players = leaderboard?.map(player => player?.playerData) || [];
    // from playerData object, get the property players (which is an array of objects and get the length of each array)
    const playersLength = players.map(player => player.players?.length) || 0;
    // sum all the length of players array
    const totalPlayers = playersLength.reduce((a, b) => a + b, 0);
    return totalPlayers;
  };

  const [showItem, setShowItem] = useState(false);
  const [showTokenName, setShowTokenName] = useState(false);
  const [userStatusInPool, setUserStatusInPool] = useState(PoolStatus.NOT_JOINED);
  const history = useHistory();
  const { detectMetamask } = useAppState();
  const [timeState, setTimeState] = useState({});

  const { convertedPrice, conversionError } = usePriceConverter(tokenSymbol, poolPrize, "USD");
  const { convertedPrice: convertedEntranceFee, conversionError: conversionEntranceError } = usePriceConverter(
    tokenSymbol,
    entrance,
    "USD",
  );

  const onMouseOver = () => {
    setShowItem(true);
  };

  const onMouseOut = () => {
    setShowItem(false);
  };

  const showName = () => {
    setShowTokenName(true);
  };
  const hideName = () => {
    setShowTokenName(false);
  };

  useEffect(() => {
    if (poolStatus === "FINISHED" || poolStatus === "FINISHED_COLLECTING_DATA" || poolStatus === "ARCHIVED") {
      setUserStatusInPool(PoolStatus.FINISHED);
    } else if (poolStatus === "RUNNING") {
      if (leaderboard.findIndex(elem => elem.userId === headers.userId) !== -1) {
        setUserStatusInPool(PoolStatus.JOINED_STARTED);
      } else {
        setUserStatusInPool(PoolStatus.JOINED_NOT_STARTED);
      }
    } else {
      setUserStatusInPool(PoolStatus.NOT_JOINED);
    }
  }, [leaderboard, headers, poolStatus]);

  useEffect(() => {
    let timeLeft;
    const interval = setInterval(() => {
      const currentTime = Date.now();
      if (poolStatus === "NOT_STARTED") {
        timeLeft = moment.duration(unformattedStartDate - currentTime);
      } else if (poolStatus === "PROCESSING_REGISTERED_USERS") {
        timeLeft = moment.duration(endDate - currentTime);
      }
      setTimeState(timeLeft);
    }, 1000);
    return () => clearInterval(interval);
  }, [poolStatus]);

  const displayStatusText = status => {
    switch (status) {
      case "FINISHED":
        return <span data-testid="finished">FINISHED</span>;
      case "FINISHED_COLLECTING_DATA":
        return <span data-testid="finishing">FINISHING</span>;
      case "RUNNING":
        return (
          <PlayingWrapper data-testid="playing">
            <div>
              <span />
            </div>
            <p>PLAYING</p>
          </PlayingWrapper>
        );
      case "NOT_STARTED":
        return (
          <div data-testid="not-started">
            {timeState?._data?.days > 0 ? (
              `IN ${timeState?._data?.days} DAY${timeState?._data?.days > 1 ? "S" : ""}`
            ) : timeState?._data?.days <= 0 && timeState?._data?.hours > 0 ? (
              <Less24HoursContainer>
                <span>
                  <Less24Hours />
                </span>
                {`${padString(timeState?._data?.hours, 0) || "00"}:${padString(timeState?._data?.minutes, 0) || "00"}:${
                  padString(timeState?._data?.seconds, 0) || "00"
                }`}
              </Less24HoursContainer>
            ) : (
              `SOON`
            )}
          </div>
        );
      case "ARCHIVED":
        return <span data-testid="finished">FINISHED</span>;
      case "JOINING":
        return <span data-testid="joining">REGISTRATION OPEN</span>;
      case "PROCESSING_REGISTERED_USERS":
        return (
          <div data-testid="processing-registered-users">
            {timeState?._data?.days > 0 ? (
              `IN ${timeState?._data?.days} DAY${timeState?._data?.days > 1 ? "S" : ""}`
            ) : timeState?._data?.days <= 0 && timeState?._data?.hours > 0 ? (
              <Less24HoursContainer>
                <span>
                  <Less24Hours />
                </span>
                {`${padString(timeState?._data?.hours, 0) || "00"}:${padString(timeState?._data?.minutes, 0) || "00"}:${
                  padString(timeState?._data?.seconds, 0) || "00"
                }`}
              </Less24HoursContainer>
            ) : (
              `SOON`
            )}
          </div>
        );
      default:
        return (
          <PlayingWrapper>
            <div>
              <span />
            </div>
            <p>PLAYING</p>
          </PlayingWrapper>
        );
    }
  };

  const checkLess24Hours = poolStatus => {
    /* It checks the start time and the time now, if the difference is less than 24 hours it returns that starts in less than 24 hours, 
    otherwise it returns that starts in more than 24 hours */
    const timeRemaining = moment.duration(unformattedStartDate - Date.now());
    if (timeRemaining._data.days < 0 || poolStatus === "RUNNING") return "";
    if (timeRemaining._data.days === 0 && timeRemaining._data.hours > 0) {
      return "startsIn24Hours";
    } else {
      return "startsInMoreThan24Hours";
    }
  };

  return (
    <Link to={`/pool/${poolId}`}>
      <Container>
        <CardContainer
          onMouseOver={() => onMouseOver()}
          onFocus={() => onMouseOver()}
          onMouseOut={() => onMouseOut()}
          onBlur={() => onMouseOut()}
        >
          <ImageWrapper bgImage={image} isFinished={isFinished}>
            <Img showItem={showItem} playing={playing} isFinished={isFinished} />
            {joinMode === "TEAM" ? (
              <Tooltip
                title="Tournament for Teams"
                color="#7265C8"
                placement="top"
                overlayInnerStyle={{ borderRadius: "6px" }}
              >
                <TeamContainer className="team-container">
                  <TeamIcon />
                </TeamContainer>
              </Tooltip>
            ) : null}
            {tournCardLogo && (
              <GameNameContainer leaveSpaceForTeamIcon={joinMode === "TEAM"}>
                <img src={tournCardLogo} height="15px" style={{ maxWidth: "65px" }} />
              </GameNameContainer>
            )}
            {streamStatus?.isStreaming === true && (
              <LiveWrapper>
                <LivecamWhite />
                <p>LIVE</p>
              </LiveWrapper>
            )}
          </ImageWrapper>
          <CardContent>
            <DetailsWrapper>
              <TimeStatusWrapper>
                <StateWrapper poolStatus={poolStatus} isStarting24={checkLess24Hours(poolStatus)}>
                  <Border isStarting24={checkLess24Hours(poolStatus)} poolStatus={poolStatus}>
                    <StateText>{displayStatusText(poolStatus, unformattedStartDate)}</StateText>
                  </Border>
                </StateWrapper>
              </TimeStatusWrapper>
              <PoolDataWrapper>
                <PoolNameText>{poolName}</PoolNameText>
                <DescriptionText>{description}</DescriptionText>
              </PoolDataWrapper>
            </DetailsWrapper>
            {isFinished ? (
              <WinnerWrapper>
                <WinnerPosition>Prize Pool winner</WinnerPosition>
                <WinnerNameWrapper>
                  <CupIcon />
                  <Avatar src={winnerPrize[0]?.avatarUrl || newDefaultAvatar} size="18px" />
                  <WinnerName>
                    {(winnerPrize[0] && truncateWithDots(winnerPrize[0]?.nick, 16)) || "Not Available"}
                  </WinnerName>
                </WinnerNameWrapper>
              </WinnerWrapper>
            ) : (
              <DataWrapper>
                <TournamentDataMainWrapper>
                  <TournamentDataWrapper>
                    <TournamentDataContainer>
                      <CoinIcon width="16px" height="13px" />
                      <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                        <CardDetailsText>{poolPrize}</CardDetailsText>
                        {tokenImage ? (
                          <TokenImg src={tokenImage} width="11px" height="9px" />
                        ) : (
                          <TokenSymbolWrapper width="11px" height="9px">
                            {tokenSymbol}
                          </TokenSymbolWrapper>
                        )}
                      </div>
                      <CurrencySmall>${conversionError ? 0 : cutUnderNDigits(convertedPrice, 2)}</CurrencySmall>
                    </TournamentDataContainer>
                    <TournamentDataContainer>
                      <TicketIcon width="14px" height="11px" />
                      <EntryFeeContainer>
                        {entrance && entrance > 0 ? (
                          <div>
                            <CardDetailsText>{entrance}</CardDetailsText>
                            {tokenImage ? (
                              <TokenImg src={tokenImage} width="11px" height="9px" />
                            ) : (
                              <TokenSymbolWrapper width="11px" height="9px">
                                {tokenSymbol}
                              </TokenSymbolWrapper>
                            )}
                            <CurrencySmall>
                              $
                              {conversionEntranceError
                                ? 0
                                : convertedEntranceFee
                                ? cutUnderNDigits(convertedEntranceFee, 2)
                                : "0.00"}
                            </CurrencySmall>
                          </div>
                        ) : (
                          <CardDetailsText>Free Entry</CardDetailsText>
                        )}
                      </EntryFeeContainer>
                    </TournamentDataContainer>
                  </TournamentDataWrapper>
                  <TournamentDataWrapper>
                    <TournamentDataContainer>
                      <CalendarIcon />
                      <CardDetailsText>{toUpperCase(startDateTime)}</CardDetailsText>
                    </TournamentDataContainer>
                    {/* <TournamentDataContainer>
                    <CupIcon width="11px" height="9px" />
                    <CardDetailsText>Qualifier - Group 1 of 2 </CardDetailsText>
                    {/* TODO: Add dynamic data
                  </TournamentDataContainer> */}
                    {organizerInfo?.find(organizer => organizer?.role === "caster") && (
                      <TournamentDataContainer>
                        <Livecam />
                        <CardDetailsText>LIVE</CardDetailsText>
                      </TournamentDataContainer>
                    )}
                  </TournamentDataWrapper>
                </TournamentDataMainWrapper>
              </DataWrapper>
            )}
            <BottomLine>
              <PlayingStatus>
                {joinMode === "TEAM" && (
                  <TeamWrapper>
                    <PlayerIconWrapper>
                      <TeamIcon style={{ height: "15px" }} />
                      <DetailsText>
                        {leaderboard.length}/{maxPlayers}&nbsp;Teams
                      </DetailsText>
                    </PlayerIconWrapper>
                  </TeamWrapper>
                )}
                <PlayerIconWrapper>
                  <UserIcon />
                  <DetailsText>
                    {joinMode === "TEAM" ? `${getPlayers(leaderboard)}` : `${leaderboard.length}/${maxPlayers}`}
                    &nbsp;Players
                  </DetailsText>
                </PlayerIconWrapper>
              </PlayingStatus>
            </BottomLine>
          </CardContent>
        </CardContainer>
      </Container>
    </Link>
  );
}
