import React, { useEffect, useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { useWallet } from "../../providers/WalletProvider";
// import { addNetwork, POLYGON_NETWORK } from "../../constants/chains";
import { checkUserSessionState } from "../../state/checkUserSessionState";

// import { CHAIN_ID } from "../../constants/env";
// import { useNetworkListener } from "../../hooks/useNetworkListener";

const WindowFocusHandler = () => {
  const setCheckUserSession = useSetRecoilState(checkUserSessionState);
  // const { chainId } = useNetworkListener();
  const { injectedProvider } = useWallet();
  const addNewNetwork = useCallback(async () => {
    if (injectedProvider) {
      //  addNetwork(injectedProvider, POLYGON_NETWORK);
    }
  }, [injectedProvider]);

  // User has switched back to the tab
  const onFocus = () => {
    // check network -> force to Polygon
    addNewNetwork();
    // check 4 valid session
    setCheckUserSession(true);
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  });

  return <></>;
};

export default WindowFocusHandler;
