import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Crown, DottedMenu, OwnerKey, WarningIcon } from "../../../../assets/svg";
import { TOAST_OPTIONS } from "../../../../constants";
import { truncateWithDots } from "../../../../helpers/utils";
import Avatar from "../../common/Avatar";
import { Button } from "../../common/Button";
import Flex from "../../common/Flex";
import { SpinnerLoader } from "../../common/SpinningLoader";
import { TextNoMargin } from "../../common/Text";
import { ButtonContainer } from "../RequestRow/styles";
import * as S from "./styles";
import DottedMenuComponent from "../../DottedMenuComponent";

const toastOptions = {
  autoClose: 6000,
  hideProgressBar: true,
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
  bodyClassName: "toast-custom-body",
  className: "toast-custom-class",
  style: {
    color: "#D1CCEF",
    background: "#3A2F81",
    width: "400px",
    borderRadius: "6px",
    float: "right",
    marginRight: "63px",
  },
  closeButton: false,
};

const MemberRow = ({
  item,
  isOwner,
  handlePlayersListState,
  removeMemberFromTeam,
  cancelInvite,
  isMobile,
  activePools,
  handleCaptainChange,
  isCurrentUserCaptain,
}) => {
  const [isRowCaptain, setIsRowCaptain] = useState(false); // This variable is used to determine of this row member is captain. Not to determine if current logged in user is captain.
  const [isRowOwner, setIsRowOwner] = useState(false); // This variable is used to determine of this row member is owner. Not to determine if current logged in user is owner.
  const [isRowOwnerAndCaptain, setIsRowOwnerAndCaptain] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const [isLoadingCaptainChange, setIsLoadingCaptainChange] = useState(false);

  useEffect(() => {
    if (item?.role !== "owner_captain") {
      setIsRowCaptain(item?.role?.toLowerCase()?.includes("captain"));
      setIsRowOwner(item?.role?.toLowerCase()?.includes("owner"));
    }
    setIsRowOwnerAndCaptain(item?.role?.toLowerCase() === "owner_captain");
  }, [item?.role]);

  async function handleRemovePlayer(user) {
    setIsLoading(true);
    const res = await removeMemberFromTeam(user?.userId);

    if (res.err) {
      console.log(res.err);
      toast(
        <Flex alignItems="center">
          <WarningIcon style={{ height: "40px", width: "40px" }} />{" "}
          {res?.err?.response?.data?.message
            ? res?.err?.response?.data?.message?.[0]
            : "Something went wrong. Please try again!"}
        </Flex>,
        toastOptions,
      );
      setIsLoading(false);
      return;
    } else {
      handlePlayersListState("remove", user);
      setIsLoading(false);
    }
  }

  async function handleCancelInvite(user) {
    setIsLoading(true);
    const res = await cancelInvite(user?.userId);

    if (res.err) {
      console.log(res.err);
      toast(
        <Flex alignItems="center">
          <WarningIcon style={{ height: "40px", width: "40px" }} />{" "}
          {res?.err?.response?.data?.message
            ? res?.err?.response?.data?.message?.[0]
            : "Something went wrong. Please try again!"}
        </Flex>,
        toastOptions,
      );
      setIsLoading(false);
      return;
    } else {
      handlePlayersListState("cancel-request", user);
      setIsLoading(false);
    }
  }

  return (
    <S.RowContainer>
      <S.Member>
        <Avatar src={item?.thumbnail} />
        <TextNoMargin fontSize="16px">{isMobile ? truncateWithDots(item?.nick, 6) : item?.nick}</TextNoMargin>
        <Flex alignItems="center" columnGap="1">
          {isRowOwner || isRowOwnerAndCaptain ? <OwnerKey style={{ height: "15px", width: "20px" }} /> : null}
          {isRowCaptain || isRowOwnerAndCaptain ? <Crown style={{ height: "15px", width: "20px" }} /> : null}
        </Flex>
      </S.Member>
      <ButtonContainer>
        <div style={{ marginRight: "30px" }}>
          {/* Make captain button should be available only for the captain. The button should appear in rows of players that are part of the team (accepted status) and only on rows that are not already captain */}
          {(isCurrentUserCaptain || isOwner) && !isRowCaptain && !isRowOwnerAndCaptain && item?.status === "accepted" ? (
            <S.MakeCaptainBtn
              onClick={async () => {
                try {
                  setIsLoadingCaptainChange(true);
                  handleCaptainChange(item.userId, () => {
                    setIsLoadingCaptainChange(false);
                  });
                } catch (err) {
                  console.log(err);
                }
              }}
              disabled={isLoadingCaptainChange}
            >
              Make Captain
            </S.MakeCaptainBtn>
          ) : null}
        </div>
        {isLoading ? (
          <div style={{ marginRight: "48px" }}>
            <SpinnerLoader />
          </div>
        ) : isRowOwnerAndCaptain ? (
          <TextNoMargin fontSize="14px" fontWeight="350" lineHeight="32px" style={{ paddingRight: "30px" }}>
            Owner/Captain
          </TextNoMargin>
        ) : isRowOwner ? (
          <TextNoMargin fontSize="14px" fontWeight="350" lineHeight="32px" style={{ padding: "0 30px" }}>
            Owner
          </TextNoMargin>
        ) : isRowCaptain ? (
          <TextNoMargin fontSize="14px" fontWeight="350" lineHeight="32px" style={{ paddingRight: "30px" }}>
            Captain
          </TextNoMargin>
        ) : (isOwner || isCurrentUserCaptain) ? (
          item?.status == "pending-confirm" ? (
            <Button
              background="#6C47FF"
              height="37px"
              width={isMobile ? "fit-content" : "110px"}
              borderRadius="20px"
              fontSize="14px"
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              onClick={() => handleCancelInvite(item)}
            >
              {isHovering ? "Cancel" : "Pending"}
            </Button>
          ) : (
            <Button
              border="2px solid #4E3CCA"
              background="transparent"
              height="37px"
              width={isMobile ? "fit-content" : "110px"}
              borderRadius="20px"
              fontSize="14px"
              onClick={() => handleRemovePlayer(item)}
            >
              Remove
            </Button>
          )
        ) : null}
      </ButtonContainer>
    </S.RowContainer>
  );
};

export default MemberRow;
