/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TournamentRow from "./TournamentRow";
import Flex from "../../components/v2/common/Flex";

const Button = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin: 0px 9px;
  background-color: #312c83;
  color: #877add;
  border: none;
  cursor: pointer;
  &.active {
    background-color: #3f2faa;
    color: #ffffff;
  }
`;

const Arrow = styled.button`
  width: 36px;
  height: 36px;
  background-color: #312c83;
  border: none;
  color: #877add;
  border-radius: 50%;
  cursor: pointer;
  &.disabled {
    cursor: default;
    opacity: 0.4;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  position: absolute;
  /* bottom: 24px; */
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
`;

const PageNumbersContainer = styled.div`
  min-width: 300px;
  display: flex;
  justify-content: center;
  @media (max-width: 450px) {
    min-width: 200px;
  }
`;

const Container = styled.div`
  position: relative;
`;

// Custom pagination component for UserActivity. Creating new component instead of using the regular pagination component we currently have because some slight changes are needed

export default function UserActivityPaginationWrapper({
  data = {},
  RenderComponent,
  pageLimit = 3,
  dataLimit,
  isMobile = false,
  currentPage,
  setCurrentPage,
  isLoading,
  renderComponentProps,
}) {
  // const pages = Math.ceil(data?.length / dataLimit);
  // const [currentPage, setCurrentPage] = useState(1);

  const pages = data?.totalPages || 1;
  const poolsToDisplay = data?.poolsToDisplay || [];
  function goToNextPage() {
    setCurrentPage(page => (page >= pages ? page : page + 1));
  }

  function goToPreviousPage() {
    setCurrentPage(page => (page <= 1 ? page : page - 1));
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return poolsToDisplay?.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => {
    const start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  const group = getPaginationGroup();

  if (poolsToDisplay?.length <= 0) {
    return null;
  }
  return (
    <Container>
      <Flex style={{ marginBottom: "48px" }} direction="column" rowGap="14">
        {poolsToDisplay?.map((d, idx) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TournamentRow key={idx} {...d} isMobile={isMobile} data={{...d}} {...renderComponentProps} />
        ))}
      </Flex>

      <ButtonContainer>
        {!group.includes(1) && (
          <div style={{ marginRight: "14px" }}>
            <Arrow
              type="button"
              onClick={() => {
                if (isLoading) return;
                setCurrentPage(1);
              }}
              className={`prev ${currentPage === 1 || isLoading ? "disabled" : ""}`}
            >
              &lsaquo;&lsaquo;&lsaquo;
            </Arrow>
          </div>
        )}
        <Arrow
          type="button"
          onClick={() => {
            if (isLoading) return;
            goToPreviousPage();
          }}
          className={`prev ${currentPage === 1 || isLoading ? "disabled" : ""}`}
          style={{ marginRight: "24px" }}
        >
          &laquo;
        </Arrow>

        <PageNumbersContainer>
          {pages > 0 ? (
            group.map((item, index) => {
              return (
                item <= pages && (
                  <Button
                    key={index}
                    onClick={changePage}
                    type="button"
                    className={`paginationItem ${currentPage === item ? "active" : null} `}
                    disabled={isLoading}
                    style={isLoading ? { opacity: 0.5, cursor: "default" } : {}}
                  >
                    <span>{item}</span>
                  </Button>
                )
              );
            })
          ) : (
            <Button
              onClick={() => {
                if (isLoading) return;
                changePage();
              }}
              type="button"
              className="active"
              disabled={isLoading}
              style={isLoading ? { opacity: 0.5, cursor: "default" } : {}}
            >
              <span>1</span>
            </Button>
          )}
        </PageNumbersContainer>

        <Arrow
          type="button"
          onClick={goToNextPage}
          className={`next ${currentPage === pages || pages === 0 || isLoading ? "disabled" : ""}`}
          style={{ marginLeft: "24px" }}
        >
          &raquo;
        </Arrow>
        {!group.includes(pages) && (
          <div style={{ marginLeft: "14px" }}>
            <Arrow
              type="button"
              onClick={() => {
                if (isLoading) return;
                setCurrentPage(pages);
              }}
              className={`${isLoading ? "disabled" : ""}`}
            >
              &rsaquo;&rsaquo;&rsaquo;
            </Arrow>
          </div>
        )}
      </ButtonContainer>
    </Container>
  );
}
