// INFO: this code is from the following package: https://www.npmjs.com/package/react-responsive-masonry

import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

const DEFAULT_COLUMNS_COUNT = 1;

const useIsomorphicLayoutEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect;

const useHasMounted = () => {
  const [hasMounted, setHasMounted] = useState(false);
  useIsomorphicLayoutEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted;
};

const useWindowWidth = () => {
  const hasMounted = useHasMounted();
  const [width, setWidth] = useState(typeof window !== "undefined" ? window.innerWidth : 0);

  const handleResize = useCallback(() => {
    if (!hasMounted) return;
    setWidth(window.innerWidth);
  }, [hasMounted]);

  useIsomorphicLayoutEffect(() => {
    if (hasMounted) {
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasMounted, handleResize]);

  return width;
};

const MasonryResponsive = ({
  columnsCountBreakPoints = {
    350: 1,
    750: 2,
    900: 3,
  },
  children,
  className = null,
  style = null,
}) => {
  const windowWidth = useWindowWidth();
  const columnsCount = useMemo(() => {
    const breakPoints = Object.keys(columnsCountBreakPoints).sort((a, b) => a - b);
    let count = breakPoints.length > 0 ? columnsCountBreakPoints[breakPoints[0]] : DEFAULT_COLUMNS_COUNT;

    breakPoints.forEach(breakPoint => {
      if (breakPoint < windowWidth) {
        count = columnsCountBreakPoints[breakPoint];
      }
    });

    return count;
  }, [windowWidth, columnsCountBreakPoints]);

  return (
    <div className={className} style={style}>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          key: index,
          columnsCount: columnsCount,
        }),
      )}
    </div>
  );
};

MasonryResponsive.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  columnsCountBreakPoints: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
};

class Masonry extends React.Component {
  getColumns() {
    const { children, columnsCount } = this.props;
    const columns = Array.from({ length: columnsCount }, () => []);
    let validIndex = 0;
    React.Children.forEach(children, child => {
      if (child && React.isValidElement(child)) {
        columns[validIndex % columnsCount].push(child);
        validIndex++;
      }
    });
    return columns;
  }

  renderColumns() {
    const { gutter, itemTag, itemStyle } = this.props;
    return this.getColumns().map((column, i) =>
      React.createElement(
        itemTag,
        {
          key: i,
          style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "stretch",
            flex: 1,
            width: 0,
            gap: gutter,
            ...itemStyle,
          },
        },
        column.map(item => item),
      ),
    );
  }

  render() {
    const { gutter, className, style, containerTag } = this.props;

    return React.createElement(
      containerTag,
      {
        style: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "stretch",
          boxSizing: "border-box",
          width: "100%",
          gap: gutter,
          ...style,
        },
        className,
      },
      this.renderColumns(),
    );
  }
}

Masonry.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  columnsCount: PropTypes.number,
  gutter: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  containerTag: PropTypes.string,
  itemTag: PropTypes.string,
  itemStyle: PropTypes.object,
};

Masonry.defaultProps = {
  columnsCount: 3,
  gutter: "0",
  className: null,
  style: {},
  containerTag: "div",
  itemTag: "div",
  itemStyle: {},
};

export default Masonry;
export { MasonryResponsive };
