import React from "react";
import styled from "styled-components";

const SectionContainer = styled.div`
  margin-top: 57px;
  margin-left: 45px;
  margin-right: 67px;
  max-width: 1200px;

  @media (min-width: 1570px) {
    margin: 107px auto 0;
  }
`;

const HeaderTitle = styled.p`
  font-size: 26px;
  line-height: 35px;
  color: white;
  margin-bottom: 19px;
`;

export default function ChallengePage() {
  return (
    <div>
      <SectionContainer>
        <HeaderTitle>Challenge</HeaderTitle>
      </SectionContainer>
    </div>
  );
}
