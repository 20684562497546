import React from "react";
import styled from "styled-components";
import { Select } from "antd";
import { DownArrow } from "../../../../assets/svg";

const GroupSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
`;

const GroupTitle = styled.h3`
  color: #ffffff;
  font-size: 18px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;

const GroupSelect = styled(Select)`
  width: 217px;
  height: 37px;
  border-radius: 4px;
  border: 2px solid #6a5dbd;
  color: white;

  .ant-select-selector {
    all: unset !important;
    display: flex !important;
    align-items: center !important;
    background-color: #201366 !important;
    padding-left: 5px !important;
  }

  .ant-empty-description {
    color: #ffffff !important;
  }

`;
/*
This React component called GroupSelector receives two props: options and title.

It renders a GroupSelectorWrapper containing a GroupTitle and a GroupSelect component.

The options prop is used to populate the dropdown menu with a list of options. 

The title prop is used to display a title above the dropdown menu.

When a user selects an option from the dropdown menu, the setOptionSelected function is called, updating the optionSelected state with the selected option.

The optionSelected state is then used to apply a background color to the selected option, indicating the current selection.

  @example
    <GroupSelector options={PLACEHOLDER_SELECT_DATA} title={"a title"} onChange={aFunctionThatEditsTheValue} onChangeValue={valueAfterOnChange}  />
*/

export const GroupSelector = ({ options, title, onChange, getStageData, onChangeValue, activeButton, activeStage }) => {
  const { Option } = Select;

  return (
    <GroupSelectorWrapper>
      <GroupTitle>{title}</GroupTitle>
      <GroupSelect
        dropdownStyle={{ background: "#201366", border: "2px solid #6a5dbd" }}
        key={onChangeValue}
        defaultValue={onChangeValue}
        suffixIcon={<DownArrow />}
        onChange={selectedValue => {
          onChange(selectedValue);
          getStageData(activeButton, activeStage, selectedValue);
        }}
      >
        {options?.map(option => (
          <Option
            key={option?.id}
            value={option?.id}
            style={{
              color: "#fff",
              background: option?.id === onChangeValue ? "#4A39BA" : "transparent",
            }}
          >
            {option?.name}
          </Option>
        ))}
      </GroupSelect>
    </GroupSelectorWrapper>
  );
};
