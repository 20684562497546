import styled from "styled-components";
import { Bracket, RoundProps, Seed, SeedItem, SeedTeam, SingleLineSeed } from "react-brackets";
import { breakpoints } from "../../themes";

export const SectionContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  // padding: 0px 72px 136px 38px;

  // ${breakpoints.down(breakpoints.laptopL)} {
  //   padding: 0px 38px 136px 38px;
  // }

  .slick-next,
  .slick-prev {
    top: 40px;
  }

  .slick-prev {
    left: -50px;
  }

  @media only screen and (max-width: 1555px) {
    .slick-prev {
      left: -25px;
    }
  }

  @media only screen and (max-width: 1460px) {
    .slick-next,
    .slick-prev {
      top: 75px;
    }
  }
  .slick-list {
    /* overflow-x: unset !important; */
    overflow: inherit;
    overflow-x: clip;
  }
`;

export const Text = styled.p`
  font-weight: 350;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  color: ${props => props.color || "#FFFFFF"};
`;

export const SingleLineSeedStyled = styled.div(
  props => `
padding: 1em 1.5em;
min-width: 225px;
width:100%;
// position: unset;
display: flex;
align-items: center;
flex: 0 1 auto;
flex-direction: column;
justify-content: center;
font-size: 14px;
@media (max-width: ${props.mobileBreakpoint}px) {
  width:50% !important;
}
@media (min-width: ${(props.mobileBreakpoint || 0) + 1}px) {
  &::after {
      content: "";
      position: absolute;
      height: 50%;
      width: 3em;
    [dir="rtl"] & {
      left: -1.5em;
    }
    [dir="ltr"] & {
      right: -1.5em;
    }
  }
  &:nth-child(even)::after {
    border-bottom: 2px solid #1C1644;
    top: -0.5px;
  }
  &:nth-child(odd)::after {
    border-top: 2px solid #1C1644;
    top: calc(50% - 0.5px);
  }
}
`,
);

export const TrophyContainer = styled.div(
  props => ` 
      display: ${props.winner === 0 ? "none" : "flex"};
      position: absolute;
      top: ${props.isDesktop ? (props.winner == 1 ? "-120px" : "90px") : props.winner == 1 ? "-120px" : "100px"};
      left: ${props.isDesktop ? "80px" : "30%"};
      align-items: center;
      gap: 7px;

      @media (max-width: 1480px) {
        left: ${props.isDesktop ? "90px" : "40%"};
      }
      @media (max-width: 1360px) {
        left: ${props.isDesktop ? "85px" : "40%"};
      }
      @media (max-width: 1280px) {
        left: ${props.isDesktop ? "75px" : "40%"};
      }
      @media (max-width: 1216px) {
        left: ${props.isDesktop ? "110px" : "40%"};
      }
    
  `,
);

export const NumberTile = styled.span(
  props => `
    background: #4E3CCA;
    width: 45px;
    height: 37px;
    margin-right: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: ${props.modified ? "8px" : props.top ? "8px" : "0"};
    border-bottom-left-radius:  ${props.modified ? "8px" : props.top ? "0" : "8px"};
    border-left: 0px;
    border-right: 0px;
    border: ${props.lastRound && props.isWinner && props.lastPage ? "2px solid #6C47FF" : ""};
    border-left: 0px;
    border-right: 0px;
    @media (max-width: ${(props.mobileBreakpoint || 0) + 1}px) {
      &::before {
        content: "";
        border: ${props.firstRound ? "none" : "1px solid #342788"};
        position: absolute;
        width: ${props.firstRound ? 0 : "60px !important"};
        left: -50px;
        z-index: -1;
        
      }
    }
`,
);

export const BadgeContainer = styled.div`
  svg {
    height: 25px;
  }
`;
export const SeedTeamStyled = styled(SeedTeam)`
  height: 37px;
  border-bottom-left-radius: 8px;
  justify-content: unset;
  padding: 0;
  min-width: 244.37px;
  width: 100%;
  position: relative;
  background: #342788;
  ${props => `  
    background: ${props.isCurrentUser ? "#6C47FF" : "#342788"};
    border-top-right-radius: ${props.top ? `8px` : "0"};
    border-bottom-right-radius: ${!props.top ? `8px` : "0"};
    border-top-left-radius:  ${props.top ? `8px` : "0"};
    border-bottom-left-radius:  ${props.top ? `0` : "8px"};
    border: ${props.lastRound && props.isWinner && props.lastPage ? "2px solid #6C47FF" : ""};
  `}

  @media (min-width: 1216px) {
    max-width: 244.37px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const SeedStyled = styled(Seed)(
  props => `
  padding: 1em 1.5em;
  min-width: 225px;
  width:100%;
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  @media (max-width: ${props.mobileBreakpoint}px) {
    width:75%;
    display: flex;
    justify-content: center;
  }

  &::after {
    content: "";
    position: absolute;
    // height: 50%;
    width: 1.5em;
  [dir="rtl"] & {
    left: 0px;
  }
  [dir="ltr"] & {
    right: 0px;
  }
}


// Lines from the left
&:nth-child(even)::before{
  content:'';
  border-top: 2px solid #342788;
  border-top-left-radius: 4px;
  position:absolute;
  top: 20px;
  // height: 50%;
  width:1.7em;
  [dir="rtl"] & {
    left:-1.5em;
    }
  [dir="ltr"] & {
    right:-1.6em;
  }
}


&:nth-child(odd):not(:last-child)::before{
  content:'';
  border-bottom: 2px solid #342788 ;
  // border: 2px solid #1c1644;
  border-bottom-left-radius: 4px;  
  position:absolute;
  height: calc(100% - 30px);
  width:1.705em;
  [dir="rtl"] & {
    left:-1.5em;
    }
  [dir="ltr"] & {
    right:-1.58em;
  }
}

&:nth-child(even)::after {
  border-bottom: 2px solid #342788;
  border-bottom-right-radius: 8px;
  // top: -0.5px;
 [dir="rtl"] & {
    // border-left: 2px solid brown;
    }
  [dir="ltr"] & {
    border-right: 2px solid #342788;
    height: calc(50% - 19px);
    top: 20px;
  }
}
&:nth-child(odd):not(:last-child)::after {
  border-top: 2px solid #342788;
  border-top-right-radius: 8px;
  // top: calc(50% - 0.5px);
  // height: 25%;
  [dir="rtl"] & {
    border-left: 2px solid #342788;
    }
  [dir="ltr"] & {
    border-right: 2px solid #342788;
    // border: 2px solid #342788;
    height: calc(50% - 15px);
    // height: 28% !important;
  }
}
}

// MEDIA QUERIES
@media (max-width: ${(props.mobileBreakpoint || 0) + 1}px) {
    &::after {
        content: "";
        position: absolute;
        height: 50%;
        width: 1.5em;
      [dir="rtl"] & {
        left: 0px;
      }
      [dir="ltr"] & {
        right: 0px;
      }
    }

    //new
    &::before {
      content: "";
      position: absolute;
      height: 50%;
      width: 1.5em;
      [dir="rtl"] & {
        left: 0px;
      }
      [dir="ltr"] & {
        right: 0px;
      }
    }

    &:nth-child(even)::before{
      content:'';
      border-top: 2px solid #342788;
      border-top-left-radius: 4px;
      position:absolute;
      top: 20px;
      height: 50%;
      width:1.7em;
      [dir="rtl"] & {
        left:-1.5em;
        }
      [dir="ltr"] & {
        right:-1.5em;
      }
    }

    &:nth-child(odd):not(:last-child)::before{
      content:'';
      border-bottom: 2px solid #342788;
      border-bottom-left-radius: 4px;  
      position:absolute;
      top: 30px;
      height: 50%;
      width:1.705em;
      [dir="rtl"] & {
        left:-1.5em;
        }
      [dir="ltr"] & {
        right:-1.5em;
      }
    }


    &:nth-child(odd):not(:last-child)::after {
      border-top: 2px solid #342788;
      border-top-right-radius: 8px;
      top: calc(50% - 0.5px);
      height: 25%;
      [dir="rtl"] & {
        border-left: 2px solid  #342788;
        }
      [dir="ltr"] & {
        border-right: 2px solid #342788;
        height: 28% !important;
      }
    }
`,
);

export const DottedMenuCotnainer = styled.div`
  position: relative;
`;

export const DottedMenuButton = styled.button`
  cursor: pointer;
  height: 40px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
`;

export const MenuContent = styled.div`
  background-color: #3f2faa;
  width: 276px;
  position: absolute;
  right: -16px;
  padding: 8px 17px;
  border-radius: 6px;
  color: #fff;
  z-index: 999;
  overflow: visible !important;
  padding-bottom: 12px;
  div {
    margin: 0px;
    font-size: 14px;
    // cursor: pointer;
    display: flex;
  }
  hr {
    margin-top: 12px;
    margin-bottom: 9px;
    border: 1px solid #4b3e9a;
    opacity: 0.5;
  }
  @media (max-width: 1480px) {
    right: -36px;
  }
  @media (max-width: 1380px) {
    right: -72px;
  }
  @media (max-width: 450px) {
    right: 0;
  }
`;

export const MainPageLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 50vh;
`;

export const ResizeableContainer = styled.div`
  @media (min-width: 1315px) and (max-width: 1460px) {
    transform: scale(0.9);
    margin-top: -48px;
    margin-left: -55px;
  }
  @media (min-width: 1298px) and (max-width: 1316px) {
    transform: scale(0.75);
    margin-top: -100px;
    margin-left: -145px;
  }
  @media (min-width: 1215px) and (max-width: 1297px) {
    transform: scale(0.75);
    margin-top: -100px;
    margin-left: -140px;
  }
`;

export const PlayerInfoModalRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &:hover {
    color: #fff;
  }
  a {
    background-color: #2b2075;
    min-width: 44px;
    min-height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border-radius: 4px;
    &:hover {
      color: #fff;
    }
  }
  p {
    font-size: 14px;
    color: #877add;
    line-height: 30px;
    margin: 0;
  }
  input {
    background-color: #2b2075;
    outline: none;
    border-radius: 4px;
    border: 0;
    height: 27px;
    padding-left: 12px;
  }
`;

export const ReplayContainer = styled.span`
  display: flex;
  gap: 4px;
  overflow-x: scroll;
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    background: #7265c8;
  }

  ::-webkit-scrollbar-thumb {
    background: #3f338ce6;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #3f338c;
  }
`;

export const ReplayContainerArrow = styled.button`
  position: absolute;
  bottom: 0;
  background: transparent;
  border: none;
  top: 20px;
  cursor: pointer;
`;

export const SelectContainer = styled.div`
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    border: 1px solid #877add;
    outline: none;
    color: #fff;
    width: 234.33px;
    border-radius: 6px;
  }

  .ant-select-arrow {
    // display: none;
    color: #6a5dbd;
  }
`;

export const RoundDescription = styled.span`
  color: #877add;
  font-size: 12px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  position: absolute;
  left: -18px;
  bottom: 40%;
  display: none;
`;
