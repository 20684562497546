import React, { useEffect } from "react";
import { useState } from "react";
import { FacebookAuthProvider, GoogleAuthProvider, TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../../helpers/firebase";
import { useAppState } from "../../../providers/AppStateProvider";
import { useWallet } from "../../../providers/WalletProvider";
import Modal from "../Modal";
import * as S from "./styles";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../common/SpinningLoader";

import { DiscordNoBg, FacebookRound, GoogleIcon, MetamaskNew, TwitterNew, WalletConnect } from "../../../assets/svg";
import {
  DISCORD_AUTHORIZE_URL,
  DISCORD_CLIENT_ID,
  DISCORD_ENCRYPTED_VALUE,
  DISCORD_REDIRECT_URI,
} from "../../../constants/env";

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });

const fbProvider = new FacebookAuthProvider();

const twitterProvider = new TwitterAuthProvider();

function mapError(err) {
  if (err === "USER_EMAIL_ALREADY_EXIST") {
    return "This email address is already taken. Please try another";
  } else if (err === "SOCIAL_ACCIUNT_ALREADY_REGISTERED_OTHER_USER") {
    return "This social account is already registered with another user";
  } else {
    return err;
  }
}

const AuthModal = ({ showAuthModal = false, setShowAuthModal = () => {}, socialLoginApiCall, setSocialAuthData }) => {
  const [showSocialTabs, setShowSocialTabs] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const { detectMetamask } = useAppState();
  const { loadWeb3Modal, web3Modal, displaySelectionModal, connectWithMetamask, connectWithWalletConnect } =
    useWallet();

  function connectWeb3() {
    detectMetamask(displaySelectionModal);
  }

  const toggleAuthModal = () => {
    setShowAuthModal(prev => !prev);
  };

  // const googleLogin = async () => {
  //   setIsLoading(true);
  //   try {
  //     const result = await signInWithPopup(auth, googleProvider);
  //     if (result) {
  //       const provider_token = result.user?.accessToken;
  //       const provider = result.providerId;
  //       await socialLogin(provider_token, provider);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const facebookLogin = async () => {
  //   setIsLoading(true);
  //   try {
  //     const result = await signInWithPopup(auth, fbProvider);
  //     if (result) {
  //       const provider_token = result.user?.accessToken;
  //       const provider = result.providerId;
  //       await socialLogin(provider_token, provider);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const twitterLogin = async () => {
  //   setIsLoading(true);
  //   try {
  //     const result = await signInWithPopup(auth, twitterProvider);
  //     if (result) {
  //       const provider_token = result.user?.accessToken;
  //       const provider = result.providerId;
  //       await socialLogin(provider_token, provider);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const providerLogin = async provider => {
    let authProvider;
    if (provider === "google") {
      authProvider = googleProvider;
    } else if (provider === "facebook") {
      authProvider = fbProvider;
    } else if (provider === "twitter") {
      authProvider = twitterProvider;
    }

    setIsLoading(true);
    try {
      const result = await signInWithPopup(auth, authProvider);
      if (result) {
        const provider_token = result.user?.accessToken;
        const provider = result.providerId;
        await socialLogin(provider_token, provider);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const discordLogin = async () => {
    setIsLoading(true);
    const discordEncryptedVal = DISCORD_ENCRYPTED_VALUE;
    localStorage.setItem("discordState", discordEncryptedVal);
    const params = new URLSearchParams({
      client_id: DISCORD_CLIENT_ID,
      redirect_uri: DISCORD_REDIRECT_URI,
      response_type: "code",
      scope: ["identify", "email"].join(" "),
      state: discordEncryptedVal,
    });

    const url = new URL(DISCORD_AUTHORIZE_URL);
    url.search = params;

    window.open(url, "_self");
  };

  const socialLogin = async (provider_token, provider) => {
    const refCode = localStorage.getItem("refCode");
    setError("");
    try {
      const { data, error } = await socialLoginApiCall(provider, provider_token, refCode);
      if (error) {
        setError(data?.response?.data?.message || "Something went wrong. Please try again later");
      } else {
        setSocialAuthData(data);
        setShowAuthModal(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      localStorage.removeItem("discordState");
      localStorage.removeItem("refCode");
    }
  };

  useEffect(() => {
    if (!window.location?.search || !localStorage.getItem("discordState")) return;
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");

    if (!code || !state || state != localStorage.getItem("discordState")) return;
    setIsLoading(true);
    setShowAuthModal(true);

    // check state
    socialLogin(code, "discord");
  }, [window.location?.search]);

  return (
    <Modal
      showModal={showAuthModal}
      toggleModal={toggleAuthModal}
      customStyles={{
        height: "618px",
        width: "501px",
        padding: "30px",
      }}
      tabletStyles={{
        width: "90%",
      }}
      mobileStyles={{
        width: "90%",
      }}
      beforeClose={() => {
        setError("");
      }}
    >
      {isLoading ? (
        <>
          <SpinnerLoaderWrapper style={{ flexDirection: "column", alignItems: "center", gap: "10px", height: "100%" }}>
            <SpinnerLoader style={{ width: "40px", height: "40px" }} />
            <S.SignInLoaderText>We are signing you in</S.SignInLoaderText>
          </SpinnerLoaderWrapper>
        </>
      ) : (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <S.SignInHeader>Sign In</S.SignInHeader>

          <S.Tabs>
            <S.TabBtn
              active={showSocialTabs}
              onClick={() => {
                setError("");
                setShowSocialTabs(true);
              }}
            >
              Social
            </S.TabBtn>
            <S.TabBtn
              active={!showSocialTabs}
              onClick={() => {
                setError("");
                setShowSocialTabs(false);
              }}
            >
              Wallet
            </S.TabBtn>
          </S.Tabs>

          {error && <S.ErrorText>{mapError(error)}</S.ErrorText>}

          {showSocialTabs ? (
            <S.AuthBtnGroup>
              <S.AuthButton color="#757575" onClick={() => providerLogin("google")}>
                <GoogleIcon />
                <div>Continue with Google</div>
              </S.AuthButton>
              <S.AuthButton background="#3479EA" onClick={() => providerLogin("facebook")}>
                <FacebookRound />
                <div>Continue with Facebook</div>
              </S.AuthButton>
              <S.AuthButton background="#03A9F4" onClick={() => providerLogin("twitter")}>
                <TwitterNew />
                <div>Continue with Twitter</div>
              </S.AuthButton>
              <S.AuthButton background="#4253DC" onClick={discordLogin}>
                <DiscordNoBg />
                <div>Continue with Discord</div>
              </S.AuthButton>
            </S.AuthBtnGroup>
          ) : (
            <S.AuthBtnGroup style={{gap: "20px"}}>
              <S.AuthButton
                onClick={() => {
                  // connectWeb3();
                  connectWithMetamask();
                  setShowAuthModal(false);
                }}
                background="linear-gradient(256.61deg, #E88A3B 46.59%, #EDB545 84.45%)"
                style={{ display: "flex", gap: "12px", paddingLeft: "23px" }}
              >
                <MetamaskNew />
                <div>Connect With Metamask</div>
              </S.AuthButton>
              <S.AuthButton
                onClick={() => {
                  // connectWeb3();
                  connectWithWalletConnect();
                  setShowAuthModal(false);
                }}
                background="linear-gradient(270deg, #5D9DF6 10%, #006FFF 98.13%);"
                style={{ display: "flex", gap: "12px", paddingLeft: "23px" }}
              >
                <WalletConnect />
                <div>Connect With WalletConnect</div>
              </S.AuthButton>
            </S.AuthBtnGroup>
          )}
        </div>
      )}
    </Modal>
  );
};

export default AuthModal;
