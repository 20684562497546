import React, { useState } from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import Modal from "styled-react-modal";
import { useMedia } from "use-media";

import { EphereLogo, ModalCancelIcon } from "../../../../../assets/svg";
import ephereBanner from "../../../../../assets/ephere-banner-new.png";
import DecoratedAvatar from "../../../common/Avatar/DecoratedAvatar";
import { breakpoints } from "../../../../../themes";
import { EPHERE_GAME_ID, KOINS_GAME_ID } from "../../../../../constants";

import { calculatePercentageWidth, truncateWithDots } from "../../../../../helpers/utils";
import Avatar from "../../../common/Avatar";

export const StyledModal = Modal.styled`
  width:  ${props => (props.gameId === EPHERE_GAME_ID ? "750px" : "687px")};
  height:  fit-content;
  min-height: 499px;
  display: flex;
  flex-direction: column;
  background-color: #342788;
  // border: ${props => (props.error ? "1px solid #D06565" : "none")};
  border-radius: 4px;
  opacity: 1;
  transition : all 0.3s ease-in-out;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${breakpoints.down(breakpoints.tablet)} {
    width: 436px;
    height: 90vh; 
    overflow-y: auto  ;
  }
  ${breakpoints.down(breakpoints.mobileL)} {
    width: calc(100% - 80px);
    height: 90vh;
    overflow-y: auto  ;
  }
  ${breakpoints.down(breakpoints.mobileL)} {
    top: 20%;
    transform: translateY(-20%);
  }
`;

const ModalContainer = styled.div`
  padding: 32px 41px 32px 41px;
  position: relative;
`;

const ModalHeader = styled.div`
  color: #d1ccef;
  font-size: 20px;
`;

const ModalContent = styled.div`
  margin: 21px 0px 24px 0px;
  display: flex;
  justify-content: space-between;
  ${breakpoints.down(breakpoints.tablet)} {
    flex-direction: column;
  }
`;

const AvatarCard = styled.div`
  width: 227px;
  background: #3f2faa;
  border-radius: 10px;
  padding: 11px 30px 24px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const AvatarWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Name = styled.span`
  font-size: 16px;
  line-height: 26px;
  color: white;
`;

const CardItemWrap = styled.div``;

const CardItem = styled.div`
  display: flex;
  flex-direction: Row;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.span`
  color: #877add;
  font-size: 14px;
  line-height: 26px;
`;

const Value = styled.span`
  color: white;
  font-size: 24px;
  line-height: 36px;
`;

const ModalFooter = styled.div`
  font-size: 11px;
  color: #877add;
`;

const DetailCard = styled.div`
  width: 356px;
  background: #3f2faa;
  border-radius: 10px;
  padding: 20px 21px 24px 21px;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

const DetailList = styled.div`
  padding: 0px 11px 11px 11px;
`;

const DetailItem = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #3f347e;
  font-size: 12px;
  color: white;

  &:last-child {
    border-bottom: none;
  }
`;

const TotalLine = styled.div`
  background: #6c47ff;
  border-radius: 4px;
  font-size: 12px;
  line-height: 40px;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
`;

const CloseIconWrap = styled.span`
  position: absolute;
  right: 27px;
  top: 23px;
  cursor: pointer;
  ${breakpoints.down(breakpoints.mobileL)} {
    right: 20px;
    top: 11px;
  }
`;

const RowContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr 1fr;
  justify-content: center;
  ${breakpoints.down(breakpoints.tablet)} {
    div:nth-child(3) {
      width: 76px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      padding-top: 15%;
    }
    div: nth-child(2) {
      display: none;
    }
  }
`;

const PubgDetailModalMatchRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr;
  justify-content: center;
  ${breakpoints.down(breakpoints.tablet)} {
    div: nth-child(2) {
      display: none;
    }
    div: nth-child(4) {
      display: none;
    }
    div: nth-child(5) {
      display: none;
    }
  }
`;

const TableContainer = styled.div`
  border-radius: 10px;
  background: #3f2faa;
  margin-bottom: 42px;
  max-height: 200px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    background: #7265c8;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #3f338ce6;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #3f338c;
  }
  > div:not(:last-child) {
    border-bottom: 1px solid #3f347e;
  }
`;

const TableHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr 1fr;
  margin-bottom: 14px;
  div {
    padding-left: 32px;
    color: #877add;
    font-size: 12px;
    font-weight: 325;
  }
  ${breakpoints.down(breakpoints.tablet)} {
    div: nth-child(2) {
      display: none;
    }
    padding-left: 0px;
    div {
      padding-left: 12px;
    }
  }
`;

const PubgTableHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr;
  margin-bottom: 14px;
  div {
    padding-left: 32px;
    color: #877add;
    font-size: 12px;
    font-weight: 325;
  }

  ${breakpoints.down(breakpoints.tablet)} {
    div: nth-child(2) {
      display: none;
    }
    div: nth-child(4) {
      display: none;
    }
    div: nth-child(5) {
      display: none;
    }
    padding-left: 0px;
    div {
      padding-left: 12px;
    }
  }
`;

const TableRow = styled.div`
  height: 43px;
  display: flex;
  align-items: center;
  padding-left: 32px;
  color: #fff;
  font-size: 12px;
  font-weight: 325;
  ${breakpoints.down(breakpoints.tablet)} {
    padding-left: 24px;
  }
`;

const TableSection = styled.div`
  margin: 20px 0;
`;

const PlayerDataContainer = styled.div`
  background: #3f2faa;
  border-radius: 10px;
  padding: 19px 22px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin: 20px 0;
  overflow-y: auto;
`;

const PlayerWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ProgressBarWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ProgressBarContainer = styled.div`
  min-width: 100px;
  max-width: 100%;
  width: ${props => props.width};
  display: flex;
  align-items: center;
  background: ${props => props.background};
  border-radius: 3px;
  height: 31px;
  padding: 10px;
`;

const Score = styled.span`
  font-style: normal;
  font-weight: 325;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
`;

const ProgressBarText = styled.span`
  font-weight: 325;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
`;

const Ephere_title = styled.div`
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  position: absolute;
  bottom: -18px;
  background: #6c47ff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  color: #fff;
  margin: 0px auto;
  font-weight: 325;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

const EphereModalContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  max-height: 499px;
  ${breakpoints.down(breakpoints.tablet)} {
    flex-direction: column;
    align-items: center !important;
    max-height: 635px;
  }
`;

const EphereAvatarCardContainer = styled.div`
  width: 227px;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 98%;
  }
`;

const EphereMatchContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: #7265c8;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #201366;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #3f338c;
  }
`;

const EphereMatchRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background: #3f2faa;
  height: 50px;
  width: 418px;
  border-radius: 8px;
  div {
    align-self: center;
    justify-self: center;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  ${breakpoints.down(breakpoints.tablet)} {
    width: 98%;
    padding: 2px 11px;
  }
`;

const EphereCardItem = styled.div`
  display: flex;
  flex-direction: Row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0px;
`;

const EphereLable = styled.span`
  color: #877add;
  font-size: 14px;
  font-size: 12px;
  line-height: 10px;
  color: white;
`;

const EphereValue = styled.span`
  font-size: 12px;
  line-height: 14.4px;
  color: white;
`;

const TimeContainer = styled.small`
  font-size: 10px;
  color: #877add;
`;

const DetailModal = ({ isOpen, toggleModal, item, joinMode }) => {
  const {
    nick,
    thumbnail,
    points,
    bestMatch: { kills, score, teamPlacement, kdRatio, damageDone, damageTaken, totalPoints } = {},
    order,
    platform,
    playerData,
    gamesData,
    gameId,
    address,
  } = item;

  const sortedPlayers = playerData?.players?.sort((a, b) => b.totalPoints - a.totalPoints);

  function getUserEphereIndex(gamesData) {
    const indx = gamesData[0]?.addresses?.findIndex(addr => addr === address);

    return indx;
  }

  const isLargeScreen = useMedia({ minWidth: "768px" });

  return (
    <StyledModal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal} gameId={gameId}>
      <ModalContainer>
        <CloseIconWrap onClick={toggleModal}>
          <ModalCancelIcon />
        </CloseIconWrap>
        <ModalHeader>Leaderboard detail</ModalHeader>
        {/* TODO: Add dynamic data to the modal for ephere */}
        {gameId === KOINS_GAME_ID ? (
          <>
            <ModalContent>
              <AvatarCard>
                <AvatarWrap>
                  <DecoratedAvatar src={playerData.image || ""} size="94.23px" bgColor="#272054" />
                  <Name>{joinMode === "TEAM" ? playerData?.team : nick || ""}</Name>
                </AvatarWrap>
                <CardItemWrap>
                  <CardItem>
                    <Label>Score</Label>
                    <Value>{playerData?.score || 0}</Value>
                  </CardItem>
                </CardItemWrap>
              </AvatarCard>
              <DetailCard>
                <DetailList>
                  <DetailItem>
                    <Label>ID</Label>
                    <span>#{playerData?.id || 0}</span>
                  </DetailItem>
                  <DetailItem>
                    <Label>Lineage</Label>
                    <span>{playerData?.lineage || "N/A"}</span>
                  </DetailItem>
                  <DetailItem>
                    <Label>Genre</Label>
                    <span>{playerData?.genre || "N/A"}</span>
                  </DetailItem>
                  <DetailItem>
                    <Label>Breed</Label>
                    <span>{playerData?.breed || "N/A"}</span>
                  </DetailItem>
                  <DetailItem>
                    <Label>Races</Label>
                    <span>{playerData?.races || 0}</span>
                  </DetailItem>
                </DetailList>
              </DetailCard>
            </ModalContent>
            {gamesData.length > 0 && (
              <TableSection>
                <TableHeaderContainer>
                  <div>Race</div>
                  <div>Date</div>
                  <div>Race Name</div>
                  <div>Place</div>
                  <div>Score</div>
                </TableHeaderContainer>
                <TableContainer>
                  {gamesData.map((gameItem, ind) => (
                    <RowContainer>
                      <TableRow>{ind + 1}</TableRow>
                      <TableRow>
                        {gameItem?.timestamp !== null || undefined
                          ? moment.unix(gameItem?.timestamp / 1000).format("MMM DD, YYYY")
                          : "-"}
                      </TableRow>
                      <TableRow>{gameItem.mapName || "-"}</TableRow>
                      <TableRow>{gameItem.position || "-"}</TableRow>
                      <TableRow>{gameItem.totalPoints || "-"}</TableRow>
                    </RowContainer>
                  ))}
                </TableContainer>
              </TableSection>
            )}
          </>
        ) : gameId === EPHERE_GAME_ID ? (
          <ModalContent>
            <EphereModalContent>
              <EphereAvatarCardContainer>
                <AvatarCard style={{ padding: "0px", maxHeight: "360px" }}>
                  <AvatarWrap
                    style={{
                      position: "relative",
                      padding: "2rem",
                      borderRadius: "10px",
                      backgroundImage: `url(${ephereBanner})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "right 72% bottom 3px",
                    }}
                  >
                    <DecoratedAvatar
                      src={item.gamesData[0]?.teamLogos?.[getUserEphereIndex(item?.gamesData)] || ""}
                      size="94px"
                      bgColor="transparent"
                    />
                    <Ephere_title>{joinMode === "TEAM" ? playerData?.team : nick || ""}</Ephere_title>
                  </AvatarWrap>
                  <CardItemWrap style={{ padding: "11px 30px 24px 30px", marginTop: "2rem" }}>
                    <EphereCardItem>
                      <EphereLable>Position</EphereLable>
                      <EphereValue>{order ?? "-"}</EphereValue>
                    </EphereCardItem>
                    <EphereCardItem>
                      <EphereLable>Point</EphereLable>
                      <EphereValue>{points ?? "-"}</EphereValue>
                    </EphereCardItem>
                    <EphereCardItem>
                      <EphereLable>Win Rate</EphereLable>
                      <EphereValue>{points ?? "-"}</EphereValue>
                    </EphereCardItem>
                  </CardItemWrap>
                </AvatarCard>
              </EphereAvatarCardContainer>
              <EphereMatchContainer>
                {/* <div style={{ background: "red", height: "10px", width: "100%" }}></div> */}
                <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                  {gamesData?.map(item => (
                    // <div style={{ background: "#3F2FAA", height: "50px", width: "418px", borderRadius: "8px" }}></div>
                    <EphereMatchRow>
                      <div>
                        {!isLargeScreen
                          ? truncateWithDots(item?.teamNames?.[0], 4)
                          : truncateWithDots(item?.teamNames?.[0], 9)}
                        <img src={item?.teamLogos?.[0]} style={{ width: "24px", height: "24px" }} />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", gap: "0px" }}>
                        <div>
                          {item.score?.[0]} - {item?.score[1]}
                        </div>
                        {item.startDatetime && moment(item.startDatetime).isValid() && (
                          <TimeContainer>
                            {moment(item.startDatetime).format(isLargeScreen ? "DD MMM, h:mm:ss A" : "DD MMM, h A")}
                          </TimeContainer>
                        )}
                        <div></div>
                      </div>
                      <div>
                        <img src={item?.teamLogos?.[1]} style={{ width: "24px", height: "24px" }} />
                        {!isLargeScreen
                          ? truncateWithDots(item?.teamNames?.[1], 4)
                          : truncateWithDots(item?.teamNames?.[1], 7)}
                      </div>
                    </EphereMatchRow>
                  ))}
                </div>
              </EphereMatchContainer>
            </EphereModalContent>
            {/*<DetailList>
                <DetailItem>
                  <Ephere_Card_Content>
                    <p>Progreso FC</p>
                    <DecoratedAvatar src={thumbnail || ""} size="15px" />
                  </Ephere_Card_Content>
                  <Ephere_Card_Content>
                    <p>Progreso FC</p>    
                    <p>Progreso FC</p>

                  </Ephere_Card_Content>
                  <div>
                    <p>Bullish FC</p>
                    <DecoratedAvatar src={thumbnail || ""} size="15px"/>
                  </div>
                </DetailItem> */}
            {/* <DetailItem>
                  <Label>Team Pos.</Label>
                  <span>{teamPlacement ?? "-"}</span>
                </DetailItem>
                <DetailItem>
                  <Label>K/D</Label>
                  <span>{kdRatio ?? "-"}</span>
                </DetailItem>
                <DetailItem>
                  <Label>{platform === "pubg" ? "Damage" : "Damage Done"}</Label>
                  <span>{damageDone ?? "-"}</span>
                </DetailItem>
                {platform !== "pubg" && (
                  <DetailItem>
                    <Label>Damage Taken</Label>
                    <span>{damageTaken ?? "-"}</span>
                  </DetailItem>
                )} */}
            {/* </DetailList>
               <TotalLine>
                <span>Total</span>
                <span>{score ?? "-"}</span>
              </TotalLine> */}
          </ModalContent>
        ) : (
          <>
            <ModalContent>
              <AvatarCard>
                <AvatarWrap>
                  <DecoratedAvatar
                    src={thumbnail || ""}
                    size="94.23px"
                    bgColor="#272054"
                  />
                  <Name>{joinMode === "TEAM" ? playerData?.name : nick || ""}</Name>
                </AvatarWrap>
                <CardItemWrap>
                  <CardItem>
                    <Label>Position</Label>
                    <Value>{order ?? "-"}</Value>
                  </CardItem>
                  <CardItem>
                    <Label>Score</Label>
                    <Value>{points ?? "-"}</Value>
                  </CardItem>
                </CardItemWrap>
              </AvatarCard>
              <DetailCard>
                <DetailList>
                  <DetailItem>
                    <Label>Top kills</Label>
                    <span>{kills ?? "-"}</span>
                  </DetailItem>
                  <DetailItem>
                    <Label>Team Pos.</Label>
                    <span>{teamPlacement || "-"}</span>
                  </DetailItem>
                  <DetailItem>
                    <Label>K/D</Label>
                    <span>{kdRatio ?? "-"}</span>
                  </DetailItem>
                  <DetailItem>
                    <Label>{platform === "pubg" ? "Damage" : "Damage Done"}</Label>
                    <span>{damageDone > 0 ? Math.round(damageDone) : "-"}</span>
                  </DetailItem>
                  {platform !== "pubg" && (
                    <DetailItem>
                      <Label>Damage Taken</Label>
                      <span>{damageTaken ?? "-"}</span>
                    </DetailItem>
                  )}
                </DetailList>
                <TotalLine>
                  <span>Total</span>
                  <span>{totalPoints ?? "-"}</span>
                </TotalLine>
              </DetailCard>
            </ModalContent>
            {joinMode === "TEAM" && (
              <>
                <ModalHeader>Team Contribution</ModalHeader>
                <PlayerDataContainer>
                  {/* when mapping playerData?.players, first sort the array by descendent order */}
                  {sortedPlayers?.map((player, ind) => (
                    <PlayerWrap>
                      <Avatar size="28px" type="square" src={player?.thumbnail} />
                      <ProgressBarWrapper>
                        <ProgressBarContainer
                          width={calculatePercentageWidth(player?.totalPoints, item?.points)}
                          background={player?.totalPoints > 0 ? "#6c47ff" : "#6c47ff"}
                        >
                          <ProgressBarText>{truncateWithDots(player?.nick, 9)}</ProgressBarText>
                        </ProgressBarContainer>
                      </ProgressBarWrapper>
                    </PlayerWrap>
                  ))}
                </PlayerDataContainer>
              </>
            )}
            {item?.gameId === 3 && gamesData.length > 0 && (
              <>
                <ModalHeader>Match Stats</ModalHeader>
                <TableSection>
                  <PubgTableHeaderContainer>
                    <div></div>
                    <div>Map</div>
                    <div>Kills</div>
                    <div>Position</div>
                    <div>Damage</div>
                    <div>Score</div>
                  </PubgTableHeaderContainer>
                  <TableContainer>
                    {gamesData.map((gameItem, ind) => (
                      <PubgDetailModalMatchRow>
                        <TableRow>{ind + 1}</TableRow>
                        <TableRow>{gameItem?.mapName || "-"}</TableRow>
                        <TableRow>{gameItem?.kills}</TableRow>
                        <TableRow>{gameItem?.teamPlacement || "-"}</TableRow>
                        <TableRow>{Math.round(gameItem?.damageDone) || "0"}</TableRow>
                        <TableRow>{gameItem?.totalPoints}</TableRow>
                      </PubgDetailModalMatchRow>
                    ))}
                  </TableContainer>
                </TableSection>
              </>
            )}
          </>
        )}
        {!playerData && (
          <ModalFooter>
            {platform === "pubg"
              ? "The formula for the scores is (300)*Kills + (100)*Assists + (50)*Headshots +(1/2)*DamageDone + (1/TeamPosition ^(1/4)) * (5000)"
              : "The formula for the scores is (300)*Kills + (100)*Assists + (300)*Executions + (50)*Headshots +(1/2)*DamageDone + (1/TeamPosition ^(1/4)) * (5000)"}
          </ModalFooter>
        )}
      </ModalContainer>
    </StyledModal>
  );
};

export default DetailModal;
