/* eslint-disable no-else-return */
import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import * as S from "../styles";
import axios from "axios";

import * as styles from "../BracketsPage.module.css";
import { breakpoints } from "../../../themes";
import { Bracket, RoundProps, Seed, SeedItem, SeedTeam, SingleLineSeed } from "react-brackets";
import Rounds from "../../../components/v2/Tournament/Rounds/Rounds";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Slider from "react-slick";
import Round from "../../../components/v2/Tournament/Rounds/Rounds";
import Flex from "../../../components/v2/common/Flex";
import { TextNoMargin } from "../../../components/v2/common/Text";
import useMedia from "use-media";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  CarouselArrowLeft,
  CarouselArrowRight,
  ChallengeActiveIcon,
  Clipboard,
  DottedMenu,
  FadeArrowRight,
  FirstPlacePodium,
  FirstPlaceTrohy,
  FourthPlaceTrophy,
  GreenCheckMark,
  LostRect,
  WinnerFirstIconMobile,
  WinnerSecondIcon,
  WinnerSecondIconMobile,
  WinnerThirdIcon,
  Winrect,
} from "../../../assets/svg";
import Loader from "../../../components/v2/Loader";
import { sessionState } from "../../../state/globalState";
import { useRecoilValue } from "recoil";
import { BE_API_BASE_URL } from "../../../constants/env";
import { useGetSecHeaders } from "../../../hooks";
import { Select, Tooltip } from "antd";
import { getResults, truncateWithDots } from "../../../helpers/utils";
import BracketInfoModal from "../../../components/v2/BracketModal";
import { modRoundsFunc } from "../bracektsUtils";

const CustomTooltip = ({ className, children, ...restProps }) => (
  <Tooltip overlayClassName={className} {...restProps} getPopupContainer={triggerNode => triggerNode.parentNode}>
    {children}
  </Tooltip>
);

const StyledTooltip = styled(CustomTooltip)`
  // .ant-tooltip-arrow {
  //   display: none;
  // }
  @media only screen and (max-width: 528px) {
    max-width: 200px !important;
  }
`;

// CUSTOM BRACKET UI COMPONENTS //
const CustomSeed = ({
  seed,
  breakpoint,
  roundIndex,
  rounds,
  lastPage,
  user,
  userPositionInfo,
  headers,
  runnerupMatch,
  template,
}) => {
  const isLineConnector = rounds[roundIndex]?.seeds?.length === rounds[roundIndex + 1]?.seeds?.length;

  const Wrapper = isLineConnector ? S.SingleLineSeedStyled : S.SeedStyled;

  const isDesktop = useMedia({ minWidth: "1215px" });

  const lastRound = rounds && rounds.length > 0 ? roundIndex === rounds.length - 1 : false;
  const firstRound = roundIndex === 0;

  return (
    <Wrapper
      mobileBreakpoint={1215}
      style={{
        fontSize: 12,
        minHeight: isDesktop ? `${Math.round(120 / (rounds && rounds.length > 0 ? rounds.length * 2 : 1))}vh` : "unset",
      }}
    >
      <SeedItem
        style={{
          width: "100%",
          borderRadius: "8px",
          background: "transparent",
          boxShadow: "none",
          marginTop: "0px",
          ...(lastPage && lastRound && rounds.length <= 1
            ? { display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }
            : {}),
        }}
      >
        <div style={{ alignItems: "center", gap: "20px" }}>
          <S.RoundDescription>{roundIndex + 1}</S.RoundDescription>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CustomSeedTeamComponent
              team={seed?.teams && seed?.teams[0]}
              top
              mobileBreakpoint={1215}
              firstRound={firstRound}
              lastRound={lastRound}
              style={
                lastRound && seed?.teams && seed?.teams[0]?.winner == 1 && (isDesktop ? lastPage : true)
                  ? { boxShadow: "#5E2FE4 0 -10px 60px -5px" }
                  : {}
              }
              isDesktop={isDesktop}
              lastPage={lastPage}
              user={user}
              winner={seed.teams && seed?.teams[0]?.winner}
              isWinner={lastRound && seed.teams && seed?.teams[0]?.winner == 1}
              isChallengePlayer={seed.teams[0]?.challengePlayer == 1}
              matchStatus={seed?.teams && seed.teams[0]?.matchStatus}
              userPositionInfo={userPositionInfo}
              headers={headers}
              match={seed?.teams}
              template={template}
            />
            <div style={{ background: "#342788", margin: "0px", maxWidth: lastRound || !isDesktop ? "244px" : "100%" }}>
              <div style={{ border: "1px solid #272054", margin: "0px", maxWidth: "244px" }} />
            </div>
            <CustomSeedTeamComponent
              team={seed.teams && seed.teams[1]}
              mobileBreakpoint={1215}
              firstRound={firstRound}
              lastRound={lastRound}
              lastPage={lastPage}
              user={user}
              style={
                lastRound && seed?.teams && seed.teams[0]?.winner == 2 && (isDesktop ? lastPage : true)
                  ? { boxShadow: "#5E2FE4 -12px 15px 60px -5px" }
                  : {}
              }
              winner={seed?.teams && seed.teams[0]?.winner}
              isWinner={lastRound && seed?.teams && seed.teams[0]?.winner == 2}
              isChallengePlayer={seed?.teams && seed.teams[0]?.challengePlayer == 2}
              matchStatus={seed?.teams && seed.teams[0]?.matchStatus}
              userPositionInfo={userPositionInfo}
              headers={headers}
              isDesktop={isDesktop}
              match={seed?.teams}
              template={template}
            />
          </div>
        </div>
        {/* In mobile screens, there is no last page variable available. Since the last round on mobile will be on the last page, we can can use (lastROund && !siDesktop to determine whether or not to show 3rd and 4th position players) */}
        {((lastPage && lastRound) || (lastRound && !isDesktop)) &&
          runnerupMatch?.teams &&
          runnerupMatch?.teams.length > 0 && (
            <div style={{ maxWidth: "244px", position: "absolute", top: "120px" }}>
              <S.RoundDescription style={{ marginBottom: "12px", marginTop: "12px" }}>
                3rd and 4th place
              </S.RoundDescription>
              <ModifiedSeedTeamComponent
                team={runnerupMatch?.teams && runnerupMatch?.teams[0]}
                mobileBreakpoint={1215}
                firstRound={firstRound}
                lastRound={lastRound}
                lastPage={lastPage}
                user={user}
                winner={runnerupMatch?.teams && runnerupMatch?.teams[0]?.winner}
                isWinner={lastRound && runnerupMatch?.teams && runnerupMatch?.teams[0]?.winner == 1}
                userPositionInfo={userPositionInfo}
                headers={headers}
                matchStatus={runnerupMatch?.teams && runnerupMatch?.teams[0]?.matchStatus}
                isDesktop={isDesktop}
                isChallengePlayer={runnerupMatch?.teams[0]?.challengePlayer == 1}
                match={runnerupMatch?.teams}
                template={template}
              />
              <div style={{ border: "2px solid #201366", margin: "0px", maxWidth: "244px" }} />
              <ModifiedSeedTeamComponent
                team={runnerupMatch?.teams && runnerupMatch?.teams[1]}
                mobileBreakpoint={1215}
                firstRound={firstRound}
                lastRound={lastRound}
                lastPage={lastPage}
                user={user}
                winner={runnerupMatch?.teams && runnerupMatch?.teams[0]?.winner}
                isWinner={lastRound && runnerupMatch?.teams[0]?.winner == 2}
                userPositionInfo={userPositionInfo}
                headers={headers}
                matchStatus={runnerupMatch?.teams && runnerupMatch?.teams[0]?.matchStatus}
                isDesktop={isDesktop}
                isChallengePlayer={runnerupMatch?.teams[0]?.challengePlayer == 2}
                match={runnerupMatch?.teams}
                template={template}
              />
            </div>
          )}
      </SeedItem>
    </Wrapper>
  );
};

const CustomSeedTeamComponent = ({
  team,
  top,
  mobileBreakpoint,
  firstRound,
  lastRound,
  isDesktop,
  style,
  lastPage,
  user,
  winner,
  isWinner,
  userPositionInfo,
  headers,
  isChallengePlayer,
  matchStatus,
  match,
  template,
}) => {
  const blockRef = useRef();
  const menuRef = useRef();
  const [showPlayerDetails, setShowPlayerDetails] = useState(false);
  const [scrollState, setScrollState] = useState(0);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const replayContainerRef = useRef();

  const scroll = scrollOffset => {
    replayContainerRef.current.scrollLeft += scrollOffset;
    setScrollState(replayContainerRef.current.scrollLeft);
  };
  const { id } = user || {};
  useEffect(() => {
    setIsCurrentUser(team.playerId === id);
  }, [team?.playerId]);

  const clickHandler = e => {
    if (
      menuRef.current?.contains(e.target) ||
      // e.target.contains(menuRef.current) ||
      e.target.isEqualNode(menuRef.current)
    ) {
      return;
    }

    if (blockRef.current?.contains(e.target)) {
      setShowPlayerDetails(!showPlayerDetails);
    } else {
      setShowPlayerDetails(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [showPlayerDetails]);

  const options = {
    autoClose: 3000,
    hideProgressBar: true,
    position: toast.POSITION.TOP_RIGHT,
    pauseOnHover: true,
    style: {
      color: "#D1CCEF",
      background: "#3A2F81",
      width: "fit-content",
      borderRadius: "6px",
      float: "right",
      marginRight: "63px",
    },
    closeButton: false,
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <S.SeedTeamStyled
        top={top}
        lastRound={lastRound}
        lastPage={lastPage}
        style={style ? style : {}}
        isCurrentUser={isCurrentUser}
        isWinner={isWinner}
        onClick={() => {
          setShowPlayerDetails(!showPlayerDetails);
        }}
        ref={blockRef}
      >
        <S.NumberTile
          top={top}
          mobileBreakpoint={mobileBreakpoint}
          firstRound={firstRound}
          lastRound={lastRound}
          lastPage={lastPage}
          isWinner={isWinner}
        >
          {team?.results?.length > 0 ? (
            getResults(team?.results)?.wins
          ) : team?.isCheckedIn ? (
            <Tooltip title="Checked in to play" color="#4E3CCA" placement="left">
              <GreenCheckMark />
            </Tooltip>
          ) : (
            ""
          )}
        </S.NumberTile>

        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div>{truncateWithDots(team?.name, 17)}</div>

          <div style={{ display: "flex", alignItems: "center" }}>
            {/* Sword icon */}
            {isChallengePlayer && (
              <Tooltip
                color="#4E3CCA"
                placement="top"
                title={
                  <div style={{ fontSize: "12px", lineHeight: "16px" }}>
                    <div>You have the first move.</div>
                    <div>This is randomly selected.</div>
                  </div>
                }
              >
                <ChallengeActiveIcon style={{ height: "18px", marginRight: "12px" }} />
              </Tooltip>
            )}
            <div>
              <BracketInfoModal
                showModal={showPlayerDetails}
                toggleModal={() => setShowPlayerDetails(false)}
                team={team}
                match={match}
                template={template}
              />
            </div>
          </div>
        </div>
      </S.SeedTeamStyled>
      <div>
        {((lastPage && lastRound) || (lastRound && !isDesktop)) &&
          matchStatus == "FINISHED" &&
          (isWinner ? (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <WinnerFirstIconMobile style={{ width: "20px" }} />
              <S.Text color="#F5BC46">1st</S.Text>
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <WinnerSecondIconMobile style={{ width: "16px" }} />
              <S.Text color="#66CC9B">2nd</S.Text>
            </div>
          ))}
      </div>
    </div>
  );
};

const ModifiedSeedTeamComponent = ({
  team,
  top,
  mobileBreakpoint,
  firstRound,
  lastRound,
  isDesktop,
  style,
  lastPage,
  user,
  winner,
  isWinner,
  userPositionInfo,
  headers,
  matchStatus,
  isChallengePlayer,
  match,
  template,
}) => {
  const blockRef = useRef();
  const menuRef = useRef();
  const [showPlayerDetails, setShowPlayerDetails] = useState(false);
  const [scrollState, setScrollState] = useState(0);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const replayContainerRef = useRef();
  const scroll = scrollOffset => {
    replayContainerRef.current.scrollLeft += scrollOffset;
    setScrollState(replayContainerRef.current.scrollLeft);
  };

  const { id } = user || {};
  useEffect(() => {
    setIsCurrentUser(team?.playerId === id);
  }, [team?.playerId]);

  const clickHandler = e => {
    if (
      menuRef.current?.contains(e.target) ||
      // e.target.contains(menuRef.current) ||
      e.target.isEqualNode(menuRef.current)
    ) {
      return;
    }

    if (blockRef.current?.contains(e.target)) {
      setShowPlayerDetails(!showPlayerDetails);
    } else {
      setShowPlayerDetails(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [showPlayerDetails]);

  const options = {
    autoClose: 3000,
    hideProgressBar: true,
    position: toast.POSITION.TOP_RIGHT,
    pauseOnHover: true,
    style: {
      color: "#D1CCEF",
      background: "#3A2F81",
      width: "fit-content",
      borderRadius: "6px",
      float: "right",
      marginRight: "63px",
    },
    closeButton: false,
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <S.SeedTeamStyled
        top={top}
        lastRound={lastRound}
        lastPage={lastPage}
        isCurrentUser={isCurrentUser}
        isWinner={isWinner}
        modified
      >
        <S.NumberTile
          top={top}
          mobileBreakpoint={mobileBreakpoint}
          firstRound={firstRound}
          lastRound={lastRound}
          lastPage={lastPage}
          isWinner={isWinner}
          modified
        >
          <S.BadgeContainer>
            {team?.isCheckedIn ? (
              <Tooltip title="Checked in to play" color="#4E3CCA" placement="left">
                <GreenCheckMark />
              </Tooltip>
            ) : (
              getResults(team?.results).wins
            )}
          </S.BadgeContainer>
        </S.NumberTile>

        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div>{truncateWithDots(team?.name, 17)}</div>

          <div style={{ display: "flex", alignItems: "center" }}>
            {isChallengePlayer && (
              <Tooltip
                color="#4E3CCA"
                placement="top"
                title={
                  <div style={{ fontSize: "12px", lineHeight: "16px" }}>
                    <div>You have the first move.</div>
                    <div>This is randomly selected.</div>
                  </div>
                }
              >
                <ChallengeActiveIcon style={{ height: "18px" }} />
              </Tooltip>
            )}
            <S.DottedMenuCotnainer ref={blockRef}>
              {team?.name && (
                <S.DottedMenuButton
                  onClick={() => {
                    setShowPlayerDetails(!showPlayerDetails);
                  }}
                  style={{ transform: "rotate(90deg)" }}
                >
                  <DottedMenu />
                </S.DottedMenuButton>
              )}

              <div>
                <BracketInfoModal
                  showModal={showPlayerDetails}
                  toggleModal={() => setShowPlayerDetails(false)}
                  team={team}
                  match={match}
                  template={template}
                />
              </div>
            </S.DottedMenuCotnainer>
          </div>
        </div>
      </S.SeedTeamStyled>
      <div>
        {matchStatus == "FINISHED" &&
          (isWinner ? (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <WinnerThirdIcon style={{ width: "16px" }} />
              <S.Text color="#6791FB">3rd</S.Text>
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <FourthPlaceTrophy style={{ width: "16px" }} />
              <S.Text color="#CF5E94">4th</S.Text>
            </div>
          ))}
      </div>
    </div>
  );
};

// CUSTOM BRACKET UI COMPONENTS //

// CUSTOM WRAPPER COMPONENTS //
const BracketWrapper = ({
  count,
  lastPage,
  setActivePageIndex,
  index,
  isDesktop,
  user,
  tournamentId,
  userPositionInfo,
  playersQty,
  getPoolData,
  branchPagesInfo,
  setActivePageIndexArray,
  activePageIndexArray,
  userIsPlaying,
  template,
  joinMode,
  sectionData,
  returnCurrentStageData,
  stageId,
  poolMode
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState(null);
  const [activeBranch, setActiveBranch] = useState(1);

  const [manualBranch, setManualBranch] = useState(1);

  const headers = useGetSecHeaders();

  useEffect(() => {
    let ind = userPositionInfo?.pages?.findIndex(page => page.id === count);
    if (userPositionInfo?.pages && ind !== -1) {
      setActiveBranch(userPositionInfo.pages[ind].branch);
    }
  }, [userPositionInfo?.pages]);

  async function getBranchData(branchArg) {
    setIsLoading(true);
    try {
      let res = await returnCurrentStageData(count, branchArg || 1);
      setPageData(res);
      if (res?.rounds) {
        let rounds = res?.rounds;
        if (rounds.some(round => round.status === "ACTIVE")) {
          setActivePageIndex(index);
          setActivePageIndexArray(prev => [...prev, index]);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setManualBranch(activeBranch);
  }, [activeBranch]);

  useEffect(() => {
    getBranchData(manualBranch);
  }, [manualBranch]);

  const moddedRounds = useMemo(() => {
    if (pageData) {
      return modRoundsFunc(pageData.rounds);
    }
  }, [pageData]);

  const { Option } = Select;
  return (
    <div>
      {isLoading || !pageData ? (
        <S.MainPageLoaderContainer>
          <Loader animation="rotate" />
        </S.MainPageLoaderContainer>
      ) : (
        <S.ResizeableContainer>
          {pageData && (
            <div>
              <S.SelectContainer>
                {[...Array(branchPagesInfo[pageData.page]).keys()].length > 1 && (
                  <Flex
                    gap="20"
                    alignItems="center"
                    extraStyles={
                      [...Array(branchPagesInfo[pageData.page]).keys()].length > 1
                        ? { marginBottom: "21.94px", marginTop: "24px", minHeight: "32px" }
                        : null
                    }
                  >
                    <TextNoMargin fontSize="18px">Branch {manualBranch}</TextNoMargin>
                    <Select
                      onChange={value => {
                        setManualBranch(value);
                      }}
                      dropdownStyle={{
                        background: "#201366",
                        outline: "2px solid #6a5dbd",
                        width: "234.34px",
                        marginTop: "90px",
                      }}
                      placeholder="Branch"
                      value={manualBranch}
                    >
                      {[...Array(branchPagesInfo[pageData.page]).keys()].map(item => (
                        <Option
                          style={{
                            color: "#fff",
                            background: manualBranch === item + 1 ? "#3F2FAA" : "#201366",
                          }}
                          value={item + 1}
                        >
                          Branch {item + 1}
                        </Option>
                      ))}
                    </Select>
                  </Flex>
                )}
              </S.SelectContainer>
              <Bracket
                // rounds={moddedRounds}
                // ** NOTE: removing third and fourht position match data from round. Since the final round should only have one match to show proper UI.
                rounds={moddedRounds?.map((modRound, i) => {
                  if (i === moddedRounds.length - 1) {
                    return {
                      ...modRound,
                      seeds: [modRound.seeds[0]],
                    };
                  } else return modRound;
                })}
                bracketClassName={moddedRounds.length < 4 ? `brackets-class-${moddedRounds.length}` : `brackets-class`}
                roundTitleComponent={(title, roundIndex) => {
                  return (
                    <Round
                      pageCount={count}
                      roundIndex={roundIndex}
                      roundsCount={moddedRounds.length}
                      title={title}
                      isDesktop={isDesktop}
                      status={moddedRounds[roundIndex].status}
                      endDate={moddedRounds[roundIndex].endDate}
                      startDate={moddedRounds[roundIndex].startDate}
                      poolMode={moddedRounds[roundIndex].mode}
                      playersQty={playersQty}
                      userPositionInfo={userPositionInfo}
                      headers={headers}
                      data={moddedRounds[roundIndex]}
                      roundId={moddedRounds[roundIndex].id}
                      pageId={pageData?.page}
                      branchId={pageData?.branch}
                      poolId={pageData?.poolId}
                      setIsLoading={setIsLoading}
                      getPoolData={getPoolData}
                      userIsPlaying={userIsPlaying}
                      lastPage={lastPage}
                      joinMode={joinMode}
                      stageId={stageId}
                      tournamentMode={poolMode}
                    />
                  );
                }}
                renderSeedComponent={props => (
                  <CustomSeed
                    {...props}
                    // ** NOTE: removing third and fourht position match data from round. Since the final round should only have one match to show proper UI.
                    rounds={moddedRounds?.map((modRound, i) => {
                      if (i === moddedRounds.length - 1) {
                        return {
                          ...modRound,
                          seeds: [modRound.seeds[0]],
                        };
                      } else return modRound;
                    })}
                    runnerupMatch={moddedRounds[moddedRounds.length - 1]?.seeds[1]}
                    lastPage={lastPage}
                    user={user}
                    userPositionInfo={userPositionInfo}
                    headers={headers}
                    template={template}
                  />
                )}
                mobileBreakpoint={1215}
              />
            </div>
          )}
        </S.ResizeableContainer>
      )}
    </div>
  );
};

const MobileBracketsWrapper = ({
  pageCount,
  isDesktop,
  user,
  tournamentId,
  userPositionInfo,
  playersQty,
  getPoolData,
  branchPagesInfo,
  userIsPlaying,
  template,
  joinMode,
  returnCurrentStageData,
}) => {
  const [allRounds, setAllRounds] = useState([]);
  const [activeRoundIndex, setActiveRoundIndex] = useState(0);
  // const [branchMap, setBranchMap] = useState({});
  const [manualBranchMap, setManualBranchMap] = useState({});

  const [respData, setRespData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const headers = useGetSecHeaders();

  const { Option } = Select;

  useEffect(() => {
    if (pageCount == 0) return;

    let tempRounds = [];
    setIsLoading(true);

    (async function getDdata() {
      for (let i = 1; i <= pageCount; i++) {
        let branchNum = 1;

        let x;
        if (userPositionInfo?.pages) {
          x = userPositionInfo?.pages.find(page => page.id == i);
          if (x) branchNum = x.branch;
        }

        try {
          let res = await returnCurrentStageData(i, branchNum);

          if (x) setRespData(res); // we will use respData only for checkIn service. So we will only setRespData if checkIn functionality applies

          const { rounds } = res;

          const roundsWithPageNum = rounds.map(round => ({ ...round, pageNum: i }));
          setIsLoading(false);

          if (roundsWithPageNum) {
            tempRounds = [...tempRounds, ...roundsWithPageNum];
          }
        } catch (err) {
          console.log("Error: ", err);
        } finally {
          setIsLoading(false);
        }
      }
      tempRounds = modRoundsFunc(tempRounds);

      let activeRoundIdx = 0;
      tempRounds.forEach((round, index) => {
        if (round.status === "ACTIVE") {
          activeRoundIdx = index;
        }
      });

      if (userPositionInfo && userIsPlaying) {
        const userHighestPage = userPositionInfo?.pages?.find(
          page => page.id === Math.max(...userPositionInfo.pages.map(page => page.id)),
        );

        const userHighestRoundOnHighestPage = userHighestPage.rounds.find(
          round => round.id === Math.max(...userHighestPage.rounds.map(round => round.id)),
        );

        const targetRoundIndex = tempRounds.findIndex(poolRound => {
          return poolRound.pageNum == userHighestPage.id && poolRound.id === userHighestRoundOnHighestPage.id;
        });
        setActiveRoundIndex(targetRoundIndex);
      } else {
        setActiveRoundIndex(activeRoundIdx);
      }

      setAllRounds(tempRounds);
    })();
  }, [userPositionInfo?.pages, pageCount]);

  useEffect(() => {
    if (Object.keys(manualBranchMap).length < 1) return;
    let tempRounds = [];
    setIsLoading(true);

    (async function getDdata() {
      for (let i = 1; i <= pageCount; i++) {
        let branchNum = 1;
        let x;
        if (userPositionInfo?.pages) {
          x = userPositionInfo?.pages.find(page => page.id == i);
          if (x) branchNum = x.branch;
        }
        try {
          let res = await returnCurrentStageData(i, manualBranchMap[i] || branchNum || 0);
          if (x) setRespData(res); // we will use respData only for checkIn service. So we will only setRespData if checkIn functionality applies

          const { rounds } = res;

          const roundsWithPageNum = rounds.map(round => ({ ...round, pageNum: i }));
          setIsLoading(false);

          if (roundsWithPageNum) {
            tempRounds = [...tempRounds, ...roundsWithPageNum];
          }
        } catch (err) {
          console.log("Error: ", err);
        } finally {
          setIsLoading(false);
        }
      }
      tempRounds = modRoundsFunc(tempRounds);

      let activeRoundIdx = 0;
      tempRounds.forEach((round, index) => {
        if (round.status === "ACTIVE") {
          activeRoundIdx = index;
        }
      });

      if (userPositionInfo && userIsPlaying) {
        const userHighestPage = userPositionInfo?.pages?.find(
          page => page.id === Math.max(...userPositionInfo.pages.map(page => page.id)),
        );

        const userHighestRoundOnHighestPage = userHighestPage.rounds.find(
          round => round.id === Math.max(...userHighestPage.rounds.map(round => round.id)),
        );

        const targetRoundIndex = tempRounds.findIndex(poolRound => {
          return poolRound.pageNum == userHighestPage.id && poolRound.id === userHighestRoundOnHighestPage.id;
        });
        setActiveRoundIndex(targetRoundIndex);
      } else {
        setActiveRoundIndex(activeRoundIdx);
      }
      setActiveRoundIndex(activeRoundIdx);

      setAllRounds(tempRounds);
    })();
  }, [manualBranchMap]);

  if (isLoading) {
    return (
      <S.MainPageLoaderContainer>
        <Loader animation="rotate" />
      </S.MainPageLoaderContainer>
    );
  }

  return (
    <div>
      {allRounds.length > 0 && (
        <Bracket
          bracketClassName={styles.brackets_class}
          // rounds={allRounds}
          // ** NOTE: removing third and fourht position match data from round. Since the final round should only have one match to show proper UI.
          rounds={allRounds?.map((modRound, i) => {
            if (i === allRounds.length - 1) {
              return {
                ...modRound,
                seeds: [modRound.seeds[0]],
              };
            } else return modRound;
          })}
          roundTitleComponent={(title, roundIndex) => {
            return (
              <>
                <Round
                  pageCount={1} //mobile view doesn't have multiple pages
                  title={title}
                  status={allRounds[roundIndex].status}
                  endDate={allRounds[roundIndex].endDate}
                  startDate={allRounds[roundIndex].startDate}
                  poolMode={allRounds[roundIndex].mode}
                  roundIndex={roundIndex}
                  roundsCount={allRounds.length}
                  isDesktop={isDesktop}
                  playersQty={playersQty}
                  userPositionInfo={userPositionInfo}
                  headers={headers}
                  data={allRounds[roundIndex]}
                  roundId={allRounds[roundIndex].id}
                  pageId={respData?.page}
                  branchId={respData?.branch}
                  poolId={respData?.poolId}
                  setIsLoading={setIsLoading}
                  getPoolData={getPoolData}
                  userIsPlaying={userIsPlaying}
                  joinMode={joinMode}
                />
                <S.SelectContainer>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {[...Array(branchPagesInfo[allRounds[roundIndex]?.pageNum]).keys()].length > 1 && (
                      <Select
                        onChange={value => {
                          // Correctly sets branch map
                          setManualBranchMap({ ...manualBranchMap, [allRounds[roundIndex]?.pageNum]: value });
                        }}
                        dropdownStyle={{ background: "#fff", width: "234.34px" }}
                        placeholder="Branch"
                        value={
                          manualBranchMap[allRounds[roundIndex]?.pageNum] ||
                          userPositionInfo?.pages.find(page => page.id == allRounds[roundIndex]?.pageNum)?.branch ||
                          1
                        }
                      >
                        {[...Array(branchPagesInfo[allRounds[roundIndex]?.pageNum]).keys()].map(item => (
                          <Option
                            style={{
                              color: "#fff",
                              background:
                                // manualBranch === item + 1
                                manualBranchMap[allRounds[roundIndex]?.pageNum] === item ? "#fff" : "#201366",
                            }}
                            value={item + 1}
                          >
                            Branch {item + 1}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </div>
                </S.SelectContainer>
              </>
            );
          }}
          renderSeedComponent={props => (
            <CustomSeed
              {...props}
              // rounds={allRounds}
              rounds={allRounds?.map((modRound, i) => {
                if (i === allRounds.length - 1) {
                  return {
                    ...modRound,
                    seeds: [modRound.seeds[0]],
                  };
                } else return modRound;
              })}
              runnerupMatch={allRounds[allRounds.length - 1]?.seeds[1]}
              user={user}
              userPositionInfo={userPositionInfo}
              headers={headers}
              template={template}
            />
          )}
          mobileBreakpoint={1215}
          swipeableProps={{
            index: activeRoundIndex != -1 ? activeRoundIndex : 0,
            ignoreNativeScroll: true,
          }}
        />
      )}
    </div>
  );
};
// CUSTOM WRAPPER COMPONENTS //

export default function MultiStageBracketsSection({
  tournamentData,
  sectionData,
  tournamentId,
  userPositionInfo,
  playersQty,
  branchPagesInfo,
  returnCurrentStageData,
  stageId,
  poolMode,
  selectedStageIdx,
}) {
  const isDesktop = useMedia({ minWidth: "1216px" });

  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const [activePageIndex, setActivePageIndex] = useState(0);

  const [activePageIndexArray, setActivePageIndexArray] = useState([]);

  const session = useRecoilValue(sessionState);

  const sliderRef = useRef(null);

  async function getData() {
    setIsLoading(true);
    try {
      let resPageCount = Object.keys(branchPagesInfo)?.length;

      setPageCount(resPageCount);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (sliderRef && sliderRef.current) {
      if (tournamentData.userPositionInfo && tournamentData.isPlaying) {
        sliderRef.current.slickGoTo(Math.max(...tournamentData.userPositionInfo?.pages.map(page => page.id)) - 1);
      } else {
        sliderRef.current.slickGoTo(activePageIndex);
      }
    }
  }, [activePageIndex]);

  if (isLoading) {
    return (
      <S.MainPageLoaderContainer>
        <Loader animation="rotate" />
      </S.MainPageLoaderContainer>
    );
  }

  return (
    <>
      <ToastContainer />
      <S.SectionContainer>
        {isDesktop ? (
          <Slider
            infinite={false}
            // arrows={false}
            nextArrow={<CarouselArrowRight />}
            prevArrow={<CarouselArrowLeft />}
            //initialSlide={Math.max(...activePageIndexArray)} // This is not needed. Keeping for future reference
            ref={sliderRef}
          >
            {[...Array(pageCount).keys()].map((count, index) => {
              return (
                <BracketWrapper
                  count={count + 1}
                  lastPage={count === pageCount - 1}
                  setActivePageIndex={setActivePageIndex}
                  setActivePageIndexArray={setActivePageIndexArray}
                  activePageIndexArray={activePageIndexArray}
                  index={index}
                  isDesktop={isDesktop}
                  user={session?.user}
                  tournamentId={tournamentId}
                  userPositionInfo={poolMode === "MULTI_STAGE" ? tournamentData?.stages?.[selectedStageIdx]?.userPositionInfo : tournamentData?.userPositionInfo}
                  playersQty={playersQty}
                  getPoolData={getData}
                  branchPagesInfo={branchPagesInfo}
                  userIsPlaying={poolMode === "MULTI_STAGE" ? tournamentData?.stages?.[selectedStageIdx]?.isPlaying : tournamentData?.isPlaying}
                  template={tournamentData?.detailsTemplate}
                  joinMode={tournamentData.joinMode}
                  sectionData={sectionData}
                  returnCurrentStageData={returnCurrentStageData}
                  poolMode={poolMode}
                  stageId={stageId}
                />
              );
            })}
          </Slider>
        ) : (
          <MobileBracketsWrapper
            pageCount={pageCount}
            isDesktop={isDesktop}
            user={session?.user}
            tournamentId={tournamentId}
            userPositionInfo={userPositionInfo}
            playersQty={playersQty}
            getPoolData={getData}
            branchPagesInfo={branchPagesInfo}
            userIsPlaying={tournamentData?.isPlaying}
            template={tournamentData?.detailsTemplate}
            joinMode={tournamentData.joinMode}
            returnCurrentStageData={returnCurrentStageData}
          />
        )}
      </S.SectionContainer>
    </>
  );
}
