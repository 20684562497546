import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import * as S from "./styles";
import { Text, TextNoMargin } from "../../../components/v2/common/Text";
import Container from "../../../components/v2/common/Container";
import Flex from "../../../components/v2/common/Flex";
import Checkbox from "../../../components/v2/common/Checkbox";
import { Button } from "../../../components/v2/common/Button";
import useCallAxios from "../../../hooks/useAxiosCall";
import { ENDPOINT } from "../../../constants/endpoints";
import { useGetSecHeaders } from "../../../hooks";
import { SpinnerLoader } from "../../../components/v2/common/SpinningLoader";
import { useRecoilState } from "recoil";
import { sessionState } from "../../../state/globalState";
import LoadingSkeleton from "../../../components/v2/LoadingSkeleton";
import { BE_API_BASE_URL } from "../../../constants/env";
import { TOAST_OPTIONS } from "../../../constants";
import { toast } from "react-toastify";
import { TickWithBorder } from "../../../assets/svg";

const NotificationSettings = () => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const ref = useRef(null);
  const { callAxiosFunc } = useCallAxios();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const headers = useGetSecHeaders();
  const [session, setSession] = useRecoilState(sessionState);

  const showNotifSettings = queryParams.get("showNotifSettings");

  async function handleSave() {
    try {
      setIsSubmitting(true);
      const response = await callAxiosFunc({
        method: "PUT",
        url: `${BE_API_BASE_URL}user/notifPref`, 
        body: JSON.stringify({ medium: { ...selectedOptions } }),
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      });

      if (!response.error) {
        const updatedSession = {
          ...session,
          user: {
            ...session.user,
            notifPreferences: response?.data?.data?.notifPreferences,
          },
        };
        setSession(updatedSession);
        toast.success(
          <Flex alignItems="center" columnGap="13">
            <TickWithBorder />
            Notifications updates successfully
          </Flex>,
          TOAST_OPTIONS,
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSelectedOptions({});
      setIsSubmitting(false);
    }
  }
  useEffect(() => {
    if (ref.current && showNotifSettings) {
      // using setTimeout was required to make it work.
      // Explanation: The useEffect runs immediately after the first render. At this point, even though ref.current exists, the browser might not have fully calculated all layouts and positions
      setTimeout(function () {
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "center", // Try "start", "center", or "end"
        });
      }, 100);
    }
  }, []);

  const options = [
    { value: "sms", label: "SMS" },
    { value: "discord", label: "Discord" },
    { value: "email", label: "Email" },
    { value: "whatsapp", label: "Whatsapp" },
  ];

  const handleCheckboxChange = event => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    setSelectedOptions({ ...selectedOptions, [value]: isChecked ? true : false });

    // if (isChecked) {
    //   setSelectedOptions([...selectedOptions, value]);
    // } else {
    //   setSelectedOptions(selectedOptions.filter(option => option !== value));
    // }
  };

  const { user } = session;

  if (!user) return <LoadingSkeleton height="401px" />;

  return (
    <S.NotifSettingsContainer ref={ref}>
      <Container marginBottom="22px">
        <Text fontSize="24px">Notification Details</Text>
        <Text fontSize="14px" color="#A498ED">
          You can choose to enable or disable notifications based on your preferences.
        </Text>
      </Container>

      <S.CheckboxContainer>
        {options.map(({ value, label }) => (
          <Flex alignItems="center" columnGap="12">
            <Checkbox
              value={value}
              name={value}
              id={value}
              defaultChecked={user?.notifPreferences?.[value] === true}
              isChecked={selectedOptions?.[value] === true}
              onChange={handleCheckboxChange}
              accentColor="transparent"
              checkedBgColor="#6C47FF"
            />
            <label htmlFor={value}>{label}</label>
          </Flex>
        ))}

        <Container marginTop="60px" flex columnGap="24px" justifyContent="flex-end" paddingRight="8px">
          <Button onClick={handleSave} borderRadius="30px" width="100px" height="42px" disabled={isSubmitting}>
            {isSubmitting ? <SpinnerLoader /> : "Save"}
          </Button>
        </Container>
      </S.CheckboxContainer>
    </S.NotifSettingsContainer>
  );
};

export default NotificationSettings;
