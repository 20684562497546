import React, { useState } from "react";
import { Crown, WarningIcon } from "../../../../assets/svg";
import { Button } from "../../common/Button";
import Avatar from "../../common/Avatar";
import { TextNoMargin } from "../../common/Text";
import * as S from "./styles";
import Flex from "../../common/Flex";
import { capitalizeFirstLetter, truncateWithDots } from "../../../../helpers/utils";
import { toast } from "react-toastify";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../../common/SpinningLoader";
import { TOAST_OPTIONS } from "../../../../constants";

const toastOptions = {
  autoClose: 3000,
  hideProgressBar: true,
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
  style: {
    color: "#D1CCEF",
    background: "#3A2F81",
    width: "400px",
    borderRadius: "6px",
    float: "right",
    marginRight: "63px",
  },
  closeButton: false,
};

const RequestRow = ({
  item,
  isMobile,
  respondToRequest,
  handlePlayersListState,
  sortedPlayers,
  usersLimit,
  activePools,
  teamStatus,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [requestStatus, setRequestStatus] = useState("");

  async function handleRespondingRequests(status, user) {
    setIsLoading(true);
    const res = await respondToRequest(status, user.userId);

    if (res.err) {
      toast(
        <Flex alignItems="center">
          <WarningIcon style={{ height: "40px", width: "40px" }} /> {res?.err?.response?.data?.message?.[0]}
        </Flex>,
        toastOptions,
      );
      setIsLoading(false);
      return;
    } else {
      setRequestStatus(status);
      handlePlayersListState(status, user);
      setIsLoading(false);
    }
  }
  return (
    <S.RequestRowContainer>
      <Flex alignItems="center">
        <S.AvatarContainer>
          <Avatar size="59px" src={item?.thumbnail} />
        </S.AvatarContainer>
        <TextNoMargin style={{ padding: isMobile ? "0 10px" : " 0 20px" }} fontSize="16px">
          {isMobile ? truncateWithDots(item?.nick, 6) : item?.nick}
        </TextNoMargin>
      </Flex>
      {isLoading ? (
        <div style={{ marginRight: "48px" }}>
          <SpinnerLoader />
        </div>
      ) : (
        <Flex alignItems="center" columnGap={isMobile ? "2" : "13"} extraStyles={{ "margin-right": "34.31px" }}>
          <Button
            paddingLeft={isMobile ? "6px" : "16px"}
            paddingRight={isMobile ? "12px" : "16px"}
            background="transparent"
            color="#877ADD"
            fontSize="16px"
            style={{ textDecoration: "underline" }}
            onClick={() => handleRespondingRequests("rejected", item)}
            disabled={teamStatus === "inactive"}
          >
            Deny
          </Button>
          <Button
            fontSize="16px"
            width={isMobile ? "auto" : "105px"}
            height="36px"
            background="#6C47FF"
            borderRadius="18.5px"
            onClick={() => {
              if (
                sortedPlayers?.filter(player => player?.status === "accepted" || player?.status === "pending-confirm")
                  ?.length >= usersLimit
              ) {
                toast(
                  <div>
                    <div>You cannot add more players</div>
                    <div>
                      You have {sortedPlayers?.filter(player => player.status === "accepted")?.length} active and{" "}
                      {sortedPlayers?.filter(player => player.status === "pending-confirm")?.length} pending players
                    </div>
                  </div>,
                  TOAST_OPTIONS,
                );
              } else {
                handleRespondingRequests("accepted", item);
              }
            }}
            disabled={teamStatus === "inactive"}
          >
            Accept
          </Button>
        </Flex>
      )}
    </S.RequestRowContainer>
  );
};

export default RequestRow;
