// import axios from "axios";
import { useEffect, useState } from "react";
import { BE_API_BASE_URL } from "../constants/env";
import { useGetSecHeaders } from "./SecurityHeaders";
import useCallAxios from "./useAxiosCall";
// import { useGetSecHeaders } from "./SecurityHeaders";
/*
  ~ What it does? ~

  Gets the information of the tournament id provided

  ~ How can I use? ~

  const { data, isLoading, isError } = useTournamentData(id);
*/

export default function useTournamentData(id) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const headers = useGetSecHeaders();

  const { callAxiosFunc } = useCallAxios();

  const fetchTournamentData = async () => {
    setIsError(false);
    setIsLoading(true);
    const resp = await callAxiosFunc({
      method: "GET",
      url: `${BE_API_BASE_URL}poolList/pool/${id}`,
      headers: headers.token ? JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }) : null,
    });

    if (resp && resp.err) {
      console.log("Error occured while fetching tournament data ", resp.err);
      setData(null);
      setIsError(true);
    } else {
      setData(resp.data);
    }
    setIsLoading(false);
  };

  // using fetchTournamentData on the array makes a infinite loop of the data of the tournament
  useEffect(() => {
    fetchTournamentData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, headers.token, headers.userId]);

  return { data, isLoading, isError, fetchTournamentData };
}
