/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { toast } from "react-toastify";

import * as S from "./styles";
import { ModalCancelIcon, Tick } from "../../../../assets/svg";
import { BE_API_BASE_URL } from "../../../../constants/env";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { cutUnderNDigits } from "../../../../helpers/utils";
import { useGetSecHeaders, usePriceConverter } from "../../../../hooks";
import VerifyNumber from "./VerifyNumber";
import CodeInput from "./CodeInput";
import useCallAxios from "../../../../hooks/useAxiosCall";
import { TOAST_OPTIONS } from "../../../../constants";
import Flex from "../../common/Flex";

export default function WhatsappVerification({
  isOpen,
  opacity,
  toggleModal,
  entrance,
  name,
  image,
  bgImage,
  startDate,
  leaderboard,
  tokenImage,
  poolPrize,
  tokenSymbol,
  joinBtnRef,
}) {
  const { isMobile } = useWindowSize();
  const { convertedPrice } = usePriceConverter(tokenSymbol, poolPrize, "USD");

  const headers = useGetSecHeaders();
  const { callAxiosFunc } = useCallAxios();

  const [codeSent, setCodeSent] = useState(false);
  // Lifting loader states to parent component to prevent modal closing while loading
  const [isLoadingNumberVerif, setIsLoadingNumberVerif] = useState(false);
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);

  const [userNumberInput, setUserNumberInput] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  async function setWhatsappNumber() {
    const userNumber = userNumberInput.substring(dialCode?.length);
    const url = `${BE_API_BASE_URL}/user/wsp`;
    try {
      setIsLoadingNumberVerif(true);
      const data = await callAxiosFunc({
        method: "PUT",
        url,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
        body: JSON.stringify({ countryCode, countryDialCode: dialCode, phoneNumber: userNumber }),
      });
      if (data?.err) {
        setErrorMsg(data?.err?.response?.data?.message || "Something went wrong");
      } else {
        setCodeSent(true);
        toast(
          <Flex alignItems="center">
            <Tick style={{ height: "15px", width: "15px", marginRight: "14px" }} />
            Verification code sent.
          </Flex>,
          TOAST_OPTIONS,
        );
      }
    } catch (err) {
      setErrorMsg("Something went wrong. Please try again.");
    } finally {
      setIsLoadingNumberVerif(false);
    }

    setIsLoadingNumberVerif(false);
  }

  function customToggle() {
    if (isLoadingNumberVerif || isVerifyingCode) return;
    toggleModal();
  }
  return (
    <div>
      <S.StyledModal
        isOpen={isOpen}
        onBackgroundClick={customToggle}
        onEscapeKeydown={customToggle}
        opacity={opacity}
        isMobile={isMobile}
      >
        <S.ModalContainer>
          <S.MainWrapper>
            {isMobile ? <S.mobBg /> : <S.BgImage src={bgImage} alt="" />}
            {!isMobile && <S.Img src={image} alt="" />}
            <S.IconWrapper onClick={toggleModal}>
              <ModalCancelIcon />
            </S.IconWrapper>
            <S.ContentWrapper>
              {!isMobile && (
                <>
                  <S.TimeAndIconsWrapper>
                    <S.TimeWrapper>
                      <S.TimeText>{startDate}</S.TimeText>
                    </S.TimeWrapper>
                    <S.TimeWrapper>
                      <S.TimeText>{leaderboard.length} players</S.TimeText>
                    </S.TimeWrapper>
                  </S.TimeAndIconsWrapper>
                </>
              )}
              <S.Title>{name}</S.Title>

              <S.PrizeWrapper>
                <S.TextBlock>
                  <S.BlockDescription>Prize Pool</S.BlockDescription>
                  <S.BlockContent>
                    {poolPrize} {tokenImage ? <S.TokenImg src={tokenImage} /> : tokenSymbol} ~ $
                    {cutUnderNDigits(convertedPrice, 2) || 0}
                  </S.BlockContent>
                </S.TextBlock>
                {entrance === 0 && (
                  <>
                    <S.TextBlock>
                      <S.BlockDescription>Entry</S.BlockDescription>
                      <S.BlockContent>Free</S.BlockContent>
                    </S.TextBlock>
                  </>
                )}
              </S.PrizeWrapper>
            </S.ContentWrapper>
          </S.MainWrapper>
          {/* {modalContentLoading || isLoading ? (
            <S.loaderWrapper>
              <Loader animation="rotate" width={100} />
            </S.loaderWrapper>
          ) : (
            <S.ModalContent>
              Test
            </S.ModalContent>
          )} */}
          <S.ModalContent>
            {codeSent ? (
              <CodeInput
                {...{
                  joinBtnRef,
                  setCodeSent,
                  toggleModal,
                  setWhatsappNumber,
                  isLoadingNumberVerif,
                  isVerifyingCode,
                  setIsVerifyingCode,
                }}
              />
            ) : (
              <VerifyNumber
                {...{
                  userNumberInput,
                  setUserNumberInput,
                  setWhatsappNumber,
                  isLoadingNumberVerif,
                  setCountryCode,
                  errorMsg,
                  setDialCode,
                }}
              />
            )}
          </S.ModalContent>
        </S.ModalContainer>
      </S.StyledModal>
    </div>
  );
}
