import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Header = styled.div`
  font-size: 24px;
  font-weight: 325;
  text-align: center;
  color: #fff;
  margin-top: 25px;
`;
export const Subtext = styled.div`
  font-size: 16px;
  font-weight: 325;
  text-align: center;
  color: #A498ED;
  width: 70%;
  margin: auto;
  margin-top: 15px;
  line-height: 20px;
  @media (max-width: 425px) {
    width: 100%;
  }
`;
