import React, { useEffect } from "react";
import { useState } from "react";
import { FacebookAuthProvider, GoogleAuthProvider, TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../helpers/firebase";
import * as S from "./styles";

import {
  DiscordNoBg,
  DiscordSquare,
  FacebookBlueSquare,
  FacebookRound,
  GoogleIcon,
  GoogleWhiteSquare,
  TwitterBlueSquare,
  TwitterNew,
  WarningIcon,
} from "../../assets/svg";
import {
  BE_API_BASE_URL,
  DISCORD_AUTHORIZE_URL,
  DISCORD_CLIENT_ID,
  DISCORD_ENCRYPTED_VALUE,
  DISCORD_REDIRECT_URI,
} from "../../constants/env";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../../components/v2/common/SpinningLoader";
import Flex from "../../components/v2/common/Flex";
import useUserLogin from "../../hooks/UserLogin";
import useCallAxios from "../../hooks/useAxiosCall";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../constants";
import { TextNoMargin } from "../../components/v2/common/Text";

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });

const fbProvider = new FacebookAuthProvider();

const twitterProvider = new TwitterAuthProvider();

function mapError(err) {
  if (err === "USER_EMAIL_ALREADY_EXIST") {
    return "This email address is already taken. Please try another";
  } else if (err === "SOCIAL_ACCIUNT_ALREADY_REGISTERED_OTHER_USER") {
    return "This social account is already registered with another user";
  } else if (err === "SOCIAL_ACCIUNT_ALREADY_REGISTERED_IN_THIS_USER") {
    return "A social account is already registerd with this account";
  } else {
    return "Something went wrong. Please try again later";
  }
}

const ConnectAccounts = ({ session, headers }) => {
  const [isLoading, setIsLoading] = useState("");

  const { callAxiosFunc } = useCallAxios();

  const { setUserState } = useUserLogin();

  const connectSocialAccountApiCall = async (provider, provider_token) => {
    if (!headers?.encryptedHeader) return;
    try {
      const response = await callAxiosFunc({
        method: "PUT",
        url: `user/social/connect`,
        body: JSON.stringify({ provider, provider_token }),
        headers: headers.token ? JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }) : null,
      });
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  const providerConnect = async provider => {
    let authProvider;
    if (provider === "google") {
      authProvider = googleProvider;
    } else if (provider === "facebook") {
      authProvider = fbProvider;
    } else if (provider === "twitter") {
      authProvider = twitterProvider;
    }

    setIsLoading(provider);
    try {
      const result = await signInWithPopup(auth, authProvider);
      if (result) {
        const provider_token = result.user?.accessToken;
        const provider = result.providerId;
        await connectSocialAccountFunc(provider_token, provider);
      }
    } catch (error) {
      console.log(error);
      setIsLoading("");
    } finally {
      setIsLoading("");
    }
  };

  const discordLogin = async () => {
    const discordEncryptedVal = DISCORD_ENCRYPTED_VALUE;
    localStorage.setItem("discordConnectState", discordEncryptedVal);
    const params = new URLSearchParams({
      client_id: DISCORD_CLIENT_ID,
      redirect_uri: DISCORD_REDIRECT_URI,
      response_type: "code",
      scope: ["identify", "email"].join(" "),
      state: discordEncryptedVal,
    });

    const url = new URL(DISCORD_AUTHORIZE_URL);
    url.search = params;

    window.open(url, "_self");
  };

  const connectSocialAccountFunc = async (provider_token, provider) => {
    localStorage.removeItem("discordConnectState");
    setIsLoading("discord"); // Also setting loading here because discord takes userout of the page
    try {
      const response = await connectSocialAccountApiCall(provider, provider_token);
      if (!response) return;

      if (response.err?.response?.data?.error) {
        toast(
          <Flex alignItems="center">
            <WarningIcon style={{ height: "40px", width: "40px" }} />
            <div>{mapError(response.err?.response?.data?.message)}</div>
          </Flex>,
          TOAST_OPTIONS,
        );
        return;
      }

      if(response?.data?.data) {
        setUserState(response.data?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      localStorage.removeItem("discordConnectState");
    }
  };

  useEffect(() => {
    if (!window.location?.search || !localStorage.getItem("discordConnectState") || !headers?.encryptedHeader) return;
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");

    if (!code || !state || state != localStorage.getItem("discordConnectState")) return;
    setIsLoading(true);

    // check state
    connectSocialAccountFunc(code, "discord");
  }, [window.location?.search, headers?.encryptedHeader]);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
        {/* {error && <S.ErrorText>{ mapError(error) }</S.ErrorText>} */}

        <Flex direction="column" style={{ width: "100%" }}>
          <S.ConnectedAccountRow>
            <Flex alignItems="center" columnGap="23">
              <GoogleWhiteSquare />
              <TextNoMargin>Google</TextNoMargin>
            </Flex>
            <S.ConnectAccountBtn
              disabled={isLoading}
              onClick={() => providerConnect("google")}
              id="analytics-connect-google"
            >
              {isLoading === "google" ? (
                <SpinnerLoaderWrapper>
                  <SpinnerLoader />
                </SpinnerLoaderWrapper>
              ) : (
                "Connect"
              )}
            </S.ConnectAccountBtn>
          </S.ConnectedAccountRow>
          <S.ConnectedAccountRow>
            <Flex alignItems="center" columnGap="23">
              <FacebookBlueSquare />
              <TextNoMargin>Facebook</TextNoMargin>
            </Flex>
            <S.ConnectAccountBtn
              onClick={() => providerConnect("facebook")}
              disabled={isLoading}
              id="analytics-connect-facebook"
            >
              {isLoading === "facebook" ? (
                <SpinnerLoaderWrapper>
                  <SpinnerLoader />
                </SpinnerLoaderWrapper>
              ) : (
                "Connect"
              )}{" "}
            </S.ConnectAccountBtn>
          </S.ConnectedAccountRow>
          <S.ConnectedAccountRow>
            <Flex alignItems="center" columnGap="23">
              <TwitterBlueSquare />
              <TextNoMargin>Twitter</TextNoMargin>
            </Flex>
            <S.ConnectAccountBtn
              onClick={() => providerConnect("twitter")}
              disabled={isLoading}
              id="analytics-connect-twitter"
            >
              {isLoading === "twitter" ? (
                <SpinnerLoaderWrapper>
                  <SpinnerLoader />
                </SpinnerLoaderWrapper>
              ) : (
                "Connect"
              )}{" "}
            </S.ConnectAccountBtn>
          </S.ConnectedAccountRow>
          <S.ConnectedAccountRow>
            <Flex alignItems="center" columnGap="23">
              <DiscordSquare />
              <TextNoMargin>Discord</TextNoMargin>
            </Flex>
            <S.ConnectAccountBtn onClick={discordLogin} disabled={isLoading} id="analytics-connect-discord">
              {isLoading === "discord" ? (
                <SpinnerLoaderWrapper>
                  <SpinnerLoader />
                </SpinnerLoaderWrapper>
              ) : (
                "Connect"
              )}{" "}
            </S.ConnectAccountBtn>
          </S.ConnectedAccountRow>
        </Flex>
      </div>
    </div>
  );
};

export default ConnectAccounts;
