import React from "react";
import styled from "styled-components";
import Avatar from "./index";

const RootWrap = styled.div`
  position: relative;
  ${props => `
    width: ${props.size ? `calc(${props.size} + 20px)` : "56px"};
    height: ${props.size ? `calc(${props.size} + 20px)` : "56px"};    
  `}
`;

const Background = styled.div`
  border-radius: 50%;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  ${props => `
  width: ${props.size ? `calc(${props.size} + 15px)` : "56px"};
  height: ${props.size ? `calc(${props.size} + 15px)` : "56px"};
  background: ${props.src ? (props.bgColor ? props.bgColor : `#302862`) : "transparent"};
`}
`;

const AvatarWrap = styled.div`
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const DecoratedAvatar = ({ src, size, bgColor }) => {
  return (
    <RootWrap size={size}>
      <Background size={size} src={src} bgColor={bgColor}>
        <AvatarWrap>
          <Avatar src={src} size={size} />
        </AvatarWrap>
      </Background>
    </RootWrap>
  );
};

export default DecoratedAvatar;
