import { dateFormatter } from "../helpers/utils";

// a variable that sets the title and description according to the PoolStatus
export const getBracketStatusMessage = data => ({
  JOINING: {
    title: "Registration Open",
    description: "Register now to play in the tournament.",
    dateText: `${dateFormatter(data?.registrationStartDate)} -  ${dateFormatter(data?.registrationEndDate)}`,
  },
  PROCESSING_REGISTERED_USERS: {
    title: "Registration Closed",
    description: "Creating the brackets, we'll start soon.",
    dateText: `${dateFormatter(data?.registrationEndDate)}`,
  },
  RUNNING: {
    title: "Playing",
    description: "Tournament started Good luck!",
    dateText: `${dateFormatter(data?.startDate)} -  ${dateFormatter(data?.endDate)}`,
  },
  FINISHED: {
    title: "Finished",
    description: "Tournament finished Prizes are on their way.",
    dateText: `${dateFormatter(data?.endDate)}`,
  },
  ARCHIVED: {
    title: "Paid",
    description: "Payments sent to the winners. Congrats!",
    dateText: `${dateFormatter(data?.endDate)}`,
  },
});

export const getLeaderboardStatusMessage = data => ({
  NOT_STARTED: {
    title: "Registration Open",
    description: "Register now to play in the tournament.",
    dateText: `${dateFormatter(data?.registrationStartDate)} -  ${dateFormatter(data?.registrationEndDate)}`,
  },
  RUNNING: {
    title: "Tournament Started",
    description: "Tournament started Good luck!",
    dateText: `${dateFormatter(data?.startDate)}`,
  },
  FINISHED: {
    title: "Finished",
    description: "Tournament finished Prizes are on their way.",
    dateText: `${dateFormatter(data?.endDate)}`,
  },
  ARCHIVED: {
    title: "Paid",
    description: "Payments sent to the winners. Congrats!",
    dateText: `${dateFormatter(data?.endDate)}`,
  },
});
