import styled from "styled-components";

export const Container = styled.div`
  width: 605px;
  max-width: 610px;

  @media (max-width: 450px) {
    width: 100% !important;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  max-width: 583px;
`;
export const TeamsRowContainer = styled.div`
  /* height: ${props => (props.height ? props.height : "300px")};
  width: ${props => (props.width ? props.width : "600px")};
  max-width: 610px; */
  width: 610px;
  height: 250px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  background: ${props => (props.background ? props.background : "transparent")};
  border: ${props => (props.border ? props.border : "none")};
  border-radius: 4px;
  max-height: ${props => (props.maxHeight ? props.maxHeight : "300px")};
  overflow-y: auto;
  scrollbar-width: "5px";
  padding-top: ${props => (props.padding ? props.padding : "0px")};
  ::-webkit-scrollbar {
    width: 6px;
    height: 146px;
  }
  ::-webkit-scrollbar-thumb {
    background: #2b1c7e;
    border-radius: 30px;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const SingleTeamRowContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 71px;
  width: 590px;
  margin: 5px 0px;
  background: #36279c;
  border: 1px solid #5647b5;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  @media (max-width: 640px) {
    width: 100% !important;
  }
  opacity: ${props => (props.enabled ? 1 : 0.5)};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const EmptyCheckbox = styled.div`
  width: 32px;
  height: 32px;
  opacity: 0.5;
  border: 3px solid #5443c7;
  border-radius: 50px;
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: 640px) {
    margin-left: 4px;
    margin-right: 2px;
    width: 30px;
    height: 30px;
  }
`;
export const FilledCheckbox = styled.div`
  width: 32px;
  height: 32px;
  opacity: 0.5;
  border: 3px solid #5443c7;
  border-radius: 50px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const ManagePlayers = styled.div`
  margin-left: 25px;
  margin-right: 10px;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 350;
  line-height: 32px;
  color: #ffffff;
  text-decoration: underline;
  :hover {
    cursor: pointer;
    color: #a498ed;
  }

  @media (max-width: 640px) {
    width: 90px;
    line-height: 20px;
  }
  @media (max-width: 375px) {
    margin-left: 10px;
  }
`;

export const MembersCount = styled.span`
  font-size: 18px;
  color: #a498ed;
  margin-left: 10px;
  margin-right: 30px;
  line-height: 36px;
  @media (max-width: 640px) {
    font-size: 15px;
    margin-left: 15px;
    margin-right: 10px;
  }
  @media (max-width: 375px) {
    font-size: 12px;
    margin-left: 20px;
    margin-right: 0; 
  }
`;
