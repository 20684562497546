import React from "react";
import styled from "styled-components";
import TopWinners from "./TopWinners";
import { breakpoints } from "../../../../themes";

const TopWinnerWrap = styled.div`
  margin-top: 30px;
  width: 100%;
  border-radius: 10px;
  height: 100%;
  gap: 27px;
  display: grid;
  grid-template-columns: ${props => (props.length > 1 ? "repeat(auto-fill, minmax(205px, 1fr))" : "1fr")};
  align-self: center;
  justify-self: center;

  ${breakpoints.down(breakpoints.tablet)} {
    display: flex;
    flex-direction: column;
    padding: 25px 0px;
    background-color: transparent;
  }
`;

const TopWinnerContainer = styled.div`
  width: 100%;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function TopWinnersList({ topWinners }) {
  // function that given just 5 gradients sets the background color for each topWinner but not randomly, also it must be for N top winner elementselements
  const setTopWinnerBgColor = index => {
    const firstGradient = "linear-gradient(180deg, #2FAA94 0%, rgba(63, 47, 170, 0) 100%)";
    const secondGradient = "linear-gradient(180deg, #8C54FD 0%, rgba(63, 47, 170, 0) 100%)";
    const thirdGradient = "linear-gradient(180deg, #5487F4 0%, rgba(63, 47, 170, 0) 100%)";
    const fourthGradient = "linear-gradient(180deg, #3F2FAA 0%, rgba(63, 47, 170, 0) 100%)";
    const fifthGradient = "linear-gradient(180deg, #CB49B5 0%, rgba(63, 47, 170, 0) 100%)";
    const gradientArray = [firstGradient, secondGradient, thirdGradient, fourthGradient, fifthGradient];
    // now repeat for each item of TopWinnersList component the gradient color set above it must work for N top winners since can be more than just five topWinners
    switch (index) {
      case 0:
        return firstGradient;
      case 1:
        return secondGradient;
      case 2:
        return thirdGradient;
      case 3:
        return fourthGradient;
      case 4:
        return fifthGradient;
      default:
        return gradientArray[index % 5];
    }
  };

  const setTopWinnerMobileBgColor = index => {
    const firstGradient = "linear-gradient(90deg, #2FAA94 1.01%, rgba(32, 19, 102, 0) 100%)";
    const secondGradient = "linear-gradient(90deg, #8C54FD 1.01%, rgba(32, 19, 102, 0) 100%)";
    const thirdGradient = "linear-gradient(90deg, #5487F4 1.01%, rgba(32, 19, 102, 0) 100%)";
    const fourthGradient = "linear-gradient(90deg, #3F2FAA 1.01%, rgba(32, 19, 102, 0) 100%)";
    const fifthGradient = "linear-gradient(90deg, #CB49B5 1.01%, rgba(32, 19, 102, 0) 100%)";
    const gradientArray = [firstGradient, secondGradient, thirdGradient, fourthGradient, fifthGradient];
    // now repeat for each item of TopWinnersList component the gradient color set above it must work for N top winners since can be more than just five topWinners
    switch (index) {
      case 0:
        return firstGradient;
      case 1:
        return secondGradient;
      case 2:
        return thirdGradient;
      case 3:
        return fourthGradient;
      case 4:
        return fifthGradient;
      default:
        return gradientArray[index % 5];
    }
  };

  const setTopWinnerMainColor = index => {
    const firstMainColor =
      "linear-gradient(to bottom, rgba(63, 47, 170, 0.8) 0%, rgba(63, 47, 170, 0.8) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%)";
    const secondMainColor =
      "linear-gradient(to bottom, rgba(63, 47, 170, 0.8) 0%, rgba(63, 47, 170, 0.8) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%)";
    const thirdMainColor =
      "linear-gradient(to bottom, rgba(63, 47, 170, 0.8) 0%, rgba(63, 47, 170, 0.8) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%)";
    const fourthMainColor =
      "linear-gradient(to bottom, rgba(63, 47, 170, 0.8) 0%, rgba(63, 47, 170, 0.8) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%)";
    const fifthMainColor =
      "linear-gradient(to bottom, rgba(63, 47, 170, 0.8) 0%, rgba(63, 47, 170, 0.8) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%)";
    const mainColorArray = [firstMainColor, secondMainColor, thirdMainColor, fourthMainColor, fifthMainColor];
    switch (index) {
      case 0:
        return firstMainColor;
      case 1:
        return secondMainColor;
      case 2:
        return thirdMainColor;
      case 3:
        return fourthMainColor;
      case 4:
        return fifthMainColor;
      default:
        return mainColorArray[index % 5];
    }
  };

  const setMobileColorData = index => {
    const firstGradient = "linear-gradient(90deg, rgba(47, 170, 148, 0.3) 1.01%, rgba(32, 19, 102, 0) 100%)";
    const secondGradient = "linear-gradient(90deg, rgba(140, 84, 253, 0.3) 1.01%, rgba(32, 19, 102, 0) 100%)";
    const thirdGradient = "linear-gradient(90deg, rgba(84, 135, 244, 0.3) 1.01%, rgba(32, 19, 102, 0) 100%)";
    const fourthGradient = "linear-gradient(90deg, rgba(63, 47, 170, 0.3) 1.01%, rgba(32, 19, 102, 0) 100%)";
    const fifthGradient = "linear-gradient(90deg, rgba(203, 73, 181, 0.3) 1.01%, rgba(32, 19, 102, 0) 100%)";
    const gradientArray = [firstGradient, secondGradient, thirdGradient, fourthGradient, fifthGradient];
    // now repeat for each item of TopWinnersList component the gradient color set above it must work for N top winners since can be more than just five topWinners
    switch (index) {
      case 0:
        return firstGradient;
      case 1:
        return secondGradient;
      case 2:
        return thirdGradient;
      case 3:
        return fourthGradient;
      case 4:
        return fifthGradient;
      default:
        return gradientArray[index % 5];
    }
  };

  const topWinnersLength = topWinners.length;

  return (
    <TopWinnerWrap length={topWinnersLength}>
      {topWinners.map((topWinner, index) => {
        return (
          <TopWinnerContainer>
            <TopWinners
              topWinnerData={topWinner}
              bgColorData={setTopWinnerBgColor(index)}
              bgMainColorData={setTopWinnerMainColor(index)}
              bgColorMobileData={setTopWinnerMobileBgColor(index)}
              mobileColorData={setMobileColorData(index)}
            />
          </TopWinnerContainer>
        );
      })}
    </TopWinnerWrap>
  );
}

export default TopWinnersList;
