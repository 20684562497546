import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import useNotifMethods from "../../../hooks/useNotifMethods";
import { NOTIFICATION_HOURS } from "../../../constants/env";
import { useHistory } from "react-router-dom";
import { breakpoints } from "../../../themes";
import { useRecoilState } from "recoil";
import { sessionState } from "../../../state/globalState";
import { NoMessageIcon } from "../../../assets/svg";
import Loader from "../Loader";
import { useCallback } from "react";

const NotificationWrap = styled.div`
  width: 323px;
  border-radius: 6px;
  background: #332E8A;
  position: absolute;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  top: 56px;
  ${props => `
  display: ${props.open === true ? "block" : "none"};

  `}
  ${breakpoints.down(breakpoints.mobileL)} {
    transform: translateX(-100px);
  }
  ${breakpoints.down(breakpoints.mobileM)} {
    transform: translateX(-75px);
  }
  ${breakpoints.down(breakpoints.mobileS)} {
    transform: translateX(-50px);
    width: 300px;
  }
`;

const NotificationHeader = styled.div`
  height: 49px;
  font-size: 14px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 18px;
`;
const Mark = styled.span`
  font-size: 11px;
  line-height: 18px;
  color: #877add;
  cursor: pointer;
`;

const NotificationItemWrap = styled.div`
  padding: 18px 34px 12px 18px;
  display: flex;
  font-size: 11px;
  line-height: 14px;
  color: white;
  border-bottom: 1px solid #4a3e9c;

  ${props => `
    background: ${props.read === false ? "#5443C7" : "inherit"};
  `}
`;

const StatusWrap = styled.div`
  padding: 5px 2px 2px 0px;
  width: 22px;
  height: 22px;
`;

const GradientDot = styled.div`
  width: 7px;
  height: 7px;
  background: linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%);
  border-radius: 8px;
`;

const SeperationDot = styled.div`
  width: 3px;
  height: 3px;
  background: #877add;
  border-radius: 3px;
`;

const TextWrap = styled.div`
  width: 255px;
`;

const MetaInfo = styled.div`
  display: flex;
  flex-direction: row;
  color: #877add;
  align-items: center;
  gap: 8px;
`;

const Footer = styled.div`
  height: 38px;
  color: #A498ED;
  font-size: 10px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5443C7;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

const Description = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin-bottom: 8px;
`;

const NoMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 0;
`;

const NoMessageHead = styled.p`
  font-size: 20px;
  font-weight: 400;
  color: #d1ccef;
`;

const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NotificationItem = ({ id, title, createdOn, unread, source }) => {
  const [session, setSession] = useRecoilState(sessionState);
  const duration = moment.duration(Date.now() - createdOn);
  const [notifRead, setNotifRead] = useState(!unread);
  const { markAsRead } = useNotifMethods();

  function markNotifAsRead(id) {
    if (notifRead) return;
    try {
      markAsRead(id);
      const {
        user: { unreadMessages },
      } = session;

      const updatedSession = {
        ...session,
        user: {
          ...session.user,
          unreadMessages: unreadMessages <= 0 ? 0 : unreadMessages - 1,
        },
      };
      setSession(updatedSession);
      setNotifRead(true);
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <NotificationItemWrap read={notifRead} onClick={() => markNotifAsRead(id)}>
      <StatusWrap>{!notifRead && <GradientDot />}</StatusWrap>
      <TextWrap>
        {typeof title === "string" ? <Description>{title}</Description> : <Description>{title?.title}</Description>}
        <MetaInfo>
          <span>
            {duration?._data.hours < NOTIFICATION_HOURS && duration?._data.days < 1
              ? moment.duration(createdOn - Date.now()).humanize(true)
              : moment(createdOn).format("MMM DD, h:mm A")}
          </span>
          <SeperationDot />
          <span>{source}</span>
        </MetaInfo>
      </TextWrap>
    </NotificationItemWrap>
  );
};

const Notification = ({ open, toggle, notificationIconRef }) => {
  const notificationRef = useRef();
  const { fetchNotifications, markAsRead } = useNotifMethods();
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [session, setSession] = useRecoilState(sessionState);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        notificationRef &&
        notificationRef.current &&
        !notificationRef.current.contains(event.target) &&
        !notificationIconRef.current.contains(event.target)
      ) {
        toggle(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationRef, notificationIconRef, toggle]);

  const fetchFunction = async () => {
    setIsLoading(true);
    const res = await fetchNotifications({
      startAfter: 0,
      endBefore: 0,
      qty: 5,
      unread: false,
    });
    setNotifications(res.messages);
    setIsLoading(false);
    // fetchNotifications remove due to infinite loop on fetching notifications
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const readAll = async () => {
    setIsLoading(true);

    for (let index = 0; index < notifications.length; index += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await markAsRead(notifications[index].id);

        // Update the session state once the last notification is read
        if (index === notifications.length - 1) {
          const {
            // unreadMessages no removed due to probably being used to get the unread Messages
            // eslint-disable-next-line no-unused-vars
            user: { unreadMessages },
          } = session;

          const updatedSession = {
            ...session,
            user: {
              ...session.user,
              unreadMessages: 0,
            },
          };
          setSession(updatedSession);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchFunction();
  };

  useEffect(() => {
    if (open) {
      fetchFunction();
    }
  }, [open]);

  const goMessagePage = () => {
    history.push("/messages");
    toggle();
  };

  return (
    <NotificationWrap open={open} ref={notificationRef}>
      <NotificationHeader>
        <span>Notifications</span>
        <Mark onClick={readAll}>Mark all as read</Mark>
      </NotificationHeader>
      <ul>
        {isLoading ? (
          <LoaderWrapper>
            <Loader animation="rotate" width={80} />
          </LoaderWrapper>
        ) : notifications.length <= 0 ? (
          <NoMessageContainer>
            <div>
              <NoMessageIcon />
            </div>
            <NoMessageHead>No messages yet</NoMessageHead>
          </NoMessageContainer>
        ) : (
          <>
            {notifications.map(item => (
              <NotificationItem
                id={item.id}
                key={item.id}
                title={item.title}
                createdOn={item.createdOn}
                unread={item.unread}
                source={item.senderNick}
              />
            ))}
          </>
        )}
      </ul>
      <Footer onClick={goMessagePage}>View all</Footer>
    </NotificationWrap>
  );
};

export default Notification;
