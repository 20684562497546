import styled from "styled-components";
import { breakpoints } from "../../../../themes";

export const RequestRowContainer = styled.div`
  width: 100%;
  height: 130px;
  background: #312c83;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  padding-left: 44px;
  padding-right: 44px;
  justify-content: space-between;

  @media (max-width: 1200px) {
    padding-left: 14px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 1rem;
  justify-content: end;
  padding-right: 35px;
`;

export const AvatarContainer = styled.div`
  display: block;

  @media screen and (max-width: 370px) {
    display: none;
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const Text = styled.div`
  ${props => `
    color: ${props.color ? `${props.color}` : "#fff"}};
    font-size: ${props.size ? `${props.size}px` : "16px"}};
    font-weight: ${props.weight ? `${props.weight}` : "unset"}};
  `}
`;

export const Flex = styled.div`
  display: flex;
  ${props => `
    gap: ${props.gap ? `${props.gap}px` : "unset"}};
    row-gap: ${props.rowGap ? `${props.rowGap}px` : "unset"}};
    column-gap: ${props.columnGap ? `${props.columnGap}px` : "unset"}};
    flex-direction: ${props.direction ? props.direction : "row"};
    align-items: ${props.alignItems ? props.alignItems : "unset"};
    justify-content: ${props.justifyContent ? props.justifyContent : "unset"};
  `}
`;

export const ImageContainer = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid #be7eff;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 36px;
    height: 36px;
  }
`;

export const ImageAvatar = styled.img`
  width: 59px;
  height: 59px;
  border-radius: 50%;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 36px;
    height: 36px;
  }
`;

export const Badge = styled.div`
  width: 21px;
  height: 21px;
  position: absolute;
  background-color: red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: -5px;
  font-size: 11px;
  font-weight: 325;
  border: 2px solid #3f2faa;
`;
