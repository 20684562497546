/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import styled from "styled-components";
import * as S from "./styles";
import { usePriceConverter } from "../../../hooks";
import { useAppState } from "../../../providers/AppStateProvider";
import { Button } from "../common/Button";
import promotedBg from "../../../assets/promoted-bg.png";
import { PoolStatus } from "../../../constants/games";
import { formatDateTime, cutUnderNDigits } from "../../../helpers/utils";
import { CupIcon, ColoredCheckMark, UserIcon, TimeArrowIcon } from "../../../assets/svg";
import { breakpoints } from "../../../themes";

const CardContainer = styled.div`
  width: 504px;
  height: 303px;
  position: relative;
  opacity: ${props => (props.isFinished ? "0.4" : "1")};
  cursor: pointer;
  ${breakpoints.down(breakpoints.mobileL)} {
    width: 100%;
  }
`;

const BgImg = styled.img`
  width: 502px;
  height: 303px;
  border-radius: 6px;
  ${breakpoints.down(breakpoints.mobileL)} {
    width: 100%;
  }
`;

const Img = styled.img`
  width: 502px;
  height: 303px;
  top: 0;
  right: 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  ${breakpoints.down(breakpoints.mobileL)} {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 32px;
  left: 29px;
  color: white;
`;

const ActivisionImg = styled.img`
  width: 72px;
  height: 18px;
`;

const TitleText = styled.p`
  font-size: 22px;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 8px;
`;

const DateTimeText = styled.p`
  font-size: 10px;
  line-height: 20px;
  margin-bottom: 0;
  text-transform: uppercase;
`;

const CurrencyText = styled.p`
  font-size: 30px;
  line-height: 20px;
  letter-spacing: 3px;
  margin-top: 8px;
  margin-bottom: 0;
`;

const JoinBtn = styled(Button)`
  background-image: none;
  background-color: white;
  color: ${props => props.theme.darkBackground};
  border-radius: 50px;
  font-size: 14px;
  width: 75px;
`;

const ButtonWrapper = styled.div`
  margin-left: 30px;
  display: flex;
  align-items: center;
  gap: 18px;
`;

const LinkText = styled.p`
  font-size: 11px;
  line-height: 20px;
  color: white;
  text-decoration: underline;
  margin-bottom: 0;

  :hover {
    cursor: pointer;
  }
`;

const StateWrapper = styled.div`
  border-radius: 2px;
  padding: 2px 5px;
  background-color: #ae4747;
  color: white;
  margin-left: 5px;
  height: min-content;
  opacity: ${props => (props.isFinished ? "0.4" : "1")};
`;

const StateText = styled.div`
  color: white;
  font-size: 9px;
  font-weight: 400;
`;

const CountDownTimer = styled.span`
  font-size: 10px;
  color: white;
`;

const DetailWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 11px;
  display: flex;
  align-items: center;
  z-index: 1;
`;
const DateTimeWrapper = styled.div`
  min-width: 75px;
  border-radius: 4px;
  color: white;
  margin-left: 12px;
`;

const TimeStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 14px;
`;

const UnavailableText = styled.span`
  text-decoration: line-through;
  text-transform: uppercase;
`;

export const PrizeWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 8px;
`;

export const TextBlock = styled.div`
  border-radius: 8px;
  padding: 5px;
  height: 60px;
`;

export const BlockDescription = styled.p`
  color: #ffffff7a;
  font-size: 9px;
  line-height: 14px;
  margin-bottom: 0px;
`;

export const BlockContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 26px;
  color: white;
  margin-bottom: 0px;
`;

export const TokenImg = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 8px;
  margin-left: 8px;
`;

export const PrizeIconWrap = styled.span`
  margin-right: 5px;
`;

export const BottomLine = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
`;

export const PlayerIconWrapper = styled.span`
  margin-left: -8px;
  align-self: center;
`;

export const DetailsText = styled.span`
  font-size: 11px;
  line-height: 17px;
  color: white;
  align-self: center;
  margin-left: 8px;
`;

export const ArrowWrap = styled.span`
  margin-left: 5px;
  margin-right: 5px;
`;

const StatusWrapper = styled.div`
  position: absolute;
  right: 27px;
  top: 19px;
  z-index: 100;
`;

const PlayingWrapper = styled.div`
height: 30px;
width: 57px;
display: flex;
align-items: center;
justify-content: center;
p {
  margin: 0px;
  color: #fff;
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
}
div {
  width: 12px;
  height: 12px;
  background-color: transparent;
  opacity: 0.8;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
}
span {
  width: 7px;
  height: 7px;
  background: #c33b3b;
  border-radius: 50px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  transform: scale(1);
  animation: pulse 1.4s infinite;
  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    40% {
      transform: scale(1);
      box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.4);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
}
`;

export const CurrencySmall = styled.span`
  font-size: 9px;
  line-height: 14px;
  font-weight: 400;
  color: #7f81b0;
`;

export default function PromotedTournamentCard({
  image,
  poolName,
  description,
  startDateTime,
  price,
  sponsored,
  entrance,
  playing,
  isFinished,
  endDate,
  poolId,
  poolStatus,
  leaderboard,
  headers,
  tokenImage,
  poolPrize,
  tokenSymbol,
  entranceTokenImage,
  entranceTokenSymbol,
  joinMode,
  winnersPrize = [],
  maxPlayers,
}) {
  const [timeLeft, setTimeLeft] = useState({});
  const [userStatusInPool, setUserStatusInPool] = useState(PoolStatus.NOT_JOINED);
  const { convertedPrice, conversionError } = usePriceConverter(tokenSymbol, poolPrize, "USD");
  const { convertedPrice: convertedEntranceFee, conversionError: conversionEntranceError } = usePriceConverter(
    tokenSymbol,
    entrance,
    "USD",
  );

  const history = useHistory();
  const { detectMetamask } = useAppState();

  const Timer = () => {
    if (timeLeft._data.days < 1) {
      return (
        <CountDownTimer>
          {String(timeLeft._data.hours).padStart(2, "0")}:{String(timeLeft._data.minutes).padStart(2, "0")}:
          {String(timeLeft._data.seconds).padStart(2, "0")} min
        </CountDownTimer>
      );
    }
    return (
      <DateTimeWrapper style={{ marginLeft: "0px" }}>
        <div style={{ fontSize: "10px" }}>{moment(endDate).format("MMM DD, h:mm A")}</div>
      </DateTimeWrapper>
    );
  };

  useEffect(() => {
    if (poolStatus === "FINISHED" || poolStatus === "FINISHED_COLLECTING_DATA" || poolStatus === "ARCHIVED") {
      setUserStatusInPool(PoolStatus.FINISHED);
    } else if (poolStatus === "RUNNING") {
      if (leaderboard.findIndex(elem => elem.userId === headers.userId) !== -1) {
        setUserStatusInPool(PoolStatus.JOINED_STARTED);
      } else {
        setUserStatusInPool(PoolStatus.JOINED_NOT_STARTED);
      }
    } else {
      setUserStatusInPool(PoolStatus.NOT_JOINED);
    }
  }, [leaderboard, headers, poolStatus]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      const endTime = endDate;
      setTimeLeft(moment.duration(endTime - currentTime));
    }, 1000);
    return () => clearInterval(interval);
  }, [endDate]);

  const joinTournament = async () => {
    history.push(`/pool/${poolId}`);
  };

  const JoinButton = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    return <JoinBtn>{loading ? <S.loader style={{ marginTop: "6px" }} /> : children}</JoinBtn>;
  };

  return (
    <>
      <CardContainer isFinished={isFinished} onClick={joinTournament}>
        {poolStatus === "RUNNING" && (
          <StatusWrapper>
            <PlayingWrapper>
              <div>
                <span />
              </div>
              <p>Playing</p>
            </PlayingWrapper>
          </StatusWrapper>
        )}
        <BgImg src={promotedBg} />
        <Img src={image} />
        {/* {(playing || isFinished) && (
          <DetailWrapper>
            {playing && (timeLeft._data ? <Timer /> : <CountDownTimer>00:00 min</CountDownTimer>)}
            <StateWrapper>
              <StateText>{playing ? "Playing" : "Finished"}</StateText>
            </StateWrapper>
          </DetailWrapper>
        )} */}
        <ContentWrapper>
          <ActivisionImg src={sponsored?.logoURL} />

          <TimeStatusWrapper>
            <DateTimeText>
              {(userStatusInPool === PoolStatus.NOT_JOINED || userStatusInPool === PoolStatus.JOINED_NOT_STARTED) &&
                formatDateTime(startDateTime)}
              {userStatusInPool === PoolStatus.JOINED_STARTED && (
                <>
                  <UnavailableText>{formatDateTime(startDateTime)}</UnavailableText>
                  <ArrowWrap>
                    <TimeArrowIcon />
                  </ArrowWrap>
                  <span>{formatDateTime(endDate)}</span>
                </>
              )}
              {userStatusInPool === PoolStatus.FINISHED && <UnavailableText>{formatDateTime(endDate)}</UnavailableText>}
            </DateTimeText>
            {userStatusInPool === PoolStatus.FINISHED && (
              <StateWrapper isFinished>
                <StateText>Finished</StateText>
              </StateWrapper>
            )}
          </TimeStatusWrapper>
          <TitleText>{poolName}</TitleText>
          <PrizeWrapper>
            <TextBlock>
              <BlockDescription>Prize Pool</BlockDescription>
              <BlockContent>
                <PrizeIconWrap>
                  <CupIcon />
                </PrizeIconWrap>
                {poolPrize} {tokenImage ? <TokenImg src={tokenImage} /> : tokenSymbol} ~ $
                {conversionError ? 0 : cutUnderNDigits(convertedPrice, 2)}
              </BlockContent>
            </TextBlock>
            <TextBlock>
              <BlockDescription>Entry</BlockDescription>
              <BlockContent>
                <PrizeIconWrap>
                  <ColoredCheckMark />
                </PrizeIconWrap>
                {entrance && entrance > 0 ? (
                  <div>
                    {entrance}
                    {entranceTokenImage ? (
                      <TokenImg src={entranceTokenImage} />
                    ) : (
                      <span style={{ marginRight: "4px" }}>{entranceTokenSymbol}</span>
                    )}
                    {entranceTokenSymbol !== "KUZ" && (
                      <CurrencySmall>
                        $ {conversionEntranceError ? 100 : cutUnderNDigits(convertedEntranceFee, 2)}
                      </CurrencySmall>
                    )}
                  </div>
                ) : (
                  "Free"
                )}
              </BlockContent>
            </TextBlock>
          </PrizeWrapper>
          <BottomLine>
            <PlayerIconWrapper>
              <UserIcon />
            </PlayerIconWrapper>
            <DetailsText>{leaderboard.length}&nbsp;&nbsp;{joinMode === "TEAM" ? "teams" : "players"}</DetailsText>
            <ButtonWrapper>
              {headers.token &&
                poolStatus !== "FINISHED" &&
                poolStatus !== "FINISHED_COLLECTING_DATA" &&
                poolStatus !== "ARCHIVED" && (
                  <JoinButton borderRadius="30px" fontSize="15px" lineHeight="20px">
                    {leaderboard?.find(elem => elem.userId === headers.userId)?.statusOnChain === "retry"
                      ? "Pending"
                      : leaderboard.findIndex(elem => elem.userId === headers.userId) !== -1
                      ? "Joined"
                      : "Join"}
                  </JoinButton>
                )}
            </ButtonWrapper>
          </BottomLine>
        </ContentWrapper>
      </CardContainer>
    </>
  );
}
