import React from "react";
import { FirstPlaceTrophy, Star, Wallet } from "../../../../assets/svg";
import StatsCard from "./StatsCard";
import * as S from "./styles";

const PLACEHOLDER_DATA = {
  moneyEarned: 12000,
  tournamentsWon: 14,
  tournamentsPlayed: 21,
};
const TeamStats = ({ stats }) => {
  return (
    <S.Container>
      {stats?.map(stat => (
        <StatsCard label={stat.name} value={stat.value} />
      ))}
    </S.Container>
  );
};

export default TeamStats;
