import React from "react";
import { Button } from "../../common/Button";
import Modal from "../../Modal";
import * as S from "./styles";
import { YellowWarningIcon } from "../../../../assets/svg";

const MaxPlayersReachedModal = ({ showModal, toggleModal }) => {
  return (
    <>
      <Modal
        showModal={showModal}
        toggleModal={() => toggleModal()}
        centerHeader
        customStyles={{ background: "#1C1644", width: "501px", height: "359px" }}
        mobileStyles={{width: "360px"}}
      >
        <S.TextContainer>
          <S.HeaderContainer>
            <YellowWarningIcon /> <div> Max players joined</div>
          </S.HeaderContainer>
          <span>All the slots for this tournament have been filled.</span>
        </S.TextContainer>
        <Button
          onClick={toggleModal}
          style={{ height: "40px", width: "128px", margin: "auto", marginTop: "24px", borderRadius: "24px" }}
        >
          Close
        </Button>
      </Modal>
    </>
  );
};

export default MaxPlayersReachedModal;
