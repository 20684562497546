import React from "react";
import {TeamDefaultAvatar, NotificationIconRed } from "../../../../assets/svg";
import { Button } from "../../common/Button";
import * as S from "./styles";
import Avatar from "../../common/Avatar";

const TeamTotalRequests = ({ isMobile, item }) => {
  return (
    <S.RequestRowContainer>
      <S.Flex direction="column" justifyContent="space-between" alignItems="center">
        <S.Flex columnGap={21}>
          {window.screen.width < 768 ? (
            <S.ImageContainer>
              {item?.thumbnail (
                <Avatar src={item?.thumbnail} style={{ position: "relative", width: "36px", height: "36px"}}></Avatar>
              )}
            </S.ImageContainer>
          ) : (
            <S.ImageContainer>
                <Avatar src={item?.thumbnail} size="70px" />
            </S.ImageContainer>
          )}
          <S.Flex direction="column">
            <S.Flex columnGap={26} alignItems="center">
              {isMobile ? (
                <S.Link href={`/#/team-profile/${item?.id}?show-requests=true`} rel="noreferrer">
                  <S.Text size="22">{item?.name}</S.Text>
                </S.Link>
              ) : (
                <S.Link href={`/#/team-profile/${item?.id}?show-requests=true`} rel="noreferrer">
                  <S.Text size="26">{item?.name}</S.Text>
                </S.Link>
              )}
            </S.Flex>
            <S.Flex columnGap={21}>
              <S.Flex alignItems="center" columnGap={10}>
                <NotificationIconRed />
                <S.Text color="#ffffff">{item?.teamRequestJoin > 0 ? item?.teamRequestJoin : "No" } Pending Requests</S.Text>
              </S.Flex>
            </S.Flex>
          </S.Flex>
        </S.Flex>
      </S.Flex>
      <S.Flex>
        <Button
          fontSize="16px"
          width="auto"
          height="36px"
          background="#6C47FF"
          borderRadius="18.5px"
          onClick={() => {
            window.location.href = `/#/team-profile/${item?.id}?show-requests=true`;
          }}
        >
          Go to Team Details
        </Button>
      </S.Flex>
    </S.RequestRowContainer>
  );
};

export default TeamTotalRequests;
