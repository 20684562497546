import React, { useEffect, useState } from "react";
import Tooltip from "react-simple-tooltip";
import { useHistory } from "react-router-dom";
import * as S from "../Teams/styles";
import Flex from "../../components/v2/common/Flex";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../../components/v2/common/SpinningLoader";
import moment from "moment-timezone";
import { TextNoMargin } from "../../components/v2/common/Text";
import pigSaveAnimation from "../../assets/lotties/pig_save_animation.json";
import Fire from "../../assets/fire.png";
import { padString, truncateWithDots } from "../../helpers/utils";
import Lottie from "react-lottie";
import { TeamsIconForCards, UserIcon, WarningIcon } from "../../assets/svg";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../constants";
import { useWallet } from "../../providers/WalletProvider";
import { useWindowSize } from "../../hooks/useWindowSize";

const ButtonContent = ({
  isLoading,
  prizeClaimed,
  prizeWon,
  claimingPrize,
  enableButton,
  claimEnabled,
  startDate,
  endDate,
  currentTime,
  status,
  claimFunction,
  timeState,
  isPaid,
  isTeam,
  isTeamCaptain,
  txExplorerURL,
  btnRef,
}) => {
  if (isLoading) {
    return (
      <S.FinishButton disabled>
        <SpinnerLoader />
      </S.FinishButton>
    );
  } else if (prizeClaimed) {
    return (
      <S.FinishButton backgroundColor="transparent" disabled>
        {txExplorerURL ? (
          <a href={txExplorerURL} target="_blank" style={{ textDecoration: "underline" }}>
            Claimed!
          </a>
        ) : (
          <span target="_blank" style={{ textDecoration: "underline" }}>
            Claimed!
          </span>
        )}
      </S.FinishButton>
    );
  } else if (claimingPrize) {
    return (
      <S.FinishButton backgroundColor="#6C47FF">
        <Lottie options={{ animationData: pigSaveAnimation }} height={35} width={35} />
      </S.FinishButton>
    );
  } else if (claimEnabled && enableButton) {
    return (
      <S.FinishButton ref={btnRef} onClick={claimFunction} backgroundColor="#6C47FF" style={{ cursor: "pointer" }}>
        Claim
      </S.FinishButton>
    );
  } else if (!claimEnabled && !isPaid && prizeWon && isTeam && !isTeamCaptain) {
    // If isPaid is false and prizeWon is true, that means user has won a prize and has not claimed it yet so claim should be enabled.
    // If it is not enabled (such as the case here), that means they are being vblocked from claiming. This means this was a team pool and they are not captain
    // Also added additional checks to ensure that the selected pool is a team pool and the user is not team captain
    return (
      <Tooltip
        fontSize="11px"
        fontFamily="Gotham"
        color="#fff"
        background="#7265C8"
        placement="top"
        padding={4}
        content={
          <div style={{ width: "160px", borderRadius: "6px", textAlign: "center", fontSize: "13px", padding: 0 }}>
            Waiting for the captain
          </div>
        }
        border="transparent"
      >
        <S.FinishButton backgroundColor="transparent" disabled>
          <span style={{ textDecoration: "underline" }}>Claim</span>
        </S.FinishButton>
      </Tooltip>
    );
  } else {
    // Show Date and Time
    return (
      <S.FinishButton disabled>
        <div>
          {startDate >= currentTime ? (
            <Flex alignItems="center" columnGap="8">
              <Flex justifyContent="center">
                <img src={Fire} alt="fire" style={{width: "10px", height: "12px" }} />
              </Flex>
              {timeState?._data?.days > 0
                ? `In ${timeState?._data?.days} day${timeState?._data?.days > 1 ? "s" : ""}`
                : `${padString(timeState?._data?.hours, 0) || "00"}:${
                    padString(timeState?._data?.minutes, 0) || "00"
                  }:${padString(timeState?._data?.seconds, 0) || "00"}`}
            </Flex>
          ) : currentTime >= endDate ? (
            "Finished"
          ) : (
            "Playing"
          )}
        </div>
      </S.FinishButton>
    );
  }
};

const TournamentRow = ({
  data,
  active,
  currentTime,
  claimFunc,
  session,
  address,
  setOpenWalletModal,
  btnRef,
  setConnectModalPrizeDisplay,
  poolsToDisplay = [],
  setMakeBtnRef,
  makeBtnRef,
}) => {
  const [timeState, setTimeState] = useState({});
  const history = useHistory();
  const { isMobile } = useWindowSize();

  const [prizeWon, setPrizeWon] = useState(() => data.poolPrizeWon > 0);
  const [claimingPrize, setClaimingPrize] = useState(() => data.status === "CLAIMING_PRIZE");
  const [prizeClaimed, setPrizeClaimed] = useState(() => data.isPaid || data.status === "PRIZE_PAID");
  const [enableButton, setEnableButton] = useState(false);

  const { loadWeb3Modal, web3Modal, displaySelectionModal, providerClient } = useWallet();

  const [isLoading, setIsLoading] = useState(false);
  const { claimEnabled } = data;

  useEffect(() => {
    if (currentTime >= data.endDate) return; // returning in case Tournament Finished
    const intervalVar = setInterval(() => {
      let timeLeft;
      timeLeft = moment.duration(data?.endDate - currentTime);
      setTimeState(timeLeft);
    }, 1000);

    return () => clearInterval(intervalVar);
  }, [poolsToDisplay]);

  useEffect(() => {
    setEnableButton(claimEnabled && !prizeClaimed && !claimingPrize);
  }, [claimEnabled, prizeClaimed, claimingPrize]);

  useEffect(() => {
    setPrizeClaimed(data.isPaid || data.status === "PRIZE_PAID");
  }, [data?.isPaid, data?.status]);

  useEffect(() => {
    setPrizeWon(data?.poolPrizeWon > 0)
  }, [data?.poolPrizeWon])

  return (
    <S.TournamentRow>
      <Flex columnGap="23" alignItems="center">
        <img src={data?.gameProfileIcon} alt="game icon" style={{ width: "49px", height: "49px" }} />
        <S.PoolLink onClick={() => history.push(`/pool/${data?.poolId}`)}>
          {truncateWithDots(data?.poolName || "Placeholder Pool Name", isMobile ? 10 : 25)}
        </S.PoolLink>
      </Flex>

      <div>
        {data?.isTeam ? (
          <TeamsIconForCards style={{ width: "20px", height: "20px" }} />
        ) : (
          <UserIcon style={{ width: "20px", height: "20px" }} />
        )}
      </div>
      <div>{data?.startDate ? moment(data?.startDate).format("MMM Do") : "-"}</div>
      <div>{data?.positionInPool || "-"}</div>
      <div>
        {data?.poolPrize ? (
          <div>
            {`${data?.poolPrize}`}
            {data?.poolPrize && data?.tokenImage ? <S.TokenImg src={data?.tokenImage} alt="tokenImage" /> : ""}
          </div>
        ) : (
          "0"
        )}
      </div>
      <div>
        {prizeWon ? (
          <div>
            {`${data?.poolPrizeWon}`}
            {data?.poolPrizeWon && data?.tokenImage ? <S.TokenImg src={data?.tokenImage} alt="tokenImage" /> : ""}
          </div>
        ) : (
          "0"
        )}
      </div>

      <S.PoolButtonContainer
        prizeWon={prizeWon}
        status={data?.status}
        startDate={data.startDate}
        endDate={data.endDate}
        currentTime={currentTime}
      >
        <ButtonContent
          isLoading={isLoading}
          prizeClaimed={prizeClaimed}
          prizeWon={prizeWon}
          claimingPrize={claimingPrize}
          enableButton={enableButton}
          claimEnabled={claimEnabled}
          startDate={data.startDate}
          endDate={data.endDate}
          isPaid={data.isPaid}
          isTeam={data.isTeam}
          isTeamCaptain={data.isTeamCaptain}
          txExplorerURL={data.txExplorerURL}
          currentTime={currentTime}
          status={data.status}
          timeState={timeState}
          btnRef={makeBtnRef === data.id ? btnRef : null}
          claimFunction={async () => {
            setMakeBtnRef(null);
            if (!enableButton) return;
            if (!session.user) {
              setMakeBtnRef(null);
              toast(
                <Flex alignItems="center">
                  <WarningIcon style={{ height: "40px", width: "40px" }} /> Please sign in to claim rewards
                </Flex>,
                TOAST_OPTIONS,
              );

              return;
            }

            if (!session?.user?.address) {
              setConnectModalPrizeDisplay({ poolPrizeWon: data.poolPrizeWon, tokenImage: data.tokenImage });
              setOpenWalletModal(true);
              setMakeBtnRef(data.id);
              return;
            }

            if (
              (!web3Modal || !web3Modal.cachedProvider) &&
              (!providerClient || !Array.isArray(providerClient?.accounts) || providerClient.accounts?.length < 1)
            ) {
              loadWeb3Modal(true);
              setMakeBtnRef(null);

              return;
            }

            if (session?.user?.address?.toLowerCase() !== address?.toLowerCase()) {
              toast(
                <Flex alignItems="center">
                  <WarningIcon style={{ height: "40px", width: "40px" }} /> Please use the wallet connected to your
                  account
                </Flex>,
                TOAST_OPTIONS,
              );
              setMakeBtnRef(null);
              return;
            }

            setIsLoading(true);
            setMakeBtnRef(null);
            try {
              let resp = await claimFunc(data, () => {
                setClaimingPrize(false);
                setPrizeClaimed(true);
              });
              if (!resp?.data?.error && !prizeClaimed) {
                setClaimingPrize(true);
              }
            } catch (err) {
              console.log(err);
              setClaimingPrize(false);
              setPrizeClaimed(false);
            } finally {
              setIsLoading(false);
            }
          }}
        />
      </S.PoolButtonContainer>
    </S.TournamentRow>
  );
};

export default TournamentRow;
