/* eslint-disable no-new */
import { useState } from "react";
import { useRecoilState } from "recoil";
import * as crypto from "crypto-js";
import Compressor from "compressorjs";
import { callAxios } from "./Axios";
import { useGetSecHeaders } from "./SecurityHeaders";
import { ENDPOINT } from "../constants/endpoints";
import { sessionState } from "../state/globalState";
import { SEED } from "../constants/env";
import useCallAxios from "./useAxiosCall";
import { setUserInLocalStorage } from "../helpers/localStorage";

/*
  ~ What it does? ~

  Gets user information from the server and it's used in the user profile page.
  also updates the user information.

  ~ How can I use? ~

  const { checkUserAccount } = useUserInformation();

*/

const updateNickname = async (headers, nickname, resultOk, setIsFetching, callAxiosFunc) => {
  setIsFetching(true);
  const response = await callAxiosFunc({
    method: "PUT",
    url: `${ENDPOINT.USER}/nick/${encodeURIComponent(nickname)}`,
    headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
  });
  setIsFetching(false);
  if ((response && response.err) || (response && response.data.error)) {
    resultOk(false);
    return { success: false, err: response.err?.response?.data };
  }
  // update nick on user session

  resultOk(true);
  return { success: true };
};

const updateEmail = async (headers, email, resultOk, setIsFetching, callAxiosFunc) => {
  setIsFetching(true);
  const response = await callAxiosFunc({
    method: "PUT",
    url: `${ENDPOINT.USER}/email/${encodeURIComponent(email)}`,
    headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
  });
  setIsFetching(false);
  if ((response && response.err) || (response && response.data.error)) {
    resultOk(false);
    return false;
  }

  resultOk(true);
  return true;
};

const updateDiscord = async (headers, discordUsername, resultOk, setIsFetching, callAxiosFunc) => {
  setIsFetching(true);
  const response = await callAxiosFunc({
    method: "PUT",
    url: `${ENDPOINT.USER}/discord/${encodeURIComponent(discordUsername)}`,
    headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
  });
  setIsFetching(false);
  if ((response && response.err) || (response && response.data.error)) {
    resultOk(false);
    return false;
  }

  resultOk(true);
  return true;
};

const updateAvatar = async (headers, data, resultOk, setIsFetching, callAxiosFunc) => {
  setIsFetching(true);

  const response = await callAxiosFunc({
    method: "POST",
    url: `${ENDPOINT.USER}/thumbnail`,
    headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
    body: data,
    extraHeader: {
      "Content-Type": "application/multipart/form-data;",
    },
  });
  setIsFetching(false);
  if ((response && response.err) || (response && response.data.error)) {
    resultOk(false);
  }
  resultOk(true);
  return response;
};

const deleteAvatar = async (headers, resultOk, setIsFetching, callAxiosFunc) => {
  setIsFetching(true);

  const response = await callAxiosFunc({
    method: "DELETE",
    url: `${ENDPOINT.USER}/thumbnail`,
    headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
    body: null,
  });

  setIsFetching(false);
  if ((response && response.err) || (response && response.data.error)) {
    resultOk(false);
  }
  resultOk(true);
  return response;
};

const updateAccount = async (headers, platform, platformUserId, setResult, setIsFetching, callAxiosFunc) => {
  setIsFetching(true);
  const response = await callAxiosFunc({
    method: "PUT",
    url: `${ENDPOINT.USER}/account/${encodeURIComponent(platform)}/${encodeURIComponent(platformUserId)}`,
    headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
  });
  setIsFetching(false);
  if (response) {
    if (response.err) {
      const result = { error: true, msg: "Error updating user account, try it later." };
      setResult(result);
      return result;
    }
    if (response.data.error) {
      // const errorMsg = response.data.msg ?? "Error updating user account, contact us.";
      const result = { error: true, msg: response.data.msg };
      setResult(result);
      return result;
    }
  }
  const result = { error: false, msg: "Account updated successfuly.", account: response.data.account };
  setResult(result);
  return result;
};

const updateFirstLogin = async (headers, value, resultOk, setIsFetching) => {
  setIsFetching(true);
  const response = await callAxios({
    method: "PUT",
    url: `${ENDPOINT.USER}/firstLogin/${value}`,
    headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
  });
  setIsFetching(false);
  if ((response && response.err) || (response && response.data.error)) {
    resultOk(false);
    return false;
  }
  resultOk(true);
  return true;
};

const checkUserHasAccount = async (headers, platform, resultOk, setIsFetching) => {
  setIsFetching(true);
  const response = await callAxios({
    method: "GET",
    url: `${ENDPOINT.USER}/hasAccount/${platform}`,
    headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
  });
  setIsFetching(false);
  if ((response && response.err) || (response && response.data.error)) {
    resultOk(false);
    return false;
  }
  resultOk(response.data);
  return response.data;
};

export const useUserInformation = () => {
  const [operationResult, setOperationResult] = useState("");
  const [isFetching, setIsFetching] = useState("");
  const [session, setSession] = useRecoilState(sessionState);
  const headers = useGetSecHeaders();
  const { callAxiosFunc } = useCallAxios();

  const setAvatar = async (avatar, setIsLoading) => {
    if (setIsLoading) {
      setIsLoading(true);
    }
    const data = new FormData();
    if (!avatar) return;
    new Compressor(avatar, {
      quality: 0.4,
      success: async compressedResult => {
        data.append("avatar", compressedResult);
        const setUserResponse = await updateAvatar(headers, data, setOperationResult, setIsFetching, callAxiosFunc);

        if (setUserResponse && setUserResponse.data && setUserResponse.data.thumbnail) {
          const updatedSession = {
            ...session,
            user: {
              ...session.user,
              thumbnail: `${setUserResponse.data.thumbnail}?random={${new Date().getSeconds()}}`,
            },
          };

          setTimeout(() => {
            setSession(updatedSession);
            const encryptedState = crypto.AES.encrypt(JSON.stringify(updatedSession), SEED).toString();
            setUserInLocalStorage(encryptedState)
            if (setIsLoading) {
              setIsLoading(false);
            }
          }, [1000]);
        }
      },
    });
  };

  const removeAvatar = async () => {
    const setUserResponse = await deleteAvatar(headers, setOperationResult, setIsFetching, callAxiosFunc);
    if (setUserResponse && !setUserResponse.err) {
      const updatedSession = {
        ...session,
        user: {
          ...session.user,
          thumbnail: "",
        },
      };
      setSession(updatedSession);
      const encryptedState = crypto.AES.encrypt(JSON.stringify(updatedSession), SEED).toString();
      setUserInLocalStorage(encryptedState)

    }
  };

  // set user nickname
  const setUserNickname = async (nickname, setIsLoading = () => {}) => {
    if (setIsLoading) {
      setIsLoading(true);
    }
    const setUserResponse = await updateNickname(headers, nickname, setOperationResult, setIsFetching, callAxiosFunc);
    if (setUserResponse.success) {
      const updatedUser = { ...session.user, nick: nickname, firstLogin: false, nickLowerCase: nickname.toLowerCase() };
      const updatedSession = { ...session, user: updatedUser };
      setSession(updatedSession);
      const encryptedState = crypto.AES.encrypt(JSON.stringify(updatedSession), SEED).toString();
      setUserInLocalStorage(encryptedState);
      if (setIsLoading) {
        setIsLoading(false);
      }
      return { success: true };
    } else {
      if (setIsLoading) {
        setIsLoading(false);
      }
      return { success: false, err: setUserResponse.err };
    }
  };
  const setUserEmail = async email => {
    const setUserResponse = await updateEmail(headers, email, setOperationResult, setIsFetching, callAxiosFunc);
    if (setUserResponse) {
      const updatedUser = { ...session.user, email };
      const updatedSession = { ...session, user: updatedUser };
      setSession(updatedSession);
      const encryptedState = crypto.AES.encrypt(JSON.stringify(updatedSession), SEED).toString();
      setUserInLocalStorage(encryptedState)
    }
  };

  const setUserDiscordUsername = async discordUsername => {
    const setUserResponse = await updateDiscord(headers, discordUsername, setOperationResult, setIsFetching, callAxiosFunc);
    if (setUserResponse) {
      const updatedUser = { ...session.user, discord: discordUsername };
      const updatedSession = { ...session, user: updatedUser };
      setSession(updatedSession);
      const encryptedState = crypto.AES.encrypt(JSON.stringify(updatedSession), SEED).toString();
      setUserInLocalStorage(encryptedState)
    }
  };

  const setEmailAndDiscord = async ({ emailArg, discordArg }) => {
    const setUpdateEmailResponse = await updateEmail(headers, emailArg, setOperationResult, setIsFetching, callAxiosFunc);
    const setUpdateDiscordResponse = await updateDiscord(headers, discordArg, setOperationResult, setIsFetching, callAxiosFunc);
    let updatedUser = { ...session.user };
    if (emailArg && setUpdateEmailResponse) {
      updatedUser = { ...updatedUser, email: emailArg };
    }
    if (discordArg && setUpdateDiscordResponse) {
      updatedUser = { ...updatedUser, discord: discordArg };
    }

    const updatedSession = { ...session, user: updatedUser };
    setSession(updatedSession);

    const encryptedState = crypto.AES.encrypt(JSON.stringify(updatedSession), SEED).toString();
    setUserInLocalStorage(encryptedState)

  };

  // set user new account
  const setUserAccount = async (platform, platformUserId) => {
    const result = await updateAccount(headers, platform, platformUserId, setOperationResult, setIsFetching, callAxiosFunc);
    const updatedSession = {
      ...session,
      user: {
        ...session.user,
        accounts: [...session.user.accounts, result.account],
      },
    };
    setSession(updatedSession);
    const encryptedState = crypto.AES.encrypt(JSON.stringify(updatedSession), SEED).toString();
    setUserInLocalStorage(encryptedState)

    return result;
  };

  // Set user firstlogin flag to false.
  const setUserFirstLogin = async (value, callback) => {
    await updateFirstLogin(headers, value, setOperationResult, setIsFetching);
    const updatedUser = { ...session.user, firstLogin: value };
    const updatedSession = { ...session, user: updatedUser };
    setSession(updatedSession);
    const encryptedState = crypto.AES.encrypt(JSON.stringify(updatedSession), SEED).toString();
    setUserInLocalStorage(encryptedState)
    callback(true);
  };

  // Update Nick / Account & Change FirstLogin Flag Value.
  const setUserNickAndAccount = async (nickname, platform, platformUserId) => {
    setIsFetching(true);
    const nickUpdateResult = await updateNickname(
      headers,
      nickname,
      () => {},
      () => {},
      callAxiosFunc
    );
    const accountUpdateResult = await updateAccount(
      headers,
      platform,
      platformUserId,
      () => {},
      () => {},
      callAxiosFunc
    );
    await updateFirstLogin(
      headers,
      false,
      () => {},
      () => {},
    );
    setIsFetching(false);
    setOperationResult({ error: nickUpdateResult.success && accountUpdateResult.error, msg: accountUpdateResult.msg });
  };

  // check if user has an account (by platform) -> use callback function.
  // response: boolean
  const checkUserAccount = (platform, callback) => {
    setIsFetching(true);
    if (!headers) {
      setIsFetching(false);
      callback(false);
    }
    checkUserHasAccount(headers, platform, setOperationResult, setIsFetching).then(response => {
      setIsFetching(false);
      callback(response);
    });
  };

  return {
    setUserNickname,
    setUserEmail,
    setUserAccount,
    setUserFirstLogin,
    setUserNickAndAccount,
    setUserDiscordUsername,
    setEmailAndDiscord,
    setAvatar,
    removeAvatar,
    checkUserAccount,
    operationResult,
    isFetching,
  };
};
