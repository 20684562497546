import React, { useState } from "react";
import { useGetSecHeaders } from "../../../../hooks";
import useCallAxios from "../../../../hooks/useAxiosCall";
import { Button } from "../../common/Button";
import { Text } from "../../common/Text";
import Modal from "../../Modal";
import { useHistory } from "react-router-dom";
import { Flex } from "../PlayerInviteModal/styles";
import { ModalContainer } from "./styles";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../../common/SpinningLoader";
import { toast } from "react-toastify";
import { RedDeleteIcon, WarningIcon } from "../../../../assets/svg";
import { TOAST_OPTIONS } from "../../../../constants";

const DeleteModal = ({ showDeleteModal, toggleDeleteModal, teamId }) => {
  const headers = useGetSecHeaders();
  const { callAxiosFunc } = useCallAxios();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  async function deleteTeam() {
    setIsLoading(true);
    const resp = await callAxiosFunc({
      method: "DELETE",
      url: `team/${teamId}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
    });

    setIsLoading(false);

    if (resp.error || resp.err) {
      toast(
        <Flex alignItems="center">
          <WarningIcon style={{ height: "40px", width: "40px" }} />
          {resp?.err?.response?.data?.message
            ? resp?.err?.response?.data?.message?.[0]
            : "Something went wrong. Please try again!"}
        </Flex>,
        TOAST_OPTIONS,
      );
    } else {
      history.push("/teams");
    }
  }

  return (
    <Modal showModal={showDeleteModal} toggleModal={toggleDeleteModal} style={{ height: "300px" }}>
      <Flex justifyContent="center" style={{ width: "100%", marginBottom: "20px", marginTop: "-20px" }}>
        <RedDeleteIcon />
      </Flex>
      <ModalContainer>
        <Flex alingItems="center" justifyContent="center" style={{ height: "37px" }}>
          <Text fontSize="26px" fontWeight="325" lineHeight="36px">
            Delete team
          </Text>
        </Flex>
        <Flex
          alingItems="center"
          justifyContent="center"
          style={{ height: "37px", marginTop: "24px", marginBottom: "44px" }}
        >
          <Text color="#A498ED" fontWeight="325" fontSize="16px" style={{ textAlign: "center", width: "349px" }}>
            You are about to delete your team. There is no way to recuperate once it is deleted
          </Text>
        </Flex>
        {isLoading ? (
          <SpinnerLoaderWrapper>
            <SpinnerLoader />
          </SpinnerLoaderWrapper>
        ) : (
          <Flex alingItems="center" justifyContent="center" columnGap="18">
            <Button
              background="transparent"
              width="122px"
              height="42px"
              borderRadius="20px"
              onClick={toggleDeleteModal}
            >
              <Text
                color="#A498ED"
                fontSize="13px"
                fontWeight="350"
                lineHeight="17px"
                cursor="pointer"
                style={{ margin: 0 }}
              >
                Cancel
              </Text>
            </Button>
            <Button
              width="122px"
              height="42px"
              borderRadius="20px"
              cursor="pointer"
              background="#ED6140"
              padding="12px 20px"
              onClick={deleteTeam}
            >
              <Text fontSize="14px" fontWeight="350" lineHeight="17px" style={{ margin: 0 }} cursor="pointer">
                Yes, delete
              </Text>
            </Button>
          </Flex>
        )}
      </ModalContainer>
    </Modal>
  );
};

export default DeleteModal;
