import React, { useRef, useState } from "react";
import { Select } from "antd";

import * as S from "./styles";
import { ToastContainer, toast } from "react-toastify";

import Avatar from "../../components/v2/common/Avatar";
import { Button } from "../../components/v2/common/Button";
import { Text, TextNoMargin } from "../../components/v2/common/Text";
import Flex, { FlexStyled } from "../../components/v2/common/Flex";
import {
  Camera,
  Delete,
  DiscordIcon,
  DottedMenu,
  EditIcon,
  FacebookIcon,
  FacebookNew,
  GlobeIcon,
  GoogleIcon,
  MetamaskConnect,
  MetamaskNew,
  NotificationIconRed,
  TeamIcon,
  Tick,
  TransparentArrow,
  TwitterNew,
  Wallet,
  WarningIcon,
} from "../../assets/svg";
import useImageUpload from "../../hooks/UploadImage";
import { SpinnerLoader } from "../../components/v2/common/SpinningLoader";
import { TOAST_OPTIONS } from "../../constants";
import useCallAxios from "../../hooks/useAxiosCall";
import { ENDPOINT } from "../../constants/endpoints";

import defaultBanner from "../../assets/team-profile-default.png";
import secondBanner from "../../assets/team-profile-default.png";
import thirdBanner from "../../assets/team-profile-default.png";
import fourthBanner from "../../assets/team-profile-default.png";
import fifthBanner from "../../assets/team-profile-default.png";
import { FacebookFilled, WalletFilled } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import { sessionState } from "../../state/globalState";
import { shortenAddress } from "../../lib/web3-utils";

const { Option } = Select;

const UserNameInput = ({ editUserNickName, setEditUserNickName, userNickName, setUserNickName }) => {
  const [userNameTemp, setUserNameTemp] = useState(userNickName);
  const [isLoading, setIsLoading] = useState(false);

  return editUserNickName ? (
    <S.InputBoxContainer>
      <input
        value={userNameTemp}
        onChange={e => setUserNameTemp(e.target.value)}
        style={{ width: "100%", background: "transparent", border: "0px", outline: "none", fontSize: "28px" }}
        placeholder="Edit Name"
        bordered={false}
      />
      <S.TransparentArrowContainer>
        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <TransparentArrow
            onClick={async () => {
              const res = await setUserNickName(userNameTemp, setIsLoading);
              if (res.success) {
                setEditUserNickName(false);
              } else {
                toast(
                  <Flex alignItems="center">
                    <WarningIcon style={{ height: "40px", width: "40px" }} />
                    {res.err?.message ? res.err?.message : "Something went wrong. Please try again!"}
                  </Flex>,
                  TOAST_OPTIONS,
                );
              }
            }}
          />
        )}
      </S.TransparentArrowContainer>
    </S.InputBoxContainer>
  ) : (
    <TextNoMargin color="#fff" fontSize="28px" fontWeight="325" lineHeight="36px">
      {userNameTemp}
    </TextNoMargin>
  );
};

const bannersList = [defaultBanner, secondBanner, thirdBanner, fourthBanner, fifthBanner];

const ProviderImage = ({ user }) => {
  if (!user) return null;

  const provider = user.extProvider;
  if (!provider) return null;

  if (provider === "facebook.com")
    return (
      <S.TransparentButton>
        <FacebookNew style={{ height: "15px" }} /> Facebook
      </S.TransparentButton>
    );
  else if (provider === "twitter.com")
    return (
      <S.TransparentButton>
        <TwitterNew style={{ height: "13px", marginRight: "4px" }} /> Twitter
      </S.TransparentButton>
    );
  else if (provider === "google.com")
    return (
      <S.TransparentButton>
        <GoogleIcon style={{ height: "16px", marginRight: "6px" }} /> Google
      </S.TransparentButton>
    );
  else if (provider === "discord")
    return (
      <S.TransparentButton>
        <DiscordIcon style={{ height: "19px", marginRight: "4px" }} /> Discord
      </S.TransparentButton>
    );
  else return null;
};

const BannerMenu = ({ setEditUserNickName }) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div onClick={() => setShowMenu(prev => !prev)} style={{ position: "relative", cursor: "pointer" }}>
      <DottedMenu />

      {showMenu && (
        <S.Popover>
          <S.PopoverButtons
            onClick={() => {
              setEditUserNickName(true);
            }}
          >
            <EditIcon style={{ height: "15px", width: "15px" }} />
            <TextNoMargin fontSize="15px" fontWeight="325" cursor="pointer">
              Edit
            </TextNoMargin>
          </S.PopoverButtons>
          {/* <S.PopoverButtons onClick={toggleDeleteModal}>
            <Delete />
            <TextNoMargin fontSize="15px" fontWeight="325" cursor="pointer">
              Delete Team
            </TextNoMargin>
          </S.PopoverButtons> */}
        </S.Popover>
      )}
    </div>
  );
};

const AccountBanner = props => {
  const session = useRecoilValue(sessionState);
  const profilePicInputRef = useRef(null);
  const bannerPicInputRef = useRef(null);
  const [showBannerList, setShowBannerList] = useState(false);
  const [editUserNickName, setEditUserNickName] = useState(false);

  const [bannerUploading, setBannerUploading] = useState(false);
  const [thumbnailUploading, setThumbnailUploading] = useState(false);

  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);

  const updateAvatar = () => {};

  const uploadImge = useImageUpload();
  const { callAxiosFunc } = useCallAxios();

  const handleProfilePicClick = () => {
    if (profilePicInputRef.current) {
      profilePicInputRef.current.click();
    }
  };

  const handleBannerSelectionClick = () => {
    if (bannerPicInputRef.current) {
      bannerPicInputRef.current.click();
    }
  };

  // The media argument specifies whether the incoming file is for the team thumbnail or banner
  async function handleFileUpload(e, media = "thumbnail") {
    return;
    //   const type = "team"; // type will be the same as all actions are being made for a team here
    //   try {
    //     if (media === "thumbnail") {
    //       setThumbnailUploading(true);
    //     } else if (media === "banner") {
    //       setBannerUploading(true);
    //     }
    //     const res = await uploadImge(e.target.files, type, props?.name, media);
    //     if (res && res.data && res.data.url) {
    //       const updateTeamResp = await props.updateTeamData({ [media]: res.data.url });
    //       setThumbnailUploading(false);
    //       setBannerUploading(false);
    //       if (updateTeamResp.err) {
    //         toast(
    //           <Flex alignItems="center">
    //             <WarningIcon style={{ height: "40px", width: "40px" }} /> Something went wrong. Please try again!
    //           </Flex>,
    //           TOAST_OPTIONS,
    //         );
    //         console.log("Error!!");
    //         return;
    //       }
    //       if (media === "thumbnail") {
    //         const newThumbnail = updateTeamResp?.data?.thumbnail || "";
    //         setTeamThumbnail(`${newThumbnail}?random=${new Date().getSeconds()}`);
    //       } else if (media === "banner") {
    //         const newBanner = updateTeamResp?.data?.banner || "";
    //         setTeamBanner(`${newBanner}?random=${new Date().getSeconds()}`);
    //         setShowBannerList(false);
    //       }
    //       toast(
    //         <Flex alignItems="center">
    //           <Tick style={{ height: "15px", width: "15px", marginRight: "14px" }} />
    //           {media == "thumbnail" ? "Avatar" : "Banner"} Updated Successfully
    //         </Flex>,
    //         TOAST_OPTIONS,
    //       );
    //     } else if (res.err) {
    //       console.log("Error while uploading image: ", res.err);
    //     }
    //   } catch (err) {
    //     console.log("Error while uploading image: ", err);
    //   }
  }

  //   function showInviteModalFunc() {
  //     if (
  //       props.sortedPlayers?.filter(player => player.status === "accepted" || player.status === "pending-confirm")
  //         ?.length >= props.usersLimit
  //     ) {
  //       toast(
  //         <div>
  //           <div>You cannot invite more players</div>
  //           <div>
  //             You have
  //             {
  //               props?.sortedPlayers?.filter(
  //                 player => player.status === "accepted" || player.status === "pending-confirm",
  //               )?.length
  //             }{" "}
  //             active and {props.sortedPlayers?.filter(player => player.status === "pending-confirm")?.length} pending
  //             players
  //           </div>
  //         </div>,
  //         TOAST_OPTIONS,
  //       );
  //     } else if (props.activePools?.length > 0) {
  //       toast(<div>You cannot change a team while it is part of an active tournament</div>, TOAST_OPTIONS);
  //     } else {
  //       setShowInviteModal(true);
  //     }
  //   }

  // TODO: Replace isOwner with a correct value
  // const isOwner = true;

  return (
    <>
      <ToastContainer />
      <S.Banner>
        <FlexStyled
          extraStyles={{
            width: "100%",
            "padding-left": "38px",
            "padding-right": "38px",
            "padding-top": "16px",
            "justify-content": "flex-end !important",
          }}
        >
          <Flex columnGap="15" alignItems="center">
            <ProviderImage user={session?.user} />
            {session?.user?.address ? (
              <S.TransparentButton>
                <Wallet style={{ height: "19px", width: "24px", marginRight: "4px" }} />{" "}
                {shortenAddress(session?.user?.address, 3)}
              </S.TransparentButton>
            ) : null}
            <BannerMenu setEditUserNickName={setEditUserNickName} style={{ height: "37px" }} />
          </Flex>
        </FlexStyled>

        <S.BottomRow>
          <Flex columnGap="24" alignItems="flex-end">
            <S.AvatarContainer thumbnailUploading={thumbnailUploading}>
              <>
                {isUploadingAvatar ? (
                  <span style={{ position: "absolute", left: "45%", top: "45%" }}>
                    <SpinnerLoader />
                  </span>
                ) : (
                  <div onClick={handleProfilePicClick}>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      ref={profilePicInputRef}
                      onChange={e => props?.setAvatar(e?.target?.files[0], setIsUploadingAvatar)}
                    />
                    <Camera />
                  </div>
                )}
              </>

              <span style={thumbnailUploading ? { opacity: "0.3" } : {}}>
                <Avatar src={props?.user?.thumbnail} size="101px" customStyles={{ objectFit: "cover" }} />
              </span>
            </S.AvatarContainer>
            <Flex direction="column" extraStyles={{ "margin-bottom": "24px" }}>
              <UserNameInput
                editUserNickName={editUserNickName}
                setEditUserNickName={setEditUserNickName}
                userNickName={props?.user?.nick}
                setUserNickName={props?.setUserNickname}
              />
            </Flex>
          </Flex>
        </S.BottomRow>
      </S.Banner>
    </>
  );
};

export default AccountBanner;
