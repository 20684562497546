/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import moment from "moment-timezone";
import FlipNumbers from "react-flip-numbers";
import * as S from "./styles";
import Loader from "../Loader";
import { useWindowSize } from "../../../hooks/useWindowSize";

const TimeBlock = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  margin-right: 30px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  section {
    span {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 4px;
      margin: 0 1.5px;
      display: flex;
      justify-content: start;
      span {
        margin-left: 0px;
      }
    }
  }
  small {
    color: #ffffff80;
    margin-top: 2px;
  }

  @media (max-width: 450px) {
    margin-right: 0px;
  }
`;

const TimeDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 450px) {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }
`;

const Container = styled.div`
  background: linear-gradient(270deg, #df4bbd 0%, #302862 100%);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 130px;
  margin-bottom: 20px;
  @media (max-width: 450px) {
    display: block;
    background: #3a2f81;
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 12px;
  }
`;

const StartingText = styled.div`
  color: #ffffff;
  margin-left: 28px;
  font-size: 24px;
  font-weight: 400;
  @media (max-width: 450px) {
    margin-left: 0px;
    font-size: 18px;
  }
`;

export default function StartingTime({ endDate, status, startDate, isMobile }) {
  //   const [timeLeft, setTimeLeft] = useState({});
  const [days, setDays] = useState(null);
  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [seconds, setSeconds] = useState(null);

  useEffect(() => {
    const intervalVar = setInterval(() => {
      const currentTime = Date.now();
      // const startTime = startDate;
      let timeLeft;
      if (status === "NOT_STARTED" || status === "PROCESSING_REGISTERED_USERS") {
        timeLeft = moment.duration(startDate - currentTime);
      } else if (status === "RUNNING") {
        timeLeft = moment.duration(endDate - currentTime);
      }
      setDays((timeLeft._data.days >= 0 && timeLeft._data.days) || "0");
      setHours((timeLeft._data.hours >= 0 && timeLeft._data.hours) || "0");
      setMinutes((timeLeft._data.minutes >= 0 && timeLeft._data.minutes) || "0");
      setSeconds((timeLeft._data.seconds >= 0 && timeLeft._data.seconds) || "0");
    }, 1000);

    return () => clearInterval(intervalVar);
  }, [status, endDate, startDate]);

  return (
    <Container>
      {seconds !== null ? (
        <>
          <StartingText>
            {status === "NOT_STARTED" || status === "PROCESSING_REGISTERED_USERS" ? "Starting In" : "Finish In"}
          </StartingText>
          <TimeDetailsContainer>
            <TimeBlock>
              <FlipNumbers
                play
                color="#fff"
                width={isMobile ? 24 : 40}
                height={isMobile ? 36 : 60}
                numbers={days > 0 ? days.toString().padStart(2, "0") : "00"}
                numberStyle={{
                  fontSize: isMobile ? "24px" : "30px",
                  lineHeight: isMobile ? "30px" : "40px",
                  fontWeight: 300,
                }}
              />
              <small>Days</small>
            </TimeBlock>
            <TimeBlock>
              <FlipNumbers
                play
                color="#fff"
                width={isMobile ? 24 : 40}
                height={isMobile ? 36 : 60}
                numbers={hours > 0 ? hours.toString().padStart(2, "0") : "00"}
                numberStyle={{
                  fontSize: isMobile ? "24px" : "30px",
                  lineHeight: isMobile ? "30px" : "40px",
                  fontWeight: 300,
                }}
              />
              <small>Hours</small>
            </TimeBlock>
            <TimeBlock>
              <FlipNumbers
                play
                color="#fff"
                width={isMobile ? 24 : 40}
                height={isMobile ? 36 : 60}
                numbers={minutes > 0 ? minutes.toString().padStart(2, "0") : "00"}
                numberStyle={{
                  fontSize: isMobile ? "24px" : "30px",
                  lineHeight: isMobile ? "30px" : "40px",
                  fontWeight: 300,
                }}
              />
              <small>Min</small>
            </TimeBlock>
            <TimeBlock>
              <FlipNumbers
                play
                color="#fff"
                width={isMobile ? 24 : 40}
                height={isMobile ? 36 : 60}
                numbers={seconds > 0 ? seconds.toString().padStart(2, "0") : "00"}
                numberStyle={{
                  fontSize: isMobile ? "24px" : "30px",
                  lineHeight: isMobile ? "30px" : "40px",
                  fontWeight: 300,
                }}
              />
              <small>Sec</small>
            </TimeBlock>
          </TimeDetailsContainer>
        </>
      ) : (
        <S.loaderWrapper>
          <Loader animation="rotate" width={100} />
        </S.loaderWrapper>
      )}
    </Container>
  );
}
