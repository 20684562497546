import { useWallet } from "../providers/WalletProvider";
import { useGetSecHeaders } from "./SecurityHeaders";
import callAxios from "./Axios";
import { ENDPOINT } from "../constants/endpoints";
import { useUserAddress } from "eth-hooks";
import { useRecoilValue } from "recoil";
import { sessionState } from "../state/globalState";
import { removeUserInLocalStorage } from "../helpers/localStorage";

export default function useDisconnectUser() {
  const headers = useGetSecHeaders();
  const { injectedProvider, logoutOfWeb3Modal } = useWallet();
  const address = useUserAddress(injectedProvider);
  const session = useRecoilValue(sessionState);

  const removeDbSession = async () => {
    try {
      const response = await callAxios({
        method: "delete",
        url: `${ENDPOINT.USER}/${ENDPOINT.CLOSE_SESSION}`,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      });
      if (response && response.err) {
        return;
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };
  const disconnect = async () => {
    // setIsFetching(true);
    try {
      // remove server and local user session
      await removeDbSession(address);
      removeUserInLocalStorage()
      //   setIsFetching(false);
      return;
    } catch (e) {
    //   setIsFetching(false);
      return;
    }
  };

  const disconnecUser = async () => {
    await disconnect();
    logoutOfWeb3Modal();
  };
  return {
    disconnecUser,
  };
}
