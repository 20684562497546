import styled from "styled-components";
import { breakpoints } from "../../../../themes";

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -22px;
  margin-bottom: 40px;
  overflow-wrap: break-word;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 300px;
    margin-top: -45px;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  line-height: 36px;
  color: white;
`;

export const Description = styled.p`
  width: 100%;
  text-align: center;
  color: #877add;
  font-size: 14px;
  line-height: 20px;
`;
export const InputSection = styled.form`
  margin-top: 40px;
  margin-bottom: 45px;
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const errorMessageContainer = styled.div`
  text-align: center;
`;
export const errorMessage = styled.p`
  height: 40px;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: ${props => (props.color ? props.color : "#D06565")};
`;

export const IconContainer = styled.div``;

export const loader = styled.span`
  width: 17px;
  height: 17px;
  border: 3px solid #877add;
  border-bottom-color: #3a2f81;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Message = styled.p`
  color: ${props => (props.color ? props.color : "#FFFFFF")};
  font-size: 13px;
  font-weight: 325;
  letter-spacing: 0px;
  text-align: center;
`;
