import React from "react";
import * as S from "./styles";
import Flex from "../../components/v2/common/Flex";
import { Button } from "../../components/v2/common/Button";
import { TextNoMargin } from "../../components/v2/common/Text";

const PrizeRow = ({ prize }) => {
  return (
    <S.PrizeRow>
      <Flex alignItems="center" columnGap="14" rowGap="14">
        <Flex
          alignItems="center"
          justifyContent="center"
          extraStyles={{
            height: "60px",
            width: "60px",
            background: "#2f218c",
            "border-radius": "6.5px",
          }}
        >
          <div>{prize.icon}</div>
        </Flex>
        <div> {prize.text}</div>
      </Flex>

      <Flex>
        <Flex columnGap="49" alignItems="center">
          <Flex columnGap="18" alignItems="center">
            <div style={{ width: "106px", height: "13px", background: "#201366", borderRadius: "6.5px" }}>
              <div
                style={{ height: "13px", background: "#6C47FF", borderRadius: "6.5px", width: `${prize.percent}%` }}
              ></div>
            </div>
            <Flex alignItems="center">
              <TextNoMargin fontWeight="325" fontSize="14px">
                {prize.percent}
              </TextNoMargin>
              %
            </Flex>
          </Flex>
          <Button width="106px" height="36px" background="#6C47FF" borderRadius="56px">
            Claim
          </Button>
        </Flex>
      </Flex>
    </S.PrizeRow>
  );
};

export default PrizeRow;
