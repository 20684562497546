import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Loader";
import { BE_API_BASE_URL } from "../../../../../constants/env";
import { TickWithBorder, OkTick, CloseIcon } from "../../../../../assets/svg";
import * as S from "./styles";
import { useGetSecHeaders } from "../../../../../hooks/SecurityHeaders";
import useCallAxios from "../../../../../hooks/useAxiosCall";

export default function ReportModal({ showReportModal, toggleReportModal, userId }) {
  const headers = useGetSecHeaders();
  const {callAxiosFunc} = useCallAxios();

  const reportReasons = [
    { title: "Bot", value: "BOT" },
    { title: "Cheat", value: "CHEAT" },
    { title: "Smurf", value: "SMURF" },
  ];

  const { id: poolId } = useParams();
  const [reasonState, setReasonState] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const addToReasonState = val => {
    if (isLoading) return;
    const arr = reasonState;
    if (reasonState.includes(val)) {
      const indexToRemove = reasonState.findIndex(element => element === val);
      if (indexToRemove !== -1) arr.splice(indexToRemove, 1);
    } else {
      arr.push(val);
    }

    setReasonState([...arr]);
  };

  const options = {
    autoClose: 3000,
    hideProgressBar: true,
    position: toast.POSITION.TOP_RIGHT,
    pauseOnHover: true,
    style: {
      color: "#D1CCEF",
      background: "#3A2F81",
      width: "204px",
      borderRadius: "6px",
      float: "right",
      marginRight: "63px",
    },
    closeButton: false,
  };

  const reportFunc = async () => {
    const reasonString = reasonState.length > 1 ? reasonState.join(",") : reasonState[0];
    const url = `${BE_API_BASE_URL}ban/reportUser/${userId}/${poolId}/${[reasonString]}`; //TODO: [changeToUserId] changedd to userId. Ensure property being sent in to the ReportModal from props
    setIsLoading(true);
    const response = await callAxiosFunc({
      method: "GET",
      url,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
    });
    setIsLoading(false);
    if (response.error) {
      toast.error(<div>Something went wrong. Please try again</div>, options);
    } else {
      toggleReportModal();
      toast.success(
        <div style={{ display: "flex", justifyContent: "center", gap: "13px" }}>
          <TickWithBorder />
          Player Reported
        </div>,
        options,
      );
      setReasonState([]);
    }
  };

  return (
    <>
      <ToastContainer />

      <S.ReportModal isOpen={showReportModal} onBackgroundClick={toggleReportModal} onEscapeKeydown={toggleReportModal}>
        <S.CloseIconContainer onClick={toggleReportModal}>
          <CloseIcon />
        </S.CloseIconContainer>
        <S.ReportModalHeader>Report Cheater </S.ReportModalHeader>
        {reportReasons.map(item => (
          <S.CheckBoxContainer>
            <S.CheckBox checked={reasonState.includes(item.value)} onClick={() => addToReasonState(item.value)}>
              <OkTick
                style={{
                  visibility: reasonState.includes(item.value) ? "visible" : "hidden",
                  height: "6px",
                  width: "9px",
                }}
              />
            </S.CheckBox>
            <S.CheckBoxText onClick={() => addToReasonState(item.value)}>{item.title}</S.CheckBoxText>
          </S.CheckBoxContainer>
        ))}
        <S.ButtonContainer>
          <S.SecondaryButton disabled={isLoading} onClick={toggleReportModal}>
            Cancel
          </S.SecondaryButton>
          <S.PrimaryButton onClick={reportFunc} disabled={isLoading || reasonState < 1}>
            {isLoading ? (
              <div style={{ display: "flex", justifycContent: "center", alignItems: "center" }}>
                <Loader animation="rotate" width="50px" height="-20px" />
              </div>
            ) : (
              "Report"
            )}
          </S.PrimaryButton>
        </S.ButtonContainer>
      </S.ReportModal>
    </>
  );
}
