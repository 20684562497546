import React from "react";
import styled from "styled-components";
import { CoinIcon } from "../../../../../assets/svg";

const Card = styled.div`
  width: 224px;
  background-color: #4e3cca;
  border-radius: 8px;
  transform: skew(-20deg);
  position: relative;
  background-color: #4e3cca;
  color: #fff;
  .straight {
    transform: skew(20deg);
  }
  display: flex;
  flex-direction: column;
  row-gap: 13px;
  background: linear-gradient(#4e3cca, #4e3cca) padding-box padding-box,
    linear-gradient(to right, #7283f7, #aa57ff) border-box border-box;
  color: #fff;
  border: 3px solid transparent;
  border-radius: 8px;
  font-family: Gotham;
`;

const InnerContainer = styled.div`
  width: 262.5px;
  background: linear-gradient(#4e3cca, #4e3cca) padding-box padding-box,
    linear-gradient(to right, #7283f7, #aa57ff) border-box border-box;
  color: #fff;
  border: 3px solid transparent;
  border-radius: 8px;
  margin-left: -2px;
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding: 15px 22px;
  & > * {
    transform: skew(20deg);
  }

  .inner-prize {
    font-size: 40px;
    font-weight: 800;
    font-style: italic;
  }

  .inner-percentage {
    font-size: 20px;
    font-weight: 350;
    font-style: italic;
  }
`;

const NameRow = styled.div`
  padding: 12px;
  padding-top: 15px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  .position {
    font-weight: 800;
    font-size: 18px;
  }
  .player_name {
    font-weight: 325;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
  img {
    border-radius: 500px;
    height: 40px;
    width: 40px;
  }
`;

const ScoreContainer = styled.div`
  padding: 22px;
  padding-top: 0px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`;

const ScoreRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 350;
  div {
    transform: skew(20deg);
  }
`;

function formatPosition(_pos = 1) {
  let position = Number(_pos);
  if (position === 1) {
    return `1st`;
  } else if (position === 2) {
    return "2nd";
  } else if (position === 3) {
    return `3rd`;
  } else {
    return `${position}th`;
  }
}

const WinnerCard = props => {
  const { position, nick, avatarUrl, usdAmount, percentage, score } = props;
  const formattedAmount = usdAmount ? Math.round(usdAmount) : 0;
  const formattedPercentage = percentage ? Math.round(percentage) : 0;
  return (
    <Card>
      <NameRow>
        <img className="straight" src={avatarUrl} />
        <div className="position">{formatPosition(position)}</div>
        <div className="player_name">{nick}</div>
      </NameRow>
      <InnerContainer>
        <div className="inner-prize">${formattedAmount}</div>
        <CoinIcon style={{ width: "19px", height: "19px" }} />
        <div className="inner-percentage">{formattedPercentage}%</div>
      </InnerContainer>
      <ScoreContainer>
        <ScoreRow>
          <div>Score</div>
          <div>{score || 0}</div>
        </ScoreRow>
        <ScoreRow>
          <div>Percentage</div>
          <div>{percentage || 0}</div>
        </ScoreRow>
        <ScoreRow>
          <div>Prize</div>
          <div>{usdAmount || 0}</div>
        </ScoreRow>
      </ScoreContainer>
    </Card>
  );
};

export default WinnerCard;
