import styled from "styled-components";
import { Button } from "../../common/Button";

export const Container = styled.div`
  margin-bottom: 40px;
  color: #ffffff;
`;

export const SelectPillWrapper = styled.div`
  display: flex;
  gap: 6px;
`;

export const SelectPill = styled(Button)`
  all: unset;
  padding: 8px 10px;
  width: 84px;
  height: 20px;
  border-radius: 6px 6px 0 0;
  background: ${props => (props.selected ? "#3f2faa" : "#342788")};
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${props => (props.disabled ? "0.5" : "1")};
  cursor: ${props => (props.disabled ? "default" : "pointer")};

  &:hover {
    cursor: pointer;
  }
`;

export const SelectPillText = styled.span`
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  text-transform: capitalize;
`;

export const StageContainer = styled.div`
  background: #3f2faa;
  border-radius: 0 10px 10px 10px;
  height: 180px;
  padding: 15px 25px;
  display: flex;
  align-items: center;
  gap: 15%;
`;

export const StageDataWrapper = styled.div`
  width: 30%;
  height: 112px;
  border-right: 1px solid #4e3cca;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;

export const PlayingDot = styled.div`
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  div {
    width: 8px;
    height: 8px;
    background-color: transparent;
    opacity: 0.8;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    width: 8px;
    height: 8px;
    background: #b2fa4f;
    border-radius: 50px;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    transform: scale(1);
    animation: pulse 1.4s infinite;
    @keyframes pulse {
      0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
      }
      40% {
        transform: scale(1);
        box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.4);
      }
      100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      }
    }
  }
`;

export const StageInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const StageStatusText = styled.p`
  color: rgba(255, 248, 248, 0.5);
  font-size: 13px;
  font-style: normal;
  font-weight: 325;
  line-height: 20px;
  margin: 0;
`;

export const DescriptionText = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  margin: 0;
`;

export const PlayerAmmountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;

export const PlayerAmmountText = styled.p`
  font-size: 11px;
  line-height: 17px;
  align-self: center;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  margin: 0;
`;

export const PoolDataWrapper = styled.div`
  width: 568px;
  gap: 40px;
  height: 66px;
  padding: 21px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const PoolInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

export const PoolInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export const PoolInfoTitle = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  margin: 0;
`;

export const PoolInfoData = styled.span`
  margin: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;

export const PoolDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DateStatus = styled.div`
  width: 20px;
  height: 20px;
  background: ${props => (props.stageTimeFinish ? "#6c47ff" : "transparent")};
  border: 2px solid #6c47ff;
  border-radius: 50%;
`;

export const DateLineWrapper = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DateLine = styled.span`
  width: 2px;
  height: 20px;
  border: 2px dashed #6c47ff;
`;

export const DateInfoText = styled.small`
  width: 31px;
  color: rgba(255, 248, 248, 0.5);
  font-size: 13px;
  font-style: normal;
  font-weight: 325;
`;

export const DateText = styled.span`
  color: #fff;
  font-family: Gotham;
  font-size: 13px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;
