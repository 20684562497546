import React from "react";
import { Tooltip } from "antd";
import * as S from "./styles";
import Modal from "../Modal";
import { CheckMarkIcon, Clipboard, CloseIcon, Crown, ViewReplay } from "../../../assets/svg";
import { toast } from "react-toastify";
import Avatar from "../common/Avatar";
import { truncateWithDots } from "../../../helpers/utils";
import useMedia from "use-media";

/**
 * The function `getStyle` retrieves the value of a specific key from an array of style objects.
 * @returns The function `getStyle` returns the value of the `value` property of the first object in
 * the `style` array that has a `key` property equal to the `key` argument passed to the function. If
 * no such object is found, the function returns `undefined`.
 */
const getStyle = (style, key) => {
  const styleObject = style.find(item => item.key === key);
  return styleObject?.value;
};

/* `elementRender` is a helper function that takes in a `component` object, an `index`, and a `team`
object as arguments. It then destructures the `key`, `value`, `style`, `type`, and `placeholder`
properties from the `component` object. The function then returns a specific JSX element based on
the `key` value. If the `key` is `"h1"`, it returns a `Title` component with the `value` as its
child. If the `key` is `"label"`, it returns a `Label` component with the `value` as its child and
the `style` object as a prop. If the `key` is `"input"`, it returns an `InputContainer` component
with an `Input` component and a `Clipboard` component as its children. The `Input` component has the
`type`, `value`, and `placeholder` properties set based on the `type`, `value`, and `placeholder`
properties of the `component` object, respectively. The `Clipboard` component is wrapped in a `div`
element with an `onClick` event listener that copies the `value` of */
const elementRender = (component, index, team) => {
  const { key, value, style, type, placeholder } = component;
  const options = {
    autoClose: 3000,
    hideProgressBar: true,
    position: toast.POSITION.TOP_RIGHT,
    pauseOnHover: true,
    style: {
      color: "#D1CCEF",
      background: "#3A2F81",
      width: "204px",
      borderRadius: "6px",
      float: "right",
      marginRight: "63px",
    },
    closeButton: false,
  };
  switch (key) {
    case "h1":
      return <S.Title key={index}>{value}</S.Title>;
    case "label":
      return (
        <S.Label key={index} style={style}>
          {value}
        </S.Label>
      );
    case "input":
      return (
        <S.InputContainer>
          <S.Input
            key={index}
            type={type}
            value={truncateWithDots(team?.[value], 25) || ""}
            placeholder={placeholder}
            readOnly
          />
          <div
            onClick={async () => {
              toast.success(`${placeholder} copied successfully`, options);
              await navigator.clipboard.writeText(team?.[value] || "");
            }}
          >
            <Clipboard />
          </div>
        </S.InputContainer>
      );
    default:
      return null;
  }
};

/**
 * The function renders a row of components, with a special case for a full input component.
 * @returns The function `rowComponentRender` returns the result of calling either `elementRender` or
 * `elementRender` called on each component in the `value` array of the `full-input` component, wrapped
 * in an `InputWrapper` component. The specific component to be rendered is determined by the
 * `elementRender` function, which is not shown in the code snippet.
 */
const rowComponentRender = (components, index, team) => {
  const { key, value, style } = components;
  if (key !== undefined || key !== null || !key) {
    if (key === "full-input") {
      return (
        <S.InputWrapper key={index}>
          {value.map((component, index) => {
            return elementRender(component, index, team, style);
          })}
        </S.InputWrapper>
      );
    }
  }
  return elementRender(components, index, team);
};

/* The above code is a function that takes in an array of components, an index, a team object, and a
match object. It then maps through the components array and renders different components based on
their key value. The rendered components include h1, h2, label, row, preview, match-details, and
match-results. The function also calls other helper functions to render some of the components. */
const mapComponentRender = (components, index, team, match, isDesktop) => {
  const { key, value, style } = components;
  switch (key) {
    case "h1":
      return (
        <S.ModalTitle key={index} style={style}>
          {value}
        </S.ModalTitle>
      );
    case "h2":
      return (
        <S.Title key={index} style={style}>
          {value}
        </S.Title>
      );
    case "label":
      return <S.Label key={index}>{value}</S.Label>;
    case "row":
      return (
        <S.Row>
          {value.map((component, index) => {
            return rowComponentRender(component, index, team);
          })}
        </S.Row>
      );
    case "preview":
      return (
        <S.Container>
          <div style={{ display: "flex"}}>
            <S.Label>{value}</S.Label>
          </div>
          <S.BracketColumn>
            {match.map((player, index) => {
              return (
                <S.BracketRow>
                  <S.Position>
                    <S.Label>{player.position}</S.Label>
                  </S.Position>
                  <S.BracketContainer>
                    <S.PlayerData>
                      <Avatar size="22px" src={player.avatar} />
                      <S.Text>{player.name}</S.Text>
                    </S.PlayerData>
                  </S.BracketContainer>
                </S.BracketRow>
              );
            })}
          </S.BracketColumn>
        </S.Container>
      );
    case "match-details":
      return (
        <S.IconWrapper key={index}>
          {team?.results?.length > 0 ? (
            <S.IconRow>
              {team?.results?.map((resaultDetails, index) => {
                return (
                  // TODO: add tooltip
                  <S.IconContainer
                    key={index}
                    bg={
                      resaultDetails?.result === "W"
                        ? getStyle(style, "color-success")
                        : resaultDetails?.result === "L"
                        ? getStyle(style, "color-failure")
                        : getStyle(style, "color-default")
                    }
                  >
                    {resaultDetails?.result === "W" ? (
                      <CheckMarkIcon style={{ width: "11px", height: "11px" }} />
                    ) : (
                      <CloseIcon />
                    )}
                  </S.IconContainer>
                );
              })}
            </S.IconRow>
          ) : (
            <S.Row>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                <S.Title style={{ color: "#D1CCEF" }}>No results yet</S.Title>
              </div>
            </S.Row>
          )}
        </S.IconWrapper>
      );
    case "match-results":
      return (
        <>
          {team?.results?.length > 0 ? (
            <S.Column>
              {team?.results?.map((resaultDetails, index) => (
                <S.WinnerWrapper>
                  <S.WinnerContainer
                    key={index}
                    bg={`linear-gradient(90deg, ${
                      resaultDetails?.result === "W"
                        ? getStyle(style, "background-success")
                        : resaultDetails?.result === "L"
                        ? getStyle(style, "background-failure")
                        : getStyle(style, "background-default")
                    } 0%, #3F2FAA 36.46%, #3F2FAA 94.27%)`}
                    width={team?.replayURLS?.length > 0 && team.replayURLS[index] !== "" ? "90%" : "100%"}
                  >
                    <S.MatchPlayerData>
                      <Avatar src={team?.avatar || ""} size="28px" />
                      <S.Nickname>{team?.name?.length > 15 && !isDesktop ? truncateWithDots(team?.name, 10) : team?.name}</S.Nickname>
                    </S.MatchPlayerData>
                    {resaultDetails?.details === undefined ||
                    resaultDetails?.details === null ||
                    resaultDetails?.details?.length <= 0 ? null : (
                      <>
                        <S.ScoreContainer>
                          <S.ScoreRow>
                            <S.Score>{resaultDetails?.details?.score}</S.Score>
                            <span> - </span>
                            <S.Score>
                              {match[1]?.results[index]?.details?.score === resaultDetails?.details?.score
                                ? match[0]?.results[index]?.details?.score
                                : match[1]?.results[index]?.details?.score}
                            </S.Score>
                          </S.ScoreRow>
                          <S.MatchData>
                            <S.MatchRound> Round {index + 1}</S.MatchRound>
                            <span> - </span>
                            <S.Map>{resaultDetails?.details?.map || "N/A"}</S.Map>
                          </S.MatchData>
                        </S.ScoreContainer>
                        <S.MatchPlayerData>
                          <Avatar
                            src={match[1]?.avatar === team?.avatar ? match[0]?.avatar : match[1]?.avatar}
                            size="28px"
                          />
                          <S.Nickname>{match[1]?.name === team?.name ? 
                          match[0]?.name?.length > 10 && !isDesktop ? truncateWithDots(match[0]?.name, 10) : match[0]?.name :
                          match[1]?.name?.length > 10 && !isDesktop ? truncateWithDots(match[1]?.name, 10) : match[1]?.name
                          }</S.Nickname>
                        </S.MatchPlayerData>
                      </>
                    )}
                  </S.WinnerContainer>
                  {team?.replayURLS?.length > 0 && team.replayURLS[index] !== "" ? (
                    <Tooltip title="View Replay" placement="top" color="#7265C8">
                      <S.ReplayButton onClick={() => window.open(team?.replayURLS[index], "_blank")}>
                        <ViewReplay />
                      </S.ReplayButton>
                    </Tooltip>
                  ) : null}
                </S.WinnerWrapper>
              ))}
            </S.Column>
          ) : (
            <S.Column>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <S.Title style={{ color: "#D1CCEF" }}>No results yet</S.Title>
              </div>
            </S.Column>
          )}
        </>
      );
    case "team-detail":
      return (
        <S.TeamContainer>
          <S.Label>{value}</S.Label>
          <S.TeamColumn>
            {team?.teamMembers.map((player, index) => {
              return (
                <S.BracketRow>
                  <S.Position>
                    <S.Label>{index + 1}</S.Label>
                  </S.Position>
                  <S.BracketContainer>
                    <S.PlayerData>
                      <Avatar size="22px" src={player?.thumbnail} />
                      <S.Text>{player?.nick}</S.Text>
                      {player?.role?.includes("captain") && (<Crown />)}
                    </S.PlayerData>
                  </S.BracketContainer>
                </S.BracketRow>
              );
            })}
          </S.TeamColumn>
        </S.TeamContainer>
      );
    default:
      return elementRender(components, index, team, match);
  }
};

/**
 * This is a React component that renders a modal with a main container and maps over an array of
 * components to render them with specific props.
 * @returns A React component that renders a modal with a main container and multiple components based
 * on the `componentsToRender` array, passing in `team` and `match` as props to each component. The
 * `showModal` and `toggleModal` props are used to control the visibility of the modal. There is also a
 */
const BracketInfoModal = ({ showModal, toggleModal, team, match, template }) => {
  const isDesktop = useMedia({ minWidth: "768px" });
  return (
    <Modal showModal={showModal} toggleModal={toggleModal}>
      <S.MainContainer>
        {template?.components?.map((component, index) => {
          return mapComponentRender(component, index, team, match, isDesktop);
        }, this)}
      </S.MainContainer>
    </Modal>
  );
};

export default BracketInfoModal;
