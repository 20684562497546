import styled from "styled-components";
import { breakpoints } from "../../../../themes";
import BG from "../../../../assets/new-bg.png";

// card
export const WinnerCardsContainer = styled.div`
  display: flex;
  column-gap: 95px;
  row-gap: 45px;
  justify-content: center;
  flex-wrap: wrap;
  width: ${props => (props.dataLen < 5 ? "100%" : "85%")};
  margin: auto;
  margin-top: 87px;
  margin-bottom: 78px;
`;

export const WinnerCardBg = styled.div`
  background: ${props => (props.bgImg ? `url(${props.bgImg})` : `url(${BG})`)};
  box-shadow: 0px 100px 100px #201366 inset, #201366 0px -100px 100px -28px inset;
  margin-bottom: 80px;
  margin-top: 40px;
  min-height: 500px;
  width: 100vw;
  margin-left: -103px;
  position: absolute;
  background-size: cover;

  @media only screen and (min-width: 1650px) {
    margin-left: 0;
    width: 1200px;
  } /* margin-left: -103px; */
`;

export const CupPodiumContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 130px;
`;

export const CupPodiumHead = styled.p`
  font-size: ${props => props.fontSize || null};
  color: ${props => props.color || null};
  margin-bottom: ${props => props.marginBottom || null};
  width: ${props => props.width || null};
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
`;

// Finalist

export const FinalistTable = styled.div`
  min-height: 0px;
  max-height: 500px;
  overflow-y: auto;
  padding: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar-track {
    background: #7265c8;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #3f338ce6;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #3f338c;
  }
`;

export const FinalistContainer = styled.div`
  width: 100%;
  /* border: 2px solid red; */
  /* height: 200px; */
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  margin-top: 10px;
  padding: 10px 10px;
  min-height: 60px;
  background: #3f2faa;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
  :nth-child(1) {
    margin-top: 0;
  }
`;

export const FinalistPosition = styled.p`
  width: 62.15px;
  color: #ffffff;
  font-size: 16px;
  line-height: 19px;
  font-weight: 350;
  margin-bottom: 0;
`;

export const UserData = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
`;

export const FinalistName = styled.p`
  color: #ffffff;
  font-size: 14px;
  font-weight: 350;
  margin-bottom: 0;
`;

export const FinalistScore = styled.p`
  width: 65.42px;
  font-size: 14px;
  font-weight: 350;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: right;
  color: #be7eff;
  margin-bottom: 0;
  justify-self: end;
  @media only screen and (max-width: 528px) {
    display: none;
  }
`;

export const FinalPercentage = styled.p`
  width: 65.42px;
  font-size: 14px;
  font-weight: 350;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: right;
  color: #be7eff;
  margin-bottom: 0;
  justify-self: end;
  font-size: 14px;
  color: #ebebf5;
  @media only screen and (max-width: 528px) {
    display: none;
  }
`;

export const FinalPrize = styled.p`
  width: 65.42px;
  font-size: 14px;
  font-weight: 350;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: right;
  color: #be7eff;
  margin-bottom: 0;
  justify-self: end;
  font-size: 16px;
  color: #fff;
  font-weight: 350;
`;

// Headings
export const HeadingsContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
  :nth-child(1) {
    margin-top: 0;
  }
`;

export const PositionHeading = styled.div`
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;

  /* txt color */

  color: #a498ed;
`;
export const UserDataHeading = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;

  /* txt color */

  color: #a498ed;
`;

export const ScoreHeading = styled.div`
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;

  /* txt color */

  color: #a498ed;
  @media only screen and (max-width: 528px) {
    display: none;
  }
`;
export const PercentageHeading = styled.div`
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;

  /* txt color */

  color: #a498ed;
  @media only screen and (max-width: 528px) {
    display: none;
  }
`;

export const PrizeHeading = styled.div`
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;

  /* txt color */

  color: #a498ed;
`;