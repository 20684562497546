import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FaqAccordion from "./FaqAccordion";
import { SearchIcon } from "../../assets/svg";
import * as S from "./styles";
import adImg from "../../assets/tournament-list-page-ad.png";

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FAQ_DATA = [
  {
    title: "How do I sign up to Blast?",
    answer:
      "Click 'Sign-in'  at the top right corner of the app screen and sync a social account (Google, Twitter, Facebook or Discord) or if you're familiar with web3 apps, connect via a wallet like Metamask (https://metamask.io/download/).",
  },
  {
    title: "What do I need to create my account?",
    answer: `If you registered using a social account, you can simply head to your Profile section and start linking your gaming accounts to start playing. In this case you will only need to connect your wallet once you claim a prize. Don't worry, you don't have to deal with this right now. \nIf you connect to the app for the first time with your wallet, a sign up form will popup. All you need to do is choose a nickname, and connect one or more gaming accounts in the Profile section.`,
  },
  {
    title: "How do I set up my profile?",
    answer:
      "You'll find the Profile icon on the sidebar or by clicking the top right button, where you should now be seeing the nickname you chose. Here you’ll find information about your activity in the platform, and you can also upload a profile pic. You’ll also see your linked gaming and social accounts",
  },
  {
    title: "How do I see the gaming accounts I linked to my account?",
    answer:
      "At your profile section and under your general stats, you will find the gaming accounts you linked and you’ll be able to link additional accounts",
  },
  {
    title: "How can I join a tournament?",
    answer:
      "Go to the Tournaments section in your sidebar (left side of the screen). There you’ll find all the active and finished tournaments, and you can also filter to see free entry tournaments only, or the tournaments you already joined. In order to join a tournament, click on one of the tournament cards, it would open the tournament detail view. Here you’ll find all the details about the particular tournament, and a Join button",
  },
  {
    title: "How much does it cost to enter a tournament?",
    answer:
      "Blast supports free entry tournaments as well as tournaments with an entry fee. If, for example, the tournament has an entry fee of 1 Matic, the total cost of joining the tournament would be 1 Matic plus the Network Fee also known as ‘gas’. Network fees depend on the current state of the network. In the case of free entry tournaments, the only fee to be paid is the Network Fee",
  },
  {
    title: "Is there a platform fee in Blast?",
    answer:
      "Blast only collects a fee from the tournaments winners, it does not charge a fee for joining in tournaments or other activities. Current Service Fee is 2% of the prize paid to a tournament winner",
  },
  {
    title: "How do I claim a prize?",
    answer:
      "You'll find the `claim` button at the home page under your `Earnings` information. You may also find it in your profile section under your tournament history. If the prize is ready for grabs, a claim button will be enabled. If you haven't linked your wallet, it will be the time to do so",
  },
  {
    title: "Why would I use this platform over any other tournament site?",
    answer:
      "At Blast we believe playing competitively and engaging in tournaments should be easy, frictionless, and transparent. Blast provides fair and automatic resolution and payments, improving the experience and making it effort minimized",
  },
  {
    title: "What is Polygon and Matic?",
    answer:
      "Polygon is a blockchain network similar to Ethereum. It provides faster and cheaper transactions and it has a great ecosystem of web3 applications such as Blast. Matic is Polygon’s native crypto currency, which we use to transact in Blast during tournaments and to pay for Network Fees.",
  },
  {
    title: "How is the scoring system for the tournaments?",
    answer:
      "Each tournament has its own set of rules, you can find them on the tournament details page. Scoring is part of that ruleset and you’ll always find it there.",
  },
  {
    title: " Can I leave a tournament before it begins?",
    answer:
      "Yes, you can leave a tournament before it begins but not after, and if you choose to leave before it starts Blast will refund your Entry Fee in case there is one. Keep in mind that if you join a tournament and can’t play to get a score during the tournament duration there is no way to refund the entry nor the network fee",
  },
  {
    title: "Can I leave a tournament after it begins?",
    answer: "No, we don’t allow that to avoid speculative behavior.",
  },
  {
    title: "What is a cryptocurrency wallet?",
    answer:
      "A cryptocurrency wallet, sometimes called a blockchain wallet, is a secure digital wallet used to store, send, and receive crypto such as USDC, Matic or Ether. You'll need to generate or connect a wallet in order to receive crypto payments on the platform",
  },
  {
    title: "How can I get Matic to pay for the Network Fee (gas)?",
    answer:
      "When you create your Blast account, we'll drop you some Matic in order to try your first tournament. After that, if you have funds on Ethereum, you can buy Matic and bridge it to Polygon here: https://wallet.polygon.technology/bridge. If you don't have any funds, you can buy Matic directly to Polygon using an on-ramp service: https://wallet.polygon.technology/on-ramp, or you can always use a crypto exchange like Coinbase to buy Matic and transfer it from there to your Metamask wallet",
  },
];
export default function LobbyPage() {
  const [searchTerm, setSearchTerm] = useState("");
  const [faqData, setFaqData] = useState([]);

  useEffect(() => {
    if (searchTerm) {
      const filteredItems = faqData.filter(
        item => item.title.toLowerCase().includes(searchTerm) || item.answer.toLowerCase().includes(searchTerm),
      );
      setFaqData(filteredItems);
    } else {
      setFaqData(FAQ_DATA);
    }
    // warning unfixable atm because it generates a crash on the website
    // eslint-disable-next-line
  }, [searchTerm]);

  return (
    <S.Container>
      <S.BannerContainer>
        <Image src={adImg} alt="" />
      </S.BannerContainer>
      <S.PageTitle>FAQ</S.PageTitle>
      <S.InputContainer>
        <S.SearchIconContainer>
          <SearchIcon />
        </S.SearchIconContainer>
        <S.Input
          placeholder="Search"
          onChange={e => setSearchTerm(e.target.value.toLowerCase())}
          type="text"
          data-testid="faq_input"
        />
      </S.InputContainer>
      <S.FaqContainer>
        <FaqAccordion questions={faqData} searchTerm={searchTerm} />
      </S.FaqContainer>
    </S.Container>
  );
}
