import { BE_API_BASE_URL } from "../constants/env";
import { useGetSecHeaders } from "./SecurityHeaders";
import useCallAxios from "./useAxiosCall";

const useImageUpload = () => {
  const { callAxiosFunc } = useCallAxios();

  const headers = useGetSecHeaders();

  const uploadImage = async (fileList, type, fileNameParam, media="thumbnail") => {
    if (fileList.length <= 0) return;

    const file = fileList[0];
    const fileName = fileNameParam
      ? fileNameParam?.split(".")[0]?.replace(/[\s_]+/g, "-")
      : file.name.split(".")[0].replace(/[\s_]+/g, "-");

    const fullName = `${fileName}-${media}`;
    const formData = new FormData();

    formData.append(fullName, file);
    formData.append("type", type);

    if (formData) {
      let res = await callAxiosFunc({
        method: "POST",
        url: `${BE_API_BASE_URL}file/`,
        headers: headers.token
          ? JSON.stringify({
              accept: "*/*",
              Authorization: headers.encryptedHeader,
            })
          : null,

        extraHeader: { "Content-Type": "multipart/form-data" },
        body: formData,
      });

      return res;
    }
  };

  return uploadImage;
};

export default useImageUpload;
