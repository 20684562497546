import styled from "styled-components";
import { Button } from "../../../components/v2/common/Button";

export const Container = styled.div`
  margin-top: 57px;
`;

export const SettingsBtnContainer = styled.div`
  margin: 27px 0;
  display: flex;
  column-gap: 21px;
`;

export const SectionBtn = styled(Button)`
  border-radius: 37px;
  background: ${props => (props.active ? "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)" : "#3F2FAA")};
  height: 37px;
`;

export const AccountSettingsContainer = styled.div`
  background-color: #3f2faa;
  padding: 24px;
  border-radius: 10px;
  width: 100%;
  @media (max-width: 500px) {
    strong {
      font-size: 12px;
    }
  }
`;

export const NotifSettingsContainer = styled.div`
  background-color: #3f2faa;
  padding: 24px;
  border-radius: 10px;
  width: 100%;
`;

export const Separator = styled.div`
  background: #4e3cca;
  width: 100%;
  height: 2px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const SocialImage = styled.div`
  svg {
    height: 18px;
    width: 18px;
  }
`;

export const DetailRowName = styled.div`
  width: 160px;
  font-size: 12px;
  @media (max-width: 375px) {
    width: 60px;
  }
  @media (max-width: 320px) {
    width: 10px;
  }
`;

export const AddressText = styled.strong`
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 868px) {
    max-width: 160px;
  }
  @media (max-width: 768px) {
    max-width: 130px;
  }
  @media (max-width: 500px) {
    max-width: 100px;
  }
  @media (max-width: 375px) {
    max-width: 75px;
  }
`;

export const CheckboxContainer = styled.div`
  margin-left: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  label {
    padding-bottom: 4px;
  }
`;
