import styled from "styled-components";
import Modal from "styled-react-modal";

export const StyledModal = Modal.styled`
    width: 373px;
    height: 218px;
    display: flex;
    flex-direction: column;
    background-color: #342788;
    border: ${props => (props.error ? "1px solid #D06565" : "none")};
    border-radius: 4px;
    opacity: 1;
    transition : all 0.3s ease-in-out;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

export const ModalContainer = styled.div`
  width: 450px;
  height: 100%;
`;

export const MainWrapper = styled.div`
  padding: 20px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 18px;
  right: 26px;
  z-index: 3;

  :hover {
    cursor: pointer;
  }
`;

export const Title = styled.div`
  color: #fff;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;

export const Body = styled.div`
  color: #a498ed;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 40px;
  & > div > p {
    margin: 0;
  }
`;
export const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Cancel = styled.span`
  padding: 16px;
  font-size: 14px;
  color: #fff;
  margin-right: 20px;
  cursor: pointer;
`;
