import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 266px;
  border-radius: 8px;
  position: relative;
  box-shadow: inset 0 0 10em 3em #000;

  @media (max-width: 1200px) {
    display: none;
  }
  ${props => `
    background-image: ${props.backgroundUrl ? `url(${props.backgroundUrl})` : "#3F2FAA"};  
  `};
  background-size: cover;
  background-position: center;
`;

export const MobileBanner = styled.div`
  position: relative;
  display: none;
  height: 108px;
  @media (max-width: 1200px) {
    display: flex;
  }
  ${props => `
    background: ${props.backgroundUrl ? `url(${props.backgroundUrl})` : "#3F2FAA"};  
  `};
  background-size: cover;
  background-position: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: inset 0 0 3em 1em #000;
`;

export const MobileContainer = styled.div`
  display: none;
  /* margin-top: 60px; */
  padding-top: 40px;
  padding-bottom: 28px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #3f2faa;
  @media (max-width: 1200px) {
    display: block;
  }
`;

export const AvatarContainer = styled.div`
  border: 4px solid #be7eff;
  border-radius: 500px;
  padding: 6px;
  width: fit-content;
  position: relative;
  ${props => `
    margin: ${props.isMobile ? `auto` : "unset"};
    margin-bottom: ${props.isMobile ? `-45px` : `-65px`};  
  `};

  div {
    visibility: hidden;
    background: #00000066;
    position: absolute;
    height: ${props => (props.isMobile ? `89px` : `143px`)};
    width: ${props => (props.isMobile ? `89px` : `143px`)};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
    ${props => `
         cursor: ${!props.thumbnailUploading && props.isOwner ? `pointer` : `default`};
      `};
  }

  img {
    ${props => `
         background: ${props.isThumbnailTransparent ? "#6655D1" : ""}
    `};
  }

  &:hover {
    div {
      ${props => `
         visibility: ${props.isOwner ? `visible` : `hidden`};  
      `};
    }
  }
`;

export const BottomRow = styled.div`
  // position: absolute;
  // left: 39px;
  display: flex;
  align-items: center;
  bottom: -40px;
  align-items: flex-end;
  height: 100%;
  padding-right: 30px;
  justify-content: space-between;
  width: 100%;

  ${props => `
    padding-left: ${props.isMobile ? `16px` : "38px"};  
    padding-bottom: ${props.isMobile ? `10px` : "50px"};  

  `};
`;

export const PlayerAvatarsContainer = styled.div`
  display: flex;
  img:hover {
    transform: translateY(-6px);
    transition: transform 0.2s linear;
  }
  img:not(:nth-child(1)) {
    margin-left: -10px;
    border: ${props => (props.border ? props.border : "2px solid #1b0939")};
    z-index: 0;
  }
`;

export const PlayerCountCircle = styled.div`
  width: 40.37px;
  height: 40.37px;

  background: linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%);
  border-radius: 30px;
  margin-left: -10px;
  border: ${props => (props.border ? props.border : " 2px solid #1b0939")};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 13px;
  font-weight: 350;
  z-index: 4;
  cursor: pointer;
`;

export const CameraContainer = styled.div`
  svg path {
    fill: #ffffff80;
  }
  &:hover {
    svg path {
      fill: #ffffff;
    }
  }
`;

export const BannerListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 16px;
  column-gap: 16px;
  margin-top: 34px;
  max-height: 426px;
  overflow-y: auto;
  padding-right: 4px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #7265c8;
  }

  ::-webkit-scrollbar-thumb {
    background: #3f338ce6;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #3f338c;
  }
`;

export const BannerListItem = styled.div`
  height: 92px;
  width: 357px;
  ${props => `
    border: ${props.isSelected ? `4px solid #6c47ff` : "unset"};
    background: ${props.backgroundUrl ? `url(${props.backgroundUrl})` : "#3F2FAA"};  
  `};
  background-size: cover;
  border-radius: 8px;
`;

export const BannerPicker = styled.div`
  height: 92px;
  width: 357px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #201366;
  border: 3px solid #6c47ff;
  cursor: pointer;
`;

export const Popover = styled.div`
  position: absolute;
  width: 167px;
  height: fit-content;
  background-color: #3f2faa;
  right: 0;
  color: #ffffff;
  gap: 5px;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #4e3dca;
  z-index: 999;
`;

export const SelectContainer = styled.div`
  margin-bottom: 21.94px;
  margin-top: 24px;
  min-height: 32px;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #ffffff12;
  width: 189px;
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    border: 1px solid #877add;
    outline: none;
    color: #fff;
    width: 150px;
    border-radius: 6px;
  }

  .ant-select-arrow {
    // display: none;
    color: #6a5dbd;
  }
`;

export const SelectPlaceholder = styled.div`
  color: rgba(135, 122, 221, 1);
  font-size: 12px;
`;

export const InputBoxContainer = styled.div`
  background-color: #ffffff12;
  height: 40px;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
  gap: 10px;
  width: 250px;
`;

export const PopoverButtons = styled.button`
  background: none;
  border: none;
  display: flex;
  width: 157px;
  height: 34px;
  border-radius: 4px;
  gap: 12px;
  align-items: center;
  /* background-color: red; */
  padding-left: 10px;

  :hover {
    background-color: #4a39ba;
  }
`;

export const Empty = styled.div``;
export const UploadBtn = styled.button`
  justify-self: end;
  background: linear-gradient(141.97deg, rgba(29, 197, 236, 0.5) -117.24%, rgba(170, 87, 255, 0.5) 92.36%);
  border-radius: 40px;
  width: 150px;
  height: 40px;
  color: #fff;
  border: 0;
  font-size: 16px;
  :hover {
    background-color: #6c47ff;
    cursor: pointer;
  }
`;

export const TransparentArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  :hover {
    cursor: pointer;
  }
`;
