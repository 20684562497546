// import axios from "axios";
import { useEffect, useState } from "react";
import { useGetSecHeaders } from "./SecurityHeaders";
import { BE_API_BASE_URL } from "../constants/env";
import useCallAxios from "./useAxiosCall";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
/*
  ~ What it does? ~

  Gets a list of tournaments based on filter and the loading state of the request

  ~ How can I use? ~

  const { tournamentList, isLoading } = useTournamentList(activeFilter);
*/

export default function useTournamentList(filter) {
  const [url, setUrl] = useState();
  const location = useLocation();
  const [tournamentList, setTournamentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const headers = useGetSecHeaders();
  const { callAxiosFunc } = useCallAxios();

  const Filters = {
    ACTIVE: "Active",
    ALL: "All",
    UPCOMING: "Upcoming",
    FINISHED: "Finished",
    MY_TOURNAMENTS: "My Tournaments",
  };

  const activePools = ["RUNNING"];
  const upcomingPools = [...activePools, "NOT_STARTED", "JOINING", "PROCESSING_REGISTERED_USERS"];
  const finishedPools = ["FINISHED", "FINISHED_COLLECTING_DATA"];
  const allStatus = [
    "NOT_STARTED",
    "JOINING",
    "PROCESSING_REGISTERED_USERS",
    "RUNNING",
    "FINISHED",
    "FINISHED_COLLECTING_DATA",
  ];

  const fetchTournaments = async tournamentUrl => {
    setIsLoading(true);
    const resp = await callAxiosFunc({
      method: "POST",
      url: tournamentUrl,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      body: JSON.stringify({
        limit: 30,
        onlyUserPools: filter === Filters.MY_TOURNAMENTS,
        poolStatus:
          filter === Filters.ALL || filter === Filters.MY_TOURNAMENTS
            ? allStatus
            : filter === Filters.ACTIVE
            ? activePools
            : filter === Filters.UPCOMING
            ? upcomingPools
            : filter === Filters.FINISHED
            ? finishedPools
            : null,
        showInHome: location.pathname === "/" || location.pathname === "/pool" ? true : false,
      }),
    });
    if (resp && resp.err) {
      setTournamentList([]);
    } else {
      setTournamentList(resp.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (filter === Filters.ACTIVE) {
      setUrl(`${BE_API_BASE_URL}poolList/all`);
    } else if (filter === Filters.ALL) {
      setUrl(`${BE_API_BASE_URL}poolList/all`);
    } else if (filter === Filters.UPCOMING) {
      setUrl(`${BE_API_BASE_URL}poolList/all`);
    } else if (filter === Filters.FINISHED) {
      setUrl(`${BE_API_BASE_URL}poolList/all`);
    }
  }, [filter, fetchTournaments, Filters.ACTIVE, Filters.ALL, Filters.UPCOMING, Filters.FINISHED]);

  useEffect(() => {
    if (url) {
      fetchTournaments(url, filter);
    }
  }, [filter, url]);

  return { tournamentList, isLoading };
}

export function useTournamentListQuery(filter) {
  const headers = useGetSecHeaders();
  const { callAxiosFunc } = useCallAxios();
  const [url, setUrl] = useState("");
  const location = useLocation();
  const Filters = {
    ACTIVE: "Playing",
    ALL: "All",
    UPCOMING: "Upcoming",
    FINISHED: "Finished",
    MY_TOURNAMENTS: "My Tournaments",
  };

  const activePools = ["RUNNING"];
  const upcomingPools = ["NOT_STARTED", "JOINING", "PROCESSING_REGISTERED_USERS"];
  const finishedPools = ["FINISHED", "FINISHED_COLLECTING_DATA"];
  const allStatus = [
    "NOT_STARTED",
    "JOINING",
    "PROCESSING_REGISTERED_USERS",
    "RUNNING",
    "FINISHED",
    "FINISHED_COLLECTING_DATA",
  ];

  useEffect(() => {
    if (filter === Filters.ACTIVE) {
      setUrl(`${BE_API_BASE_URL}poolList/all`);
    } else if (filter === Filters.ALL) {
      setUrl(`${BE_API_BASE_URL}poolList/all`);
    } else if (filter === Filters.UPCOMING) {
      setUrl(`${BE_API_BASE_URL}poolList/all`);
    } else if (filter === Filters.FINISHED) {
      setUrl(`${BE_API_BASE_URL}poolList/all`);
    }
  }, [filter, Filters.ACTIVE, Filters.ALL, Filters.UPCOMING, Filters.FINISHED]);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["tournamentList", url, filter, location, headers?.userId], // adding userId in dependency so that data is refetched for new users

    queryFn: () =>
      callAxiosFunc({
        method: "POST",
        url: url,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
        body: JSON.stringify({
          limit: 30,
          onlyUserPools: filter === Filters.MY_TOURNAMENTS,
          poolStatus:
            filter === Filters.ALL || filter === Filters.MY_TOURNAMENTS
              ? allStatus
              : filter === Filters.ACTIVE
              ? activePools
              : filter === Filters.UPCOMING
              ? upcomingPools
              : filter === Filters.FINISHED
              ? finishedPools
              : null,
          showInHome: location.pathname === "/" || location.pathname === "/pool" ? true : false,
        }),
      }),

    staleTime: 30000, // Prevents refetching on mount for 30 seconds
    enabled: !!url,
  });

  return { tournamentList: data?.data, isLoading, isFetching };
}
