import React from "react";
import styled from "styled-components";
import LoadingSkeleton from "./";
import { breakpoints } from "../../themes";

const AdjustPadding = styled.div`
  position: relative;
  width: 100%;
  padding: 0px 72px 136px 47px;
  ${breakpoints.down(breakpoints.laptopL)} {
    padding: 0px 38px 136px 47px;
  }
  ${breakpoints.down(breakpoints.laptop)} {
    padding: 0px 20px 136px 20px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 13px;
`;

const PaginationLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 18px;
  margin-top: 30px;
`;

const MyActivityLoader = () => {
  return (
    <AdjustPadding>
      <LoadingSkeleton height="33px" width="158px" style={{ marginBottom: "36px" }} />
      <LoadingSkeleton height="24px" width="250px" style={{ marginBottom: "54px" }} />
      <RowContainer
        style={{
          display: "grid",
          gridTemplateColumns: "1.8fr 1fr repeat(6,1fr)",
          marginBottom: "24px",
          paddingLeft: "24px",
        }}
      >
        <LoadingSkeleton height="12px" width="180px" />
        <LoadingSkeleton height="12px" width="75px" />
        <LoadingSkeleton height="12px" width="75px" />
        <LoadingSkeleton height="12px" width="75px" />
        <LoadingSkeleton height="12px" width="75px" />
        <LoadingSkeleton height="12px" width="75px" />
      </RowContainer>
      <RowContainer>
        <LoadingSkeleton height="88px" width="100%" />
        <LoadingSkeleton height="88px" width="100%" />
        <LoadingSkeleton height="88px" width="100%" />
      </RowContainer>

      <PaginationLoaderContainer>
        <LoadingSkeleton width="36px" height="36px" borderRadius="100%" style={{marginRight:"20px"}} />
        <LoadingSkeleton width="36px" height="36px" borderRadius="100%" />
        <LoadingSkeleton width="36px" height="36px" borderRadius="100%" />
        <LoadingSkeleton width="36px" height="36px" borderRadius="100%" />
        <LoadingSkeleton width="36px" height="36px" borderRadius="100%" />
        <LoadingSkeleton width="36px" height="36px" borderRadius="100%" />
        <LoadingSkeleton width="36px" height="36px" borderRadius="100%" style={{marginLeft:"18px"}} />
        <LoadingSkeleton width="36px" height="36px" borderRadius="100%"  />
      </PaginationLoaderContainer>
    </AdjustPadding>
  );
};

export default MyActivityLoader;
