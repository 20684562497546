import React from "react";
import styled from "styled-components";

export const FlexStyled = styled.div`
  display: flex;
  ${props => ({ ...props.extraStyles })};
  ${props => `
  gap: ${props.gap ? props.gap : "unset"};
  row-gap: ${props.rowGap ? `${props.rowGap}px` : "unset"}};
  column-gap: ${props.columnGap ? `${props.columnGap}px` : "unset"}};
  flex-direction: ${props.direction ? props.direction : "row"};
  align-items: ${props.alignItems ? props.alignItems : "unset"};
  justify-content: ${props.justifyContent ? props.justifyContent : "unset"};
`}
`;

const Flex = ({ children, ...rest }) => {
  return <FlexStyled {...rest}>{children}</FlexStyled>;
};

export default Flex;
