const primaryColor = "#201366";
const altBackground = "#1C1644";
const lightBackgroundColor = "#3F338C";
const borderRadiusBase = "4px";
const componentBackground = "#211931";
const darkBackground = "#312C83";
const hoverBackground = "#3F2FAA";
const textMain = "#fff";
const textSecond = "#BE7EFF";
const textThird = "#7F81B0";
const sidebarBackground = "#2d2564";

const sizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopM: "1280px",
  laptopL: "1440px",
  desktop: "2560px",
};

const getValueFromKey = key => {
  if (Object.keys(sizes).includes(key)) return sizes[key];
  return key;
};

const theme = {
  dark: {
    primaryColor,
    lightBackgroundColor,
    textMain,
    textSecond,
    darkBackground,
    borderRadiusBase,
    componentBackground,
    textThird,
    sidebarBackground,
    hoverBackground,
    altBackground,
  },
  light: {
    primaryColor,
    lightBackgroundColor,
    textMain,
    textSecond,
    darkBackground,
    borderRadiusBase,
    componentBackground,
    textThird,
    sidebarBackground,
    hoverBackground,
    altBackground,
  },
};

export const breakpoints = {
  ...sizes,
  down: key => `@media screen and (max-width: ${getValueFromKey(key)}) `,
  up: key => `@media screen and (min-width: ${getValueFromKey(key)}) `,
};

export default theme;
