// ///////////////////////////ORIGINAL ///////////////////////////
// import React, { useCallback, useContext, useMemo, useState, useEffect } from "react";
// import Web3Modal /* , { local } */ from "web3modal";
// import WalletConnectProvider from "@walletconnect/web3-provider";
// import { Web3Provider } from "@ethersproject/providers";
// import { INFURA_ID } from "../constants/env";
// import { NETWORKS } from "../constants";

// // import { configureChains, createConfig, WagmiConfig } from 'wagmi'

// // import ether from "ethers";

// const WalletAugmentedContext = React.createContext();

// function useWalletAugmented() {
//   return useContext(WalletAugmentedContext);
// }

// function WalletAugmented({ children }) {
//   const [injectedProvider, setInjectedProvider] = useState("");
//   const networkRpcs = {};
//   Object.keys(NETWORKS).forEach(networkName => {
//     networkRpcs[NETWORKS[networkName].chainId] = NETWORKS[networkName].rpcUrl;
//   });
//   const web3Modal = new Web3Modal({
//     // network: "mainnet", // optional
//     cacheProvider: true, // optional
//     providerOptions: {
//       walletconnect: {
//         package: WalletConnectProvider, // required
//         options: {
//           infuraId: INFURA_ID,
//           rpc: networkRpcs,
//         },
//       },
//     },
//   });

//   // if web3modal is added to the dependency array it makes flick and bug the "dashboard"
//   const loadWeb3Modal = useCallback(async () => {
//     try {
//       const provider = await web3Modal.connect();
//       console.log("provider: ", provider)
//       setInjectedProvider(new Web3Provider(provider, "any"));
//     } catch (err) {
//       console.log("User closed wallet modal");
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [setInjectedProvider]);

//   // const logoutOfWeb3Modal = useCallback(async () => {
//   const logoutOfWeb3Modal = async () => {
//     web3Modal.clearCachedProvider();
//     localStorage.removeItem("walletconnect");
//     setTimeout(() => {
//       window.location.reload();
//     }, 1);
//     // }, [web3Modal]);
//   };

//   const contextValue = useMemo(
//     () => ({ injectedProvider, loadWeb3Modal, logoutOfWeb3Modal, web3Modal }),
//     [injectedProvider, web3Modal],
//   );

//   useEffect(() => {
//     if (web3Modal.cachedProvider) {
//       loadWeb3Modal();
//     }
//   }, [loadWeb3Modal]);

//   return <WalletAugmentedContext.Provider value={contextValue}>{children}</WalletAugmentedContext.Provider>;
// }

// function WalletProvider({ children }) {
//   return <WalletAugmented>{children}</WalletAugmented>;
// }

// export { useWalletAugmented as useWallet, WalletProvider };

///////////////////////////DOUBLE  ///////////////////////////
import React, { useCallback, useContext, useMemo, useState, useEffect } from "react";
import Web3Modal /* , { local } */ from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Web3Provider } from "@ethersproject/providers";
import { CHAIN_ID, INFURA_ID, WALLET_CONNECT_ID } from "../constants/env";
import { NETWORKS } from "../constants";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import Modal from "../components/v2/Modal";
import { ModalProvider } from "styled-react-modal";
import styled from "styled-components";
import { MetamaskNew, WalletConnect } from "../assets/svg";

// import ether from "ethers";

const StyledButton = styled.div`
  width: 320px;
  height: 48px;
  background: linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%);
  color: #ffffff;
  border-radius: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 14px;
  border: 0;
  cursor: pointer;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 23px;
  font-weight: 350;
  font-size: 14px;
  margin-bottom: 24px;
  justify-content: center;

  @media (max-width: 375px) {
    width: fit-content;
    padding-left: 24px;
    padding-right: 24px;
    span {
      display: none;
    }
  }
`;

const SelectModalHeader = styled.div`
  font-weight: 350;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  padding: 0;
  margin: 0;
  margin-bottom: 102px;
  @media (max-width: 480px) {
    margin-bottom: 48px;
    font-size: 24px;
  }
  @media (max-width: 375px) {
    margin-bottom: 24px;
    font-size: 18px;
  }
`;

const NewModal = ({ connectWithMetamask, connectWithWalletConnect, showModal, closeModal }) => {
  return (
    <Modal
      showModal={showModal}
      toggleModal={closeModal}
      customStyles={{
        height: "618px",
        width: "501px",
        padding: "30px",
      }}
      tabletStyles={{
        width: "90%",
        height: "400px",
      }}
      mobileStyles={{
        width: "90%",
        height: "320px",
      }}
      // beforeClose={closeModal}
    >
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <SelectModalHeader>Connect Your Wallet</SelectModalHeader>

        <StyledButton onClick={connectWithMetamask}>
          Connect with Metamask{" "}
          <span>
            <MetamaskNew />
          </span>
        </StyledButton>
        <StyledButton onClick={connectWithWalletConnect}>
          Connect with WalletConnect{" "}
          <span>
            <WalletConnect />
          </span>
        </StyledButton>
      </div>
    </Modal>
  );
};
const WalletAugmentedContext = React.createContext();

function useWalletAugmented() {
  return useContext(WalletAugmentedContext);
}

function WalletAugmented({ children }) {
  const [injectedProvider, setInjectedProvider] = useState("");
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const [walletChainChanged, setWalletChainChanged] = useState(undefined);

  const networkRpcs = {};

  const [providerClient, setProviderClient] = useState(undefined);

  // 2. Initialize sign client
  async function onInitializeProviderClient() {
    if (!EthereumProvider) return;
    try {
      const client = await EthereumProvider.init({
        projectId: WALLET_CONNECT_ID,
        showQrModal: true,
        qrModalOptions: { themeMode: "light" },
        chains: [CHAIN_ID],
        optionalChains: [137, 11155111],
        methods: ["eth_sendTransaction", "personal_sign"],
        events: ["chainChanged", "accountsChanged"],
        // metadata: {
        //   name: "My Dapp",
        //   description: "My Dapp description",
        //   url: "https://my-dapp.com",
        //   icons: ["https://my-dapp.com/logo.png"],
        // },
      });

      client.on("chainChanged", e => setWalletChainChanged(e));
      setProviderClient(client);
    } catch (err) {
      console.log("Error", err);
    }
  }

  async function connectWithWalletConnect() {
    try {
      if (providerClient) {
        await providerClient.connect();
        setInjectedProvider(new Web3Provider(providerClient, "any"));
      }
    } catch (err) {
      console.log("User cancelled request");
    }

    localStorage.setItem("connected_with", "wallet_connect");
    setShowSelectionModal(false);
  }

  async function connectWithMetamask() {
    try {
      const provider = await web3Modal.connect();
      setInjectedProvider(new Web3Provider(provider, "any"));
    } catch (err) {
      console.log("User closed wallet modal");
    }

    localStorage.setItem("connected_with", "metamask");
    setShowSelectionModal(false);
  }

  useEffect(() => {
    onInitializeProviderClient();
  }, []);
  Object.keys(NETWORKS).forEach(networkName => {
    networkRpcs[NETWORKS[networkName].chainId] = NETWORKS[networkName].rpcUrl;
  });
  const web3Modal = new Web3Modal({
    // network: "mainnet", // optional
    cacheProvider: true, // optional
    // providerOptions: {
    //   walletconnect: {
    //     package: WalletConnectProvider, // required
    //     options: {
    //       infuraId: INFURA_ID,
    //       rpc: networkRpcs,
    //     },
    //   },
    // },
  });

  // if web3modal is added to the dependency array it makes flick and bug the "dashboard"
  // const loadWeb3Modal = useCallback(async () => {
  //   try {
  //     const provider = await web3Modal.connect();
  //     setInjectedProvider(new Web3Provider(provider, "any"));
  //   } catch (err) {
  //     console.log("User closed wallet modal");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [setInjectedProvider]);
  const loadWeb3Modal = useCallback(
    async showQRIfRequired => {
      if (web3Modal.cachedProvider) {
        connectWithMetamask();
      } else if (providerClient && Array.isArray(providerClient?.accounts) && providerClient?.accounts?.length > 0) {
        // console.log("provider client: ", providerClient);
        setInjectedProvider(new Web3Provider(providerClient, "any"));
        // connectWithWalletConnect();
      } else if (showQRIfRequired) {
        // connectWithWalletConnect();
        displaySelectionModal();
      }
    },
    [setInjectedProvider, providerClient],
  );

  // const logoutOfWeb3Modal = useCallback(async () => {
  const logoutOfWeb3Modal = async () => {
    web3Modal.clearCachedProvider();
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("connected_with");
    if (providerClient && providerClient?.disconnect) {
      await providerClient.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    // }, [web3Modal]);
  };

  const displaySelectionModal = () => setShowSelectionModal(true);
  const contextValue = useMemo(
    () => ({
      injectedProvider,
      loadWeb3Modal,
      logoutOfWeb3Modal,
      web3Modal,
      displaySelectionModal,
      providerClient,
      connectWithMetamask,
      connectWithWalletConnect,
      walletChainChanged,
    }),
    [injectedProvider, web3Modal],
  );

  useEffect(() => {
    loadWeb3Modal();
  }, [loadWeb3Modal]);

  return (
    <WalletAugmentedContext.Provider value={contextValue}>
      <ModalProvider>
        <NewModal
          connectWithWalletConnect={connectWithWalletConnect}
          connectWithMetamask={connectWithMetamask}
          showModal={showSelectionModal}
          setShowModal={setShowSelectionModal}
          closeModal={() => setShowSelectionModal(false)}
        />
      </ModalProvider>
      {children}
    </WalletAugmentedContext.Provider>
  );
}

function WalletProvider({ children }) {
  return <WalletAugmented>{children}</WalletAugmented>;
}

export { useWalletAugmented as useWallet, WalletProvider };

// /////////////////////// GIVING EVENT EMITTER  ERROR///////////////
// import React, { useCallback, useContext, useMemo, useState, useEffect } from "react";
// import Web3Modal /* , { local } */ from "web3modal";
// import WalletConnectProvider from "@walletconnect/web3-provider";
// import { Web3Provider } from "@ethersproject/providers";
// import { INFURA_ID, WALLET_CONNECT_ID } from "../constants/env";
// import { NETWORKS } from "../constants";
// import { WalletConnectModalAuth, useSignIn } from "@walletconnect/modal-auth-react";
// // import ether from "ethers";

// const WalletAugmentedContext = React.createContext();

// function useWalletAugmented() {
//   return useContext(WalletAugmentedContext);
// }

// function WalletAugmented({ children }) {
//   const [injectedProvider, setInjectedProvider] = useState("");
//   const networkRpcs = {};
//   Object.keys(NETWORKS).forEach(networkName => {
//     networkRpcs[NETWORKS[networkName].chainId] = NETWORKS[networkName].rpcUrl;
//   });
//   const { signIn } = useSignIn({ statement: "Sign In to My Dapp" });
//   async function onSignIn() {
//     try {
//       const data = await signIn();
//       console.info(data);
//     } catch (err) {
//       console.error(err);
//     }
//   }
//   const web3Modal = new Web3Modal({
//     // network: "mainnet", // optional
//     cacheProvider: true, // optional
//     providerOptions: {
//       walletconnect: {
//         package: WalletConnectProvider, // required
//         options: {
//           infuraId: INFURA_ID,
//           rpc: networkRpcs,
//         },
//       },
//     },
//   });

//   // if web3modal is added to the dependency array it makes flick and bug the "dashboard"
//   const loadWeb3Modal = useCallback(async () => {
//     onSignIn();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [setInjectedProvider]);

//   // const logoutOfWeb3Modal = useCallback(async () => {
//   const logoutOfWeb3Modal = async () => {
//     web3Modal.clearCachedProvider();
//     localStorage.removeItem("walletconnect");
//     setTimeout(() => {
//       window.location.reload();
//     }, 1);
//     // }, [web3Modal]);
//   };

//   const contextValue = useMemo(
//     () => ({ injectedProvider, loadWeb3Modal, logoutOfWeb3Modal, web3Modal }),
//     [injectedProvider, web3Modal],
//   );

//   useEffect(() => {
//     if (web3Modal.cachedProvider) {
//       loadWeb3Modal();
//     }
//   }, [loadWeb3Modal]);

//   return <WalletAugmentedContext.Provider value={contextValue}>{children}</WalletAugmentedContext.Provider>;
// }

// function WalletProvider({ children }) {
//   return (
//     <WalletAugmented>
//       <WalletConnectModalAuth
//         projectId={WALLET_CONNECT_ID}
//         metadata={{
//           name: "My Dapp",
//           description: "My Dapp description",
//           url: "http://loalhost:3000",
//           icons: ["https://my-dapp.com/logo.png"],
//         }}
//       />
//       {children}
//     </WalletAugmented>
//   );
// }

// export { useWalletAugmented as useWallet, WalletProvider };

///////////////////////////////////////////// No Activity After Logging In ////////////////////////////////////////////////////////////////////

// import React, { useCallback, useContext, useMemo, useState, useEffect } from "react";
// import Web3Modal /* , { local } */ from "web3modal";
// import WalletConnectProvider from "@walletconnect/web3-provider";
// import { Web3Provider } from "@ethersproject/providers";
// import { INFURA_ID, WALLET_CONNECT_ID } from "../constants/env";
// import { NETWORKS } from "../constants";
// import { EthereumProvider } from "@walletconnect/ethereum-provider";

// // import ether from "ethers";

// const WalletAugmentedContext = React.createContext();

// function useWalletAugmented() {
//   return useContext(WalletAugmentedContext);
// }

// function WalletAugmented({ children }) {
//   const [injectedProvider, setInjectedProvider] = useState("");
//   const networkRpcs = {};

//   const [providerClient, setProviderClient] = useState(undefined);

//   // 2. Initialize sign client
//   async function onInitializeProviderClient() {
//     const client = await EthereumProvider.init({
//       projectId: WALLET_CONNECT_ID,
//       showQrModal: true,
//       qrModalOptions: { themeMode: "light" },
//       chains: [11155111],
//       methods: ["eth_sendTransaction", "personal_sign"],
//       events: ["chainChanged", "accountsChanged"],
//       // metadata: {
//       //   name: "My Dapp",
//       //   description: "My Dapp description",
//       //   url: "https://my-dapp.com",
//       //   icons: ["https://my-dapp.com/logo.png"],
//       // },
//     });
//     setProviderClient(client);
//   }

//   async function onConnect() {
//     if (providerClient) {
//       await providerClient.connect();
//       setInjectedProvider(new Web3Provider(providerClient, "any"));
//     } else {
//       throw new Error("providerClient is not initialized");
//     }
//   }

//   useEffect(() => {
//     onInitializeProviderClient();
//   }, []);
//   Object.keys(NETWORKS).forEach(networkName => {
//     networkRpcs[NETWORKS[networkName].chainId] = NETWORKS[networkName].rpcUrl;
//   });
//   const web3Modal = new Web3Modal({
//     // network: "mainnet", // optional
//     cacheProvider: true, // optional
//     providerOptions: {
//       walletconnect: {
//         package: WalletConnectProvider, // required
//         options: {
//           infuraId: INFURA_ID,
//           rpc: networkRpcs,
//         },
//       },
//     },
//   });

//   // if web3modal is added to the dependency array it makes flick and bug the "dashboard"
//   // const loadWeb3Modal = useCallback(async () => {
//   //   try {
//   //     const provider = await web3Modal.connect();
//   //     setInjectedProvider(new Web3Provider(provider, "any"));
//   //   } catch (err) {
//   //     console.log("User closed wallet modal");
//   //   }
//   //   // eslint-disable-next-line react-hooks/exhaustive-deps
//   // }, [setInjectedProvider]);
//   const loadWeb3Modal = () => {
//     onConnect();
//   };

//   // const logoutOfWeb3Modal = useCallback(async () => {
//   const logoutOfWeb3Modal = async () => {
//     web3Modal.clearCachedProvider();
//     localStorage.removeItem("walletconnect");
//     setTimeout(() => {
//       window.location.reload();
//     }, 1);
//     // }, [web3Modal]);
//   };

//   const contextValue = useMemo(
//     () => ({ injectedProvider, loadWeb3Modal, logoutOfWeb3Modal, web3Modal }),
//     [injectedProvider, web3Modal],
//   );

//   useEffect(() => {
//     if (web3Modal.cachedProvider) {
//       loadWeb3Modal();
//     }
//   }, [loadWeb3Modal]);

//   return <WalletAugmentedContext.Provider value={contextValue}>{children}</WalletAugmentedContext.Provider>;
// }

// function WalletProvider({ children }) {
//   return <WalletAugmented>{children}</WalletAugmented>;
// }

// export { useWalletAugmented as useWallet, WalletProvider };

/////////////////////////// WalletConnect Modal  (Needs a uri) ///////////////////////////
// import React, { useCallback, useContext, useMemo, useState, useEffect } from "react";
// import Web3Modal /* , { local } */ from "web3modal";
// import WalletConnectProvider from "@walletconnect/web3-provider";
// import { Web3Provider } from "@ethersproject/providers";
// import { INFURA_ID, WALLET_CONNECT_ID } from "../constants/env";
// import { NETWORKS } from "../constants";
// import { WalletConnectModal } from '@walletconnect/modal'

// // import ether from "ethers";

// const WalletAugmentedContext = React.createContext();

// function useWalletAugmented() {
//   return useContext(WalletAugmentedContext);
// }

// const walletConnectModal = new WalletConnectModal({
//   projectId: WALLET_CONNECT_ID,
//   chains: ['eip155:1']
// })

// function WalletAugmented({ children }) {
//   const [injectedProvider, setInjectedProvider] = useState("");
//   const networkRpcs = {};
//   Object.keys(NETWORKS).forEach(networkName => {
//     networkRpcs[NETWORKS[networkName].chainId] = NETWORKS[networkName].rpcUrl;
//   });
//   const web3Modal = new Web3Modal({
//     // network: "mainnet", // optional
//     cacheProvider: true, // optional
//     providerOptions: {
//       walletconnect: {
//         package: WalletConnectProvider, // required
//         options: {
//           infuraId: INFURA_ID,
//           rpc: networkRpcs,
//         },
//       },
//     },
//   });

//   // if web3modal is added to the dependency array it makes flick and bug the "dashboard"
//   const loadWeb3Modal = useCallback(async () => {
//     try {
//       const res = await walletConnectModal.openModal({
//         uri: 'http://localhost:3000'
//       });

//       console.log("res==>  ", res)
//       // setInjectedProvider(new Web3Provider(provider, "any"));
//     } catch (err) {
//       console.log("User closed wallet modal");
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [setInjectedProvider]);

//   // const logoutOfWeb3Modal = useCallback(async () => {
//   const logoutOfWeb3Modal = async () => {
//     web3Modal.clearCachedProvider();
//     localStorage.removeItem("walletconnect");
//     setTimeout(() => {
//       window.location.reload();
//     }, 1);
//     // }, [web3Modal]);
//   };

//   const contextValue = useMemo(
//     () => ({ injectedProvider, loadWeb3Modal, logoutOfWeb3Modal, web3Modal }),
//     [injectedProvider, web3Modal],
//   );

//   useEffect(() => {
//     if (web3Modal.cachedProvider) {
//       loadWeb3Modal();
//     }
//   }, [loadWeb3Modal]);

//   return <WalletAugmentedContext.Provider value={contextValue}>{children}</WalletAugmentedContext.Provider>;
// }

// function WalletProvider({ children }) {
//   return <WalletAugmented>{children}</WalletAugmented>;
// }

// export { useWalletAugmented as useWallet, WalletProvider };
