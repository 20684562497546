import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Modal from "../../Modal";
import * as S from "./styles";
import { GreenCheckMark, RedCross } from "../../../../assets/svg";

const CodeInput = styled.input`
  width: 50px;
  height: 50px;
  color: #fff;
  font-size: 24px;
  font-weight: 325;
  line-height: 36px;
  letter-spacing: 2px;
  text-align: center;
  border-radius: 6px;
  background-image: url(/assets/svg/border-input.svg);
  background-color: #342788;
  border: none;
  text-transform: uppercase;
  & {
    :valid {
      background-color: #3f2faa;
    }
  }
  :disabled {
    opacity: 0.5;
  }
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainContainer = styled.div`
  width: 660px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

function InvitationCodeModal({ showModal, toggleModal, sendJoinCode, invalidCode }) {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [codeInput, setCodeInput] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(false);

  /**
   * If an error occurs while sending the code pool, log the error and set the hasSubmitted state to
   * false.
   */
  const handleError = () => {
    console.log("Error occured while sending code pool");
    setHasSubmitted(false);
  };

  /**
   * The handleSubmit function is called when the user clicks the submit button. It sets the hasSubmitted
   * state to true, and then calls the sendJoinCode function, which sends the code to the backend.
   */
  const handleSubmit = async codeConverted => {
    if(hasSubmitted) return; // Prevent multiple submissions
    setHasSubmitted(true);
    const codeReceived = codeConverted;
    try {
      await sendJoinCode({ invitationalCode: codeReceived }, handleError);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setHasSubmitted(false);
    }
  };

  /**
   * "If the code is invalid and the user has not submitted, return an empty string. If the code is
   * invalid and the user has submitted, return a red cross. If the code is valid and the user has not
   * submitted, return nothing. If the code is valid and the user has submitted, return a green check
   * mark."
   */
  const codeIconRender = (invalidCodeOnSubmit, hasSubmitted) => {
    if (hasSubmitted === true && invalidCodeOnSubmit === false) {
      return <GreenCheckMark />;
    } else if (hasSubmitted === true && invalidCodeOnSubmit === true) {
      return <RedCross width="17px" height="17px" />;
    }
    return;
  };

  /**
   * If the user has not submitted the form, return nothing. If the user has submitted the form and the
   * code is invalid, return a message. If the user has submitted the form and the code is valid, return
   * a different message.
   * @returns a component.
   */
  const messageRender = (invalidCodeOnSubmit, hasSubmitted) => {
    if (hasSubmitted === true && invalidCodeOnSubmit === true) {
      return <S.Message color="#BB5959">The CODE is invalid. Please try again </S.Message>;
    }
    if (hasSubmitted === true && invalidCodeOnSubmit === false) {
      return <S.Message>Valid Code</S.Message>;
    }
    return "";
  };

  // Using callbackRefs to change codeInput state after the wrapper node has been rendered and is available
  const wrapper = useCallback(node => {
    if (node) {
      setCodeInput(document.querySelectorAll(".code"));
    }
  }, []);

  // Adding event listeners to codeInput after the element is available
  useEffect(() => {
    codeInput.forEach((input, index) => {
      input.addEventListener("keyup", e => {
        if (e.key === "Backspace" && e.target.value.length === 0) {
          // If backspace is pressed and the input is empty, prevent focusing the previous input
          if (index > 0) {
            e.preventDefault();
          }
          // Focus the current input
          input.focus();
        } else if (e.target.value.length === 1 && e.target.nextElementSibling) {
          // If a character is typed and there is a next input, focus the next input
          e.target.nextElementSibling.focus();
        }

        // Handle form submission when the last input is filled
        if (index === codeInput.length - 1) {
          let codeModified = "";
          codeInput.forEach(input => {
            codeModified += input.value;
          });

          if (codeModified.length === 5) {
            setIsLoading(true);
            handleSubmit(codeModified);
            setDisplayMessage(true);
          } else {
            setDisplayMessage(false);
          }
        }
      });

      input.addEventListener("keydown", e => {
        if (e.key === "Backspace" && e.target.value.length === 0) {
          // If backspace is pressed and the input is empty, prevent focusing the previous input
          e.target.previousElementSibling.focus();
        }
      });
    });

    return () => {
      setDisplayMessage(false);
    };
  }, [codeInput]);

  useEffect(() => {
    messageRender(invalidCode, hasSubmitted);
    codeIconRender(invalidCode, hasSubmitted);
  }, [invalidCode]);
  return (
    <Modal showModal={showModal} toggleModal={toggleModal} disabled={hasSubmitted}>
      <MainContainer>
        <S.TitleWrapper>
          <S.Title>Enter a valid code to continue</S.Title>
          <S.Description>
            This is an invitational tournament or event, you need an access code to join in. If you don&apos;t have one
            yet, please contact the tournament organizer.
          </S.Description>
        </S.TitleWrapper>
        <FormWrapper>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px" }}>
            <FormContainer>
              <S.InputSection ref={wrapper}>
                <CodeInput disabled = {isLoading} type="text" className="code" maxLength="1" required autoFocus />
                <CodeInput disabled = {isLoading} type="text" className="code" maxLength="1" required />
                <CodeInput disabled = {isLoading} type="text" className="code" maxLength="1" required />
                <CodeInput disabled = {isLoading} type="text" className="code" maxLength="1" required />
                <CodeInput disabled = {isLoading} type="text" className="code" maxLength="1" required />
              </S.InputSection>
            </FormContainer>
            <S.IconContainer>
              {isLoading === true ? <S.loader /> : displayMessage ? codeIconRender(invalidCode, hasSubmitted) : null}
            </S.IconContainer>
          </div>
          <div>
            {isLoading === true ? (
              <S.Message>We are validating your code.</S.Message>
            ) : displayMessage ? (
              messageRender(invalidCode, hasSubmitted)
            ) : null}
          </div>
        </FormWrapper>
      </MainContainer>
    </Modal>
  );
}

export default InvitationCodeModal;
