export const modRoundsFunc = roundsData => {
  return roundsData.map((roundData, index) => ({
    id: roundData.id,
    title: `Round ${index + 1}`,
    seeds: roundData.matches.map(match => ({
      id: match.id,
      date: new Date().toDateString(),
      teams: [
        {
          name: match.p1Nickname,
          position: match.p1Position,
          address: match.p1Address,
          aTag: match.p1aTag,
          avatar: match.p1AvatarUrl,
          replayURLS: match.replayURLS,
          winner: match.winner,
          isCheckedIn: match.p1CheckedIn,
          results: match.p1Results,
          challengePlayer: match.challengePlayer,
          matchStatus: match.status,
          teamMembers: match.p1TeamMembers,
          playerId: match.p1PlayerId,
        },
        {
          name: match.p2Nickname,
          position: match.p2Position,
          address: match.p2Address,
          aTag: match.p2aTag,
          avatar: match.p2AvatarUrl,
          replayURLS: match.replayURLS,
          winner: match.winner,
          isCheckedIn: match.p2CheckedIn,
          results: match.p2Results,
          challengePlayer: match.challengePlayer,
          matchStatus: match.status,
          teamMembers: match.p2TeamMembers,
          playerId: match.p2PlayerId,
        },
      ],
    })),
    endDate: roundData.endDate,
    startDate: roundData.startDate,
    status: roundData.status,
    mode: roundData.mode || null,
    pageNum: roundData.pageNum || null,
  }));
};
