/* eslint-disable no-else-return */
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import styled from "styled-components";
// import { Switch, Popover } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { useRecoilState } from "recoil";
import * as crypto from "crypto-js";
// import { serializeTransaction } from "ethers/lib/utils";
import * as S from "./styles";
import Avatar from "../../components/v2/common/Avatar";
import profileImg from "../../assets/profile-page-background.png";
import DecoratedAvatar from "../../components/v2/common/Avatar/DecoratedAvatar";
import ProfileSettings from "./ProfileSettings";
// import customCursor from "../../assets/svg/not-editable-cursor.svg";
import {
  BlastLogoGradient,
  DiamondIcon,
  DiscordAlt,
  DiscordIcon,
  DiscordSquare,
  FacebookBlueSquare,
  GoogleWhiteSquare,
  MailIcon,
  TwitterBlueSquare,
} from "../../assets/svg";
import {
  // TwitterIcon,
  // InstagramIcon,
  // FacebookIcon,
  // ActivisionLogo,
  // NintendoSwitchLogo,
  // PlayStationLogo,
  // XBoxLogo,
  // SteamLogo,
  SilhouetteBadge,
  SilhouetteTrophy,
  IronBadge,
  GoldBadge,
  BronzeBadge,
  SilverBadge,
  PlatinumBadge,
  DiamondBadge,
} from "../../assets/svg";
import { Button } from "../../components/v2/common/Button";
// import { breakpoints } from "../../themes";
import { sessionState } from "../../state/globalState";
import { useUserInformation } from "../../hooks/UserInformation";
import BlastSpin from "../../components/v2/common/Spin";
import { useEffect } from "react";
import AccountCard from "./AccountCard";
import useCallAxios from "../../hooks/useAxiosCall";
import useQuery from "../../hooks/useQuery";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ENDPOINT } from "../../constants/endpoints";
import { calculateEarnings } from "../../helpers/utils";
import AccountBanner from "./AccountBanner";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
} from "recharts";
import ProfileStats from "./ProfileStats";
import TournamentsSection from "./TournamentsSection";
import RequestRow from "./RequestRow";
import PrizeSection from "./PrizeSection";
import Flex from "../../components/v2/common/Flex";
import { TextNoMargin } from "../../components/v2/common/Text";
import { useMedia } from "use-media";
import { useGetSecHeaders } from "../../hooks";
import { useWallet } from "../../providers/WalletProvider";
import { useRef } from "react";
import { useContext } from "react";
import { RequestContext } from "../../providers/RequestProvider";
import { BLAST_DISCORD_ALT, TOAST_OPTIONS } from "../../constants";
import { useWindowSize } from "../../hooks/useWindowSize";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../../components/v2/common/SpinningLoader";
import ConnectAccounts from "./ConnectAccounts";
import { setUserInLocalStorage } from "../../helpers/localStorage";
import { SEED } from "../../constants/env";
import { MasonryResponsive } from "../../components/v2/common/Masonry";
import Masonry from "../../components/v2/common/Masonry";

const data = [
  {
    name: "Page A",
    uv: "0",
    pv: 800,
    amt: 0,
    cnt: 490,
  },
  {
    name: "Page B",
    uv: "",
    pv: 967,
    amt: 0,
    cnt: 590,
  },
  {
    name: "Page C",
    uv: "",
    pv: 1098,
    amt: 0,
    cnt: 350,
  },
  {
    name: "Page D",
    uv: "",
    pv: 1200,
    amt: 0,
    cnt: 480,
  },
  {
    name: "Page E",
    uv: "",
    pv: 1108,
    amt: 0,
    cnt: 460,
  },
  {
    name: "Page F",
    uv: "",
    pv: 680,
    amt: 0,
    cnt: 380,
  },
  {
    name: "Page G",
    uv: "",
    pv: 680,
    amt: 0,
    cnt: 380,
  },
  {
    name: "Page H",
    uv: "",
    pv: 150,
    amt: 0,
    cnt: 380,
  },
];
const style = {
  width: "55px",
  height: "25px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  backgroundColor: "#4E3CCA",
  borderRadius: "6px",
  border: "none",
  fontWeight: "350",
  fontSize: "14px",
};
const CustomTooltip = () => {
  return (
    <div>
      <p style={style}>$ 0</p>
    </div>
  );
};

const PopoverContent = ({ id, close, checkSwitch, setCheckSwitch }) => {
  // const handleConnect = () => {
  //   setCheckSwitch(prev => ({ ...prev, [id]: !prev[id] }));
  //   close();
  // };

  return (
    <S.PopoverContentWrap
      style={{
        width: "100%",
        marginBottom: "10px",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <S.PopoverText>
        In case you need any assistance with your gaming account, please contact us over discord.
      </S.PopoverText>
      <S.DiscordLinkWrapper style={{ marginLeft: "10px" }}>
        <a href={BLAST_DISCORD_ALT} target="_blank" rel="noopener noreferrer">
          <DiscordIcon style={{ color: "black" }} />
        </a>
      </S.DiscordLinkWrapper>
    </S.PopoverContentWrap>
  );
};

// const gamingAccounts = [
//   {
//     id: "activision",
//     icon: <ActivisionLogo />,
//   },
//   {
//     id: "playstation",
//     icon: <PlayStationLogo />,
//   },
//   {
//     id: "nintendoswitch",
//     icon: <NintendoSwitchLogo />,
//   },
//   {
//     id: "xbox",
//     icon: <XBoxLogo />,
//   },
//   {
//     id: "steam",
//     icon: <SteamLogo />,
//   },
// ];

// TODO: Replace with the real data
// const playerRequests = [];

const players = [
  {
    reference: "6d6ba128-904e-4149-a2ac-de55891c6777",
    nick: "HamzahAhmad",
    thumbnail:
      "https://storage.googleapis.com/dev-blast.appspot.com/assets/users/16a62195-2fa5-4443-940d-612e50f7e620/16a62195-2fa5-4443-940d-612e50f7e620.png",
    role: "captain",
    teamId: "1da3ca98-3729-4dee-9a3f-448fbbb8b2c6",
    id: "6d6ba128-904e-4149-a2ac-de55891c6777",
    createdOn: 1679566631631,
    userId: "16a62195-2fa5-4443-940d-612e50f7e620",
    status: "accepted",
  },
  {
    reference: "7e36cd15-d67f-4899-930b-9acb368f21b4",
    nick: "newton",
    thumbnail: "",
    role: "member",
    teamId: "1da3ca98-3729-4dee-9a3f-448fbbb8b2c6",
    id: "7e36cd15-d67f-4899-930b-9acb368f21b4",
    createdOn: 1679669988173,
    userId: "4829b5b9-58ee-4c13-a55e-7458e55669d5",
    status: "pending-accept",
  },
];

const prizes = [
  {
    icon: <DiamondIcon />,
    text: "Ensure a place in a limited tournament",
    percent: 56,
  },
];

export function ProviderImage({ provider }) {
  if (!provider) return null;

  if (provider === "facebook.com") return <FacebookBlueSquare />;
  else if (provider === "twitter.com") return <TwitterBlueSquare />;
  else if (provider === "discord") return <DiscordSquare />;
  else if (provider === "google.com") return <GoogleWhiteSquare />;
  else return null;
}

export function providerToNameMap(provider) {
  switch (provider) {
    case "google.com":
      return "Google";

    case "facebook.com":
      return "Facebook";

    case "twitter.com":
      return "Twitter";

    case "discord":
      return "Discord";

    default:
      return provider;
  }
}

export default function ProfilePage({ requiredGameId, afterConnectCb }) {
  const history = useHistory();

  const [session, setSession] = useRecoilState(sessionState);

  const {
    setAvatar,
    setUserEmail,
    setUserNickname,
    setUserDiscordUsername,
    setEmailAndDiscord,
    removeAvatar,
    isFetching,
  } = useUserInformation();
  const [action, setAction] = useState("");
  const [tournamentsPlayed, setTournamentsPlayed] = useState(0);
  const [emailState, setEmailState] = useState("");
  const [discordState, setDiscordState] = useState("");
  const [currentUserLevel, setCurrentUserLevel] = useState("");
  const [levelIndex, setLevelIndex] = useState(-1); // using -1 here as 0 matches the index for "iron" rank inside the levels array. This causes flickering
  const [allowEdit, setAllowEdit] = useState(false);
  const [activeGames, setActiveGames] = useState([]);
  const [isLoadingIntegrations, setIsLoadingIntegrations] = useState(false);
  const [activeTab, setActiveTab] = useState("Tournaments");
  const [playerReqests, setPlayerRequests] = useState([]);
  const [playerStats, setPlayerStats] = useState(null);
  const [isPlayerStatsLoading, setIsPlayerStatsLoading] = useState(false);
  const [isLoadingUserActivity, setIsLoadingUserActivity] = useState(false);
  const [userEarnings, setUserEarnings] = useState(0);
  const apiCalled = useRef(false); // Ref component to prevent multiple API calls
  const { isMobile } = useWindowSize();

  const headers = useGetSecHeaders();
  const { callAxios } = useCallAxios();
  const { injectedProvider } = useWallet();

  const requestUseContext = useContext(RequestContext);

  const { setUserTeamsRequests, userTeamsRequests, totalRequests, setTotalRequests } = requestUseContext;

  const tournamentsSectionRef = useRef(null);

  const levels = [
    { userLevel: "iron", image: <IronBadge /> },
    { userLevel: "bronze", image: <BronzeBadge /> },
    { userLevel: "silver", image: <SilverBadge /> },
    { userLevel: "gold", image: <GoldBadge /> },
    { userLevel: "platinum", image: <PlatinumBadge /> },
    { userLevel: "diamond", image: <DiamondBadge /> },
  ];

  const userStats = session?.user?.stats;
  const userLevel = session?.user?.userLevel;
  const user = session?.user;

  useEffect(() => {
    if (userStats) {
      let item = userStats.find(item => item.name === "POOLS_PLAYED");
      let val = item ? item.value : 0;
      setTournamentsPlayed(val);
    }
    if (userLevel) {
      setCurrentUserLevel(userLevel);
    }
  }, [user, userStats, userLevel]);

  useEffect(() => {
    if (!session) {
      history.push("/");
    }
  }, [session?.user]);

  useEffect(() => {
    if (currentUserLevel) {
      setLevelIndex(levels.findIndex(item => item.userLevel === currentUserLevel.toLowerCase()));
    }
  }, [currentUserLevel, levels]);

  const acceptJoinInvite = async (teamIdArg, status, setLoader = () => {}) => {
    setLoader(true);
    const response = await callAxiosFunc({
      method: "POST",
      url: `team/join/${teamIdArg}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      body: JSON.stringify({ status }),
    });
    setLoader(false);

    if (response && response.err) {
      toast("Something went wrong. Please try again", TOAST_OPTIONS);
      return { err: response.err };
    } else {
      setUserTeamsRequests(userTeamsRequests?.filter(req => req.teamId !== teamIdArg));
      if (totalRequests > 0) {
        setTotalRequests(totalRequests - 1);
      }
    }
  };

  const { callAxiosFunc } = useCallAxios();

  const updateUserAccounts = (gamingAccounts = []) => {
    const updatedSession = {
      ...session,
      user: {
        ...session.user,
        accounts: gamingAccounts,
      },
    };
    setSession(updatedSession);
    const encryptedState = crypto.AES.encrypt(JSON.stringify(updatedSession), SEED).toString();
    setUserInLocalStorage(encryptedState);
  };

  const isSmallScreen = useMedia({ maxWidth: "1200px" });

  async function getGamesList() {
    let res = await callAxiosFunc({
      method: "GET",
      url: `${ENDPOINT.GAME}`,
    });

    setActiveGames(res?.data || []);
  }

  async function getProfileState() {
    const res = await callAxiosFunc({
      method: "GET",
      url: "/profile",
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
    });

    return res;
  }

  async function getProfileActivity() {
    setIsLoadingUserActivity(true);
    const res = await callAxiosFunc({
      method: "GET",
      url: "/profile/activity",
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
    });
    setIsLoadingUserActivity(false);
    return res;
  }

  async function getUserEarnings() {
    if (!headers.encryptedHeader) return;
    try {
      const response = await callAxiosFunc({
        method: "GET",
        url: `/user/myEarnings`,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      });
      if (response && response.data && response.data?.myEarnings) {
        setUserEarnings(response?.data?.myEarnings);
      }
    } catch (error) {
      console.log({ error });
    }
  }
  useEffect(() => {
    if (!session || !session.user || !headers.token) return;
    try {
      setIsLoadingIntegrations(true);
      getGamesList().then(() => setIsLoadingIntegrations(false));
    } catch (err) {
      console.log(err);
    }
  }, [headers.token]);

  useEffect(() => {
    if (!session || !session.user || !headers.token) return;
    if (headers.encryptedHeader && !apiCalled.current) {
      apiCalled.current = true;
      setIsPlayerStatsLoading(true);
      getProfileState()
        .then(res => {
          setPlayerStats(res.data);
          updateUserAccounts(res?.data?.gamingAccounts);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setIsPlayerStatsLoading(false);
        });

      getUserEarnings();
    }
  }, [session?.user?.id]);

  if (requiredGameId) {
    let gameFound = activeGames.find(game => game.id === requiredGameId);
    if (isLoadingIntegrations || !gameFound)
      return (
        <S.GameCardInModalContainer>
          <SpinnerLoader />
        </S.GameCardInModalContainer>
      );
    return (
      <S.GameCardInModalContainer>
        <p>Let's start connecting your gaming account to join the tournament</p>
        {gameFound ? <AccountCard gameData={gameFound} afterConnectCb={afterConnectCb} isToggled /> : null}
      </S.GameCardInModalContainer>
    );
  }
  return (
    <>
      <ToastContainer />
      <S.SectionContainer>
        <S.Section>
          <AccountBanner user={session?.user} setUserNickname={setUserNickname} setAvatar={setAvatar} />
          <S.InfoSection>
            <S.ClaimsBlock>
              {isPlayerStatsLoading ? (
                <SpinnerLoaderWrapper>
                  <SpinnerLoader style={{ width: "30px", height: "30px" }} />
                </SpinnerLoaderWrapper>
              ) : (
                <>
                  <Flex direction="column" justifyContent="space-between" extraStyles={{ height: "100%" }}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <TextNoMargin fontSize="18px">Your earnings</TextNoMargin>
                      <Button
                        onClick={() => {
                          history.push("/");
                        }}
                        width="106px"
                        background="#ffffff4d"
                        borderRadius="56px"
                      >
                        Claim
                      </Button>
                    </Flex>
                    <div>
                      <TextNoMargin fontSize="16px" color="#ffffff80">
                        Your Balance
                      </TextNoMargin>
                      <div style={{ fontSize: "50px" }}>
                        {typeof userEarnings === "number"
                          ? `$${userEarnings?.toFixed(4)}`
                          : `$${userEarnings === "-" ? 0 : userEarnings}`}
                      </div>
                    </div>
                  </Flex>
                  <div style={{ position: "absolute", bottom: "-20px", right: "-20px" }}>
                    <BlastLogoGradient style={{ opacity: "0.1" }} />
                  </div>
                </>
              )}
            </S.ClaimsBlock>
            <S.StatsGraph>
              {isPlayerStatsLoading ? (
                <SpinnerLoaderWrapper>
                  <SpinnerLoader style={{ width: "30px", height: "30px" }} />
                </SpinnerLoaderWrapper>
              ) : (
                <ResponsiveContainer width="100%" height={isSmallScreen ? 200 : "100%"}>
                  <ComposedChart
                    width={800}
                    height={300}
                    data={data}
                    margin={{ top: 50, right: 50, left: 50, bottom: 10 }}
                  >
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="15%" stopColor="#BE7EFF" stopOpacity={0.6} />
                        <stop offset="85%" stopColor="#3F2FAA00" stopOpacity={0.4} />
                      </linearGradient>
                    </defs>
                    <XAxis
                      dataKey="uv"
                      tick={{ fill: "#fff", fontSize: "14px", fontWeight: "400" }}
                      tickLine={{ strokeWidth: 0 }}
                      axisLine={{ strokeWidth: 0 }}
                      style={{ marginTop: "24px" }}
                    />

                    <Tooltip
                      content={<CustomTooltip />}
                      position={{ y: 20 }}
                      cursor={{ stroke: "#BE7EFF", strokeWidth: 1 }}
                    />

                    <Area
                      type="monotone"
                      dataKey="amt"
                      stroke="#BE7EFF"
                      strokeWidth={3}
                      fillOpacity={1}
                      fill="url(#colorUv)"
                      isAnimationActive={false}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              )}
            </S.StatsGraph>
          </S.InfoSection>

          {playerStats ? <ProfileStats stats={playerStats?.stats} /> : null}

          {isLoadingIntegrations || isPlayerStatsLoading ? (
            <SpinnerLoaderWrapper style={{ marginTop: "20px" }}>
              <SpinnerLoader style={{ width: "30px", height: "30px" }} />
            </SpinnerLoaderWrapper>
          ) : (
            <>
              <S.PageTitle>Gaming Accounts</S.PageTitle>
              <MasonryResponsive columnsCountBreakPoints={{ 320: 1, 768: 2, 1280: 3 }}>
                <Masonry>
                  {activeGames.length > 0 &&
                    activeGames.map(game => game.accountRequired && <AccountCard gameData={game} />)}
                </Masonry>
              </MasonryResponsive>
            </>
          )}

          {session?.user && (
            <>
              <S.PageTitle style={{ marginTop: "66px" }}>Connected Accounts</S.PageTitle>

              {session?.user?.extProvider ? (
                <S.ConnectedAccountRow>
                  <Flex alignItems="center" columnGap="23">
                    <ProviderImage provider={session?.user?.extProvider} />
                    {providerToNameMap(session?.user?.extProvider)}
                  </Flex>
                  <TextNoMargin color="#A498ED">Connected</TextNoMargin>
                </S.ConnectedAccountRow>
              ) : (
                <ConnectAccounts session={session} headers={headers} />
              )}
            </>
          )}
        </S.Section>
       {session &&  <S.Section>
          <S.PageTitle style={{ marginTop: "66px" }}>Profile Settings</S.PageTitle>
          <ProfileSettings session={session} />
        </S.Section>}
      </S.SectionContainer>
    </>
  );
}
