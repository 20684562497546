import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { breakpoints } from "../../../themes";
import { InstagramIcon, TwitterIcon, DiscordIcon } from "../../../assets/svg";
import { BLAST_DISCORD_ALT } from "../../../constants";

const FooterWrapper = styled.div`
  ${breakpoints.down(breakpoints.tablet)} {
    display: none;
  }
  bottom: 0;
  background-color: #201366;
  padding: 17px 45px 17px 45px;
  padding-right: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  width: 100vw;
  z-index: 500;

  ${props => `
  padding-left: ${props.collapseSidebar ? "20px" : "140px"};
  bottom: ${props.visible ? "0" : "-200px"};
  // width: calc(100vw - ${props.collapseSidebar ? "198px" : "324px"});
`}

  transition: all 0.1s linear;
  border-top: 1px solid #36278a;

  ${breakpoints.down(breakpoints.tablet)} {
    padding: 17px 20px 17px 20px;
    width: 100vw;
    justify-content: space-between;
  }

  ${breakpoints.up(breakpoints.tablet)} {
    width: 100vw;
  }
`;

const LeftWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  ${breakpoints.up(breakpoints.tablet)} {
    padding-left: 180px;
  }
`;

const RightWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Copyright = styled.p`
  color: #897dd3;
  font-weight: 350;
  font-size: 14px;
  line-height: 28px;
  margin: 0;
  margin-left: 40px;
`;

const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const Links = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
  ${breakpoints.up(breakpoints.laptop)} {
    justify-content: flex-start;
  }
`;

const Link = styled.a`
  font-weight: 350;
  font-size: 13px;
  line-height: 28px;
  color: #897dd3;
  display: flex;
  align-items: center;
  &:hover {
    color: #fff;
  }
`;

const InstagramIconWithHover = styled(InstagramIcon)`
  path {
    fill: #897dd3;
  }

  &:hover {
    path {
      fill: #fff;
    }
  }
`;

const TwitterIconWithHover = styled(TwitterIcon)`
  path {
    fill: #897dd3;
  }

  &:hover {
    path {
      fill: #fff;
    }
  }
`;

const DiscordIconWithHover = styled(DiscordIcon)`
  path {
    fill: #897dd3;
  }

  &:hover {
    path {
      fill: #fff;
    }
  }
`;

export default function Footer({ collapseSidebar, bodyRef }) {
  const [visible, setVisible] = useState(false);
  // function that gets the actual year
  const getYear = () => {
    const date = new Date();
    return "blast © " + date.getFullYear();
  };

  function debounce(func, wait, immediate) {
    let timeout;
    return function () {
      const context = this;
      const args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
  const handleScroll = debounce(e => {
    setVisible(e.srcElement.scrollHeight - e.srcElement.scrollTop == e.srcElement.clientHeight);
  }, 1);

  useEffect(() => {
    if (bodyRef && bodyRef.current) bodyRef.current.addEventListener("scroll", handleScroll);
    const bodyRefCleanup = bodyRef.current;
    return () => {
      if (bodyRef && bodyRefCleanup) {
        bodyRefCleanup.removeEventListener("scroll", handleScroll);
      }
    };
  }, [visible, handleScroll, bodyRef]);

  return (
    <FooterWrapper visible={visible} collapseSidebar={collapseSidebar}>
      <LeftWrapper>
        <Links>
          <Link href="https://blast.gg/privacy-policy/" target="_blank">
            Privacy Policy
          </Link>
          <Link href="https://blast.gg/terms" target="_blank">
            Terms of Service
          </Link>
          <Link href="mailto:hi@blast.gg" target="_blank">
            hi@blast.gg
          </Link>
        </Links>
      </LeftWrapper>
      <RightWrapper>
        <SocialMedia>
          <Link href="https://www.instagram.com/blastgmi" target="_blank">
            <InstagramIconWithHover width="18px" height="18px" />
          </Link>
          <Link href="https://twitter.com/BlastGMI" target="_blank">
            <TwitterIconWithHover width="18px" height="18px" />
          </Link>
          <Link href={BLAST_DISCORD_ALT} target="_blank">
            <DiscordIconWithHover width="18px" height="18px" />
          </Link>
        </SocialMedia>
        <Copyright>{getYear()}</Copyright>
      </RightWrapper>
    </FooterWrapper>
  );
}
