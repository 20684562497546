import React from "react";
import * as S from "../styles";
import PlayerRow from "../PlayerRow/PlayerRow";
import Pagination from "../../../Pagination";
import img1 from "../../../../../assets/leaderboard-img-1.png";
import img2 from "../../../../../assets/leaderboard-img-2.png";
import img3 from "../../../../../assets/leaderboard-img-3.png";
import { LEADERBOARD_PAGE_LIMIT, LEADERBOARD_ROWS, MOBILE_LEADERBOARD_PAGE_LIMIT } from "../../../../../constants/env";

const images = [img1, img2, img3];
const randomIndex = Math.floor(Math.random() * 2);

export default function LeaderBoardComponent({
  leaderBoardInfo,
  isMobile,
  gamePlatform,
  joinMode,
  selectPlayerForTemplate,
  hasTemplate,
  groupIdSelected,
}) {
  return leaderBoardInfo.length > 0 ? (
    <>
      {!isMobile && (
        <S.RowHeaderWrapper>
          <S.HeaderText>Ranking</S.HeaderText>
          <S.HeaderText>Nick</S.HeaderText>
          <S.HeaderTextRightAlign>Top Score</S.HeaderTextRightAlign>
          <S.HeaderTextRightAlign>Top Kills</S.HeaderTextRightAlign>
          <S.HeaderTextRightAlign>Team Pos.</S.HeaderTextRightAlign>
          <S.HeaderTextRightAlign>K/D</S.HeaderTextRightAlign>
          {gamePlatform === "pubg" ? (
            <S.HeaderTextRightAlign>Damage</S.HeaderTextRightAlign>
          ) : (
            <S.HeaderTextRightAlign>Damage D/T</S.HeaderTextRightAlign>
          )}
        </S.RowHeaderWrapper>
      )}
      <Pagination
        data={leaderBoardInfo}
        isMobile={isMobile}
        RenderComponent={props => (
          <PlayerRow
            {...props}
            joinMode={joinMode}
            selectPlayerForTemplate={selectPlayerForTemplate}
            hasTemplate={hasTemplate}
            groupIdSelected={groupIdSelected}
          />
        )}
        pageLimit={isMobile ? Number(MOBILE_LEADERBOARD_PAGE_LIMIT) || 3 : Number(LEADERBOARD_PAGE_LIMIT) || 10}
        dataLimit={Number(LEADERBOARD_ROWS) || 5}
      />
    </>
  ) : (
    <S.ImageWrapper>
      <img src={images[randomIndex]} alt="" />
    </S.ImageWrapper>
  );
}
