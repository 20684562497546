import styled from "styled-components";

export const ModalContainer = styled.div`
  width: 500px;
  height: fit-content;
  padding-bottom: 20px;

  @media (max-width: 500px) {
    width: 100%;
  }
`;
