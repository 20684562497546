import React, { useEffect, useState } from "react";
import { SearchIcon, TeamIcon } from "../../../../assets/svg";
import { BE_API_BASE_URL } from "../../../../constants/env";
import { useGetSecHeaders } from "../../../../hooks/SecurityHeaders";
import { useParams } from "react-router-dom";
import useCallAxios from "../../../../hooks/useAxiosCall";
import Avatar from "../../common/Avatar";
import { Button } from "../../common/Button";
import Flex from "../../common/Flex";
import { Text } from "../../common/Text";
import Modal from "../../Modal";
import InviteRow from "./InviteRow";
import * as S from "./styles";
import { LoaderWrapper } from "../../Tournament/KoinsModal/styles";
import { SpinnerLoader } from "../../common/SpinningLoader";
import { sentRequestOnEnter } from "../../../../helpers/utils";

const PlayerInviteModal = ({
  showModal,
  toggleModal,
  teamId,
  handlePlayersListState,
  usersLimit,
  playersList = [],
}) => {
  const headers = useGetSecHeaders();
  const { callAxiosFunc } = useCallAxios();

  const [userSearch, setUserSearch] = useState("");

  const [playersResult, setPlayersResult] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(false);
  const [hasSeearched, setHasSearched] = useState(false);

  async function searchPlayer() {
    setIsLoading(true);
    setError(false);
    const response = await callAxiosFunc({  
      method: "GET",
      url: `${BE_API_BASE_URL}user/search/${userSearch}`,
      headers: headers.token ? JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }) : null,
    });
    setHasSearched(true);

    setIsLoading(false);
    if (response.err) {
      setError("Something went wrong. Please try again later");
      console.log(response.err);
    } else {
      setPlayersResult(response.data);
    }
  }

  function handleModalToggle() {
    setPlayersResult([]);
    setHasSearched(false);
    setError(false);
    setIsLoading(false);
    toggleModal();
    setUserSearch("");
  }

  return (
    <Modal
      tabletStyles={{ width: "100%" }}
      mobileStyles={{ width: "100%" }}
      showModal={showModal}
      customStyles={{ width: "fit-content", height: "fit-content", padding: 20 }}
      toggleModal={handleModalToggle}
    >
      <S.Container>
        <Flex justifyContent="space-between">
          <Text fontSize="26px">Invite Players</Text>
          <Text fontSize="16px" color="#A498ED">
            {playersList.filter(player => player.status === "accepted" || player.status === "pending-confirm")?.length}/
            {usersLimit}
          </Text>
        </Flex>
        <Flex columnGap="11">
          <TeamIcon style={{ height: "14px", width: "12px" }} />
          <Text color="#A498ED" fontSize="16px">
            {playersList.filter(player => player.status === "accepted" || player.status === "pending-confirm")?.length}{" "}
            players
          </Text>
        </Flex>
        {error && <Text color="#ff9494">Something went wrong!</Text>}
        <S.InputContainer>
          <SearchIcon
            style={{ position: "absolute", top: "14px", left: "12px", width: "12.75px", height: "12.75px" }}
          />
          <S.StyledInput value={userSearch} onChange={e => setUserSearch(e.target.value)} onKeyDown={(e) => sentRequestOnEnter(e, searchPlayer)} placeholder="Type your friend's Nickname"/>
          <Button
            borderRadius="18.5px"
            background="#4635B6"
            style={{ position: "absolute", right: "5px", top: "5px", width: 71, height: 28.44 }}
            onClick={searchPlayer}
            disabled={isLoading || !userSearch} // disabling in case user hasn't input anything, is loading
          >
            {isLoading ? <SpinnerLoader /> : "Search"}
          </Button>
        </S.InputContainer>
        {/* {userSearch ? ( */}
        <S.PlayerListContainer>
          <S.PlayerListInner>
            {isLoading ? (
              <LoaderWrapper style={{ height: "100%" }}>
                <SpinnerLoader style={{ height: "35px", width: "35px" }} />
              </LoaderWrapper>
            ) : hasSeearched && playersResult.length < 1 ? (
              <S.NotFound>No Player Found</S.NotFound>
            ) : (
              playersResult?.map((player, indx) => (
                <InviteRow
                  preventInvite={
                    playersList?.filter(player => player.status === "accepted" || player.status === "pending-confirm")
                      .length >= usersLimit
                  }
                  {...player}
                  teamId={teamId}
                  handlePlayersListState={handlePlayersListState}
                />
              ))
            )}
          </S.PlayerListInner>
        </S.PlayerListContainer>
        {/* ) : null} */}
      </S.Container>
    </Modal>
  );
};

export default PlayerInviteModal;
