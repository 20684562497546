module.exports = [
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "_challengeId",
          "type": "uint256"
        }
      ],
      "name": "ChallengeAccepted",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "_challengeId",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "bytes32",
          "name": "_winner",
          "type": "bytes32"
        }
      ],
      "name": "ChallengeSettled",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "_player1",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "_challengeId",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "address",
          "name": "_player2",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "bytes32",
          "name": "_gamertag1",
          "type": "bytes32"
        },
        {
          "indexed": false,
          "internalType": "bytes32",
          "name": "_gamertag2",
          "type": "bytes32"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint8",
          "name": "_game",
          "type": "uint8"
        },
        {
          "indexed": false,
          "internalType": "uint8",
          "name": "_gameMode",
          "type": "uint8"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_startOn",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "_finishOn",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "address",
          "name": "_tokenAddr",
          "type": "address"
        }
      ],
      "name": "NewChallenge",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "previousOwner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "OwnershipTransferred",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "_challengeId",
          "type": "uint256"
        }
      ],
      "name": "RemoveChallenge",
      "type": "event"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_challengeId",
          "type": "uint256"
        }
      ],
      "name": "acceptChallenge",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "bbFee",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_challengeId",
          "type": "uint256"
        }
      ],
      "name": "cancelChallenge",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "challengeCount",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "challenges",
      "outputs": [
        {
          "internalType": "bool",
          "name": "settled",
          "type": "bool"
        },
        {
          "internalType": "bool",
          "name": "accepted",
          "type": "bool"
        },
        {
          "internalType": "uint8",
          "name": "game",
          "type": "uint8"
        },
        {
          "internalType": "uint8",
          "name": "gameMode",
          "type": "uint8"
        },
        {
          "internalType": "address",
          "name": "tokenAddr",
          "type": "address"
        },
        {
          "internalType": "address payable",
          "name": "player1",
          "type": "address"
        },
        {
          "internalType": "address payable",
          "name": "player2",
          "type": "address"
        },
        {
          "internalType": "bytes32",
          "name": "gamertag1",
          "type": "bytes32"
        },
        {
          "internalType": "bytes32",
          "name": "gamertag2",
          "type": "bytes32"
        },
        {
          "internalType": "bytes32",
          "name": "winner",
          "type": "bytes32"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "acceptedOn",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "startOn",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "finishOn",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getTreasury",
      "outputs": [
        {
          "internalType": "address",
          "name": "_treasury",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "initialize",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "owner",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "_gamertag1",
          "type": "bytes32"
        },
        {
          "internalType": "bytes32",
          "name": "_gamertag2",
          "type": "bytes32"
        },
        {
          "internalType": "address payable",
          "name": "_player2",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        },
        {
          "internalType": "uint8",
          "name": "_game",
          "type": "uint8"
        },
        {
          "internalType": "uint8",
          "name": "_gameMode",
          "type": "uint8"
        },
        {
          "internalType": "uint256",
          "name": "_startOn",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_finishOn",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "_tokenAddr",
          "type": "address"
        }
      ],
      "name": "placeChallenge",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "receivedChallenges",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_challengeId",
          "type": "uint256"
        }
      ],
      "name": "removeChallenge",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "renounceOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_challengeId",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "_winner",
          "type": "address"
        }
      ],
      "name": "settle",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "transferOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_newFee",
          "type": "uint256"
        }
      ],
      "name": "updateFee",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address payable",
          "name": "_newTreasury",
          "type": "address"
        }
      ],
      "name": "updateTreasury",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "userChallenges",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "viewActiveChallenges",
      "outputs": [
        {
          "internalType": "uint256[]",
          "name": "_activeChallenges",
          "type": "uint256[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_addr",
          "type": "address"
        }
      ],
      "name": "viewChallenges",
      "outputs": [
        {
          "internalType": "uint256[]",
          "name": "_userChallenges",
          "type": "uint256[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_addr",
          "type": "address"
        }
      ],
      "name": "viewReceivedChallenges",
      "outputs": [
        {
          "internalType": "uint256[]",
          "name": "_receivedChallenges",
          "type": "uint256[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ]