import React from "react";
import styled from "styled-components";
import newDefaultAvatar from "../../../../assets/svg/new-default-avatar.svg";
import defaultTeamAvatar from "../../../../assets/svg/team-default-avatar.svg";
import squareDefaultAvatar from "../../../../assets/svg/square-default-avatar.svg";

const AvatarWrap = styled.img`
  cursor: pointer;
  ${props => `
    border-radius: ${props.borderRadius ? props.borderRadius : props.type === "square" ? "3px" : "50%"};
    width: ${props.size ? props.size : "33px"};
    height: ${props.size ? props.size : "33px"}
    `}
`;

const Avatar = ({ src, alt, size, team, borderRadius, type, customStyles = {} }) => {
  // DONE: implement team (team is a prop that identifies if the avatar is associated to team avatar or not)
  return (
    <AvatarWrap
      src={team ? src || defaultTeamAvatar : type === "square" ? src || squareDefaultAvatar : src || newDefaultAvatar}
      size={size}
      borderRadius={borderRadius}
      alt={alt || "Avatar"}
      style={customStyles}
    />
  );
};

export default Avatar;
