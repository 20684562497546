import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useMedia } from "use-media";
import { useGetSecHeaders } from "../../../hooks/SecurityHeaders";
import { ENDPOINT } from "../../../constants/endpoints";
import {
  BlastIcon,
  BurgerMenu,
  DiscordAlt,
  FaqActiveIcon,
  FaqIcon,
  GameActiveIcon,
  GameIcon,
  LobbyActiveIcon,
  LobbyIcon,
  MessageActiveIcon,
  MessageIcon,
  ProfileActiveIcon,
  ProfileIcon,
  SideBarArrow,
  TeamIcon,
  TeamIconActive,
  TournamentActiveIcon,
  TournamentIcon,
} from "../../../assets/svg";
import useActiveMenu from "../../../hooks/ActiveMenu";
import { breakpoints } from "../../../themes";
import callAxios from "../../../hooks/Axios";
import { RequestContext } from "../../../providers/RequestProvider";
import { Tooltip } from "antd";
import { BLAST_DISCORD, BLAST_DISCORD_ALT } from "../../../constants";
import { useRecoilState } from "recoil";
import { selectedGameState } from "../../../recoil/gameState";

const DesktopSidebarWrapper = styled.div`
  ${props => (props.collapseSidebar ? "padding: 15px 0px 13px 0px;" : "padding: 15px 0px 13px 20px;")};
  ${props => (props.collapseSidebar ? "width: 91px" : "width: 215px")};
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  background-color: #362890;
  transition: all 0.2s linear;

  ${breakpoints.down(breakpoints.tablet)} {
    display: none;
  }
`;

const MobileNavbar = styled.div`
  height: 60px;
  width: 100vw;
  background: #362890;
  position: fixed;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  display: none;

  ${breakpoints.down(breakpoints.tablet)} {
    display: flex;
    justify-content: center;
  }
`;

const Navbar = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NavItem = styled.li`
  width: 100%;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  ${props => `
      // background: ${props.active || props.hover ? props.theme.lightBackgroundColor : `inherit`};
      border-radius: ${props.active || props.hover ? `10px` : `inherit`};
      color: ${props.active || props.hover ? props.theme.textSecond : props.theme.textMain};    }
  `}

  :hover {
    border-radius: 10px;
    color: ${props => props.theme.textSecond};
    transition: background 0.2s ease-out;
  }
`;

const MobileNavItem = styled.li`
  list-style-type: none;
`;

const MobileIconWrapper = styled.span`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavTopWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Badge = styled.span`
  width: 16px;
  height: 16px;
  position: absolute;
  background-color: red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -15px;
  right: 2px;
  font-style: normal;
  font-weight: 350;
  font-size: 10px;
`;

const SidebarToggleButton = styled.span`
  width: 100%;
  ${props => (props.visible ? "opacity: 1; width: 100%" : "opacity: 0; width: 0")};

  transition: all 0.2s linear;

  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MobileNavItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

const SocialLinks = styled.a`
  :hover {
    color: white;
  }
`;

const navItems = [
  {
    icon: <LobbyIcon />,
    activeIcon: <LobbyActiveIcon />,
    title: "Home",
    public: true,
    route: "/",
    visible: true,
  },
  {
    icon: <TournamentIcon />,
    activeIcon: <TournamentActiveIcon />,
    title: "Tournaments",
    public: true,
    route: "/pool",
    visible: true,
  },
  // {
  //   icon: <ChallengeIcon />,
  //   activeIcon: <ChallengeActiveIcon />,
  //   title: "Challenge",
  //   public: true,
  //   route: "/challenge",
  //   visible: true,
  // },
  {
    icon: <TeamIcon />,
    activeIcon: <TeamIconActive />,
    title: "Teams",
    public: true,
    route: "/teams",
    visible: true,
  },
  {
    icon: <GameIcon />,
    activeIcon: <GameActiveIcon />,
    title: "Games",
    public: true,
    route: "/games",
    visible: true,
  },
  {
    icon: <MessageIcon />,
    activeIcon: <MessageActiveIcon />,
    title: "Messages",
    public: false,
    route: "/messages",
    visible: true,
  },
  {
    icon: <FaqIcon />,
    activeIcon: <FaqActiveIcon />,
    title: "FAQ",
    public: true,
    route: "/faq",
    visible: true,
  },
  {
    icon: <ProfileIcon />,
    activeIcon: <ProfileActiveIcon />,
    title: "Profile",
    public: false,
    route: "/profile",
    visible: true,
  },
];

const BlastLogoWrapper = styled.div`
  position: absolute;
  top: 0;
  background: linear-gradient(199.28deg, #12c2ea -51.84%, #7c00fa 98.68%);
  width: 87px;
  height: 73px;
  border-bottom-right-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;
const GamesListBar = styled.div`
  min-width: 87px;
  background: #201366;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 17px;
  position: relative;
  z-index: 999;
`;
const GamesList = styled.div`
  height: 100px;
  margin-top: 98px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const GamesListInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-bottom: 17px;
  justify-content: space-between;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const TitleWrapper = styled.span`
  width: ${props => (props.collapseSidebar ? 0 : "100%")};
  opacity: ${props => (props.collapseSidebar ? 0 : 1)};
  margin-left: ${props => (props.collapseSidebar ? 0 : "10px")};
  overflow-x: hidden;
  transition: width 0.5s linear, opacity 0.2s linear;
`;

const GameLink = styled.a`
  width: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props =>
    props.isActive
      ? `justify-content: space-between; & img { position: relative; right: 50%; transform: translateX(50%); } & span { content: ''; width: 6px; height: 49px; background: #A77EFF; border-top-right-radius: 25px; border-bottom-right-radius: 25px; }`
      : `&span {display: none;}`};
`;

const BurgerItem = styled.div`
  position: relative;
  width: 100%;
  height: 49px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1px;
  ${props => `
      border-radius: ${props.active || props.hover ? `10px` : `inherit`};
      color: ${props.active || props.hover ? props.theme.textSecond : props.theme.textMain};
    }
  `}

  :hover {
    border-radius: 10px;
    color: ${props => props.theme.textSecond};
    transition: background 0.2s ease-out;
  }
`;

const IconWrapper = styled.div`
  width: 50px;
  text-align: center;
`;

const ActiveTag = styled.span``;

export default function Sidebar({ scrollSize, collapseSidebar, setCollapseSidebar }) {
  const requestUseContext = useContext(RequestContext);
  const [activeItem, setActiveItem] = useState("");
  const [mobileNavActive, setmobileNavActive] = useState("");
  const [hoverItem, setHoverItem] = useState("");
  const { menuOpen, setMenuOpen } = useActiveMenu();

  const [activeGames, setActiveGames] = useState([]);
  const [loadingGamesList, setLoadingGamesList] = useState(false);
  
  const selectedGame = useRecoilState(selectedGameState)
  const [activePage, setActivePage] = useRecoilState(selectedGameState);

  const location = useLocation();
  useEffect(() => {
    try {
      async function getGamesList() {
        let res = await callAxios({
          method: "GET",
          url: `${ENDPOINT.GAME}`,
        });
        setActiveGames(res?.data || []);
      }
      setLoadingGamesList(true);
      getGamesList().then(() => setLoadingGamesList(false));
    } catch (err) {
      console.log(err);
    }
  }, []);

  /* create a useEffect that change the activePage status based on which element of the activeGames array is active, if any of them is active then set the activePage to true else set it to false using the location as reference to 
  know on which page is the user, it must change for each element */
  useEffect(() => {
    activeGames.forEach(game => {
      if (selectedGame === game?.id) {
        setActivePage(game?.id);
      } else if (
        location.pathname === "/" ||
        location.pathname == "*" ||
        (location.pathname.includes("/pool") && !location.search.includes("?g")) ||
        location.pathname === "/games" ||
        location.pathname === "/faq" ||
        location.pathname === "/messages" ||
        location.pathname === "/profile"
      ) {
        setActivePage("");
      }
    });
  }, [activeGames, location]);

  const ref = useRef(null);

  const history = useHistory();
  const headers = useGetSecHeaders();

  const isSmall = useMedia({ maxWidth: breakpoints.tablet });

  const onClick = (title, route) => {
    setActiveItem(title);
    if (route !== undefined) {
      history.push(route);
    }
    setMenuOpen(false);
  };

  const onMobileNavClick = (title, route) => {
    setmobileNavActive(title);
    if (route !== undefined) {
      history.push(route);
    }
  };

  const onMouseOver = item => {
    setHoverItem(item);
  };

  const onMouseOut = () => {
    setHoverItem("");
  };

  // create a copy of the navItems array and filter it to remove the items which item.route is /messages or /faq
  const filteredNavItems = navItems.filter(item => {
    if (item.route === "/messages" || item.route === "/faq") {
      return false;
    }
    return true;
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setMenuOpen]);
  return (
    <>
      {isSmall ? (
        <MobileNavbar scrollSize={scrollSize}>
          <MobileNavItemContainer>
            {filteredNavItems
              .filter(item => {
                if (item.route === "/messages" || item.route === "/profile") {
                  return !!headers.token;
                }
                return item.visible;
              })
              .map(item => (
                <MobileIconWrapper>
                  <MobileNavItem key={item.title} id={item.title === "Profile" ? "analytics-profile" : ""}>
                    <div onClick={() => onClick(item.title, item.route)}>
                      {hoverItem === item.title || item.route === location.pathname ? item.activeIcon : item.icon}
                    </div>
                  </MobileNavItem>
                </MobileIconWrapper>
              ))}
          </MobileNavItemContainer>
        </MobileNavbar>
      ) : (
        <>
          <GamesListBar>
            <Link to="/">
              <BlastLogoWrapper>
                <BlastIcon />
              </BlastLogoWrapper>
            </Link>
            <GamesListInner>
              <GamesList>
                {!loadingGamesList &&
                  activeGames.map((item, index) => (
                    <Tooltip
                      title={item?.visibleName}
                      placement="right"
                      align={{ offset: [-4, 0] }}
                      color="#7265C8"
                      overlayInnerStyle={{ fontSize: "12px", fontWeight: "lighter", borderRadius: "6px" }}
                    >
                      <GameLink
                        href={`/#/gamepage/${item.id}`}
                        rel="noreferrer"
                        isActive={activePage == item.id}
                        key={index}
                      >
                        <ActiveTag />
                        <img
                          style={{ height: "49px", width: "49px" }}
                          src={item?.assets?.profileIcon}
                          alt="Game Logo"
                        />
                      </GameLink>
                    </Tooltip>
                  ))}
              </GamesList>
            </GamesListInner>
            <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "24px" }}>
              <SocialLinks href={BLAST_DISCORD_ALT} target="_blank">
                <DiscordAlt />
              </SocialLinks>
            </div>
          </GamesListBar>
          <DesktopSidebarWrapper
            menuOpen={menuOpen}
            ref={ref}
            collapseSidebar={collapseSidebar}
            onClick={e => {
              setCollapseSidebar(!collapseSidebar);
            }}
          >
            <NavTopWrapper>
              <Navbar>
                <div style={{ marginBottom: "36px" }}>
                  <BurgerItem>
                    <SidebarToggleButton visible={collapseSidebar}>
                      <BurgerMenu width="14px" />
                    </SidebarToggleButton>
                    <SidebarToggleButton visible={!collapseSidebar}>
                      <SideBarArrow
                        onClick={() => setCollapseSidebar(true)}
                        style={{
                          position: "absolute",
                          transition: "right 0.2s linear",
                          right: "0",
                          opacity: collapseSidebar ? "0" : "1",
                        }}
                      />
                    </SidebarToggleButton>
                  </BurgerItem>
                </div>
                {navItems
                  .filter(item => {
                    if (item.route === "/messages" || item.route === "/profile") {
                      return !!headers.token;
                    }
                    return item.visible;
                  })
                  .map(item => (
                    <>
                      {item.route === "/profile" ? (
                        <div
                          style={{
                            width: collapseSidebar ? "60px" : "175px",
                            marginTop: "20px",
                            marginBottom: "20px",
                            marginLeft: collapseSidebar ? "16px" : "unset",
                            borderBottom: "1px solid #5D4FB6",
                          }}
                        />
                      ) : null}
                      <NavItem
                        key={item.title}
                        active={activeItem === item.title}
                        onClick={e => {
                          e.stopPropagation();
                          onMobileNavClick(item.title, item.route);
                        }}
                        onMouseEnter={() => onMouseOver(item.title)}
                        onMouseLeave={() => onMouseOut()}
                        id={item.title === "Profile" ? "analytics-profile" : ""}
                      >
                        <Tooltip
                          title={item.title}
                          placement="right"
                          color="#7265C8"
                          trigger={!collapseSidebar ? "focus" : "hover"}
                          align={{ offset: [-4, 0] }}
                          overlayInnerStyle={{ borderRadius: "6px" }}
                        >
                          <IconWrapper style={{ position: "relative" }}>
                            {activeItem === item.title || hoverItem === item.title || item.route === location.pathname
                              ? item.activeIcon
                              : item.icon}
                            {item.title === "Teams" && (
                              <div>
                                {requestUseContext?.totalRequests === 0 ? null : (
                                  <Badge>
                                    {requestUseContext?.totalRequests > 9 ? "9+" : requestUseContext?.totalRequests}
                                  </Badge>
                                )}
                              </div>
                            )}
                          </IconWrapper>
                        </Tooltip>
                        <TitleWrapper collapseSidebar={collapseSidebar}>{item.title}</TitleWrapper>
                      </NavItem>
                    </>
                  ))}
              </Navbar>
            </NavTopWrapper>
          </DesktopSidebarWrapper>
        </>
      )}
    </>
  );
}
