import React from 'react';
import { positionToString } from '../../../../helpers/utils';

// assets
import { CupPodiumNew } from '../../../../assets/svg';

// components
import * as S from "./styles";
import Avatar from '../../common/Avatar';
import WinnerCard from './WinnerCard';

export function WinnersComponent({ data = [], isFinished, winnersPrize = [], winnerSectionBg = "" }) {
  if (winnersPrize.length > 0) {
    const tablePosHolder = winnersPrize.length < 3 ? data.slice(2, data.length) : data.slice(3, data.length);

    return (
      <div>
        {isFinished ? (
          <>
            <>
              <S.WinnerCardBg className="winner-bg" bgImg={winnerSectionBg} />
              <S.WinnerCardsContainer dataLen={winnersPrize?.length}>
                {winnersPrize?.slice(0, 5)?.map(winnerItem => (
                  <WinnerCard {...winnerItem} />
                ))}
              </S.WinnerCardsContainer>
            </>
            {winnersPrize?.length > 5 ? (
              <>
                <S.HeadingsContainer>
                  <S.PositionHeading>Position</S.PositionHeading>
                  <S.UserDataHeading>
                    <div>Username</div>
                  </S.UserDataHeading>
                  <S.ScoreHeading>Score</S.ScoreHeading>
                  <S.PercentageHeading>Percentage</S.PercentageHeading>
                  <S.PrizeHeading>Prize</S.PrizeHeading>
                </S.HeadingsContainer>
                <S.FinalistTable>
                  {winnersPrize?.slice(5).map(player => (
                    <S.FinalistContainer>
                      <S.FinalistPosition>{positionToString(player?.position)}</S.FinalistPosition>
                      <S.UserData>
                        <Avatar size="34px" src={player?.avatarUrl} alt="" />
                        <S.FinalistName>{player?.nick}</S.FinalistName>
                      </S.UserData>
                      <S.FinalistScore>{Math.round(player?.score) || 0}</S.FinalistScore>
                      {player?.percentage ? (
                        <S.FinalPercentage>{Math.round(player?.percentage)}%</S.FinalPercentage>
                      ) : (
                        <S.FinalPercentage>-</S.FinalPercentage>
                      )}
                      {player?.usdAmount ? (
                        <S.FinalPrize>${Math.round(player?.usdAmount)}</S.FinalPrize>
                      ) : (
                        <S.FinalPrize>-</S.FinalPrize>
                      )}
                    </S.FinalistContainer>
                  ))}
                </S.FinalistTable>
              </>
            ) : null}
          </>
        ) : (
          <S.CupPodiumContainer>
            <div>
              <CupPodiumNew />
            </div>
            <S.CupPodiumHead fontSize="20px" color="#D1CCEF" marginBottom="15px">
              The tournament hasn&apos;t finished yet
            </S.CupPodiumHead>
            <S.CupPodiumHead fontSize="14px" color="#877ADD" width="289px">
              Once the tournament is over, the data takes a few minutes to appear.
            </S.CupPodiumHead>
          </S.CupPodiumContainer>
        )}
      </div>
    );
  } else {
    return (
      <S.CupPodiumContainer>
        <div>
          <CupPodiumNew />
        </div>
      </S.CupPodiumContainer>
    );
  }
}