import styled from "styled-components";
import { breakpoints } from "../../../themes";

export const Container = styled.div`
  height: 190px;
  width: 100%;
  background: rgba(63, 47, 170, 0.4);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0;
  ${breakpoints.down(breakpoints.tablet)} {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
export const MainContainer = styled.div`
  width: 100%;
  margin: 0 30px;
  display: flex;
  ${breakpoints.down(breakpoints.tablet)} {
    flex-direction: column;
    margin: 15px;
  }
`;

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${breakpoints.down(breakpoints.tablet)} {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  ${breakpoints.down(breakpoints.mobileL)} {
    margin: 0 15px;
    width: calc(100% - 30px);
  }
`;

export const StatusCircle = styled.div`
  width: 50px;
  height: 50px;
  background: ${props => props.bgColor};
  border-radius: 50%;
  border: 3px solid ${props => props.borderColor};
  display: flex;
  align-items: center;
  justify-content: center;
  ${breakpoints.down(breakpoints.tablet)} {
    margin-right: 33px;
    width: 40px;
    height: 40px;
  }
`;

export const StatusCircleText = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 350;
  font-size: 24px;
  color: #ffffff;
`;

export const TextContainer = styled.div`
  width: 100%;
  max-width: 170px;
  display: flex;
  flex-direction: column;
  text-wrap: wrap;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
    max-width: 100%;
  }
`;

export const StatusText = styled.h1`
  margin-top: 10px;
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  width: 100%;
  text-wrap: wrap;
`;

export const DescriptionText = styled.p`
  font-style: normal;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;
  text-wrap: wrap;
  color: #877add;
  text-align: left;
`;

export const Spacer = styled.div`
  width: calc(100% - 50px);
  height: 5px;
  background: ${props => props.bgColor};
  ${breakpoints.down(breakpoints.tablet)} {
    height: calc(100% - 40px);
    width: 5px;
    margin-right: 33px;
  }
`;
export const DateContainer = styled.div`
  ${breakpoints.down(breakpoints.tablet)} {
    margin-bottom: 15px;
  }
`;

export const Detail = styled.div`
  display: flex;
  align-items: center;
  ${breakpoints.down(breakpoints.tablet)} {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  ${breakpoints.down(breakpoints.mobileL)} {
    margin: 0;
  }
`;
