/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */
import React, { useEffect, useRef, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { InfuraProvider } from "@ethersproject/providers";
import { useUserAddress } from "eth-hooks";
import styled from "styled-components";
import Modal, { ModalProvider } from "styled-react-modal";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useWallet } from "../providers/WalletProvider";
import * as crypto from "crypto-js";
import { SEED } from "../constants/env";
import Sidebar from "../components/v2/Sidebar";
import Header from "../components/v2/Header";
import Footer from "../components/v2/Footer";
import Loader from "../components/v2/Loader";
import TournamentPage from "./TournamentPage";
import TournamentListPage from "./TournamentListPage";
import LobbyPage from "./LobbyPage";
import ChallengePage from "./ChallengePage";
import MessagePage from "./MessagePage";
import GameListPage from "./GameListPage";
import ProfilePage from "./ProfilePage/ProfilePage";
import GamePage from "./GamePage";
import TeamsPage from "./Teams";

import FaqPage from "./Faq";
import BracketsPage from "./Brackets/BracketsPage";
import BracketsPlayers from "./BracketsPlayers/BracketsPlayers";

import { useUserLogin } from "../hooks/UserLogin";
import ConnectAccount from "../components/v2/ConnectAccountModal/ConnectAccountModal";
import { sessionState } from "../state/globalState";
import { userReloadState } from "../state/userReloadState";
import NetworkSwitch from "../components/v2/NetworkSwitch";
import { useAppState } from "../providers/AppStateProvider";
import { AddIcon, BlastConnect, MetamaskConnect, ModalCancelIcon, WarningIcon } from "../assets/svg";
import { breakpoints } from "../themes";
import MaintancesView from "./MaintanceView";
import TeamProfile from "./Teams/TeamProfile";
import { RequestProvider } from "../providers/RequestProvider";
import AuthModal from "../components/v2/AuthModal";
import { useLocation } from "react-router-dom";

const MainWrapper = styled.div`
  height: 100vh;
  ${props => `
     width: calc(100% - ${props.collapseSidebar ? "178px" : "298px"});
     transition: ${props.collapseSidebar ? "unset" : "all 0.2s linear"};
  `}
  padding-bottom: 95px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #201366;
  ${breakpoints.down(breakpoints.laptop)} {
    width: 100%;
  }
`;

// @media (max-width: 450px) {
//   width: 100%;
// }

const MainContainer = styled.div`
  background-color: #201366;
  margin-top: 95px;
`;

// const OnboardingModal = Modal.styled`
//   height: 541px;
//   width: 583px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   background: #272054;
//   border-radius: 4px;
//   position: relative;
// `;

const OnboardingHeader = styled.div`
  color: #fff;
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  margin-top: 54px;
  margin-bottom: 5px;
`;
const OnboardingSubtext = styled.div`
  color: #877add;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 88px;
  width: 295px;
  text-align: center;
`;

const OnboardingInstallBtn = styled.a`
  background: linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%);
  border-radius: 30px;
  margin-top: 80px;
  width: 127px;
  height: 34px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: #fff;
  }
`;

const OnboardingImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 340px;
  align-items: center;
`;

const CancelIconContainer = styled.div`
  position: absolute;
  right: 0;
  margin: 18px 16px;
  cursor: pointer;
`;

const ModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1500;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  ${breakpoints.down(breakpoints.tablet)} {
    padding-top: 130px;
    align-items: flex-start;
  }
`;

// export const BanModal = Modal.styled`
//     width: 520px;
//     height: 570px;
//     padding: 10px 20px;
//     z-index: 1500;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     background-color: #342788;
//     border: none;
//     border-radius: 4px;
//     opacity: 1;
//     transition : all 0.3s ease-in-out;
//     position: relative;
//     ${breakpoints.down("690px")} {
//       width: calc(100% - 36px);
//     }
//     h1{
//       font-size: 20px;
//       color: #D1CCEF;
//       margin-bottom: 17px;
//       margin-top: 35px;
//     }
//     p{
//       font-weight: 400;
//       font-size: 14px;
//       line-height: 20px;
//       color: #877ADD;
//       width: 313px;
//       text-align: center;
//       margin-bottom: 67px;
//     }
//     button {
//       width: 150px;
//       height: 39.96px;
//       left: 637px;
//       top: 524.52px;
//       background: linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%);
//       border-radius: 30px;
//       color: #ffffff;
//       border: none;
//       cursor: pointer;
//     }
// `;

export const BanModalCrossIconContainer = styled.div`
  position: absolute;
  top: 18px;
  right: 26px;
  cursor: pointer;
`;

export default function Routes() {
  const { injectedProvider, web3Modal, loadWeb3Modal, logoutOfWeb3Modal, displaySelectionModal } = useWallet();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const address = useUserAddress(injectedProvider);
  const mainnetInfura = new InfuraProvider("homestead", "5b55e30382a746259ae44bb1f0eded23");
  const history = useHistory();
  const { pathname } = useLocation();

  const {
    disconnect,
    isFetching,
    showBanModal,
    setShowBanModal,
    banReason,
    socialLoginApiCall,
    setSocialAuthData,
  } = useUserLogin();
  const session = useRecoilValue(sessionState);
  const setUserReload = useSetRecoilState(userReloadState);
  const [showModal, setShowModal] = useState(false);
  const [scrollSize, setScrollSize] = useState(0); // This variable is used to hold the width of the browser's scrollbar, which will be used to prevent the mobile navbar from overlapping with the scrollbar
  const { showWalletOnboarding, toggleWalletOnboarding, detectMetamask } = useAppState();
  const [openMetamask, setOpenMetamask] = useState(false);
  useEffect(() => {
    if (session != null) {
      if (!session.user.nick || session?.user?.firstLogin) setShowModal(true);
    }
  }, [session]);

  const toggleModal = byUser => {
    if (byUser) {
      logoutOfWeb3Modal();
    }
    if (!byUser && showModal) {
      // reload user info.
      setUserReload(true);
    }
    setShowModal(!showModal);
  };

  const bodyRef = useRef();

  const userDisconnectHandler = async () => {
    await disconnect();
    logoutOfWeb3Modal();
  };

  // shows modal when application starts if user does not have metamask installed
  // waits until "load" event is run to ensure that web3 gets time to be injected to the browser before checking
  useEffect(() => {
    window.addEventListener("load", function () {
      detectMetamask();
    });
  }, [detectMetamask]);

  useEffect(() => {
    if (bodyRef.current) {
      setScrollSize(bodyRef.current.offsetWidth - bodyRef.current.clientWidth);
    }
  }, [bodyRef]);

  const handleInstall = event => {
    event.preventDefault();
    if (!openMetamask) {
      window.open("https://metamask.io/", "_blank");
      setOpenMetamask(true);
    } else {
      window.location.reload(true);
    }
  };

  const closeBanModal = () => {
    setShowBanModal(false);
  };
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.platform);

  const [collapseSidebar, setCollapseSidebar] = useState(true);

  // get the query params from the url
  const params = new URLSearchParams(history.location.search);
  const refCode = params.get("ref");

  // encrypt the ref code and save it in local storage encrypted
  useEffect(() => {
    if (refCode) {
      const encryptedRefCode = crypto.AES.encrypt(refCode, SEED).toString();
      localStorage.setItem("refCode", encryptedRefCode);
    }
  }, [refCode]);

  useEffect(() => {
    if(document.querySelector('#main-wrapper')) {
      document.querySelector('#main-wrapper').scroll(0, 0);
    }
  }, [pathname])

  return (
    <>
      {injectedProvider && <NetworkSwitch />}
      <RequestProvider>
        <Sidebar collapseSidebar={collapseSidebar} setCollapseSidebar={setCollapseSidebar} scrollSize={scrollSize} />
        <Header
          collapseSidebar={collapseSidebar}
          userProvider={injectedProvider}
          address={address}
          mainnetProvider={mainnetInfura}
          web3Modal={web3Modal}
          loadWeb3Modal={loadWeb3Modal}
          displaySelectionModal={displaySelectionModal}
          logoutOfWeb3Modal={userDisconnectHandler}
          bodyRef={bodyRef}
          setShowAuthModal={setShowAuthModal}
        />
        <MainWrapper collapseSidebar={collapseSidebar} ref={bodyRef} id="main-wrapper">
          <ModalProvider backgroundComponent={ModalBackground}>
            <BanModal beforeClose={() => userDisconnectHandler()} isOpen={showBanModal}>
              <BanModalCrossIconContainer onClick={closeBanModal}>
                <ModalCancelIcon />
              </BanModalCrossIconContainer>
              <WarningIcon />
              <h1>Account Blocked</h1>
              <p>{banReason || "Your account has been blocked because blast's rules were violated."}</p>
              <button type="button" onClick={closeBanModal}>
                Close
              </button>
            </BanModal>
            <MainContainer>
              {isFetching ? (
                <Loader animation="rotate" fullScreen />
              ) : (
                <>
                  <Switch>
                    <Route exact path="/" component={LobbyPage} />
                    <Route exact path="/pool/" component={TournamentListPage} />
                    <Route exact path="/pool/:id" component={TournamentPage} />
                    <Route exact path="/challenge" component={ChallengePage} />
                    <Route exact path="/messages" component={MessagePage} />
                    <Route exact path="/games" component={GameListPage} />
                    <Route exact path="/profile" component={ProfilePage} />
                    <Route exact path="/faq" component={FaqPage} />
                    <Route exact path="/gamepage/:id" component={GamePage} />
                    <Route exact path="/brackets" component={BracketsPage} />
                    <Route exact path="/brackets-players" component={BracketsPlayers} />
                    <Route exact path="/teams" component={TeamsPage} />
                    <Route exact path="/team-profile/:id" component={TeamProfile} />
                    <Route path="*">
                      <Redirect to="/404" />
                    </Route>
                  </Switch>

                  <ConnectAccount
                    isOpen={showModal}
                    opacity={1}
                    toggleModal={toggleModal}
                    defaultNick={session?.user?.nick} // Will have a default nickname in case of social signup
                  />
                  {!isMobile && (
                    <OnboardingModal
                      isOpen={showWalletOnboarding}
                      onBackgroundClick={toggleWalletOnboarding}
                      onEscapeKeydown={toggleWalletOnboarding}
                    >
                      <CancelIconContainer>
                        <ModalCancelIcon onClick={toggleWalletOnboarding} />
                      </CancelIconContainer>
                      <OnboardingHeader>Install Metamask</OnboardingHeader>
                      <OnboardingSubtext>
                        You&apos;ll need a crypto wallet for collecting our juicy prizes.
                      </OnboardingSubtext>
                      <OnboardingImageContainer>
                        <BlastConnect />
                        <AddIcon />
                        <MetamaskConnect />
                      </OnboardingImageContainer>
                      <OnboardingInstallBtn onClick={handleInstall}>
                        {openMetamask ? "Refresh" : "Install"}
                      </OnboardingInstallBtn>
                    </OnboardingModal>
                  )}
                  {/* <div
              style={{
                position: "fixed",
                textAlign: "right",
                right: 0,
                top: 0,
                paddingRight: 20,
                paddingTop: 5,
                haight: 70,
                zIndex: 900,
              }}
            >
              <Account address="" mainnetProvider="" blockExplorer="" />
            </div> */}
                </>
              )}
            </MainContainer>

            <AuthModal
              showAuthModal={showAuthModal}
              setShowAuthModal={setShowAuthModal}
              socialLoginApiCall={socialLoginApiCall}
              setSocialAuthData={setSocialAuthData}
            />
          </ModalProvider>
        </MainWrapper>
        <Footer collapseSidebar={collapseSidebar} bodyRef={bodyRef} />
      </RequestProvider>
    </>
    // <MaintancesView />
  );
}

const OnboardingModal = Modal.styled`
  height: 541px;
  width: 583px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #272054;
  border-radius: 4px;
  position: relative;
`;

export const BanModal = Modal.styled`
    width: 520px;
    height: 570px;
    padding: 10px 20px;
    z-index: 1500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #342788;
    border: none;
    border-radius: 4px;
    opacity: 1;
    transition : all 0.3s ease-in-out;
    position: relative;
    ${breakpoints.down("690px")} {
      width: calc(100% - 36px);
    }
    h1{
      font-size: 20px;
      color: #D1CCEF;
      margin-bottom: 17px;
      margin-top: 35px;
    }
    p{
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #877ADD;
      width: 313px;
      text-align: center;
      margin-bottom: 67px;
    }
    button {
      width: 150px;
      height: 39.96px;
      left: 637px;
      top: 524.52px;
      background: linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%);
      border-radius: 30px;
      color: #ffffff;
      border: none;
      cursor: pointer;
    }
`;