import React from "react";
import { FirstPlaceTrophy, Star, Wallet } from "../../assets/svg";
import * as S from "./styles";
import StatsCard from "../../components/v2/Teams/TeamStats/StatsCard";

const PLACEHOLDER_DATA = [
  {
      "name": "MONEY_EARNED",
      "value": 0
  },
  {
      "name": "POOLS_FINISHED",
      "value": 0
  },
  {
      "name": "POOLS_PLAYED",
      "value": 0
  },
  {
      "name": "POOLS_WON",
      "value": 0
  }
];

const ProfileStats = ({ stats }) => {
  return (
    <S.StatsContainer>
      {Object.entries(stats)?.map(stat => (
        <StatsCard label={stat[0]} value={stat[1]} />
      ))}
    </S.StatsContainer>
  );
};

export default ProfileStats;
