import React, { useMemo } from "react";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import useDisconnectUser from "./hooks/useDisconnectUser";

function createQueryClientWithContext({ disconnecUser }) {
  function globalErrorHandler(error, isQuery = false) {
    // The onError callback is being deprecated. Hence, in order to display toasts on error for queries, we are using the approach below.
    // The alert should ONLY run for queries as we are passing onError for mutations wherver we are using them

    if (error?.status === 401 || error?.status === 403) {
      //   notifyError("User unauthenticated");
      console.log("User  Disconnected");
      disconnecUser();
    } else if (isQuery) {
      let message = "";
      if (typeof error === "string") {
        message = error;
      } else {
        message = error?.message || error?.data || "Something went wrong";
      }
      console.log(message);
    }
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
    queryCache: new QueryCache({
      onError: async error => {
        globalErrorHandler(error, true);
      },
    }),
    mutationCache: new MutationCache({
      onError: async error => {
        globalErrorHandler(error);
      },
    }),
    //   });
  });

  return queryClient;
}

export function useCustomQueryClient() {
  const { disconnecUser } = useDisconnectUser();

  const queryClient = useMemo(() => createQueryClientWithContext({ disconnecUser }), []);

  return queryClient;
}

function CustomQueryClientProvider({ children }) {
  const queryClient = useCustomQueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      {children} 
    </QueryClientProvider>
  );
}

export default CustomQueryClientProvider;
