/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useRecoilState } from "recoil";
import * as crypto from "crypto-js";
import { SEED } from "../../constants/env";
import { sessionState } from "../../state/globalState";
import useNotifMethods from "../../hooks/useNotifMethods";
import { NOTIFICATION_HOURS } from "../../constants/env";
import Checkbox from "../../components/v2/common/Checkbox";
import * as S from "./styles";
import { splitDescriptionOfNotifications } from "../../helpers/utils";
import { setUserInLocalStorage } from "../../helpers/localStorage";

const NotificationBlock = ({ data, selectedNotifications, setSelectedNotifications }) => {
  const [session, setSession] = useRecoilState(sessionState);
  const { markAsRead } = useNotifMethods();
  const { id, createdOn, description, title, unread, senderNick } = data;
  const [notifUnread, setNotifUnread] = useState(unread);
  const [showDesc, setShowDesc] = useState(false);
  const [checked, setChecked] = useState(false);
  const duration = moment.duration(Date.now() - createdOn);
  const { strippedMessage, link, linkText, emoji } = splitDescriptionOfNotifications(description);
  const toggleShowDesc = e => {
    setShowDesc(prev => !prev);

    if (notifUnread) {
      markAsRead(id);
      const {
        user: { unreadMessages },
      } = session;

      const updatedSession = {
        ...session,
        user: {
          ...session.user,
          unreadMessages: unreadMessages <= 0 ? 0 : unreadMessages - 1,
        },
      };
      setSession(updatedSession);
      const encryptedState = crypto.AES.encrypt(JSON.stringify(updatedSession), SEED).toString();
      setUserInLocalStorage(encryptedState)
    }
  };
  const checkboxToggled = e => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    if (isChecked) {
      setSelectedNotifications([...selectedNotifications, data.id]);
    } else {
      const filteredNotifications = selectedNotifications.filter(notif => notif !== id);
      setSelectedNotifications(filteredNotifications);
    }
  };

  useEffect(() => {
    if (showDesc) {
      setNotifUnread(false);
    }
  }, [showDesc]);

  return (
    <S.NotificationBlock
      unread={notifUnread}
      onClick={e => {
        toggleShowDesc(e);
      }}
    >
      <S.NotificationContent>
        {showDesc ? (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <S.NotificationCheckboxContainer>
              <Checkbox label="" checked={checked} onChange={checkboxToggled} />
            </S.NotificationCheckboxContainer>
          </div>
        ) : (
          <S.NotificationIcon unread={notifUnread} />
        )}
        <div>
          {typeof title === "string" ? (
            <>
              <S.NotificationHeader>{title}</S.NotificationHeader>
              <S.NotificationDescription showDesc={showDesc}>
                {strippedMessage}{" "}
                {link ? <S.Link href={link || ""}>{linkText} {" "}</S.Link> : null}
                {emoji}
              </S.NotificationDescription>
            </>
          ) : (
            <>
              <S.NotificationHeader>{title?.title}</S.NotificationHeader>
              <S.NotificationDescription showDesc={showDesc}>{title?.description}</S.NotificationDescription>
            </>
          )}
          <S.NotificationSubtext>
            <small>
              {duration?._data.hours < NOTIFICATION_HOURS && duration?._data.days < 1
                ? moment.duration(createdOn - Date.now()).humanize(true)
                : moment(createdOn).format("MMM DD, h:mm A")}
            </small>
            <span />
            <small>{senderNick}</small>
          </S.NotificationSubtext>
        </div>
      </S.NotificationContent>
    </S.NotificationBlock>
  );
};

export default NotificationBlock;
