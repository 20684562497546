import styled, { keyframes } from "styled-components";

// export const Indicator = styled.div`
//   width: 1.2em;
//   height: 1.2em;
//   background: #302862;
//   position: absolute;
//   top: 0em;
//   left: -1.6em;
//   border: ${props => (props.hasError ? "1px solid red" : "1px solid #4a3e9c")};
//   border-radius: 0.2em;

//   ${Input}:not(:disabled):checked & {
//     background: #d1d1d1;
//   }

//   ${Label}:hover & {
//     background: #3a2f81;
//   }

//   &::after {
//     content: "";
//     position: absolute;
//     display: none;
//   }

//   ${Input}:checked + &::after {
//     display: block;
//     top: 0.1em;
//     left: 0.35em;
//     width: 35%;
//     height: 70%;
//     border: solid #aa57ff;
//     border-width: 0 0.2em 0.2em 0;
//     animation-name: ${rotate};
//     animation-duration: 0.3s;
//     animation-fill-mode: forwards;
//   }

//   &::disabled {
//     cursor: not-allowed;
//   }
// `;

export const SignInHeader = styled.p`
  font-weight: 350;
  font-size: 44px;
  line-height: 36px;

  text-align: center;

  color: #ffffff;
  padding: 0;
  margin: 0;
`;

export const Tabs = styled.div`
  width: 302px;
  height: 47px;
  background: #201366;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 43px;
  margin-top: 56px;
  @media (max-width: 375px) {
    width: 100%;
    padding-left: 2px;
    padding-right: 2px;
  }
`;

export const SignInLoaderText = styled.p`
  color: #fff;
  font-size: "16px";
`

export const TabBtn = styled.button`
  width: 147px;
  height: 41px;

  background: ${props => (props.active ? "#3f2faa" : "transparent")};
  border-radius: 11px;
  border: 0;
  cursor: pointer;
  outline: none;
  color: #fff;

  @media (max-width: 320px) {
    width: 120px;
  }

  //   border: ${props => (props.hasError ? "1px solid red" : "1px solid #4a3e9c")};
`;

export const AuthButton = styled.button`
  width: 320px;
  height: 48px;
  background: ${props => (props.background ? props.background : "#ffffff")};
  color: ${props => (props.color ? props.color : "#ffffff")};
  border-radius: 24px;
  display: flex;
  gap: 14px;
  border: 0;
  cursor: pointer;
  align-items: center;
  padding-left: 23px;
  font-weight: 350;
  font-size: 14px;
  @media (max-width: 375px) {
    width: 100%;
    gap: 8px;
  }
  @media (max-width: 320px) {
    width: 235px;
    gap: 8px;
  }
`;

export const AuthBtnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;


export const ErrorText = styled.div`
  color: #d06565;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  display: flex;
  margin-bottom: 24px;
`;