/* Hook to be used in api calls */
import axios from "axios";
import { ENDPOINT } from "../constants/endpoints";

/*
  ~ What it does? ~

  It is a hook that is used to make api calls

  ~ How can I use? ~

  const response = await callAxios({method: 'get', url: `${ENDPOINT.anyEndpoint}`});

  ~ Features ~
  - It is a hook that is used to make api calls

  ~ Notes ~
  - for endpoint list check the ../constants/endpoints.js file
*/

axios.defaults.baseURL = ENDPOINT.BASE_URL;

export async function callAxios({ url, method, body = null, headers = null, extraHeader = null }) {
  try {
    const response = await axios(url, {
      method,
      data: extraHeader && extraHeader["Content-Type"] ? body : JSON.parse(body),
      headers: {
        ...JSON.parse(headers),
        ...extraHeader,
      },
    });
    return { data: response.data };
  } catch (err) {
    return { err };
  }
}

export default callAxios;
