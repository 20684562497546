import React, { useEffect, useState } from "react";
import { useAppState } from "../../../providers/AppStateProvider";
import Modal from "../Modal";
import Flex from "../common/Flex";
import { Button } from "../common/Button";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../common/SpinningLoader";
import * as S from "./styles";
import { ToastContainer, toast } from "react-toastify";
import { GreenCheckMark } from "../../../assets/svg";
import confettiAnimation from "../../../assets/lotties/confetti_animation.json";
import confettiSparkles from "../../../assets/confetti-sparkles.png";

import { TOAST_OPTIONS } from "../../../constants";
import Lottie from "react-lottie";
import { Text } from "../common/Text";

const ConnectWalletModal = ({ loadWeb3Modal, setShowModal, showModal, toggleModal, resumePrevFlow, displaySelectionModal }) => {
  const { closeConnectWalletModal, isConnectingWallet, linkWalletError, setLinkWalletError } = useAppState();

  useEffect(() => {
    if (closeConnectWalletModal) {
      setShowModal(false);

      // resumePrevFlow();
    }
  }, [closeConnectWalletModal]);

  return (
    <Modal
      beforeClose={() => {
        setLinkWalletError("");
        if (closeConnectWalletModal) {
          resumePrevFlow();
        }
      }}
      showModal={showModal}
      toggleModal={toggleModal}
      customStyles={{
        height: "568px",
        width: "501px",
        padding: "30px",
      }}
      tabletStyles={{
        width: "90%",
      }}
      mobileStyles={{
        width: "90%",
      }}
    >
      {isConnectingWallet ? (
        <SpinnerLoaderWrapper style={{ height: "100%" }}>
          <SpinnerLoader style={{ height: "40px", width: "40px" }} />
        </SpinnerLoaderWrapper>
      ) : (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          direction="column"
          style={{ height: "100%", position: "relative", overflowX: "hidden" }}
        >
          <S.HeaderContainer>
            <S.Header>Connect Wallet</S.Header>{" "}
            <S.SubHeader>Connect your wallet to join paid tournaments and claim your rewards!</S.SubHeader>
          </S.HeaderContainer>
          {linkWalletError && <S.ErrorText>{linkWalletError}</S.ErrorText>}
          <Button
            height="48px"
            width="80%"
            borderRadius="24px"
            onClick={() => {
              localStorage.setItem("linking_wallet", true);
              // loadWeb3Modal();
              displaySelectionModal();
              setShowModal(false);
              // togglePoolJoinModal()
            }}
          >
            Connect Wallet
          </Button>
          <S.Disclaimer>
            by signing in to create an account I accept company's{" "}
            <a href="https://blast.gg/terms/" target="_blank">
              Terms
            </a>{" "}
            and{" "}
            <a href="https://blast.gg/privacy-policy/" target="_blank">
              Privacy Policy
            </a>
            .
          </S.Disclaimer>
        </Flex>
      )}
    </Modal>
  );
};

export default ConnectWalletModal;
