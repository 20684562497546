import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";

import { Text } from "../../common/Text";
import Container from "../../common/Container";
import { Button } from "../../common/Button";
import Flex from "../../common/Flex";
import { useGetSecHeaders } from "../../../../hooks/SecurityHeaders";
import useCallAxios from "../../../../hooks/useAxiosCall";

import "react-phone-input-2/lib/style.css";
import "./phoneInputStyles.css";
import { BE_API_BASE_URL } from "../../../../constants/env";
import { toast } from "react-toastify";
import { Loader } from "../../../../views/ProfilePage/styles";
import { SpinnerLoader } from "../../common/SpinningLoader";
import { WhatsappIcon } from "../../../../assets/svg";

const VerifyNumber = ({
  userNumberInput,
  setUserNumberInput,
  errorMsg,
  setWhatsappNumber,
  setCountryCode,
  setDialCode,
  isLoadingNumberVerif,
}) => {
  return (
    <Flex direction="column" alignItems="center">
      <Text fontSize="22px">Verify WhatsApp Number</Text>
      <Flex alignItems="center" columnGap="8" >
        <Text color="#877ADD" fontSize="16px">
          Before joining, we need to verify your WhatsApp
        </Text>{" "}
        <WhatsappIcon style={{marginBottom: "16px"}} />
      </Flex>
      <Flex direction="column" alignItems="center" rowGap="8">
        <PhoneInput
          country={"uy"}
          value={userNumberInput}
          onChange={(val, data) => {
            setCountryCode(data?.countryCode);
            setDialCode(data?.dialCode);
            setUserNumberInput(val);
          }}
          inputClass="input_class"
          buttonClass="btn_class"
          dropdownClass="dropdown_class custom-scroll"
          searchClass="search_class"
          disableSearchIcon
          enableSearch
        />
        {errorMsg && (
          <Text fontSize="12px" color="#ED6140">
            {errorMsg}
          </Text>
        )}
      </Flex>
      <Container marginTop="90px" flex justifyContent="center">
        <Button
          borderRadius="30px"
          width="150px"
          height="40px"
          background="#6c47ff"
          disabled={!userNumberInput || isLoadingNumberVerif}
          onClick={setWhatsappNumber}
        >
          {isLoadingNumberVerif ? <SpinnerLoader /> : "Verify"}
        </Button>
      </Container>
    </Flex>
  );
};

export default VerifyNumber;
