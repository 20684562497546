import { useState } from "react";
import { useGetSecHeaders } from "./SecurityHeaders";
import useCallAxios from "./useAxiosCall";

/*
  ~ What it does? ~

  Get nickname information from the cloud

  ~ How can I use? ~

   const { data: nickData, loading: nickLoading, checkNickmame } = useNickname();
   ( example taken from ConnectAccountModal.jsx )

  */
export function useNickname() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const headers = useGetSecHeaders();

  const { callAxiosFunc } = useCallAxios();

  const checkNickmame = async nick => {
    setData(null);
    setError(null);
    setLoading(true);
    const response = await callAxiosFunc({
      method: "GET",
      url: `user/nick/${nick ? nick : "-"}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
    });
    if (response && response.err) {
      setError(true);
    }
    setLoading(false);
    setData(response.data);
  };

  return {
    data,
    loading,
    error,
    checkNickmame,
  };
}
