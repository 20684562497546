import React, { useEffect, useState } from "react";
import { useAppState } from "../../../providers/AppStateProvider";
import Modal from "../Modal";
import Flex from "../common/Flex";
import { Button } from "../common/Button";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../common/SpinningLoader";
import * as S from "./styles";
import { ToastContainer, toast } from "react-toastify";
import { GreenCheckMark } from "../../../assets/svg";
import confettiAnimation from "../../../assets/lotties/confetti_animation.json";
import confettiSparkles from "../../../assets/confetti-sparkles.png";

import { TOAST_OPTIONS } from "../../../constants";
import Lottie from "react-lottie";
import { Text } from "../common/Text";

const ConnectWalletModalClaims = ({
  loadWeb3Modal,
  displaySelectionModal,
  setShowModal,
  showModal,
  toggleModal,
  resumePrevFlow,
  connectModalPrizeDisplay,
  setMakeBtnRef,
}) => {
  const { closeConnectWalletModal, isConnectingWallet, linkWalletError, setLinkWalletError } = useAppState();

  useEffect(() => {
    if (closeConnectWalletModal) {
      setShowModal(false);

      // resumePrevFlow();
    }
  }, [closeConnectWalletModal]);

  const isConnectModalPrizeDisplayAvailable =
    connectModalPrizeDisplay && Object.keys(connectModalPrizeDisplay)?.length > 0;

  return (
    <Modal
      beforeClose={() => {
        setLinkWalletError("")
        if (closeConnectWalletModal) {
          resumePrevFlow();
        }
        setMakeBtnRef(null);
      }}
      showModal={showModal}
      toggleModal={toggleModal}
      customStyles={{
        height: "568px",
        width: "501px",
        padding: "30px",
      }}
      tabletStyles={{
        width: "90%",
      }}
      mobileStyles={{
        width: "90%",
      }}
    >
      {isConnectingWallet ? (
        <SpinnerLoaderWrapper style={{ height: "100%" }}>
          <SpinnerLoader style={{ height: "40px", width: "40px" }} />
        </SpinnerLoaderWrapper>
      ) : (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          direction="column"
          style={{ height: "100%", position: "relative", overflowX: "hidden" }}
        >
          {isConnectModalPrizeDisplayAvailable ? <img src={confettiSparkles} style={{ position: "absolute" }} /> : null}

          <S.HeaderContainer>
            {isConnectModalPrizeDisplayAvailable ? (
              <div style={{ position: "relative" }}>
                <div style={{ marginBottom: "20px" }}>
                  <Lottie options={{ animationData: confettiAnimation }} height={110} width={110} />
                </div>
                <Text color="#fff" fontSize="50px">
                  {connectModalPrizeDisplay?.poolPrizeWon}
                  <img
                    src={connectModalPrizeDisplay?.tokenImage}
                    style={{ height: "30px", width: "30px", marginLeft: "12px" }}
                  />
                </Text>
                <Text color="#A498ED" fontSize="16px">
                  You've earned this amount. Connect your wallet to collect your prize.
                </Text>
              </div>
            ) : null}
          </S.HeaderContainer>
          {linkWalletError && <S.ErrorText>{linkWalletError}</S.ErrorText>}

          <Button
            height="48px"
            width="80%"
            borderRadius="24px"
            onClick={() => {
              localStorage.setItem("linking_wallet", true);
              // loadWeb3Modal();
              displaySelectionModal();
              setShowModal(false);
              // togglePoolJoinModal()
            }}
          >
            Connect Wallet
          </Button>
          <S.Disclaimer>
            by signing in to create an account I accept company's{" "}
            <a href="https://blast.gg/terms/" target="_blank">
              Terms
            </a>{" "}
            and{" "}
            <a href="https://blast.gg/privacy-policy/" target="_blank">
              Privacy Policy
            </a>
            .
          </S.Disclaimer>
        </Flex>
      )}
    </Modal>
  );
};

export default ConnectWalletModalClaims;
