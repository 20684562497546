import React from "react";
import styled from "styled-components";
import Avatar from "../common/Avatar";
import { WhatsappIcon, InfoIconNew, DiscordSquareSmall, TwitchIcon } from "../../../assets/svg";
import { capitalizeFirstLetter, truncateWithDots } from "../../../helpers/utils";
import { Tooltip } from "antd";
import { Text } from "../common/Text";

const RowSpacing = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.2fr;
  padding: 10px 21px;
`;
const ContactRow = styled(RowSpacing)`
  column-gap: 14px;
  width: 100%;
  height: 50px;
  background-color: #3f2faa;
  border-radius: 6px;
  padding: 10px 21px;
  color: #fff;
  min-width: 1000px;
  align-items: center;
  a {
    text-decoration: underline;
  }
`;

const ContactRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  overflow-x: auto;
`;

const NameImageContainer = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

const ContactSection = ({ organizerInfo }) => {
  return (
    <ContactRowsContainer>
      <RowSpacing>
        <Text fontSize="14px" color="#A498ED">
          Nickname
        </Text>
        <Text fontSize="14px" color="#A498ED">
          Role
        </Text>
        <Text fontSize="14px" color="#A498ED">
          Twitch
        </Text>
        <Text fontSize="14px" color="#A498ED">
          Discord
        </Text>
        <Text fontSize="14px" color="#A498ED">
          WhatsApp
        </Text>
      </RowSpacing>
      {organizerInfo.map(item => (
        <ContactRow key={item.nickname}>
          <NameImageContainer>{truncateWithDots(item.nickname, 30)}</NameImageContainer>
          <span>{capitalizeFirstLetter(item.role)}</span>
          <span>
            {item.twitchUsername ? (
              <a href={`https://twitch.tv/${item.twitchUsername}`} target="_blank" rel="noopener noreferrer">
                <TwitchIcon />
              </a>
            ) : (
              "-"
            )}
          </span>
          <div style={{ paddingLeft: "20px" }}>
            {item.discordLink ? (
              <a href={item.discordLink} target="_blank" rel="noopener noreferrer">
                <DiscordSquareSmall />
              </a>
            ) : (
              "-"
            )}
          </div>

          <div style={{ paddingLeft: "20px" }}>
            {item.whatsapp ? (
              <a href={item.whatsapp} target="_blank" rel="noopener noreferrer">
                <WhatsappIcon />
              </a>
            ) : (
              "-"
            )}
          </div>

          {item.extraInfo ? (
            <Tooltip
              title={item.extraInfo}
              color="#7265C8"
              align="center"
              overlayStyle={{
                maxWidth: "256px",
                fontSize: "11px",
                padding: "11px 8px",
                borderRadius: "6px",
              }}
            >
              <InfoIconNew style={{ justifySelf: "self-end" }} />
            </Tooltip>
          ) : null}
        </ContactRow>
      ))}
    </ContactRowsContainer>
  );
};

export default ContactSection;
