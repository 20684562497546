import styled from "styled-components";

export const CheckinButton = styled.div`
  background: #201366;
  color: #fff;
  border-radius: 30px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 10px 18px;
  ${props => `
    background: ${props.checkedIn ? "#4E3CCA" : "#201366"};
    cursor: ${props.checkedIn ? "default" : "pointer"};
`}
`;

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;

export const RoundsContainer = styled.div`
  margin-top: 37px;
  margin-bottom: 65px;
  .slick-track {
    display: flex !important;
  }
  .slick-track .slick-slide {
    display: grid !important;
    height: auto !important;
    align-items: center !important;
    margin: 0px 1.5px !important;
  }
`;

export const RoundCard = styled.div`
  padding: 17px 20px 22px;
  margin: 24px 0;
  width: 100%;
  min-width: 278px;
  background: #3f2faa;
  border-right: 1px solid #fff;
  ${props => `
    border-radius: ${props.index === 0 ? "8px 0 0 8px" : props.finalRect ? "0 8px 8px 0" : "0"};	
    border-right: ${props.finalRect ? "transparent" : "1px solid #272054"};
    height: ${props.userInPool ? "179px" : "unset"};

`}

  @media (max-width: 1480px) {
    min-width: 277px;
  }
  @media (max-width: 1215px) {
    width: 100%;
  }

  @media (max-width: 1200px) {
    transform: scaleX(1) !important;
  }

  @media (max-width: 992px) {
    border-radius: 10px !important;
  }
`;

export const RoundNumber = styled.div`
  display: flex;
  gap: 18px;
  align-items: center;
  p {
    color: ${props => (props.active ? "#fff" : "#877ADD")};
    font-size: 12px;
    margin: 0;
  }
`;

export const Status = styled.div`
  background: #c33b3b;
  color: #fff;
  font-size: 9px;
  padding: 2px 4px;
  border-radius: 2px;
  min-width: 51px;
  display: flex;
  justify-content: center;
  ${props => `
  background: ${props.status === "ACTIVE" ? "#6C47FF" : "#c33b3b"};
  border-radius: ${props.status === "ACTIVE" ? "50px" : "0"};
  // z-index: ${props.active ? "99" : "unset"};
`}
`;

export const RoundDate = styled.div`
  font-size: 14px;
  line-height: 36px;
  color: #fff;
  font-weight: 400;
  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 24px;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 36px;
  }
`;

export const PlayerCount = styled.div`
  font-size: 12px;
  color: #877add;
  margin-top: 0px;
`;

export const ExtraRect = styled.div`
  display: flex;
  @media (max-width: 992px) {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  @media (max-width: 992px) {
    display: block;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const RoundFormatWrapper = styled.span`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 50px;
  border: 1.5px solid #6c47ff;
  font-size: 9px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  text-transform: capitalize;
`;
