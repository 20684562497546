import styled from "styled-components";
import { breakpoints } from "../../themes";
import defaultBanner from "../../assets/banner-5.png";
import customCursor from "../../assets/svg/not-editable-cursor.svg";

export const SectionContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  padding: 0px 72px 136px 38px;

  ${breakpoints.down(breakpoints.laptopL)} {
    padding: 0px 38px 136px 38px;
  }

  ${breakpoints.down(breakpoints.laptop)} {
    padding: 0px 16px 136px 16px;
  }
`;

export const AdSection = styled.div`
  height: 287px;
  width: 100%;
  max-width: 1107px;
  margin: auto;
  position: relative;
  z-index: 10;
  ${breakpoints.down(breakpoints.laptop)} {
    height: 200px;
  }
`;

export const AdImg = styled.img`
  position: absolute;
  top: -110px;
`;

export const PageTitle = styled.p`
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 16px;
  ${breakpoints.down(breakpoints.tablet)} {
    display: none;
  }
`;

export const AdContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 20;
`;

export const Summary = styled.div`
  width: 100%;
  height: 125px;
  background: #3f2faa;
  box-shadow: 0px 10px 60px rgba(63, 51, 140, 0.4);
  border-radius: 10px;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  display: flex;
  ${breakpoints.down(breakpoints.laptop)} {
    display: none;
  }
`;

export const MobileSummary = styled.div`
  width: 100%;
  height: 116px;
  background: #3f2faa;
  box-shadow: 0px 10px 60px rgba(63, 51, 140, 0.4);
  border-radius: 10px;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  display: none;
  ${breakpoints.down(breakpoints.laptop)} {
    display: flex;
  }
`;

export const CardWrap = styled.div`
  display: flex;
`;

export const Card = styled.div`
  padding: 20px 30px;
  border-right: 0.5px solid rgba(135, 122, 221, 0.3);
  &:last-child {
    border-right: none;
  }

  ${breakpoints.down(breakpoints.tablet)} {
    padding: 0px;
    border-right: none;
    &:last-child {
      p {
        text-align: end;
      }
    }
  }
`;

export const UserInfo = styled.div`
  padding: 20px 0px;
  display: flex;
  gap: 10px;

  ${breakpoints.down(breakpoints.laptop)} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    padding: 24px 0px;
    flex-direction: column;
    gap: 0px;
  }
`;

export const MobileNameCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Value = styled.p`
  font-size: 20px;
  color: white;
  line-height: 36px;
  margin-bottom: 0;
`;

export const Name = styled.p`
  font-size: 24px;
  color: white;
  line-height: 36px;
  margin-bottom: 0;
`;

export const Desc = styled.p`
  font-size: 12px;
  color: #877add;
  line-height: 18px;
  margin-bottom: 0;
`;

export const ShareProfile = styled.div`
  margin-top: 10px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #877add;
  font-size: 12px;
  line-height: 18px;
  gap: 10px;

  & span {
    margin-right: 16px;
  }

  & svg {
    width: 16px;
    height: 16px;
    cursor: pointer;

    path {
      fill: #877add;
      stroke: #877add;
    }
  }

  ${breakpoints.down(breakpoints.laptop)} {
    display: none;
  }
`;

export const SectionTitle = styled.p`
  font-size: 24px;
  line-height: 36px;
  margin: 39px 0px 23px 0px;
`;

export const BackButton = styled.div`
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  background: linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%);
  width: fit-content;
  padding-right: 12px;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const Section = styled.div`
  width: 100%;
  max-width: 1107px;
  margin: auto;
  z-index: 50;
`;

export const SectionContent = styled.div`
  border-radius: 10px;
  background: #3f2faa;
  padding: 44px 35px 60px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PersonalItem = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px;

  ${breakpoints.down(breakpoints.tablet)} {
    flex-direction: column;
  }
`;

export const AvatarChanger = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  gap: 24px;
`;

export const AddressText = styled.div`
  font-size: 16px;
  line-height: 18px;
  color: #877add;
  margin-top: 24px;
  text-align: center;
`;

export const AvatarWrap = styled.div`
  width: 147px;
  display: flex;
  justify-content: center;
`;

export const PersonalItemLeft = styled.div`
  width: 147px;

  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
    color: #877add;
    margin-bottom: 4px;
  }
`;

export const PersonalItemRight = styled.div`
  width: 273px;

  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

export const AvatarControl = styled.div`
  width: 273px;
  display: flex;
  gap: 11px;
  align-items: center;
  z-index: 100;

  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Input = styled.input`
  border-radius: 6px;
  height: 42px;
  width: 273px;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  padding: 9px 13px 7px 13px;
  background-color: #372d70;
  border: none;
  outline: none;

  cursor: ${customCursor};

  ::-webkit-input-placeholder {
    color: #372d72;
  }

  :-ms-input-placeholder {
    color: #372d72;
  }

  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

export const CardInput = styled.input`
  color: #ffffff;
  border: 1px solid #a498ed;
  background: #3f2faa;
  border-radius: 6px;
  height: 45px;
  width: ${props => (props.isEditable === true ? "275px" : "100%")};
  font-size: 14px;
  line-height: 26px;

  ::-webkit-input-placeholder {
    color: rgba(135, 122, 221, 1);
  }

  :-ms-input-placeholder {
    color: rgba(135, 122, 221, 1);
  }
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 12.34px;
  outline: none;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const FloatVarLabelInput = styled.input`
  border-radius: 6px;
  height: 45.03px;
  width: 234.33px;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  background-color: transparent;
  border: 1px solid rgba(135, 122, 221, 1);

  ::-webkit-input-placeholder {
    color: rgba(135, 122, 221, 1);
  }

  :-ms-input-placeholder {
    color: rgba(135, 122, 221, 1);
  }
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 12.34px;
  outline: none;
`;

export const BtnWrap = styled.div`
  display: flex;
  gap: 11px;
  align-items: center;
`;

export const AccountsWrap = styled.div`
  border-radius: 10px;
  background: #302862;
  padding: 44px 35px 60px 35px;
  display: flex;
  gap: 18px;
  & p {
    font-size: 14px;
    line-height: 36px;
  }

  ${breakpoints.down(breakpoints.tablet)} {
    flex-direction: column;
  }
`;

export const AccountItem = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;
`;

export const AccountWrap = styled.span`
  width: 149px;
  height: 42px;
  background: #372d70;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
`;

export const SwitchWrapper = styled.span`
  & .ant-switch {
    background-color: #6351da;
  }

  & .ant-switch .ant-switch-checked {
    background-color: #3d3188;
  }

  & .ant-switch .ant-switch-handle:before {
    background: #4d4296;
    opacity: 0.5;
    box-shadow: 0px 4px 4px #302862;
  }

  & .ant-switch-checked .ant-switch-handle:before {
    background: #be7eff;
    box-shadow: 0px 4px 4px #302862;
  }

  .ant-switch-disabled,
  .ant-switch-disabled * {
    cursor: pointer;
    opacity: 1;
  }
`;

export const PopoverContentWrap = styled.div`
  width: 197px;
  height: 110px;
  background: #3f2faa;
  padding: 12px 4px 12px 4px;
  color: white;
  font-size: 13px;
  line-height: 16px;

  & p {
    margin-bottom: 27px;
  }
`;

export const NoWrap = styled.span`
  white-space: nowrap;
`;

export const IconImage = styled.span`
  margin-right: 13px;
`;

export const UserBadge = styled.img`
  width: 54px;
  height: 54px;
  object-fit: cover;
`;

export const DiscordLinkWrapper = styled.div`
  svg {
    path {
      fill: white;
    }
  }
`;
export const PopoverText = styled.div``;

export const SaveButtonContainer = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

export const NicknameText = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
  margin-top: 11px;
`;

export const InfoTextSection = styled.div`
  margin-top: 29px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const InfoText = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const GameAccordionWrapper = styled.div`
  background: #3f2faa;
  width: 353px;
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 19px; /* Space below each card */
  break-inside: avoid; /* Prevent breaking inside columns */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch; /* Stretch items to fill the width */
  gap: 20px;
  transition: all 0.3s ease;
`;

export const AccordionHeader = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: start;
  gap: 25px;
  position: relative;

  :hover {
    cursor: pointer;
  }

  #toggle-content-arrow {
    transform: ${props => (props.visible ? "rotate(180deg)" : "rotate(0deg)")};
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const AccordionContent = styled.div`
  max-height: ${props => (props.visible ? "500px" : "0")};
  display: ${props => (props.visible ? "flex" : "none")};
  flex-direction: column;
  gap: 31px;
  justify-content: center;
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out; /* Use max-height */
  overflow: hidden;
`;

export const GameInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const GameDataContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const GameName = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: 19.2px;
`;

export const SetupTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SetupText = styled.a`
  color: #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 325;
  line-height: 19.2px;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const AccountStatusContainer = styled.div`
  height: 26px;
  border-radius: 6px;
  background: ${props => (props.isConnected ? "#6C47FF" : "#4e3cca")};
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatusText = styled.span`
  color: ${props => (props.connected ? "#fff" : "#a498ed;")};
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 350;
`;

export const ControlsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 26px;
`;

export const FloatLabel = styled.div`
  position: relative;
  span {
    position: absolute;
    top: -8px;
    left: 9.51px;
    background: #3f2faa;
    margin: 0;
    padding: 0;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 10px;
    color: #877add;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 45px;

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: #4e3cca;
    color: #ffffff;
    display: flex;
    align-items: center;
    border-radius: 6px;
    outline: none;
    border: none;
    height: 45px;
  }

  .ant-select-selection-placeholder {
    color: #ffffff;
  }
`;

export const SelectPlaceholder = styled.div`
  color: #ffffff;
`;

export const VerifyBtn = styled.button`
  background: linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%);
  border-radius: 16.5px;
  width: 93px;
  height: 33px;
  border: none;
  ${props => `
  cursor: ${props.disabled ? `not-allowed` : "pointer"};
  opacity: ${props.disabled ? `0.4` : "1"};
`}
`;

export const ErrorText = styled.div`
  color: #877add;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  margin-top: 8px;
  display: flex;
  div:nth-child(2) {
    color: #d06565;
    margin-left: 4px;
    text-decoration: underline;
  }
`;

export const Loader = styled.span`
  width: 17px;
  height: 17px;
  border: 3px solid #877add;
  border-bottom-color: #fff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

export const Banner = styled.div`
  width: 100%;
  height: 160px;
  border-radius: 10px;
  position: relative;
  box-shadow: inset 0 0 10em 3em #000;

  /* @media (max-width: 1200px) {
    display: none;
  } */
  ${props => `
    background-image: ${props.backgroundUrl ? `url(${props.backgroundUrl})` : `url(${defaultBanner})`};  
  `};
  background-size: cover;
  background-position: center;
  margin-bottom: 75px;
`;

export const Popover = styled.div`
  position: absolute;
  width: 167px;
  background-color: #3f2faa;
  right: 0;
  color: #ffffff;
  gap: 5px;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PopoverButtons = styled.div`
  display: flex;
  width: 157px;
  height: 34px;
  border-radius: 4px;
  gap: 12px;
  align-items: center;
  /* background-color: red; */
  padding-left: 10px;

  :hover {
    background-color: #4a39ba;
  }
`;

export const BottomRow = styled.div`
  // position: absolute;
  // left: 39px;
  display: flex;
  align-items: center;
  bottom: -40px;
  align-items: flex-end;
  height: 100%;
  padding-right: 30px;
  justify-content: space-between;
  width: 100%;

  ${props => `
    padding-left: ${props.isMobile ? `16px` : "38px"};  
    padding-bottom: ${props.isMobile ? `10px` : "50px"};  

  `};
`;

export const AvatarContainer = styled.div`
  border: 4px solid #be7eff;
  border-radius: 500px;
  padding: 6px;
  width: fit-content;
  position: relative;
  ${props => `
    margin-bottom: -40px;  
  `};

  div {
    visibility: hidden;
    background: #00000066;
    position: absolute;
    height: ${props => (props.isMobile ? `89px` : `101px`)};
    width: ${props => (props.isMobile ? `89px` : `101px`)};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
    ${props => `
         cursor: ${!props.thumbnailUploading ? `pointer` : `default`};  
      `};
  }

  &:hover {
    /* div {
      ${props => `
         visibility: ${props.isOwner ? `visible` : `hidden`};  
      `};
    } */
    div {
      visibility: visible;
    }
  }
`;

export const InputBoxContainer = styled.div`
  background-color: #ffffff12;
  height: 40px;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
  gap: 10px;
  width: 250px;
`;

export const TransparentArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  :hover {
    cursor: pointer;
  }
`;

export const TransparentButton = styled.button`
  background: #ffffff4d;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 37px;
  padding-left: 6px;
  padding-right: 10px;
  border: none;
`;

export const InfoSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 222px;
  gap: 19px;
  margin-bottom: 21px;

  @media (max-width: 1200px) {
    display: block;
    height: unset;
  }
`;

export const ClaimsBlock = styled.div`
  background: linear-gradient(249.73deg, #5487f4 2.87%, #8c54fd 89.48%);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  padding: 21px;
`;

export const StatsGraph = styled.div`
  background: #3f2faa;
  border-radius: 10px;
  grid-column-start: 2;
  grid-column-end: 4;

  @media (max-width: 1200px) {
    margin-top: 21px;
  }
`;

export const StatsContainer = styled.div`
  margin-top: 18px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 19px;
  margin-bottom: 53px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 64px;
  }
`;

export const FlexButtons = styled.div`
  display: flex;
  gap: 18px;
  margin-bottom: 37px;
  /* margin-top: 86px; */
`;

export const Badge = styled.div`
  width: 21px;
  height: 21px;
  position: absolute;
  background-color: red;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: -5px;
  font-size: 11px;
  font-weight: 325;
  border: 2px solid #3f2faa;
`;

export const MemberRow = styled.div`
  height: 88.79px;
  background: #3f2faa;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 12px;
  align-items: center;
  padding-left: 44px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    padding-left: 14px;
  }
`;

export const PrizeRow = styled.div`
  height: 88px;
  background: #3f2faa;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 37px;
  align-items: center;
`;

export const RequestText = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 350;
  color: #877add;
  margin-top: 60px;
`;

export const TokenImg = styled.img`
  width: 16px;
  height: 14px;
  /* margin-right: 8px;
  margin-left: 8px; */
`;

export const ConnectedAccountRow = styled.div`
  width: 100%;
  height: 84px;
  background: #3f2faa;
  border-radius: 10px;
  margin-top: 14px;
  display: flex;
  align-items: center;
  padding: 0 23px;
  justify-content: space-between;
`;

export const ConnectAccountBtn = styled.button`
  width: 128px;
  height: 43px;
  background: linear-gradient(180deg, #7283f7 0%, #aa57ff 100%);
  border-radius: 30px;
  border: none;
  cursor: pointer;
  &:disabled {
    cursor: wait;
  }
  @media (max-width: 1200px) {
    width: 88px;
  }
`;

export const GameCardInModalContainer = styled.div`
  min-height: 300px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff !important;

  p {
    font-size: 16px;
    font-weight: 325;
    line-height: 22px;
    text-align: left;
    color: #a498ed;
  }
`;
