import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { TopWinnersPodium } from "../../../../assets/svg";
import { breakpoints } from "../../../../themes";
import { truncateWithDots } from "../../../../helpers/utils";
import Avatar from "../../common/Avatar";

const Container = styled.div`
  width: 205px;
  height: 300px;
  border-radius: 10px;
  background: ${props => props.bgColor}, #201366;
  background-size: 100% 184px;
  background-repeat: no-repeat;
  display: flex;
  transition: all 0.3s ease-in-out;
  &:hover {
    margin-top: -5px;
    cursor: pointer;
    box-shadow: 0px 12px 30px 0px rgba(26, 14, 86, 1);
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    background: ${props => props.bgColorMobile}, #201366;
    background-repeat: no-repeat;
    background-size: 350px 100%;
  }

`;

const DataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  ${breakpoints.down(breakpoints.tablet)} {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
  }
`;

const TotalEarned = styled.h1`
  font-size: 36px;
  font-weight: bold;
  line-height: 43px;
  text-align: center;
  color: #ffffff;
  ${breakpoints.down(breakpoints.tablet)} {
    font-size: 36px;
    font-weight: bold;
    line-height: 43px;
    text-align: center;
    color: #ffffff;
    margin: 0;
  }
`;

const EarningTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${breakpoints.down(breakpoints.tablet)} {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: center;
    padding-top: 30px;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 9px;
  ${breakpoints.down(breakpoints.tablet)} {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  };
`;

const AvatarContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${props => props.mainBgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  ${breakpoints.down(breakpoints.tablet)} {
    background: ${props => props.bgColor};
    margin-bottom: 0;
  }
`;

const ProfileName = styled.p`
  margin-top: 5px;
  font-family: Gotham;
  font-size: 16px;
  font-weight: 325;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  ${breakpoints.down(breakpoints.tablet)} {
    font-style: normal;
    font-weight: 325;
    font-size: 16px;
    color: #FFFFFF;
    margin: 0;
  }
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// edit the styling of TopWinnersPodium
const Podium = styled(TopWinnersPodium)`
  ${breakpoints.down(breakpoints.tablet)} {
    width:90px;
    height:90px;
  }
`;


function TopWinners({ topWinnerData, bgColorData, bgMainColorData, bgColorMobileData, mobileColorData }) {
  // function that gets topWinnerData?.poolsPrize and returns the value with the currency symbol as an integer
  const prize = topWinnerData?.poolsPrize;
  const getPrize = prize => {
    if (prize) {
      const prizeValue = prize.toString().split(".");
      const prizeValueWithComma = prizeValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `$${prizeValueWithComma}`;
    } else {
      return "$0";
    }
  };

  return (
    <Container bgColor={bgColorData} bgColorMobile={bgColorMobileData}>
      <DataContainer>
        <ProfileContainer>
          <AvatarContainer mainBgColor={bgMainColorData} bgColor={mobileColorData}>
            <Avatar
              src={topWinnerData?.avatarURL}
              size="42px"
            />
          </AvatarContainer>
          <NameContainer>
            {breakpoints.down(breakpoints.mobileL) ? (
              <ProfileName>
                {topWinnerData?.nickname?.length > 9
                  ? truncateWithDots(topWinnerData?.nickname, 9)
                  : topWinnerData?.nickname}
              </ProfileName>
            ) : breakpoints.down(breakpoints.laptopL) ? (
              <ProfileName>
                {topWinnerData?.nickname?.length > 9
                  ? truncateWithDots(topWinnerData?.nickname, 11)
                  : topWinnerData?.nickname}
              </ProfileName>
            ) : (
              <ProfileName>
                {topWinnerData?.nickname?.length > 9
                  ? truncateWithDots(topWinnerData?.nickname, 13)
                  : topWinnerData?.nickname}
              </ProfileName> 
            )}
            <TotalEarned>{getPrize(prize)}</TotalEarned>
          </NameContainer>
        </ProfileContainer>
        <EarningTop>
          <Podium />
        </EarningTop>
      </DataContainer>
    </Container>
  );
}
export default TopWinners;
