import React, { createContext, useEffect, useState } from "react";
import { ENDPOINT } from "../constants/endpoints";
import { useGetSecHeaders } from "../hooks";
import callAxios from "../hooks/Axios";
import useCallAxios from "../hooks/useAxiosCall";

// Create a context object
const RequestContext = createContext();

// Create a provider for components to consume and subscribe to changes
function RequestProvider({ children }) {
  const headers = useGetSecHeaders();
  const { callAxiosFunc } = useCallAxios();
  const [totalRequests, setTotalRequests] = useState(0);
  const [userTeamRequestsToJoin, setUserTeamRequestsToJoin] = useState([]);
  const [userTeamsRequests, setUserTeamsRequests] = useState([]);

  const teamNotificationRequests = async () => {
    try {
      const response = await callAxiosFunc({
        method: "GET",
        url: `${ENDPOINT.TEAM_NOTIFICATIONS}`,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      });
      setTotalRequests(response?.data?.totalTeamRequests || 0);
      setUserTeamRequestsToJoin(response?.data?.userTeamsOwnerRequests || []);
      setUserTeamsRequests(response?.data?.userTeamsRequests || []);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (headers.token !== null) {
      teamNotificationRequests();
    }
  }, [headers.token]);

  return (
    <RequestContext.Provider
      value={{
        userTeamsRequests,
        setUserTeamsRequests,
        totalRequests,
        userTeamRequestsToJoin,
        teamNotificationRequests,
        setTotalRequests,
        setUserTeamRequestsToJoin,
      }}
    >
      {children}
    </RequestContext.Provider>
  );
}

export { RequestContext, RequestProvider };
