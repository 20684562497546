import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import Tooltip from "react-simple-tooltip";
import * as S from "./styles";
// import StepCounter from "../common/StepCounter";
import Input from "../common/Input";
import { PillButton } from "../common/PillButton/PillButton";
import { useNickname } from "../../../hooks/NicknameChecker";
import { TickSuccess, AccountAvatar, RedCross, Tick, TickSuccessNewPurple, WarningIcon } from "../../../assets/svg";
import { useValidateActivisionUserId } from "../../../hooks/Tracker";
import { useUserInformation } from "../../../hooks/UserInformation";
import Loader from "../Loader";
import Checkbox from "../common/Checkbox";
import { SpinnerLoader } from "../common/SpinningLoader";
import ProfilePage from "../../../views/ProfilePage/ProfilePage";
import { TextNoMargin } from "../common/Text";

export default function ConnectAccountModal({
  step = 0,
  isOpen,
  opacity,
  afterOpen,
  beforeClose,
  toggleModal,
  modalHeaderText,
  defaultNick = "",
  requiredGameId,
  afterConnectCb,
}) {
  const [stepVisible, setStepVisible] = useState(step);
  const [buttonTitleIndex, setButtonTitleIndex] = useState(step);
  const [showButton, setShowButton] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(!step === 1);
  const [nickname, setNickname] = useState(defaultNick);
  const [userId /* , setUserId */] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [userIsRegistered, setUserIsRegistered] = useState(false);
  const title = ["Create your nickname", "", ""];
  const buttonTitle = ["Next", "Connect"];
  const [btnLoading, setBtnLoading] = useState(false);
  // const activisionIdToolTip = [
  //   "e.g.: user#1234567",
  //   "In Privacy Settings, set Game Play Data to <FRIENDS + THIRD PARTY PARTNERS>",
  // ];
  const platform = "atvi"; // only Activision so far
  const [isCheck, setIsCheck] = useState(false);

  // custom hooks
  const { data: nickData, loading: nickLoading, checkNickmame } = useNickname();
  const { userIsValid /* , checkUser */ } = useValidateActivisionUserId();
  const { setUserNickname, setUserAccount, setUserFirstLogin } = useUserInformation();
  const [skipClicked /* , setSkipClicked */] = useState(false);

  const history = useHistory();

  const handleNickOnChange = value => {
    setNickname(value);
  };

  function agreeTermsAndConditions() {
    setIsCheck(!isCheck);
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (nickname.length > 3 && isOpen) {
        checkNickmame(nickname);
      }
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [nickname]);

  useEffect(() => {
    setNickname(defaultNick);
  }, [defaultNick]);

  // const handleUserIdOnChange = value => {
  //   setUserId(value);
  //   setNextDisabled(!(value.length > 0));
  // };

  const handleButtonClick = async () => {
    switch (stepVisible) {
      case 0:
        // setNextDisabled(true);
        setBtnLoading(true);
        await setUserFirstLogin(false, () => {
          setStepVisible(1);
          setButtonTitleIndex(1);
          setUserNickname(nickname);
          setBtnLoading(false);
        });
        break;
      case 1:
        history.push("/profile");
        toggleModal(false);
        break;
      case 2:
        toggleModal(false);
        break;
      default:
        break;
    }
  };

  // const skipLinkingAccount = () => {
  //   setButtonTitleIndex(2);
  //   setShowButton(true);
  //   setNextDisabled(false);
  //   setStepVisible(2);
  //   setSkipClicked(true);
  // };

  useEffect(() => {
    if (buttonTitleIndex === 0) {
      if (nickname.length > 3 && nickData === false && !nickLoading) {
        setNextDisabled(false);
      } else {
        setNextDisabled(true);
      }
    }
  }, [nickLoading, nickData, buttonTitleIndex, nickname.length]);

  useEffect(() => {
    if (userIsValid === 1) {
      setShowLoading(false);
      setShowButton(true);
      // return;
    }
    if (userIsValid === 3) {
      setUserAccount(platform, userId)
        .then(result => {
          setShowLoading(false);
          if (result.error) {
            setUserIsRegistered(true);
            setShowButton(true);
            setNextDisabled(true);
            setStepVisible(1);
            setButtonTitleIndex(1);
          } else {
            setButtonTitleIndex(2);
            setShowButton(true);
            setNextDisabled(false);
            setStepVisible(2);
          }
        })
        .catch(() => {});
    }
  }, [userIsValid, setUserAccount, userId]);

  const nickNameIconRender = () => {
    if (!nickname || (nickname && nickname.length <= 3)) return "";
    if (nickLoading) return <S.loader />;
    if (nickData) return <RedCross style={{ width: "16px", height: "11px" }} alt="aloha" />;
    return <Tick style={{ width: "16px", height: "11px" }} />;
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <S.FirstStepContainer>
            <S.InputContainer>
              <Input
                width="200px"
                height="41px"
                inputValue={nickname}
                onchange={handleNickOnChange}
                placeholder="Nickname"
              />
              <S.IconContainer>{nickNameIconRender()}</S.IconContainer>
            </S.InputContainer>
          </S.FirstStepContainer>
        );
      case 1:
        return (
          <S.SecondStepContainer>
            <WarningIcon />
            <div>{modalHeaderText || "You made it"}</div>
            <p>Let&apos;s start connecting your gaming accounts to join the tournaments.</p>
          </S.SecondStepContainer>
        );
      case 2:
        return (
          <S.ThirdStepContainer>
            <S.TickContainer>
              <S.TickArmContainer>
                <S.TickArm1 />
                <S.TickArm2 />
              </S.TickArmContainer>
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="134px"
                height="134px"
                viewBox="0 0 134 134"
                enableBackground="new 0 0 134 134"
              >
                <circle
                  className="tick-circle "
                  fill="transparent"
                  stroke="#2ED06E"
                  strokeWidth="3"
                  strokeLinejoin="miter"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  cx="68"
                  cy="68"
                  r="64"
                  transform="rotate(-90 68 68)"
                />
              </svg>
            </S.TickContainer>
          </S.ThirdStepContainer>
        );
      default:
        return <span />;
    }
  };

  return (
    <div>
      <S.StyledModal
        showModal={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        toggleModal={toggleModal}
        opacity={opacity}
        header={
          <TextNoMargin fontSize="28px" fontWeight="325">
            Connect your gaming account
          </TextNoMargin>
        }
      >
        {requiredGameId ? (
          <ProfilePage requiredGameId={requiredGameId} afterConnectCb={afterConnectCb} />
        ) : (
          <S.ModalContainer>
            {buttonTitleIndex !== 2 ? (
              <>
                <S.TitleWrapper>
                  {stepVisible === 0 && (
                    <S.AvatarContainer>
                      <AccountAvatar />
                    </S.AvatarContainer>
                  )}
                  <S.Title>{title[stepVisible]}</S.Title>
                </S.TitleWrapper>
                <S.InputSectionWrapper>{getStepContent(stepVisible)}</S.InputSectionWrapper>

                {nickData && (
                  <S.errorMessageContainer>
                    <S.errorMessage>Nickname is already taken</S.errorMessage>
                  </S.errorMessageContainer>
                )}

                {buttonTitleIndex === 0 && (
                  <S.CheckboxContainer>
                    <Checkbox onClick={agreeTermsAndConditions} checked={isCheck} isChecked={isCheck} />
                    <S.CheckTerms>
                      By clicking next you&lsquo;re agreeing to our
                      <S.Span>
                        {" "}
                        <S.Link href="https://www.blast.gg/terms" target="_blank">
                          Terms and Conditions.
                        </S.Link>
                      </S.Span>
                    </S.CheckTerms>
                  </S.CheckboxContainer>
                )}

                {showLoading && (
                  <S.LoadingIndicatorContainer>
                    <Loader animation="rotate" width={100} />
                  </S.LoadingIndicatorContainer>
                )}

                {userIsValid === 1 && (
                  <S.errorMessageContainer>
                    <S.errorMessage color="#877ADD">We couldn&apos;t find you.&nbsp;</S.errorMessage>
                    <a href="https://s.activision.com/activision/profile" target="_blank" rel="noopener noreferrer">
                      <S.errorMessage style={{ textDecoration: "underline" }}>Check your account</S.errorMessage>
                    </a>
                  </S.errorMessageContainer>
                )}
                {userIsValid === 2 && (
                  <S.errorMessageContainer>
                    <S.errorMessage>User need to change permissions</S.errorMessage>
                  </S.errorMessageContainer>
                )}

                {userIsRegistered && (
                  <S.errorMessageContainer>
                    <S.errorMessage>Activision ID is already registered, Contact us on discord...</S.errorMessage>
                  </S.errorMessageContainer>
                )}
              </>
            ) : (
              <>
                <S.SuccessWrapper>
                  <TickSuccess />
                </S.SuccessWrapper>
                <S.BigContent>Boom! You made it </S.BigContent>
                {skipClicked ? (
                  <>
                    <S.Description>Your nickname has been created,</S.Description>
                    <S.Description>you need to connect your gaming account later.</S.Description>
                  </>
                ) : (
                  <>
                    <S.Description>Your account has been created successfully.</S.Description>
                    <S.Description>Let&apos;s start making money</S.Description>
                  </>
                )}
              </>
            )}

            {showButton && (
              <>
                <S.ButtonContainer>
                  <div>
                    <PillButton
                      disabled={btnLoading || nextDisabled || (buttonTitleIndex === 0 && (!isCheck || !nickname))}
                      onClick={handleButtonClick}
                      borderRadius="30px"
                      gradient={
                        nextDisabled || (buttonTitleIndex === 0 && (!isCheck || !nickname))
                          ? "linear-gradient(141.97deg, rgba(29, 197, 236, 0.5) -117.24%, rgba(170, 87, 255, 0.5) 92.36%)"
                          : "linear-gradient(141.97deg, rgba(29, 197, 236, 1) -117.24%, rgba(170, 87, 255, 1) 92.36%)"
                      }
                      color={
                        nextDisabled || (buttonTitleIndex === 0 && (!isCheck || !nickname))
                          ? "rgba(255, 255, 255, 0.5)"
                          : "#FFF"
                      }
                      fontSize="15px"
                      height="45px"
                      padding="12px 20px"
                    >
                      {btnLoading ? <SpinnerLoader /> : buttonTitle[buttonTitleIndex]}
                    </PillButton>
                  </div>
                </S.ButtonContainer>
              </>
            )}
          </S.ModalContainer>
        )}
      </S.StyledModal>
    </div>
  );
}
