import styled from "styled-components";
import Modal from "styled-react-modal";

export const StyledModal = Modal.styled`
    width: ${props => (props.isMobile ? "354px" : "583px")};
    height:  ${props => (props.isMobile ? "fit-content" : "541px")};
    display: flex;
    flex-direction: column;
    background-color: #342788;
    // border: ${props => (props.error ? "1px solid #D06565" : "none")};
    border-radius: 4px;
    opacity: 1;
    transition : all 0.3s ease-in-out;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 450px) {
      top: 20%;
      transform: translateY(-20%);
    }
    
`;

export const ModalContainer = styled.div`
  width: 100%;
`;

export const MainWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const ContentWrapper = styled.div`
  position: absolute;
  top: 21px;
  left: 23px;
  z-index: 2;
`;

export const BgImage = styled.img`
  width: 553px;
  height: 193px;
  margin: 13px 17px 0 13px;
`;
export const mobBg = styled.div`
  width: 333px;
  height: 173px;
  background: linear-gradient(245.83deg, #12c2ea -21.14%, #7c00fa 118.16%);
  border-radius: 10px;
  margin: 13px 17px 0 13px;
`;

export const Img = styled.img`
  position: absolute;
  bottom: 0;
  right: 17px;
  z-index: 1;
  height: 193px;
`;

export const TimeWrapper = styled.div`
  height: 30px;
  border-radius: 4px;
  background-color: #ffffff54;
  padding: 4px;
  display: flex;
  align-items: center;
`;

export const TimeAndIconsWrapper = styled.div`
  margin-left: 14px;
  display: flex;
  gap: 10.5px;
`;

export const TimeText = styled.p`
  font-size: 11px;
  line-height: 17px;
  color: ${props => props.theme.darkBackground};
  margin: 0px;
`;

export const SubTitle = styled.p`
  font-size: 16px;
  line-height: 36px;
  color: white;
  margin: 23px 0 0 21px;
`;

export const Title = styled.p`
  font-size: 22px;
  line-height: 36px;
  color: white;
  margin-left: 19px;
  margin-top: 3px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 18px;
  right: 26px;
  z-index: 3;

  :hover {
    cursor: pointer;
  }
`;

export const SettingsWrapper = styled.div`
  width: 100%;
`;

export const CurrencyWrapper = styled.div`
  width: 273px;
  height: 67px;
  border: none;
  border-radius: 4px;
  margin: 50px auto 0;
  background-color: #3F2FAA;
  padding: 42px 50px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CurrencyText = styled.p`
  font-size: 22px;
  font-weight: 400;
  line-height: 36px;
  color: white;
`;

export const CriptoWrapper = styled.div`
  width: 273px;
  height: 53px;
  border: none;
  border-radius: 4px;
  margin: 13px auto 13px;
  background-color: #3F2FAA;
  padding: 11px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CriptoContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 11px;
`;

export const CriptoIconWrapper = styled.div`
  display: inline-block;
  transition-duration: 0.5s;
  transition-property: transform;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CriptoText = styled.p`
  font-size: 16px;
  line-height: 32px;
  color: white;
  margin-bottom: 0;
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

export const CheckBox = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 11px;
  margin-left: ${props => (props.isMobile ? "41px" : "155px")};
  border: 2px solid;
  border-color: ${props => (props.checked ? "#7485ff" : "#3A2F81")};
  border-radius: 4px;
  box-sizing: border-box;
  background: ${props => (props.checked ? "#3A2F81" : "#272054")};
  :hover {
    background: #3a2f81;
  }
`;

export const CheckBoxText = styled.p`
  position: relative;
  bottom: 5px;
  width: 260px;
  height: 32px;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 32px;
  color: #877add;
`;

export const ToolTipContainer = styled.div`
  position: relative;
  top: -28px;
  left: 382px;
  width: 200px;
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -13px;
`;

export const ErrorMessage = styled.p`
  color: #d06565;
`;

export const loaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const errorWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
`;

export const loader = styled.span`
    width: 35px;
    height: 35px;
    border: 3px solid #877ADD;
    border-bottom-color: #3A2F81;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const modalHeader = styled.div`
  color: #d1ccef;
  font-size: 20px;
  line-height: 36px;
  font-weeight: 400;
  margin-bottom: 17px;
`;

export const modalSubtext = styled.div`
  color: #877add;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
`;

export const PrizeWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 19px;
`;

export const TextBlock = styled.div`
  border-radius: 8px;
  padding: 5px 12px;
  background: rgb(0, 0, 0, 0.085);
  height: 60px;
  color: white;
`;

export const TokenImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  margin-left: 8px;
`;

export const BlockDescription = styled.p`
  color: #ffffff7a;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 0px;
`;

export const BlockContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  color: white;
  margin-bottom: 0px;
`;
