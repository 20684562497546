import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "../../Modal";
import { GreenCheckMark, GreenValidationIcon, RedCross, YellowWarningIcon } from "../../../../assets/svg";
import callAxios from "../../../../hooks/Axios";
import { ENDPOINT } from "../../../../constants/endpoints";
import { useGetSecHeaders } from "../../../../hooks";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../../../constants";
import { Flex } from "../MyTeamCard/styles";

const Container = styled.div`
  width: 500px;
  height: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleContainer = styled.div`
  margin: 40px 0;
`;

const Title = styled.h1`
  font-style: normal;
  font-weight: 325;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
`;

const Description = styled.p`
  width: 317px;
  height: 51px;
  font-style: normal;
  font-weight: 325;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #a498ed;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
  width: 100%;
`;

const TeamInput = styled.input`
  width: 250px;
  height: 48px;
  background: #3f2faa;
  border: 1px solid #877add;
  border-radius: 4px;
  color: #ffffff;
  padding-left: 17px;
  &::placeholder {
    color: #877add;
    font-weight: 325;
    font-size: 15px;
    line-height: 18px;
  }
`;

export const Button = styled.button`
  width: 112px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background: linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%);
  border-radius: 20px;
  border: none;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  &:hover {
    cursor: pointer;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const IconContainer = styled.div`
  height: 22px;
  width: 22px;
  position: absolute;
  right: 80px;
`;

const Loader = styled.span`
  width: 17px;
  height: 17px;
  border: 3px solid #877add;
  border-bottom-color: #3a2f81;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const TeamsModal = ({ showModal, toggleModal, setNewTeamData }) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTeamNameInvalid, setIsTeamNameInvalid] = useState(false);
  const headers = useGetSecHeaders();

  /**
   * "If the code is invalid and the user has not submitted, return an empty string. If the code is
   * invalid and the user has submitted, return a red cross. If the code is valid and the user has not
   * submitted, return nothing. If the code is valid and the user has submitted, return a green check
   * mark."
   */
  const codeIconRender = invalidNameOnSubmit => {
    if (isLoading === true) {
      return <Loader />;
    }
    if (hasSubmitted === true && invalidNameOnSubmit === false) {
      return <GreenCheckMark />;
    }
    if (hasSubmitted === false && invalidNameOnSubmit === true) {
      return <RedCross width="17px" height="17px" />;
    }
    return "";
  };

  // get the content from the input with classname teamNameInput and set it to the state
  const handleTeamName = async () => {
    setHasSubmitted(true);
    try {
      const teamNameInput = document.querySelector(".teamNameInput");
      const teamName = teamNameInput.value;
      setIsLoading(true);
      const response = await callAxios({
        method: "POST",
        url: `${ENDPOINT.CREATE_TEAM}`,
        body: JSON.stringify({ name: teamName, visibility: "visible" }),
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      });

      // setNewTeamData is a function that updates the state on the parent component
      if (response?.data?.error === false) {
        setNewTeamData(response?.data?.data);
        setIsTeamNameInvalid(false);
        toast(
          <Flex alignItems="center" columnGap={10}>
            <GreenValidationIcon style={{ height: "20px", width: "20px" }} />
            <p style={{ margin: 0 }}>{response?.data?.message}</p>
          </Flex>,
          TOAST_OPTIONS,
        );
      } else {
        toast(
          <Flex alignItems="center">
            <YellowWarningIcon style={{ height: "40px", width: "40px" }} /> {response?.err?.response?.data?.message}
          </Flex>,
          TOAST_OPTIONS,
        );
        setIsTeamNameInvalid(true);
      }
    } catch (error) {
      console.log({ error });
      setHasSubmitted(false);
    }
  };

  useEffect(() => {
    codeIconRender(isTeamNameInvalid);
    if (hasSubmitted === true && isTeamNameInvalid === false) {
      setTimeout(() => {
        setIsLoading(false);
        toggleModal();
        setHasSubmitted(false);
      }, 2000);
    } else if (hasSubmitted === true && isTeamNameInvalid === true) {
      setIsLoading(false);
    }
  }, [hasSubmitted, isTeamNameInvalid]);

  return (
    <Modal showModal={showModal} toggleModal={toggleModal} customStyles={{ padding: "30px 25px 25px 25px" }}>
      <Container>
        <TitleContainer>
          <Title>Create a team</Title>
          <Description>To create a team you need to enter a team name</Description>
        </TitleContainer>
        <ContentContainer>
          <InputWrapper>
            <TeamInput placeholder="Team name" className="teamNameInput" maxLength={15} />
            <IconContainer>{codeIconRender(isTeamNameInvalid)}</IconContainer>
          </InputWrapper>
          <Button onClick={handleTeamName}>Create</Button>
        </ContentContainer>
      </Container>
    </Modal>
  );
};

export default TeamsModal;
