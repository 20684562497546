import styled from "styled-components";

export const RowContainer = styled.div`
  height: 88.79px;
  background: #312c83;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 12px;
  align-items: center;
  padding-left: 44px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    padding-left: 14px;
  }

  :hover {
    /* background-color: #3f2faa; */
  }
`;

export const Member = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

export const MenuBtn = styled.button`
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  padding: 8px 17px;
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

export const MakeCaptainBtn = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: none;
  font-size: 12px;
  text-decoration: underline;
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;
