import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { sessionState } from "../state/globalState";
import * as crypto from "crypto-js";
import { SEED } from "../constants/env";

/*
  ~ What it does? ~

  Return security object to add in every request header

  ~ How can I use? ~

  const headers = useGetSecHeaders();

  ~ Features ~

  - gets the security object from the global state
  - returns the security object
*/

/*
*/

export const useGetSecHeaders = () => {
  const userSession = useRecoilValue(sessionState);
  const [headers, setHeaders] = useState({ token: null, address: null, ip: null, userId: null });

  useEffect(() => {
    if (userSession) {
      const secHeader = {
        token: userSession.access_token,
        address: userSession.user.address,
        ip: userSession.ip,
        userId: userSession.user ? userSession.user.id : "",
      }
      setHeaders({
        token: userSession.access_token,
        address: userSession.user.address,
        ip: userSession.ip,
        userId: userSession.user ? userSession.user.id : "",
        encryptedHeader: crypto.AES.encrypt(JSON.stringify(secHeader), SEED).toString()
      });
    }
  }, [userSession, userSession?.user]);
  return headers;
};
