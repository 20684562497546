import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { KoinsLogo } from "../../../../assets/svg";
import { APPROVE_AMOUNT } from "../../../../constants/env";
import { useTournamentHook } from "../../../../hooks";
import { useWallet } from "../../../../providers/WalletProvider";
import { Button } from "../../common/Button";
import Loader from "../../Loader";
import Modal from "../../Modal";
import KoinCard from "./KoinCard";
import * as S from "./styles";

const KoinsModal = ({
  koinsData,
  showModal,
  toggleModal,
  koinsJoinPool,
  entranceFee,
  entranceTokenImage,
  entranceTokenSymbol,
  convertedEntranceFee,
  approveAllchecked,
  entranceTokenAddress,
  contractAddress,
}) => {
  const [selectedKoins, setSelectedKoins] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [userTokenBalance, setUserTokenBalance] = useState(ethers.BigNumber.from(0));
  const [remainingBalance, setRemainingBalance] = useState(ethers.BigNumber.from(0));
  const [entranceBigInt, setEntranceBigInt] = useState(ethers.BigNumber.from(0));
  const Link = "https://opensea.io/collection/koinsnft";

  const defApproveAmount = approveAllchecked ? ethers.constants.MaxUint256 : ethers.BigNumber.from(APPROVE_AMOUNT);

  const { checkTokenApproved, getTokenBalance } = useTournamentHook();

  const handleToggle = id => {
    if (selectedKoins.includes(id)) {
      setSelectedKoins(prev => prev.filter(x => x !== id));
      let sum = remainingBalance.add(entranceBigInt);
      setRemainingBalance(sum);
    } else {
      setSelectedKoins([...selectedKoins, id]);
      let diff = remainingBalance.sub(entranceBigInt);
      setRemainingBalance(diff);
    }
  };

  const handleError = () => {
    console.log("Error occured while joining koins pool");
    setHasSubmitted(false);
  };

  const handleSubmit = async () => {
    setHasSubmitted(true);
    let obj = selectedKoins.map(koinId => ({
      id: koinId,
    }));
    try {
      await koinsJoinPool({ joinConfiguration: obj }, handleError);
    } catch (err) {
      console.log({ err });
    } finally {
      setHasSubmitted(false);
    }
  };

  useEffect(() => {
    const bnstr = ethers.utils.parseUnits(String(entranceFee), "mwei");
    setEntranceBigInt(bnstr);
  }, [entranceFee]);

  useEffect(() => {
    if (!showModal || !entranceTokenAddress) return;
    (async function main() {
      // set loading flag to true
      setIsLoading(true);

      // check and set token approval
      try {
        const balanceRes = await getTokenBalance(entranceTokenAddress);
        setRemainingBalance(balanceRes);

        const tokenAllowance = koinsData.length * entranceFee;
        const approvedRes = await checkTokenApproved(entranceTokenAddress, tokenAllowance, contractAddress);
        setIsApproved(approvedRes);

        // check and set user token balance
      } catch (err) {
        console.log({ err });
      } finally {
        setHasSubmitted(false);
        setIsLoading(false);
      }
    })();
  }, [showModal]);

  const onCloseCallback = () => {
    setHasSubmitted(false);
    setSelectedKoins([]);
  };

  return (
    <>
      <Modal
        showModal={showModal}
        toggleModal={() => toggleModal(onCloseCallback)}
        header={
          <div>
            <S.HeaderContainer>
              <div>Select your koins</div>
              <S.SelectedKoinsCount>{`${selectedKoins.length} / ${koinsData.length}`}</S.SelectedKoinsCount>
            </S.HeaderContainer>
            <S.SubText>choose the koins that you&apos;ll use to compete</S.SubText>
          </div>
        }
        customStyles={{ maxHeight: "644px", background: "#1C1644", minHeight: "548px" }}
      >
        {isLoading ? (
          <S.LoaderWrapper>
            <Loader animation="rotate" width={100} />
          </S.LoaderWrapper>
        ) : (
          <>
            <S.Container>
              {koinsData.length > 0 ? (
                <S.CardsContainer>
                  {koinsData.map(data => (
                    <KoinCard
                      koinInfo={data}
                      handleToggle={handleToggle}
                      shouldDisable={selectedKoins.includes(data.id) == false && remainingBalance.lt(entranceBigInt)}
                      entranceFee={entranceFee}
                      entranceTokenImage={entranceTokenImage}
                      entranceTokenSymbol={entranceTokenSymbol}
                    />
                  ))}
                </S.CardsContainer>
              ) : (
                <S.Container>
                  <S.NoKoinsContainer>
                    <S.NoKoinsTextContainer>
                      <S.NoKoinsTitle>It seems you don&apos;t have Koins to compete</S.NoKoinsTitle>
                      <S.NoKoinsText>
                        You can buy your Koins{" "}
                        <S.Link href={Link} target="_blank" rel="noopener noreferrer">
                          here
                        </S.Link>
                      </S.NoKoinsText>
                    </S.NoKoinsTextContainer>
                  </S.NoKoinsContainer>
                </S.Container>
              )}
            </S.Container>
            <S.ButtonContainer>
              <S.SubText>
                {`You will add ${selectedKoins.length} new koins for ${selectedKoins.length * entranceFee}  `}
                <KoinsLogo />
              </S.SubText>
              <Button
                disabled={selectedKoins.length < 1 || hasSubmitted}
                onClick={handleSubmit}
                width="109.29px"
                height="34.34px"
                borderRadius="30px"
              >
                {isApproved ? "Next" : "Approve"}
              </Button>
            </S.ButtonContainer>
          </>
        )}
      </Modal>
    </>
  );
};

export default KoinsModal;
