import styled from "styled-components";
import { breakpoints } from "../../../../themes";

export const Container = styled.div`
  width: 100%;
  height: 200px;
  background: #3f2faa;
  border-radius: 6px;
  display: flex;
  padding: 20px;
  align-items: center;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

export const ImageAvatar = styled.img`
  width: 59px;
  height: 59px;
  border-radius: 50%;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 36px;
    height: 36px;
  }
`;

export const Flex = styled.div`
  display: flex;
  ${props => `
    gap: ${props.gap ? `${props.gap}px` : "unset"}};
    row-gap: ${props.rowGap ? `${props.rowGap}px` : "unset"}};
    column-gap: ${props.columnGap ? `${props.columnGap}px` : "unset"}};
    flex-direction: ${props.direction ? props.direction : "row"};
    align-items: ${props.alignItems ? props.alignItems : "unset"};
    justify-content: ${props.justifyContent ? props.justifyContent : "unset"};
  `}
`;

export const FlexGap = styled.div`
  display: flex;
  align-items: center;
  ${props => `gap: ${props.gap ? `${props.gap}px` : "unset"}};`}
`;

export const Text = styled.div`
  ${props => `
    color: ${props.color ? `${props.color}` : "#fff"}};
    font-size: ${props.size ? `${props.size}px` : "16px"}};
    font-weight: ${props.weight ? `${props.weight}` : "unset"}};
  `}
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const ImageContainer = styled.div`
  ${breakpoints.down(breakpoints.tablet)} {
    width: 36px;
    height: 36px;
  }
`;

export const Button = styled.button`
  width: 200px;
  width: ${props => (props.width ? props.width : "200px")};
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: ${props =>
    props.requestSent === true ? "#4635B6" : "linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%)"};
  border-radius: 20px;
  border: none;
  color: #ffffff;
  font-weight: 350;
  font-size: 13px;
  &:hover {
    cursor: pointer;
  }
  ${breakpoints.down(breakpoints.tablet)} {
    width: 120px;
    height: 26px;
  }
`;

export const TeamName = styled.div`
  font-size: 26px;
  color: #fff;
  white-space: nowrap;
  :hover {
    color: #be7eff;
    text-decoration: underline;
  }
  @media (max-width: 768px) {
    font-size: 24px;
  }
  @media (max-width: 425px) {
    font-size: 20px;
  }
`;

export const PlayerAvatarsContainer = styled.div`
  display: flex;
  span {
    border: ${props => (props.border ? props.border : "3px solid #3f2faa")};
    border-radius: 500px;
  }
  span:not(:nth-child(1)) {
    margin-left: -12px;
    /* border: ${props => (props.border ? props.border : "2px solid #1b0939")}; */
    z-index: 0;
  }
  span:hover {
    transform: translateY(-6px);
    transition: all 0.2s linear;
  }

  span:not(:hover) {
    transition: all 0.2s linear;
  }

  svg:not(:nth-child(1)) {
    margin-left: -10px;
    border-radius: 500px;
    z-index: 0;
  }
`;

export const PlayerCountCircle = styled.div`
  width: 40.37px;
  height: 40.37px;

  background: linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%);
  border-radius: 30px;
  margin-left: -10px;
  border: ${props => (props.border ? props.border : " 2px solid #3f2faa")};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 13px;
  font-weight: 350;
  z-index: 4;
  cursor: pointer;
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;

  div:nth-child(1) {
    margin-bottom: 8px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 14px;
    align-items: start;

    div:nth-child(1) {
      margin-bottom: 2px;
    }
  }
`;
