import React from "react";
import styled from "styled-components";

export const Text = styled.p`
    font-family: ${props => props.fontFamily ? props.fontFamily : "Gotham" };
    font-style: ${props => props.fontStyle ? props.fontStyle : "normal" };
    font-weight: ${props => props.fontWeight ? props.fontWeight : "normal" };
    font-size: ${props => props.fontSize ? props.fontSize : "12px" };
    line-height: ${props => props.lineHeight ? props.lineHeight : "18px" };
    color: ${props => props.color ? props.color : "white" };
    cursor: ${props => props.cursor ? props.cursor : "default" };
    text-decoration: ${props => props.textDecoration ? props.textDecoration : "" };
`;
export const TextNoMargin = styled.p`
    margin: 0;
    font-family: ${props => props.fontFamily ? props.fontFamily : "Gotham" };
    font-style: ${props => props.fontStyle ? props.fontStyle : "normal" };
    font-weight: ${props => props.fontWeight ? props.fontWeight : "normal" };
    font-size: ${props => props.fontSize ? props.fontSize : "12px" };
    line-height: ${props => props.lineHeight ? props.lineHeight : "18px" };
    color: ${props => props.color ? props.color : "white" };
    cursor: ${props => props.cursor ? props.cursor : "default" };
    text-decoration: ${props => props.textDecoration ? props.textDecoration : "" };
`;