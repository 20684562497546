import React from "react";
import * as S from "./styles";
import { GreenCheckMark } from "../../../assets/svg";

const TournamentStatus = ({ status, message, steps, isPaid }) => {
  const tournamentStatusStep = status;

  const changeStatusColor = item => {
    if (item === tournamentStatusStep && isPaid !== true) {
      return "#201366";
    }
    if (steps.indexOf(item) < steps.indexOf(tournamentStatusStep)) {
      return "#6C47FF";
    }

    if ((status === "FINISHED" || status === "ARCHIVED") && isPaid === true) {
      return "#6C47FF";
    }
    return "#34278B";
  };

  const changeSpacerColor = item => {
    if (steps.indexOf(item) < steps.indexOf(tournamentStatusStep)) {
      return "#6C47FF";
    }

    if ((status === "FINISHED" || status === "ARCHIVED") && isPaid === true) {
      return "#6C47FF";
    }
    return "#34278B";
  };

  const getDateStyles = item => {
    const commonStyles = {
      padding: "5px 10px 5px 10px",
      borderRadius: "4px",
      color: "#fff",
      width: "fit-content",
      fontSize: "9px",
      textTransform: "uppercase",
    };
    if (steps.indexOf(item) < steps.indexOf(tournamentStatusStep)) {
      return {
        ...commonStyles,
        background: "#6C47FF",
      };
    }

    if ((status === "FINISHED" || status === "ARCHIVED") && isPaid === true) {
      return {
        ...commonStyles,
        background: "#6C47FF",
      };
    }
    return {
      ...commonStyles,
      background: "transparent",
      border: "2px solid #34278B",
    };
  };

  const changeBorderColor = item => {
    if (item === tournamentStatusStep) {
      return "#6C47FF";
    }
    if (steps.indexOf(item) < steps.indexOf(tournamentStatusStep)) {
      return "#6C47FF";
    }

    if ((status === "FINISHED" || status === "ARCHIVED") && isPaid === true) {
      return "#6C47FF";
    }

    return "#34278B";
  };

  return (
    <S.Container>
      <S.MainContainer>
        {steps.map((item, index) => (
          <S.MainWrapper>
            <S.Detail>
              <S.StatusCircle bgColor={changeStatusColor(item)} borderColor={changeBorderColor(item)}>
                {/* based on the current step add a check, if it's the last step set all the previous ones with a checkmark, and if it's the last step and isPaid === true set the last step with the checkmark, otherwise display the step number */}
                {item === tournamentStatusStep && isPaid !== true ? (
                  <GreenCheckMark />
                ) : steps.indexOf(item) < steps.indexOf(tournamentStatusStep) ? (
                  <GreenCheckMark />
                ) : (status === "FINISHED" || status === "ARCHIVED") && isPaid === true ? (
                  <GreenCheckMark />
                ) : (
                  <S.StatusCircleText>{index + 1}</S.StatusCircleText>
                )}
              </S.StatusCircle>
              {index !== steps.length - 1 ? <S.Spacer bgColor={changeSpacerColor(item)} /> : null}
            </S.Detail>
            <S.TextContainer>
              <S.StatusText>{message[item].title}</S.StatusText>
              <S.DescriptionText>{message[item].description}</S.DescriptionText>
              <S.DateContainer>
                <div style={getDateStyles(item)}>{message[item].dateText}</div>
              </S.DateContainer>
            </S.TextContainer>
          </S.MainWrapper>
        ))}
      </S.MainContainer>
    </S.Container>
  );
};

export default TournamentStatus;
