import { useGetSecHeaders } from "./SecurityHeaders";
import { BE_API_BASE_URL } from "../constants/env";
import useCallAxios from "./useAxiosCall";
/*
  ~ What it does? ~

  Gets user's notifications

*/

export default function useNotifMethods() {
  const url = `${BE_API_BASE_URL}userNotifications`;
  const headers = useGetSecHeaders();

  const {callAxiosFunc} = useCallAxios();

  const fetchNotifications = async params => {
    const { unread = false, startAfter = 0, endBefore = 0, qty = 5 } = params;
    const resp = await callAxiosFunc({
      method: "GET",
      url: `${url}?unread=${unread}&startAfter=${startAfter}&endBefore=${endBefore}&qty=${qty}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
    });
    if (resp && resp.err) {
      console.log("err: ", resp.err);
      return { total: 0, messages: [] };
    }
    return resp.data;
  };

  const markAsRead = async id => {
    const resp = await callAxiosFunc({
      method: "PUT",
      url: `${url}/readed/${id}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
    });
    if (!(resp.data && resp.data.error)) {
      console.log(resp.err);
    } else {
      console.log(resp.data);
    }
  };

  const markAsUnread = async id => {
    const resp = await callAxiosFunc({
      method: "PUT",
      url: `${url}/unreaded/${id}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
    });
    if (resp && resp.err) {
      console.log(resp.err);
    }
  };

  const deleteNotification = async id => {
    const resp = await callAxiosFunc({
      method: "PUT",
      url: `${url}/deleted/${id}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
    });
    if (resp && resp.err) {
      console.log(resp.err);
    }
  };

  return { markAsRead, markAsUnread, deleteNotification, fetchNotifications };
}
