import React from "react";
import { useState } from "react";
import { BE_API_BASE_URL } from "../../../../constants/env";
import { toast } from "react-toastify";
import { useGetSecHeaders } from "../../../../hooks/SecurityHeaders";
import useCallAxios from "../../../../hooks/useAxiosCall";
import Avatar from "../../common/Avatar";
import { Button } from "../../common/Button";
import { SpinnerLoader } from "../../common/SpinningLoader";
import * as S from "./styles";
import { CheckMarkIcon, ColoredCheckMark, GreenCheckMark, WarningIcon } from "../../../../assets/svg";
import { ENDPOINT } from "../../../../constants/endpoints";
import { TOAST_OPTIONS } from "../../../../constants";
import Flex from "../../common/Flex";

const InviteRow = props => {
  const { teamId, preventInvite = false, handlePlayersListState, ...player } = props;

  const { thumbnail, nick, reference } = player || {};

  const headers = useGetSecHeaders();
  const { callAxiosFunc } = useCallAxios();

  const [loadingInvite, setLoadingInvite] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);

  const options = {
    autoClose: 3000,
    hideProgressBar: true,
    position: toast.POSITION.TOP_RIGHT,
    pauseOnHover: true,
    style: {
      color: "#D1CCEF",
      background: "#3A2F81",
      width: "204px",
      borderRadius: "6px",
      float: "right",
      marginRight: "63px",
    },
    closeButton: false,
  };

  async function invitePlayer() {
    if (preventInvite) {
      toast(<div>You cannot add any more players. Your team is full</div>, TOAST_OPTIONS);
      return;
    }
    setLoadingInvite(true);
    const response = await callAxiosFunc({
      method: "POST",
      url: `${ENDPOINT.JOIN_TEAM}/${teamId}`,
      headers: headers.token ? JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }) : null,
      body: JSON.stringify({ userId: reference }),
    });
    setLoadingInvite(false);
    if (response.err) {
      console.log(response.err);
      toast.error(
        <Flex alignItems="center">
          <WarningIcon style={{ height: "40px", width: "40px" }} />{" "}
          {response?.err?.response?.data?.message
            ? response?.err?.response?.data?.message?.[0]
            : "Something went wrong. Please try again!"}
        </Flex>,
        TOAST_OPTIONS,
      );
    } else {
      setInviteSent(true);
      handlePlayersListState("sent-request", { ...player, userId: player.reference });
    }
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-between", paddingRight: "12px" }}>
      <S.PlayerRow>
        <Avatar src={thumbnail} size="25px" />
        <S.Text>{nick}</S.Text>
      </S.PlayerRow>
      <div style={{ display: "flex", justifyContent: "center", width: "82px" }}>
        {loadingInvite ? (
          <SpinnerLoader />
        ) : inviteSent ? (
          <div>
            <GreenCheckMark />
          </div>
        ) : (
          <Button background="#6C47FF" borderRadius="18.5px" width="82px" onClick={invitePlayer}>
            Invite
          </Button>
        )}
      </div>
    </div>
  );
};

export default InviteRow;
