import React from "react";
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import NotificationBlock from "./NotificationBlock";
import * as S from "./styles";

const NotificationsList = ({ list = [], selectedNotifications, setSelectedNotifications }) => {
  return list && list.length > 0 ? (
    <S.NotificationListContainer>
      {list.map(item => (
        <NotificationBlock
          key={item.id}
          data={item}
          selectedNotifications={selectedNotifications}
          setSelectedNotifications={setSelectedNotifications}
        />
      ))}
    </S.NotificationListContainer>
  ) : (
    <S.NoNotificationBlock>No Notifications found</S.NoNotificationBlock>
  );
};

export default NotificationsList;
