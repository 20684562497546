import { useState } from "react";
import { callAxios } from "./Axios";
import { ENDPOINT } from "../constants/endpoints";
import { PLATFORM } from "../constants/tracker";
import { useGetSecHeaders } from "./SecurityHeaders";
import useCallAxios from "./useAxiosCall";

/*
  Get address information from the cloud
*/
export async function isActivisionIdAvailable(platform, user) {
  const hackPlatform = platform === PLATFORM.bnet ? PLATFORM.atvi : platform;
  const response = await callAxios({
    method: "get",
    url: `${ENDPOINT.VALIDATE_ID}?id=${encodeURIComponent(user)}&platform=${hackPlatform}`,
    headers: JSON.stringify({ accept: "*/*" }),
  });

  if (response && (response.err || response.data.isAuthorized === false)) {
    return false;
  }
  return true;
}

/*
  This hook is used to validate an activision user ID.
  return:
        0 - stand by
        1 - User not Found
        2 - User Found -> Need to change permissions
        3 - User Found & Valid
*/
export const useValidateActivisionUserId = () => {
  const [userIsValid, setUserIsValid] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const headers = useGetSecHeaders();

  const { callAxiosFunc } = useCallAxios();

  const fetchData = async (platform, user) => {
    const hackPlatform = platform === PLATFORM.bnet ? PLATFORM.atvi : platform;
    const response = await callAxiosFunc({
      method: "get",
      url: `${ENDPOINT.VALIDATE_ID}?id=${encodeURIComponent(user)}&platform=${hackPlatform}`,
      headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
    });

    setIsLoading(false);
    if (response && (response.err || response.data.isAuthorized === false)) {
      setUserIsValid(1);
      return;
    }
    if (response && !response.err && response.data.isAuthorized !== false) {
      setUserIsValid(3);
    }
  };
  const checkUser = (platform, user) => {
    setUserIsValid(0);
    setIsLoading(true);
    fetchData(platform, user);
  };
  return { userIsValid, isLoading, checkUser };
};
