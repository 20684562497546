import React from "react";
import { FirstPlaceTrophy, Star, Wallet } from "../../../../assets/svg";
import { TextNoMargin } from "../../common/Text";
import * as S from "./styles";

const map = {
  POOLS_PLAYED: {
    name: "Tournaments Played",
    icon: <Star />,
  },
  POOLS_WON: {
    name: "Tournaments Won",
    icon: <FirstPlaceTrophy />,
  },
  MONEY_EARNED: {
    name: "Money Earned",
    icon: <Wallet />,
  },
};

const StatsCard = ({ label, value }) => {
  return (
    <>
      {map[label] ? (
        <S.CardContainer>
          <S.FlexLeft>{map[label]["icon"]}</S.FlexLeft>
          <S.FlexRight>
            <TextNoMargin fontSize="24px" color="#fff" style={{ marginBottom: "12px" }}>
              {label === "MONEY_EARNED" && "$"}
              {value}
            </TextNoMargin>
            <TextNoMargin color="#877ADD" fontSize="14px">
              {map[label]["name"]}
            </TextNoMargin>
          </S.FlexRight>
        </S.CardContainer>
      ) : null}
    </>
  );
};

export default StatsCard;
