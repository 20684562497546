import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import * as crypto from "crypto-js";

import * as S from "./styles";
import Flex from "../../common/Flex";
import { Text, TextNoMargin } from "../../common/Text";
import { Button } from "../../common/Button";
import Container from "../../common/Container";
import { useRecoilState } from "recoil";
import { sessionState } from "../../../../state/globalState";
import { SpinnerLoader } from "../../common/SpinningLoader";
import { BE_API_BASE_URL, SEED } from "../../../../constants/env";
import { useGetSecHeaders } from "../../../../hooks";
import useCallAxios from "../../../../hooks/useAxiosCall";
import { BackArrow, WhatsappIcon } from "../../../../assets/svg";
import { setUserInLocalStorage } from "../../../../helpers/localStorage";

const codeInputStyles = {
  background: "#342788",
  width: "50px",
  height: "50px",
  borderRadius: "6px",
  border: "2px solid #6C47FF",
  fontSize: "24px",
  color: "#fff",
};

const CodeInput = ({ joinBtnRef, toggleModal, setWhatsappNumber, isLoadingNumberVerif, setCodeSent, isVerifyingCode, setIsVerifyingCode }) => {
  const headers = useGetSecHeaders();
  const { callAxiosFunc } = useCallAxios();

  const [code, setCode] = useState("");


  const [session, setSession] = useRecoilState(sessionState);

  const [resendCounter, setResendCounter] = useState(30);
  const [verificationErrMsg, setVerificationErrMsg] = useState(false);

  useEffect(() => {
    const timerFunction = () => {
      setResendCounter(prev => {
        if (prev <= 0 || isLoadingNumberVerif) return prev;
        return prev - 1;
      });
    };
    const timer = setInterval(timerFunction, 1000);

    return () => clearTimeout(timer);
  }, [isLoadingNumberVerif]);

  async function handleResend() {
    await setWhatsappNumber();
    setResendCounter(30);
  }

  async function verifyCode() {
    setVerificationErrMsg("")
    const url = `${BE_API_BASE_URL}/user/validateWspCode`;
    try {
      setIsVerifyingCode(true);
      const data = await callAxiosFunc({
        method: "PUT",
        url,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
        body: JSON.stringify({ verificationCode: code }),
      });
      if (data?.err) {
        setVerificationErrMsg(data?.err?.response?.data?.message || "Something went wrong");
      } else {
        const updatedSession = {
          ...session,
          user: {
            ...session.user,
            whatsApp: {
              ...session?.user?.whatsApp,
              isVerified: true,
            },
          },
        };
        setSession(updatedSession);
        const encryptedState = crypto.AES.encrypt(JSON.stringify(updatedSession), SEED).toString(); 
        setUserInLocalStorage(encryptedState);

        if (joinBtnRef && joinBtnRef.current) {
          joinBtnRef.current.click();
          toggleModal();
        }
      }
    } catch (err) {
      setVerificationErrMsg("Something went wrong. Please try again.");
    } finally {
      setIsVerifyingCode(false);
    }
  }

  return (
    <Flex direction="column" alignItems="center">
      <S.BackButton
        type="button"
        onClick={() => {
          setCodeSent(false);
        }}
      >
        <BackArrow />
      </S.BackButton>
      <Text fontSize="20px">Verify WhatsApp Number</Text>

      <Flex alignItems="center" columnGap="8">
        <Text color="#877ADD" fontSize="16px">
          Enter the code sent to your WhatsApp
        </Text>{" "}
        <WhatsappIcon style={{ marginBottom: "16px" }} />
      </Flex>

      <Flex direction="column" alignItems="center" rowGap="12">
        {/* <PhoneInput
          country={"uy"}
          value={value}
          onChange={val => setValue(val)}
          inputClass="input_class"
          buttonClass="btn_class"
          dropdownClass="dropdown_class custom-scroll"
          searchClass="search_class"
          disableSearchIcon
          enableSearch
        /> */}
        <OtpInput
          value={code}
          onChange={val => {
            setCode(val?.toUpperCase());
          }}
          numInputs={5}
          renderSeparator={<span style={{ margin: "0 5px" }} />}
          renderInput={props => <input {...props} />}
          inputStyle={codeInputStyles}
        />
        {verificationErrMsg && (
          <Text fontSize="12px" color="#ED6140">
            {verificationErrMsg}
          </Text>
        )}
      </Flex>
      <Container marginTop={verificationErrMsg ? "45px" : "90px"} flex justifyContent="center">
        <Flex rowGap="12" direction="column" alignItems="center">
          <Button
            borderRadius="30px"
            width="150px"
            height="40px"
            background="#6c47ff"
            disabled={code?.length < 5 || isVerifyingCode}
            onClick={verifyCode}
            id="verify-btn"
          >
            {isVerifyingCode ? <SpinnerLoader /> : "Verify"}
          </Button>
          <S.ResendCodeBlock>
            <TextNoMargin fontSize="12px" color="#be7eff">
              Didn't receive the code?
            </TextNoMargin>
            <S.ResendCodeBtn disabled={resendCounter > 0 || isLoadingNumberVerif} onClick={handleResend}>
              Send again {resendCounter > 0 && !isLoadingNumberVerif && `in ${resendCounter} seconds`}
            </S.ResendCodeBtn>
            {isLoadingNumberVerif && <SpinnerLoader style={{ height: "15px", width: "15px" }} />}
          </S.ResendCodeBlock>
        </Flex>
      </Container>
    </Flex>
  );
};

export default CodeInput;
