import React from "react";
import styled from "styled-components";
import { Skeleton } from "antd";
import Avatar from "../../common/Avatar";
import { PurpleArrow } from "../../../../assets/svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const GroupTitle = styled.h3`
  margin: 0;
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;

const LeaderboardUrl = styled.a`
  color: #d7cfe8;
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  text-decoration-line: underline;
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 275px);
  grid-gap: 10px;
`;

const Card = styled.div`
  width: 275px;
  height: 59px;
  border-radius: 10px;
  padding: 10px;
  background-color: #342788;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const PlayerPosition = styled.span`
  color: #877add;
  font-size: 12px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;

const Nickname = styled.span`
  color: #ffffff;
  font-size: 13px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;

export const GroupSection = ({
  isLoading,
  selectedSectionData = [],
  extraInfo,
  setActiveButton,
  getStageData,
  stageId,
}) => {
  return (
    <Container>
      {selectedSectionData?.map((group, index) => {
        return (
          <GroupWrapper>
            <Skeleton loading={isLoading} title paragraph={{ rows: 0 }} active>
              <TitleWrapper>
                <GroupTitle>{extraInfo?.groups?.[index]?.name}</GroupTitle>
                <PurpleArrow />
                <LeaderboardUrl
                  onClick={() => {
                    getStageData("leaderboard", stageId, extraInfo?.groups?.[index]?.id);
                    setActiveButton("leaderboard");
                  }}
                >
                  Go to Leaderboard
                </LeaderboardUrl>
              </TitleWrapper>
            </Skeleton>
            <CardWrapper>
              {group?.groupPlayers?.map((player, index) => {
                return (
                  <Card>
                    <Skeleton loading={isLoading} title={false} paragraph={{ rows: 1 }} active>
                      <PlayerPosition>{index + 1}</PlayerPosition>
                      <Avatar src={player.avatarURL} size="35px" />
                      <Nickname>{player.nick}</Nickname>
                    </Skeleton>
                  </Card>
                );
              })}
            </CardWrapper>
          </GroupWrapper>
        );
      })}
    </Container>
  );
};
