import React from "react";
import styled from "styled-components";
import { UnderMaintenance } from "../assets/svg";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #272054;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleMaintenance = styled.h1`
  padding-top: 60px;
  font-size: 30px;
  font-weight: 325;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
  color: #d1ccef;
`;

const MaintancesContent = styled.p`
  width: 313px;
  font-size: 14px;
  font-weight: 325;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #877add;
`;

const MaintancesView = () => {
  return (
    <Container>
      <UnderMaintenance />
      <TitleMaintenance>We are under maintenance</TitleMaintenance>
      <MaintancesContent>We are currently performing scheduled maintenance. We will be back shortly.</MaintancesContent>
    </Container>
  );
};

export default MaintancesView;
