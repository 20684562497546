import React, { useState, useEffect } from "react";
import { Tooltip } from "antd";

import * as S from "./styles";
import Avatar from "../../common/Avatar";
import {
  GlobeIcon,
  TeamIcon,
  AddIcon,
  TeamDefaultAvatar,
  NewDefaultAvatar,
  EyeOpen,
  EyeClose,
} from "../../../../assets/svg";
import newDefaultAvatar from "../../../../assets/svg/new-default-avatar.svg";
import { breakpoints } from "../../../../themes";
import callAxios from "../../../../hooks/Axios";
import { ENDPOINT } from "../../../../constants/endpoints";
import { useGetSecHeaders } from "../../../../hooks";
import { SpinnerLoader } from "../../common/SpinningLoader";
import { Button, IconButton } from "../../common/Button";
import { useMedia } from "use-media";
import { truncateWithDots } from "../../../../helpers/utils";

const FindTeamContainer = ({ teamData, isDisabled }) => {
  const headers = useGetSecHeaders();
  const acceptedPlayers = teamData?.players?.filter(player => player.status === "accepted");
  const [isLoading, setIsLoading] = useState(false);
  const [requestSent, setRequestSent] = useState(false);

  const isMobile = useMedia({ maxWidth: "768px" });
  const isSmallScreen = useMedia({ maxWidth: "425px" });

  useEffect(() => {
    // get each element of isDisabled array and get the ID of the team
    // if the ID of the team is equal to the ID of the teamData, then set requestSent to true
    isDisabled.forEach(team => {
      if (teamData?.id === team?.id) {
        setRequestSent(true);
      }
    });
  }, [isDisabled]);

  const requestToJoin = async () => {
    setIsLoading(true);
    try {
      const response = await callAxios({
        method: "POST",
        url: `${ENDPOINT.JOIN_TEAM}/${teamData?.id}`,
        headers: JSON.stringify({ accept: "*/*", Authorization: headers.encryptedHeader }),
      });
      const status = response?.data?.status;
      if (status === "pending-accept") {
        setRequestSent(true);
      }
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const getLanguageName = language => {
    switch (language) {
      case "en":
        return "English";
      case "es":
        return "Spanish";
      case "fr":
        return "French";
      case "de":
        return "German";
      case "pt":
        return "Portuguese";
      case "tl":
        return "Tagalog";
      default:
        return "English";
    }
  };

  return (
    <S.Container>
      <S.Flex direction="column" style={{ width: "100%" }}>
        <S.Flex columnGap={21}>
          {window.screen.width < 365 ? null : isMobile ? (
            <S.ImageContainer>
              {teamData?.thumbnail ? (
                <Avatar size={isMobile ? "40px" : "60px"} src={teamData?.thumbnail} />
              ) : (
                <TeamDefaultAvatar
                  style={isMobile ? { width: "40px", height: "40px" } : { width: "60px", height: "60px" }}
                />
              )}
            </S.ImageContainer>
          ) : (
            <S.ImageContainer>
              {teamData?.thumbnail ? <Avatar size="60px" src={teamData?.thumbnail} /> : <TeamDefaultAvatar />}
            </S.ImageContainer>
          )}

          <S.Flex direction="column" style={{ width: "100%" }}>
            <S.TitleRow>
              <S.Flex columnGap={26} alignItems="center">
                <S.Link href={`/#/team-profile/${teamData?.id}`} rel="noreferrer">
                  <S.TeamName>
                    {isMobile ? truncateWithDots(teamData?.name, isSmallScreen ? 15 : 30) : teamData?.name}
                  </S.TeamName>
                </S.Link>
                <Tooltip
                  color="#7265C8"
                  placement="top"
                  title={`This team has ${acceptedPlayers?.length} players of ${teamData?.usersLimit}`}
                >
                  <S.Text color="#A498ED">
                    {acceptedPlayers?.length}/<span style={{ color: "#fff" }}>{teamData?.usersLimit}</span>
                  </S.Text>
                </Tooltip>
              </S.Flex>

              {/*  TODO: Uncomment these buttons once API becomes available  */}
              {/* {teamData?.lookingForPlayers ? (
                <IconButton style={{ padding: "0" }}>
                  <Tooltip title="Actively looking for new players" placement="top" color="#7265C8">
                    <EyeOpen />
                  </Tooltip>
                </IconButton>
              ) : (
                <IconButton style={{ padding: "0" }}>
                  <Tooltip title="Currently not looking for new players" placement="top" color="#7265C8">
                    <EyeClose />
                  </Tooltip>
                </IconButton>
              )} */}
            </S.TitleRow>
            <S.Flex columnGap={21}>
              <S.Flex columnGap={10} alignItems="center">
                <TeamIcon />
                <S.Text color="#A498ED">{acceptedPlayers?.length} players</S.Text>
              </S.Flex>
              <S.Flex columnGap={10} alignItems="center">
                <GlobeIcon />
                <S.Text color="#A498ED">{getLanguageName(teamData?.language)}</S.Text>
              </S.Flex>
            </S.Flex>
            {/* <S.Flex style={{ paddingTop: "20px" }}>
              {window.screen.width > 768 ? (
                <S.Text size={14} weight={325}>
                  {teamData?.description || "No description"}
                </S.Text>
              ) : (
                <div>
                  <S.Text size={14} weight={325}>
                    {teamData?.description || "No description"}
                  </S.Text>
                </div>
              )}
            </S.Flex> */}
            <S.Flex alignItems="center" justifyContent="space-between" style={{ paddingTop: "20px", gap: "20px" }}>
              <S.PlayerAvatarsContainer>
                <>
                  {acceptedPlayers?.slice(0, isSmallScreen ? 2 : 5).map((player, index) => (
                    <Tooltip
                      title={player?.nick}
                      placement="bottom"
                      color="#7265C8"
                      showArrow={false}
                      overlayInnerStyle={{ fontSize: "12px", borderRadius: "4px" }}
                      transitionName=""
                    >
                      <span>
                        {!player?.thumbnail ? (
                          <Avatar size="40px" src={newDefaultAvatar} key={index} />
                        ) : (
                          <Avatar size="40px" src={player?.thumbnail} key={index} />
                        )}
                      </span>
                    </Tooltip>
                  ))}
                  {acceptedPlayers?.length > (isSmallScreen ? 2 : 5) ? (
                    <S.PlayerCountCircle>+{acceptedPlayers?.length - (isSmallScreen ? 2 : 5)}</S.PlayerCountCircle>
                  ) : null}
                </>
              </S.PlayerAvatarsContainer>
              <S.Flex>
                {teamData.visibility !== "hidden" && (
                  <Button
                    height="37px"
                    borderRadius="20px"
                    gap="10px"
                    background={
                      requestSent === true ? "#4635B6" : "linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%)"
                    }
                    onClick={requestToJoin}
                    disabled={headers.token === null || requestSent === true}
                    requestSent={requestSent}
                  >
                    {requestSent === true && isLoading === false ? (
                      "Request Sent"
                    ) : isLoading === true ? (
                      <SpinnerLoader />
                    ) : isMobile ? (
                      <>Request</>
                    ) : (
                      <>
                        <AddIcon /> <>Request to Join</>
                      </>
                    )}
                  </Button>
                )}
              </S.Flex>
            </S.Flex>
          </S.Flex>
        </S.Flex>
      </S.Flex>
    </S.Container>
  );
};

export default FindTeamContainer;
