import styled from "styled-components";

// export const OrderText = styled.p`
//   font-size: 16px;
//   margin-left: 8px;
//   margin-bottom: 0;
//   color: ${props => (props.color ? props.color : "white")};
//   margin-left: ${props => (props.win ? "8px" : "20px")};
// `;

export const TextContainer = styled.div`
  text-align: center;
  color: #fff;
  margin: auto;
  span {
    color: #877ADD;
    font-size: 16px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

`;
