import styled from "styled-components";

export const RequestRowContainer = styled.div`
  height: 88.79px;
  background: #312c83;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  padding-left: 44px;
  justify-content: space-between;

  @media (max-width: 1200px) {
    padding-left: 14px;
  }

  :hover {
    background-color: #3f2faa;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 1rem;
  justify-content: end;
  padding-right: 35px;
`;

export const AvatarContainer = styled.div`
  display: block;

  @media screen and (max-width: 370px) {
    display: none;
  }
`;
