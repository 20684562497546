import styled from "styled-components";

export const MainWrapper = styled.div`
  width: auto;
  height: auto;
  position: relative;
`;

export const Input = styled.input`
  border: none;
  height: ${props => (props.height ? props.height : "47px")};
  width: ${props => (props.width ? props.width : "200px")};
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  background-color: #3f2faa;
  color: #ffffff;
  border-radius: 6px;
  padding-left: 11px;

  ::-webkit-input-placeholder {
    color: #a498ed;
    font-size: 14px;
    font-style: normal;
    font-weight: 325;
    line-height: normal;
  }

  :-ms-input-placeholder {
    color: #a498ed;
    font-size: 14px;
    font-style: normal;
    font-weight: 325;
    line-height: normal;
  }

  &:focus + label {
    color: #a498ed;
    font-size: 14px;
    font-style: normal;
    font-weight: 325;
    line-height: normal;
  }

  &:focus {
    border: 1.5px solid #7a6ddb;
    outline: solid 2px;
    outline-color: #877add50;
    ::-webkit-input-placeholder {
      color: #a498ed;
    }
    :-ms-input-placeholder {
      color: #a498ed;
    }
  }

  &:required:valid + label {
    font-size: 10px;
    color: #877add;
    top: -15px;
    left: 5px;
    background: #342788;
    padding: 0px 5px 0px 5px;
  }
`;

export const Label = styled.label`
  color: #a498ed;
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  position: absolute;
  top: 25%;
  left: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  pointer-events: none;
`;
