import React from "react";
import styled from "styled-components";

const PushNotificationButton = styled.button`
  width: 150px;
  height: 40px;
  background: linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%);
  border-radius: 30px;
  border: none;
  margin-right: 20px;
  cursor: pointer;
`;

const PushNotificationsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #42397e;
  border-radius: 10px;
  height: 200px;
`;

function PushNotifications() {
  const startTimer = () => {
    const time = 20;
    const img = "/logo512.png";
    let count = time;
    const timer = setInterval(() => {
      count -= 1;
      if (count === 0) {
        clearInterval(timer);
      }
      if (Notification.permission !== "denied") {
        Notification.requestPermission().then(permission => {
          if (permission === "granted" && count === 10) {
            const newNoti = new Notification("Blast", { body: "Event is about to start", icon: img });
          } else if (Notification.permission === "granted" && count === 0) {
            const newNoti = new Notification("Blast", { body: "Event is starting", icon: img });
          }
        });
      }
      document.getElementById("timer").innerHTML = `${count} seconds`;
    }, 1000);
  };

  return (
    <PushNotificationsContainer>
      <PushNotificationButton onClick={startTimer}>Click Me</PushNotificationButton>
      <span id="timer">Timer no initialized</span>
    </PushNotificationsContainer>
  );
}

export default PushNotifications;
