// THIS PAGE WILL BE USED TO BE EMBEDDED TO THE iframes OF OTHER SITES

/* eslint-disable import/no-named-as-default */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { Redirect, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUserAddress } from "eth-hooks";
// eslint-disable-next-line import/no-named-as-default-member
import TournamentHeader from "../components/v2/Tournament/Header/TournamentHeader";
import Loader from "../components/v2/Loader";
import Rules from "../components/v2/Tournament/Rules";
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import LeaderBoard from "../components/v2/Tournament/LeaderBoard/LeaderBoard";
import useTournamentData from "../hooks/TournamentData";
import StartingTime from "../components/v2/Tournament/StartingTime";
import { checkUserInPoolLeaderboard } from "../helpers/utils";
import { useUserInformation } from "../hooks/UserInformation";
import { useWallet } from "../providers/WalletProvider";
import { useWindowSize } from "../hooks/useWindowSize";
import MobileSocialNav from "../components/v2/common/MobileSocialNav";
import { ClockPending } from "../assets/svg";
import BracketsPage from "./Brackets/BracketsPage";
import BracketsPlayers from "./BracketsPlayers/BracketsPlayers";
import { Tooltip } from "antd";
import CountdownTimer from "../components/v2/Tournament/CountdownTimer";
import { breakpoints } from "../themes";
import { useGetSecHeaders } from "../hooks";

// export const Loader = styled.span`
//     width: 17px;
//     height: 17px;
//     border: 3px solid #877ADD;
//     border-bottom-color: #3A2F81;
//     border-radius: 50%;
//     display: inline-block;
//     box-sizing: border-box;
//     animation: rotation 1s linear infinite;
//     @keyframes rotation {
//     0% {
//         transform: rotate(0deg);
//     }
//     100% {
//         transform: rotate(360deg);
//     }
// `;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SectionContainer = styled.div`
  margin-top: 57px;
  // margin-bottom: 200px;
  margin-left: 45px;
  margin-right: 67px;
  max-width: 1200px;

  @media (max-width: 768px) {
    margin-left: 25px;
    margin-right: 25px;
    padding-bottom: 120px;
  }
  @media (min-width: 1570px) {
    // margin: 107px auto 0;
  }

  ${breakpoints.down(breakpoints.laptop)} {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const CollectingDataBanner = styled.div`
  height: 130px;
  background: #7265c8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 26px;
`;

const CollectingDataBannerText = styled.div`
  font-size: 18px;
  color: #fff;
  margin-left: 23px;
  line-height: 24px;
`;

const NavButtonContainer = styled.div`
  display: flex;
  margin-bottom: 43px;
  gap: 16px;
`;

const NavButton = styled.button`
  background: #302862;
  outline: none;
  border: none;
  width: ${props => (props.isMobile ? "90.74px" : "103px")};
  color: ${props => (props.active ? "#fff" : "#8F7FB0")};
  padding: 8px;
  border-radius: 30px;
  background: ${props => (props.active ? "#3F338C" : "#302862")};
  cursor: pointer;
`;

// eslint-disable-next-line no-unused-vars
const TournamentDetails = ({ data, isError, userId, userAddress, fetchTournamentData }) => {
  const [poolStarted, setPoolStarted] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [bracketsCounterData, setBracketsCounterData] = useState(null); // Data for timer banner in BEST_OF_X tournament modes
  const [selectedSection, setSelectedSection] = useState("");

  const checkUserInPool = (data, userId) => {
    const showButtonResult =
      (data.poolStatus === "NOT_STARTED" || data.poolStatus === "RUNNING") &&
      !checkUserInPoolLeaderboard(userId, data.leaderboard);
    setShowButton(showButtonResult);
  };

  const { isMobile } = useWindowSize();

  const sectionsMap = ["Brackets", "Players", /*"Winners",*/ "Rules"];

  useEffect(() => {
    if (data && data.startDate) setPoolStarted(moment(Date.now()).isAfter(data.startDate));
    if (data && data.poolStatus && userId) checkUserInPool(data, userId);

    if (
      data &&
      (data.poolStatus === "FINISHED" ||
        data.poolStatus === "BRACKETS_CREATED" ||
        data.poolStatus === "RUNNING" ||
        data.poolStatus === "FINISHED_COLLECTING_DATA" ||
        data.poolStatus === "ARCHIVED")
    ) {
      setSelectedSection("Brackets");
    } else {
      setSelectedSection("Players");
    }

    // Setting countdown timer data for brackets mode
    if (data) {
      if (data.poolStatus === "NOT_STARTED") {
        setBracketsCounterData({
          endDate: data.registrationStartDate,
          text: "Registration opens in",
        });
      } else if (data.poolStatus === "JOINING") {
        setBracketsCounterData({
          endDate: data.registrationEndDate,
          text: "Registration closes in",
        });
      } else if (data.poolStatus === "PROCESSING_REGISTERED_USERS") {
        setBracketsCounterData({
          endDate: data.startDate,
          text: "Tournament starts in",
        });
      } else if (data.poolStatus === "RUNNING") {
        setBracketsCounterData({
          endDate: data.endDate,
          text: "Tournament ending in",
        });
      }
    }
  }, [data, userId]);

  return isError ? (
    <LoaderWrapper>
      <Redirect to="/404" />
    </LoaderWrapper>
  ) : (
    data && (
      <>
        {/* <TournamentHeader
          tournamentData={{
            ...data,
            isRunning: data.poolStatus === "RUNNING",
            isFinished:
              data.poolStatus === "FINISHED_COLLECTING_DATA" ||
              data.poolStatus === "FINISHED" ||
              data.poolStatus === "ARCHIVED",
            image: data.assets.poolDetailHeaderFront,
            bgImage: data.assets.poolDetailHeaderBack,
            currency: data.poolPrize,
          }}
          poolStarted={poolStarted}
          showButton={showButton}
          fetchTournamentData={fetchTournamentData}
        /> */}
        {/* {data?.poolMode == "LEADERBOARD" ? (
          <>
            {(data.poolStatus === "NOT_STARTED" || data.poolStatus === "RUNNING") && (
              <StartingTime
                endDate={data.endDate}
                startDate={data.startDate}
                status={data.poolStatus}
                isMobile={isMobile}
              />
            )}
          </>
        ) : (
          <div>{bracketsCounterData && <CountdownTimer {...bracketsCounterData} isMobile={isMobile} />}</div>
        )} */}
        {/* {data.poolStatus === "FINISHED_COLLECTING_DATA" && (
          <CollectingDataBanner>
            <ClockPending />
            <CollectingDataBannerText>
              The tournament is over, we&apos;re processing the results and prizes
            </CollectingDataBannerText>
          </CollectingDataBanner>
        )} */}
        {data.poolMode === "LEADERBOARD" || data.poolMode === "ROUND_ROBIN" ? (
          <>
            <LeaderBoard
              isFinished={data.poolStatus === "FINISHED" || data.poolStatus === "ARCHIVED"}
              isFinishedCollectingData={data.poolStatus === "FINISHED_COLLECTING_DATA"}
              leaderBoardData={data.leaderboard}
              // isMobile={isMobile}
              gamePlatform={data.platform}
              koinsLeaderboardHeaders={data?.displayData?.sort((a, b) => a?.dataOrder- b?.dataOrder)}
              gameId={data.game}
              winnersPrize={data.winnersPrize}
              status={data.poolStatus}
              id={data.id}
            />
            <Rules rules={data.rules} />
          </>
        ) : (
          <div>
            {/* <NavButtonContainer>
              {sectionsMap.map(item => (
                <Tooltip
                  title={
                    (item == "Bracket" || item == "Winners") && (data?.poolStatus === "NOT_STARTED" || data?.poolStatus === "JOINING")
                      ? `${item} will be available once the tournament starts`
                      : ""
                  }
                  color="#7265C8"
                  overlayStyle={{ maxWidth: "fit-content", fontSize: "11px", padding: "11px 8px", borderRadius: "3px" }}
                >
                  <NavButton
                    onClick={() => {
                      if (
                        (item == "Bracket" || item == "Winners") &&
                        (data?.poolStatus !== "RUNNING" && data.poolStatus !== "FINISHED" && data.poolStatus !== "FINISHED_COLLECTING_DATA")
                      ) {
                        return;
                      }
                      setSelectedSection(item);
                    }}
                    active={selectedSection == item}
                    isMobile={isMobile}
                  >
                    {item}
                  </NavButton>
                </Tooltip>
              ))}
            </NavButtonContainer> */}
            <div>
              {/* {selectedSection === "Bracket" && (
                <div style={{ minHeight: "50vh" }}>
                  <BracketsPage
                    tournamentData={data}
                    tournamentId={data.id}
                    userPositionInfo={data.userPositionInfo}
                    playersQty={data?.gameDetails?.tournamentPlayersQty}
                  />
                </div>
              )}
              {selectedSection === "Rules" && <Rules rules={data.rules} />}
              {selectedSection === "Players" && <BracketsPlayers players={data.leaderboard} />} */}
              <div style={{ minHeight: "50vh" }}>
                <BracketsPage
                  tournamentData={data}
                  tournamentId={data.id}
                  userPositionInfo={data.userPositionInfo}
                  playersQty={data?.gameDetails?.tournamentPlayersQty}
                />
              </div>
            </div>
          </div>
        )}
        <MobileSocialNav />
      </>
    )
  );
};
export default function TournamentBracketsView() {
  const { id } = useParams();
  const { data, isLoading, isError, fetchTournamentData } = useTournamentData(id);
  const { injectedProvider } = useWallet();
  const address = useUserAddress(injectedProvider);
  const headers = useGetSecHeaders();

  return (
    <>
      <SectionContainer>
        {isLoading ? (
          <LoaderWrapper>
            <Loader animation="rotate" width={120} />
          </LoaderWrapper>
        ) : (
          <TournamentDetails
            data={data}
            fetchTournamentData={fetchTournamentData}
            isError={isError}
            userAddress={address}
            userId={headers.userId}
          />
        )}
      </SectionContainer>
    </>
  );
}
